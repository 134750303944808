import { Flex, FormControl, FormLabel, useToast } from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import { humanize } from "inflection";
import React, { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { ServiceUser, ServiceUserCreateParams } from "../../../../api/service_users";
import { UserSelect } from "../../../Users/shared/UserSelect";
interface AssignUsersToServiceModalProps<T extends ServiceUser> {
  serviceId: number;
  isOpen: boolean;
  onClose: () => void;
  serviceUsers: T[];
  onSubmit: (params: ServiceUserCreateParams) => Promise<boolean>;
  isLoading: boolean;
  typeOfUser: string;
}

type AssignUsersToServiceFormValuesType = {
  recipients: number[];
};

const defaultFormValues: AssignUsersToServiceFormValuesType = {
  recipients: [],
};

export function AssignUsersToServiceModal<T extends ServiceUser>({
  serviceId,
  isOpen,
  onClose,
  serviceUsers,
  onSubmit,
  isLoading,
  typeOfUser,
}: AssignUsersToServiceModalProps<T>) {
  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: defaultFormValues,
  });
  const toast = useToast();

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  const onSubmitHandler = useCallback(
    handleSubmit((data) => {
      if (data.recipients) {
        onSubmit({
          service_id: serviceId,
          user_ids: data.recipients,
        }).then(() => {
          toast({
            title: "Recipients updated",
            status: "success",
          });
          handleClose();
        });
      }
    }),
    [serviceId, onSubmit, handleClose, toast]
  );

  return (
    <FormModal
      size={"md"}
      title={`Default ${humanize(typeOfUser)}`}
      isOpen={isOpen}
      submitButtonColorSchema="teal"
      onClose={handleClose}
      isLoading={isLoading}
      handleSubmit={onSubmitHandler}>
      <Flex direction="column" w="100%" gap={4}>
        <Controller
          name="recipients"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>
                {typeOfUser === "assigned"
                  ? "Who should fulfill this service?"
                  : "Who should receive notifications for all requests?"}
              </FormLabel>
              <UserSelect
                setValue={(value) => setValue("recipients", value)}
                alreadyAddedUsers={serviceUsers.map((user) => user.user)}
              />
            </FormControl>
          )}
        />
      </Flex>
    </FormModal>
  );
}

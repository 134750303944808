import {
  Button,
  Flex,
  Text,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Box,
  Card,
  CardBody,
  HStack,
  Switch,
  ButtonGroup,
  Alert,
  AlertIcon,
  Progress,
} from "@chakra-ui/react";
import { CollectionTable, Column, EmptyState, RecordLink } from "@sciencecorp/helix-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  BudgetableTypeData,
  BudgetIndexItemData,
  useBulkAllocateBudgets,
} from "../../../../../api/budget";
import { MoneyText } from "../../../../MoneyText";
import { Money } from "../../../../../helpers/Money";
import { MoneyInput } from "../../../../MoneyInput";
import { CiMoneyBill } from "react-icons/ci";
import {
  BudgetItemShowData,
  invalidateBudgetItem,
  useBulkAllocateBudgetItems,
} from "../../../../../api/budget_items";
import { BackgroundTask, useTaskPoller } from "../../../../../api/background_tasks";
import { invalidateBudgetGroup } from "../../../../../api/budget_groups";

const steps = [
  { title: "Teams", value: "Team" },
  { title: "Projects", value: "Project" },
  { title: "Capital Equipment", value: "CapitalEquipment" },
  { title: "Users", value: "User" },
];

type BaseManageFundingAllocationProps = {
  fundingAmount: Money;
  currency: string;
};

type ManageGroupFundingAllocation = BaseManageFundingAllocationProps & {
  location: "group";
  data: BudgetIndexItemData[];
};

type ManageItemFundingAllocation = BaseManageFundingAllocationProps & {
  location: "items";
  data: BudgetItemShowData[];
};

type UnifiedDataType = {
  id: number;
  type: "group" | "item";
  name: string;
  app_href: string;
  originalData: BudgetIndexItemData | BudgetItemShowData;
};

export const ManageFundingAllocation = (
  props: ManageGroupFundingAllocation | ManageItemFundingAllocation
) => {
  const { fundingAmount, currency, data, location } = props;

  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();
  const [budgetableType, setBudgetableType] = useState<BudgetableTypeData>("Team");
  const [budgetAllocations, setBudgetAllocations] = useState<
    { id: number; allocated_amount: Money }[]
  >([]);

  const [overFunding, setOverFunding] = useState<boolean>(false);

  const [equalAllocation, setEqualAllocation] = useState(false);
  const [equalAllocationAmount, setEqualAllocationAmount] = useState<Money>(Money.zero(currency));
  const [progress, setProgress] = useState<number>(0);

  const onTaskUpdate = useCallback((task: BackgroundTask) => {
    setProgress(task.progress || 0);
  }, []);
  const invalidateBudgetItemFn = invalidateBudgetItem();
  const invalidateBudgetGroupFn = invalidateBudgetGroup();

  const { mutateAsync: updateBudgetAllocations } = useBulkAllocateBudgets();
  const { pollTask: startBudgetAllocationPolling, isLoading: isLoadingBudgetUpdate } =
    useTaskPoller({ onUpdate: onTaskUpdate });

  const { mutateAsync: updateBudgetItemAllocations } = useBulkAllocateBudgetItems();
  const { pollTask: startBudgetItemAllocationPolling, isLoading: isLoadingBudgetItemUpdate } =
    useTaskPoller({ onUpdate: onTaskUpdate });

  useEffect(() => {
    if (data) {
      setBudgetAllocations(
        data.map((result) => ({
          id: result.id,
          allocated_amount: result.allocated_amount,
        }))
      );
    }
  }, [data]);

  const filteredData: UnifiedDataType[] = useMemo(
    () =>
      location === "group"
        ? props.data
            .filter((result) => result.budgetable.type === budgetableType)
            .map((result) => ({
              id: result.id,
              type: "group",
              name: result.budgetable.name,
              app_href: result.budgetable.app_href,
              originalData: result,
            }))
        : props.data
            .filter((result) => result.is_archived === false)
            .map((result) => ({
              id: result.id,
              type: "item",
              name: result.name,
              app_href: result.app_href,
              originalData: result,
            })),
    [props.data, budgetableType, location]
  );

  const { activeStep, setActiveStep } = useSteps({ index: 0, count: steps.length });

  const sumBudgetAllocations = useMemo(
    () =>
      Money.sum(
        Money.zero(currency),
        ...budgetAllocations.map((budget) => budget.allocated_amount)
      ) || Money.zero(currency),
    [budgetAllocations]
  );

  const percentageAllocated = useMemo(
    () =>
      sumBudgetAllocations.cents
        .div(fundingAmount.cents.toNumber() === 0 ? 1 : fundingAmount.cents)
        .times(100)
        .toNumber()
        .toFixed(),
    [sumBudgetAllocations, fundingAmount]
  );

  const handleAllocationAmountChange = (id: number, amount: Money) => {
    setBudgetAllocations((prev) => {
      const updatedBudgets = prev.map((budget) => {
        if (budget.id === id) {
          return { ...budget, allocated_amount: amount };
        }
        return budget;
      });
      return updatedBudgets;
    });
  };

  useEffect(() => {
    if (sumBudgetAllocations.gt(fundingAmount)) {
      setOverFunding(true);
    } else {
      setOverFunding(false);
    }
  }, [sumBudgetAllocations]);

  const handleEqualAllocationAmountChange = (value: Money) => {
    setEqualAllocationAmount(value);
    const updatedBudgetAllocations = [...budgetAllocations];

    const equalAmount = value.div(filteredData.length || 1);
    filteredData?.forEach((result) => {
      const existingIndex = updatedBudgetAllocations.findIndex(
        (allocation) => allocation.id === result.id
      );

      const newAllocation = {
        id: result.id,
        allocated_amount: equalAmount,
      };

      if (existingIndex !== -1) {
        updatedBudgetAllocations[existingIndex] = newAllocation;
      } else {
        updatedBudgetAllocations.push(newAllocation);
      }
    });

    setBudgetAllocations(updatedBudgetAllocations);
  };

  const handleStepChange = (index: number) => {
    setActiveStep(index);
    setBudgetableType(steps[index].value as BudgetableTypeData);
    setEqualAllocation(false);
    setEqualAllocationAmount(Money.zero(currency));
  };

  const handleClose = () => {
    setProgress(0);
    onClose();
    setBudgetAllocations([]);
    setBudgetableType("Team");
    setActiveStep(0);
  };

  const handleSubmit = useCallback(() => {
    if (location === "group") {
      updateBudgetAllocations({ budgets: budgetAllocations })
        .then((task) => startBudgetAllocationPolling(task.id))
        .then(() => {
          handleClose();
          invalidateBudgetGroupFn();
          invalidateBudgetItemFn();
          toast({
            title: "Allocations Updated",
            description: "Budget allocations have been successfully updated.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error) => {
          toast({
            title: "Error allocating funding.",
            description: error instanceof Error ? error.message : "An error occurred",
            status: "error",
            duration: 5000,
          });
        });
    } else {
      updateBudgetItemAllocations({ budget_items: budgetAllocations })
        .then((task) => startBudgetItemAllocationPolling(task.id))
        .then(() => {
          handleClose();
          invalidateBudgetGroupFn();
          invalidateBudgetItemFn();
          toast({
            title: "Allocations Updated",
            description: "Budget item allocations have been successfully updated.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error) => {
          toast({
            title: "Error allocating funding.",
            description: error instanceof Error ? error.message : "An error occurred",
            status: "error",
            duration: 5000,
          });
        });
    }
  }, [location, budgetAllocations]);

  const columns: Column<UnifiedDataType>[] = useMemo(
    () => [
      {
        label: "Name",
        render: (item) => (
          <RecordLink maxWidth="15ch" identifier={item.name} link={item.app_href} />
        ),
      },
      {
        label: "Allocation Amount",
        render: (item) => {
          const allocationAmount = budgetAllocations.find(
            (ele) => ele.id === item.id
          )?.allocated_amount;

          return (
            <MoneyInput
              data-testid={`${item.name}-money-input-amount`}
              isDisabled={equalAllocation}
              size="sm"
              value={allocationAmount}
              onChange={(value) => handleAllocationAmountChange(item.id, value)}
            />
          );
        },
      },
      {
        label: "%",
        render: (item) => {
          const allocationAmount =
            budgetAllocations.find((ele) => ele.id === item.id)?.allocated_amount ||
            Money.zero(currency);

          return (
            <Text fontWeight="semibold">
              {allocationAmount.cents
                .div(fundingAmount.cents.toNumber() === 0 ? 1 : fundingAmount.cents)
                .times(100)
                .toNumber()
                .toFixed(0)}
              %
            </Text>
          );
        },
      },
    ],
    [data, equalAllocationAmount, equalAllocation, budgetAllocations, location]
  );

  return (
    <>
      <Button colorScheme="teal" variant="outline" onClick={onOpen} leftIcon={<CiMoneyBill />}>
        Manage Allocation
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        size="2xl"
        closeOnOverlayClick={false}
        data-testid="manage-funding-allocation-modal">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Manage Allocation
            {location === "group" && (
              <Stepper index={activeStep} colorScheme="teal" py={3} size={["xs", "xs", "sm"]}>
                {steps.map((step, index) => (
                  <Step key={index} onClick={() => handleStepChange(index)}>
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>
                    <Box
                      flexShrink="0"
                      color={activeStep >= index ? "teal.500" : "gray.500"}
                      display={["none", "none", "inline"]}>
                      <StepTitle>{step.title}</StepTitle>
                    </Box>
                    <StepSeparator />
                  </Step>
                ))}
              </Stepper>
            )}
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Flex direction="column" gap={4}>
              <Card w="100%">
                <CardBody display="flex" flexDir="column" alignItems="center">
                  <Text fontSize="2xl" fontWeight="bold">
                    <MoneyText
                      as="span"
                      fontWeight="bold"
                      fontSize="2xl"
                      money={sumBudgetAllocations}
                      formatOptions={{ compact: "never" }}
                    />{" "}
                    /
                    <MoneyText
                      as="span"
                      fontSize="md"
                      fontWeight="bold"
                      money={fundingAmount}
                      formatOptions={{ compact: "never" }}
                    />
                  </Text>
                  <Text fontSize="sm" color="gray.500">
                    {percentageAllocated}%
                  </Text>
                  <Text fontSize="sm" color="gray.500">
                    Allocated
                  </Text>
                  <HStack>
                    <MoneyText
                      as="span"
                      fontSize="sm"
                      fontWeight="bold"
                      money={fundingAmount.subtract(sumBudgetAllocations)}
                      formatOptions={{ compact: "never" }}
                    />
                    <Text fontSize="sm" color="gray.500">
                      remaining
                    </Text>
                  </HStack>
                </CardBody>
              </Card>

              <Flex direction="column">
                <HStack justify="space-between" w="100%">
                  <HStack>
                    <Text fontSize="sm">Distribute Equally</Text>
                    <Switch
                      isChecked={equalAllocation}
                      onChange={(e) => {
                        setEqualAllocation(e.target.checked);
                        if (!e.target.checked) {
                          setEqualAllocationAmount(Money.zero(currency));
                        }
                      }}
                      colorScheme="teal"
                    />
                  </HStack>

                  <MoneyInput
                    isDisabled={!equalAllocation}
                    size="sm"
                    value={equalAllocationAmount}
                    onChange={(value) => handleEqualAllocationAmountChange(value)}
                  />
                  <Text fontWeight="medium" fontSize="sm">
                    {equalAllocationAmount.cents
                      .div(fundingAmount.cents.toNumber() === 0 ? 1 : fundingAmount.cents)
                      .times(100)
                      .toNumber()
                      .toFixed(0)}
                    %
                  </Text>
                </HStack>
              </Flex>
              {overFunding && (
                <Alert status="error">
                  <AlertIcon />
                  Your allocation exceeds the available amount. Please reduce the allocation to
                  continue.
                </Alert>
              )}
              <Box maxH={64} overflow="auto">
                {filteredData.length || 0 > 0 ? (
                  <CollectionTable items={filteredData} columns={columns} />
                ) : (
                  <EmptyState size="2xs" title={`No ${budgetableType} found`} />
                )}
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                variant="ghost"
                onClick={() =>
                  setBudgetAllocations(
                    data.map((result) => ({
                      id: result.id,
                      allocated_amount: result.allocated_amount,
                    }))
                  )
                }>
                Discard Changes
              </Button>

              {location === "group" ? (
                <>
                  {activeStep !== 0 && (
                    <Button
                      onClick={() => {
                        handleStepChange(activeStep - 1);
                      }}>
                      Back
                    </Button>
                  )}
                  {activeStep === 3 ? (
                    <Flex align="center" gap={3}>
                      <Button
                        colorScheme="teal"
                        isDisabled={overFunding}
                        isLoading={isLoadingBudgetUpdate}
                        onClick={handleSubmit}>
                        Submit
                      </Button>
                      {isLoadingBudgetUpdate ? <Text>{progress.toFixed()}%</Text> : null}
                    </Flex>
                  ) : (
                    <Button colorScheme="teal" onClick={() => handleStepChange(activeStep + 1)}>
                      Next
                    </Button>
                  )}
                </>
              ) : (
                <Flex align="center" gap={3}>
                  <Button
                    colorScheme="teal"
                    isDisabled={overFunding}
                    isLoading={isLoadingBudgetItemUpdate}
                    onClick={handleSubmit}>
                    Submit
                  </Button>

                  {isLoadingBudgetItemUpdate ? <Text>{progress.toFixed()}%</Text> : null}
                </Flex>
              )}
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

import { ConfirmationButton, Header } from "@sciencecorp/helix-components";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTeamQuery } from "../../../../api/team";
import { useGetServiceQuery, useUpdateService } from "../../../../api/services";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Stack,
  Tag,
  VStack,
  Text,
  useColorModeValue,
  Center,
  AlertIcon,
  AlertTitle,
  Alert,
  AlertDescription,
  Input,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { FormFieldSubmission } from "../ServiceRequest/FormFieldSubmission";

export const PreviewPage = () => {
  const { team_id, id } = useParams();
  const { data: service } = useGetServiceQuery(Number(id));
  const textColor = useColorModeValue("gray.500", "gray.200");
  const { control } = useForm({});
  const template = service?.form.draft_template || service?.form.active_template;
  const { mutateAsync: updateService } = useUpdateService(service?.id);
  const navigate = useNavigate();

  return (
    <>
      {service?.status === "draft" && (
        <Alert status="warning" alignItems="center" marginBottom={4}>
          <AlertIcon />
          <VStack alignItems="start" gap={0}>
            <AlertTitle>Draft Mode</AlertTitle>
            <AlertDescription>
              This service is currently in draft mode. Once you submit, it will be visible to all
              users.
            </AlertDescription>
          </VStack>
        </Alert>
      )}
      <Header
        title={"Preview"}
        crumbs={[
          {
            url: "/teams",
            label: "Teams",
          },
          {
            url: `/teams/${team_id}/services`,
            label: service?.team?.name || "Team Name",
          },
          {
            url: `/teams/${team_id}/services/${id}`,
            label: service?.name || "Service Name",
          },
        ]}
        crumbsColor="teal"
        actions={[
          <Button
            onClick={() => {
              navigate(`/teams/${team_id}/services/${id}`);
            }}>
            Back to Editing
          </Button>,
          service?.status !== "active" ? (
            <ConfirmationButton
              variant="Button"
              label="Publish Service"
              confirmationButtonLabel="Submit"
              children="By submitting this service, you are making it active and accessible to other Science employees."
              colorScheme="teal"
              onConfirm={() => {
                updateService({
                  id: service?.id,
                  status: "active",
                }).then(() => {
                  navigate(`/teams/${team_id}/services/${id}`);
                });
              }}
            />
          ) : (
            <></>
          ),
        ]}
      />
      {service && (
        <Center>
          <Flex
            direction="column"
            gap={4}
            width={{ base: "100%", md: "50%" }}
            p={7}
            bgColor={useColorModeValue("white", "gray.700")}
            border="1px"
            borderColor={useColorModeValue("gray.200", "gray.700")}
            borderRadius="md"
            boxShadow="md">
            <Stack direction="column" spacing={1}>
              <HStack>
                <Heading size="sm" fontWeight="medium">
                  {service.name}
                </Heading>
                <Tag colorScheme="teal">by {service.team.name}</Tag>
              </HStack>
              <Text>
                {service.description.split("\n").map((line, i) => (
                  <Text key={i} fontSize={["xs", "sm"]} color={textColor} fontWeight="medium">
                    {line}
                  </Text>
                ))}
              </Text>
            </Stack>
            <Text fontSize="sm" color={textColor}>
              <Text as="span" color="red.500">
                *
              </Text>{" "}
              All fields marked with the asterisk are required
            </Text>
            <VStack width="100%" gap={4}>
              {template?.fields.map((field, idx) => (
                <FormFieldSubmission
                  key={`field-${field.id}-${idx}`}
                  formField={field}
                  control={control}
                  isPreview={true}
                />
              ))}
              <Stack width="100%" direction={{ base: "column", md: "row" }}>
                <Controller
                  name="spending_authority"
                  control={control}
                  render={({ field }) => (
                    <FormControl isRequired={service.spending_authority_required}>
                      <FormLabel>
                        Spending Authority{" "}
                        {!service.spending_authority_required && (
                          <Box as="span" opacity="0.7" fontWeight="normal">
                            (optional)
                          </Box>
                        )}
                      </FormLabel>
                      <Input {...field} placeholder="Select" disabled />
                    </FormControl>
                  )}
                />
                <Controller
                  name="site"
                  control={control}
                  render={({ field }) => (
                    <FormControl isRequired={true}>
                      <FormLabel>Site</FormLabel>
                      <Input {...field} placeholder="Select" disabled />
                    </FormControl>
                  )}
                />
              </Stack>
            </VStack>
          </Flex>
        </Center>
      )}
    </>
  );
};

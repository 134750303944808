import { useMutation } from "@tanstack/react-query";
import { api } from ".";
import { ServiceUserCreateParams } from "./service_users";
import { invalidateService } from "./services";

export const SERVICE_SUBSCRIBED_USER_BASE_URL = "/service_subscribed_users";

export const bulkCreateServiceSubscribedUser = async (
  service_subscribed_users: ServiceUserCreateParams
) => {
  const result = await api.post(`${SERVICE_SUBSCRIBED_USER_BASE_URL}/bulk`, {
    service_subscribed_users,
  });
  return result.status === 201;
};

export const deleteServiceSubscribedUser = async (id: number) => {
  await api.delete(`${SERVICE_SUBSCRIBED_USER_BASE_URL}/${id}`);
};

/** hooks */

export const useBulkCreateServiceSubscribedUser = (id?: number) => {
  return useMutation({
    mutationFn: bulkCreateServiceSubscribedUser,
    onSuccess: invalidateService(id),
  });
};

export const useDeleteServiceSubscribedUser = (id?: number) => {
  return useMutation({
    mutationFn: deleteServiceSubscribedUser,
    onSuccess: invalidateService(id),
  });
};

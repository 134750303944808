import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { zodParse } from "./zodParse";
import { invalidateService } from "./services";
import { serviceSubtaskUserSchema } from "./service_subtask_user";
export const SERVICE_SUBTASKS_BASE_URL = "service_subtasks";

export const serviceSubtaskSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().nullable(),
  order: z.number(),
  service_id: z.number(),
});

export const serviceSubtaskSchemaWithUsers = serviceSubtaskSchema.extend({
  service_subtask_users: z.array(z.lazy(() => serviceSubtaskUserSchema)),
});

export const serviceSubtaskReorderSchema = z.object({
  id: z.number(),
  order: z.array(z.number()),
});

export type ServiceSubtaskData = z.infer<typeof serviceSubtaskSchema>;
export type ServiceSubtaskCreateData = Omit<ServiceSubtaskData, "id">;
export type ServiceSubtaskWithUsers = z.infer<typeof serviceSubtaskSchemaWithUsers>;
export type ServiceSubtaskReorderData = z.infer<typeof serviceSubtaskReorderSchema>;
//api queries

export const createServiceSubtask = async (service_subtask: ServiceSubtaskCreateData) => {
  const result = await api.post(`${SERVICE_SUBTASKS_BASE_URL}`, { service_subtask });
  return zodParse(serviceSubtaskSchemaWithUsers, result.data);
};

export const reorderServiceSubtask = async (service_subtask: ServiceSubtaskReorderData) => {
  await api.put(`${SERVICE_SUBTASKS_BASE_URL}/${service_subtask.id}/reorder`, {
    order: service_subtask.order,
  });
};

export const updateServiceSubtask = async (service_subtask: Partial<ServiceSubtaskData>) => {
  const result = await api.put(`${SERVICE_SUBTASKS_BASE_URL}/${service_subtask.id}`, {
    service_subtask,
  });
  return zodParse(serviceSubtaskSchema, result.data);
};

export const deleteServiceSubtask = async (id: number) => {
  await api.delete(`${SERVICE_SUBTASKS_BASE_URL}/${id}`);
};

export const invalidateServiceSubtask = (id?: number, subquery?: string) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [SERVICE_SUBTASKS_BASE_URL];
  if (id) queryKey.push(id);
  if (subquery) queryKey.push(subquery);

  return async () => {
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
  };
};

export const useCreateServiceSubtask = (id?: number) => {
  return useMutation({
    mutationFn: createServiceSubtask,
    onSuccess: invalidateService(id),
  });
};

export const useUpdateServiceSubtask = (id?: number) => {
  return useMutation({
    mutationFn: updateServiceSubtask,
    onSuccess: invalidateService(id),
  });
};

export const useReorderServiceSubtask = (id?: number) => {
  return useMutation({
    mutationFn: reorderServiceSubtask,
    onSuccess: invalidateService(id),
  });
};

export const useDeleteServiceSubtask = (id?: number) => {
  return useMutation({
    mutationFn: deleteServiceSubtask,
    onSuccess: invalidateService(id),
  });
};

import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Tag,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import { DateTime } from "luxon";
import { default as React, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useBudgetItemSummary } from "../../../api/budget_items";
import { MinimalSpendingAuthority, useGetSpendingAuthority } from "../../../api/spending_authority";
import { useCreateSpendingAuthorityRequest } from "../../../api/spending_authority_change_requests";
import { useCurrency } from "../../../contexts/CurrencyContext";
import { Money } from "../../../helpers/Money";
import { MoneyText } from "../../MoneyText";
import { SpendingAuthoritySelect } from "../SpendingAuthoritySelectTree";
import { WarnIfSpendingAuthorityIrrelevant } from "../../shared/WarnIfSpendingAuthorityIrrelevant";

type FormValues = {
  spending_authority: MinimalSpendingAuthority | null;
};
export type ChangeSpendingAuthorityModalProps = {
  isOpen: boolean;
  onClose: () => void;
  pastEditableState: boolean;
  expenditure_id: number;
  expenditure_type: string;
  spending_authority: MinimalSpendingAuthority | null;
  currentUser: any;
  amount: Money;
  updateExpenditure: (expenditure: any) => Promise<void>;
  invalidateExpenditure: () => Promise<void>;
};

export const ChangeSpendingAuthorityModal = ({
  isOpen,
  onClose,
  pastEditableState,
  expenditure_id,
  expenditure_type,
  spending_authority,
  currentUser,
  amount,
  updateExpenditure,
  invalidateExpenditure,
}: ChangeSpendingAuthorityModalProps) => {
  const currency = useCurrency();
  const { setValue, control, watch, reset } = useForm<FormValues>({});
  const formValues = watch();
  useEffect(() => {
    if (spending_authority && !formValues.spending_authority) {
      setValue("spending_authority", spending_authority);
    }
  }, [spending_authority]);

  const { mutateAsync: createSpendingAuthorityRequest } =
    useCreateSpendingAuthorityRequest(expenditure_id);

  const handleClose = () => {
    reset({ spending_authority: null });
    onClose();
  };

  const spendingAuthorityId = formValues?.spending_authority?.id;
  const spendingAuthorityType = formValues?.spending_authority?.type;
  const spendingAuthorityQuery = useGetSpendingAuthority(
    spendingAuthorityId,
    spendingAuthorityType
  );

  const rootSpendingAuthority =
    (spendingAuthorityQuery && spendingAuthorityQuery.data?.rootSpendingAuthority) || null;

  const rootSAIsBudgetItem = rootSpendingAuthority?.type === "BudgetItem";

  const { data: budgetItem } = useBudgetItemSummary(
    rootSAIsBudgetItem ? rootSpendingAuthority?.id : null,
    DateTime.now().endOf("year")
  );
  const handleSubmitForm = (spendingAuthority) => {
    if (!pastEditableState) {
      updateExpenditure({
        id: expenditure_id,
        spending_authority_type: spendingAuthority.type,
        spending_authority_id: spendingAuthority.id,
      }).then(() => invalidateExpenditure());
    } else {
      createSpendingAuthorityRequest({
        user_id: currentUser.id,
        id: expenditure_id,
        spending_authority_type: spendingAuthority.type,
        spending_authority_id: spendingAuthority.id,
        expenditure_id: expenditure_id,
        expenditure_type: expenditure_type,
      }).then(() => invalidateExpenditure());
    }
    handleClose();
  };

  const lighterDefaultGray = useColorModeValue("gray.600", "auto");
  const defaultGray = useColorModeValue("gray.600", "auto");
  const negNumColor = useColorModeValue("red.400", "red.400");

  const fundedAmount =
    budgetItem?.budget_group_summary?.funding_amount ||
    budgetItem?.funding_amount ||
    Money.zero(currency);
  const spentAmount =
    budgetItem?.budget_group_summary?.spent_amount ||
    budgetItem?.spent_amount ||
    Money.zero(currency);
  const committedAmount =
    budgetItem?.budget_group_summary?.committed_amount ||
    budgetItem?.committed_amount ||
    Money.zero(currency);
  const budgetRemaining =
    budgetItem?.budget_group_summary?.remaining_amount ||
    budgetItem?.remaining_amount ||
    Money.zero(currency);

  const amountRemainingIfApproved = rootSAIsBudgetItem
    ? budgetRemaining.subtract(amount)
    : Money.zero(currency);
  const approvalWouldOverdrawBudget = rootSAIsBudgetItem && amountRemainingIfApproved.isNeg();

  const disableSubmitButton =
    !formValues.spending_authority ||
    !budgetRemaining.isPos() ||
    (spending_authority?.id === formValues.spending_authority?.id &&
      spending_authority?.type === formValues.spending_authority?.type);

  return (
    <FormModal
      title="Change Spending Authority"
      submitButtonColorSchema="teal"
      submitButtonTitle="Submit"
      size="lg"
      isOpen={isOpen}
      onClose={handleClose}
      handleSubmit={() => handleSubmitForm(formValues.spending_authority)}
      submitButtonDisabled={rootSpendingAuthority?.type !== "RevenueItem" && disableSubmitButton}>
      <Controller
        name="spending_authority"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FormControl>
            <FormLabel>
              {expenditure_type === "Purchase"
                ? "Choose Spending Authority to Reallocate to"
                : "Update Spending Authority to"}
            </FormLabel>
            <SpendingAuthoritySelect
              key={"spending_authority"}
              onChange={(spendingAuthority) => {
                if (!!spendingAuthority) {
                  setValue("spending_authority", spendingAuthority);
                }
              }}
              spendingAuthority={formValues.spending_authority}
            />
            <WarnIfSpendingAuthorityIrrelevant id={field.value?.id} type={field.value?.type} />
          </FormControl>
        )}
      />
      {amount.gt(budgetItem?.remaining_amount ?? Money.zero(currency)) && (
        <HStack alignItems={"center"} marginTop={"-2"} width={"100%"}>
          <InfoOutlineIcon boxSize={"3"} color={lighterDefaultGray} />
          <Text fontSize={"xs"} color={lighterDefaultGray}>
            Only adequately funded spending authorities will be accepted.
          </Text>
        </HStack>
      )}
      {rootSpendingAuthority?.type === "BudgetItem" ? (
        <Flex direction="column" w="100%" p={2}>
          <HStack justify="space-between" w="100%">
            <Text color={defaultGray}>Funded:</Text>
            <MoneyText
              fontWeight="semibold"
              money={fundedAmount}
              formatOptions={{ compact: "never" }}
            />
          </HStack>
          <HStack justify="space-between" w="100%">
            <Text color={defaultGray}>Spent:</Text>
            <Text color={negNumColor} fontWeight="semibold">
              - <MoneyText as="span" money={spentAmount} formatOptions={{ compact: "never" }} />
            </Text>
          </HStack>
          <HStack justify="space-between" w="100%">
            <Text color={defaultGray}>Committed to Pay:</Text>
            <Text color={negNumColor} fontWeight="semibold">
              - <MoneyText as="span" money={committedAmount} formatOptions={{ compact: "never" }} />
            </Text>
          </HStack>
          <HStack justify="space-between" w="100%">
            <Text color={defaultGray}>This Expenditure:</Text>
            <Text color={negNumColor} fontWeight="semibold">
              -
              <MoneyText as="span" money={amount} formatOptions={{ compact: "never" }} />
            </Text>
          </HStack>

          <Divider />
          <HStack justify="space-between" w="100%">
            <Text color={defaultGray}>Remaining after change:</Text>
            <Text color={approvalWouldOverdrawBudget ? negNumColor : defaultGray}>
              <MoneyText
                fontWeight="semibold"
                fontSize="xl"
                money={amountRemainingIfApproved}
                formatOptions={{ compact: "never" }}
              />
            </Text>
          </HStack>
        </Flex>
      ) : (
        rootSpendingAuthority?.type === "RevenueItem" && (
          <Tag colorScheme="green" width={"100%"}>
            Attributed to Revenue Item
          </Tag>
        )
      )}

      {pastEditableState && !(approvalWouldOverdrawBudget && spendingAuthorityId) && (
        <Alert p={2} mt={4}>
          <AlertIcon margin={"3"} />
          <AlertDescription>
            This request will require the approval of the team/project lead responsible for the new
            spending authority.
          </AlertDescription>
        </Alert>
      )}

      {approvalWouldOverdrawBudget && spendingAuthorityId && (
        <Alert p={2} mt={4} status={"error"}>
          <AlertIcon margin={"3"} />
          <AlertDescription>
            Spending Authority does not have enough funds to cover this expenditure.
          </AlertDescription>
        </Alert>
      )}
    </FormModal>
  );
};

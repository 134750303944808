import React, { useMemo, useState } from "react";

import {
  Collection,
  Select,
  RecordLink,
  useCollection,
  Column,
  Facets,
  buildFacets,
  EmptyState,
} from "@sciencecorp/helix-components";
import { PurchaseFlagIndexItemData, useSearchPurchasesByFlags } from "../../../api/purchase";

import { VStack, HStack, Avatar, Tag, Box, Text, Heading } from "@chakra-ui/react";
import { titleize, humanize } from "inflection";
import { purchaseRequestStatusColor } from "../util";
import { formatDaysToReadable } from ".";

export const FlaggedPurchases = ({ startDate, endDate }) => {
  const { pagination, onPagination, filters, order, onOrder, facets, onFacets } = useCollection();
  const [statusFilter, setStatusFilter] = useState("awaiting_purchase");
  const { data: purchaseFlags, isLoading } = useSearchPurchasesByFlags({
    term: "*",
    filters,
    aggs: ["source_of_authority_name"],
    pagination,
    order,
    statusFilter,
  });

  const populatedFacets = buildFacets(purchaseFlags?.aggregations || {}, facets);

  const columns: Column<PurchaseFlagIndexItemData>[] = useMemo(
    () => [
      {
        label: "Requests",
        orderOptions: { orderKey: "id" },
        render: (purchase) => (
          <VStack align="start" data-testid={`purchase-link-${purchase.id}`}>
            <RecordLink
              link={`/services/purchasing/${purchase.id}`}
              identifier={`#${purchase.id}`}
              size="small"
            />
          </VStack>
        ),
      },
      {
        label: "Requested By",
        render: (purchase) => (
          <HStack marginTop={["2", "0"]}>
            <Avatar src={purchase.user?.picture_uri} size="sm" />
            <Box>
              <Text fontSize={["xs", "sm"]}>{purchase.user?.name}</Text>
            </Box>
          </HStack>
        ),
      },
      {
        label: "Created",
        orderOptions: { orderKey: "created_at" },
        render: (purchase) => <Text>{purchase.created_at.toFormat("LLL dd yyyy")}</Text>,
      },

      {
        label: "Status",
        render: (purchase) => (
          <VStack align="start" marginTop={["2", "0"]}>
            <Tag
              colorScheme={purchaseRequestStatusColor(purchase.status)}
              flexShrink={0}
              whiteSpace="nowrap"
              size={["sm", "md"]}>
              {titleize(humanize(purchase.status))}
            </Tag>
          </VStack>
        ),
      },
      {
        label: "Time in State",
        render: (purchase) => (
          <VStack align="start">
            <Text fontWeight="bold">{formatDaysToReadable(purchase.time_in_status.time)}</Text>
            <Text color="red.500" fontSize="xs">
              {formatDaysToReadable(purchase.time_in_status.difference)} late
            </Text>
          </VStack>
        ),
      },
      {
        label: "Last Updated",
        render: (purchase) => (
          <Text>
            {humanize(purchase.last_event.event_type)} on{" "}
            {purchase.last_event.created_at.toFormat("LLL dd yyyy")}
          </Text>
        ),
      },
    ],
    [purchaseFlags?.results]
  );

  return (
    <>
      <HStack justify="space-between">
        <VStack align="start">
          <Heading size="md">High Risk Orders</Heading>
          <Text>Active purchase requests outside of normal range</Text>
        </VStack>
        <HStack>
          <Select
            options={[
              { label: "Awaiting Approval", value: "awaiting_approval" },
              { label: "Awaiting Purchase", value: "awaiting_purchase" },
              { label: "Awaiting Delivery", value: "awaiting_delivery" },
            ]}
            value={statusFilter}
            onChange={(value) => setStatusFilter(value.toString())}
          />
          <Facets variant="button" facets={populatedFacets} onChange={onFacets} />
        </HStack>
      </HStack>
      {isLoading || (purchaseFlags?.results.length || 0) > 0 ? (
        <Collection
          items={purchaseFlags?.results || []}
          columns={columns}
          isLoading={isLoading}
          onPagination={onPagination}
          pagination={purchaseFlags?.pagination || pagination}
          onOrder={onOrder}
          order={order}
        />
      ) : (
        <EmptyState title="No Purchases" />
      )}
    </>
  );
};

import { FormModal } from "@sciencecorp/helix-components";
import { Text, Tag, VStack, Circle, useColorModeValue, Badge, HStack } from "@chakra-ui/react";
import React from "react";
import { useGetServiceQuery } from "../../api/services";
import { ArrowDownIcon } from "@chakra-ui/icons";
import { useRequestServiceTypeChange } from "../../api/service_requests";
import { useNavigate } from "react-router-dom";
import { humanize } from "inflection";
export type ChangeFulfillingTeamModalProps = {
  isOpen: boolean;
  onClose: () => void;
  oldServiceId: number;
  newServiceId: number;
  serviceRequestId: number;
};

export const ChangeFulfillingTeamModal = ({
  isOpen,
  onClose,
  oldServiceId,
  newServiceId,
  serviceRequestId,
}: ChangeFulfillingTeamModalProps) => {
  const { data: oldService } = useGetServiceQuery(oldServiceId);
  const { data: newService } = useGetServiceQuery(newServiceId);
  const bgColor = useColorModeValue("teal.50", "teal.100");
  const { mutateAsync: requestServiceTypeChange, isLoading: isRequestingServiceTypeChangeLoading } =
    useRequestServiceTypeChange(oldServiceId);
  const navigate = useNavigate();

  return (
    <FormModal
      title="Confirm Service Type Change"
      closeOnOverlayClick={false}
      submitButtonColorSchema="teal"
      submitButtonTitle="Confirm Change"
      size="2xl"
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isRequestingServiceTypeChangeLoading}
      handleSubmit={() => {
        requestServiceTypeChange({
          service_request_id: serviceRequestId,
          old_service_id: oldServiceId,
          new_service_id: newServiceId,
        }).then((data) => {
          onClose();
          navigate(`/services/requests/${data.id}`);
        });
      }}>
      <Text>
        You are about to change the service type for this request. Doing this will close the current
        request and open a new one. Relevent parties will be notified about these changes.
      </Text>
      <VStack alignItems="flex-start" width="100%" gap={4}>
        <VStack
          width="100%"
          border="1px"
          borderColor="red.200"
          borderRadius="md"
          p={4}
          alignItems="flex-start">
          <Badge border={"1px"} bg="black.100" color="black.500">
            {oldService?.name}
          </Badge>
          <Text>The following information will be discarded:</Text>
          <HStack flexWrap="wrap">
            {oldService?.form.active_template?.fields.map((field) => (
              <Tag key={field.id} colorScheme="red">
                {field.label}
              </Tag>
            ))}
          </HStack>
        </VStack>

        <Circle size="10" bg={bgColor} border="1px" borderColor="teal.200">
          <ArrowDownIcon color="teal.500" />
        </Circle>
        <VStack
          width="100%"
          border="1px"
          borderColor="gray.200"
          borderRadius="md"
          p={4}
          alignItems="flex-start">
          <Badge border={"1px"} bg="black.100" color="black.500">
            {newService?.name}
          </Badge>
          <Text>New Requirements:</Text>
          <HStack flexWrap="wrap">
            {newService?.form.active_template?.fields.map((field) => (
              <Tag key={field.id} colorScheme="orange">
                {field.label}
              </Tag>
            ))}
          </HStack>
        </VStack>
      </VStack>
    </FormModal>
  );
};

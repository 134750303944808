import { UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { indexParams, searchParams } from "./collection_types";
import { zodParse } from "./zodParse";
import { createSearchResponseSchema } from "./shared";
import { TEAMS_BASE_URL } from "./team";

export const TEAM_SERVICE_CATEGORY_BASE_URL = "team_service_categories";

export const teamServiceCategorySchema = z.object({
  id: z.number(),
  name: z.string(),
  team_id: z.number(),
  service_request_count: z.number().nullable(),
});

export type TeamServiceCategory = z.infer<typeof teamServiceCategorySchema>;
export type TeamServiceCategoryParams = Omit<
  z.infer<typeof teamServiceCategorySchema>,
  "id" | "service_request_count"
>;

export const createTeamServiceCategory = (
  params: TeamServiceCategoryParams[]
): Promise<TeamServiceCategory> => {
  return api.post(TEAM_SERVICE_CATEGORY_BASE_URL, { team_service_category: params });
};

export const deleteTeamServiceCategory = (id: number): Promise<void> => {
  return api.delete(`${TEAM_SERVICE_CATEGORY_BASE_URL}/${id}`);
};

export const invalidateTeamServiceCategories = (teamId?: number, subQuery?: string) => {
  const queryClient = useQueryClient();
  const queryKey: any[] = [TEAM_SERVICE_CATEGORY_BASE_URL];
  if (subQuery) queryKey.push(subQuery);
  return async () => {
    await queryClient.invalidateQueries([TEAMS_BASE_URL, teamId]);
    await queryClient.invalidateQueries({
      queryKey: queryKey,
    });
  };
};

export const useCreateTeamServiceCategory = (teamId?: number) => {
  return useMutation({
    mutationFn: createTeamServiceCategory,
    onSuccess: invalidateTeamServiceCategories(teamId),
  });
};

export const useDeleteTeamServiceCategory = (teamId?: number) => {
  return useMutation({
    mutationFn: deleteTeamServiceCategory,
    onSuccess: invalidateTeamServiceCategories(teamId),
  });
};

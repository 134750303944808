import { InfoIcon } from "@chakra-ui/icons";
import { ButtonGroup, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { ConfirmationButton } from "@sciencecorp/helix-components";
import React from "react";
import {
  SubscriptionShowData,
  useApproveSubscription,
  useDeclineSubscription,
} from "../../../../api/subscription";
import { useCurrentUserQuery } from "../../../../api/user";
import { MoneyText } from "../../../MoneyText";
import { Money } from "../../../../helpers/Money";

export type ApprovalFlowProps = {
  subscription: SubscriptionShowData;
};

export const ApprovalFlow = ({ subscription }: ApprovalFlowProps) => {
  const { mutate: approveSubscription } = useApproveSubscription(subscription.id);
  const { mutate: declineSubscription } = useDeclineSubscription(subscription.id);

  return (
    <Stack
      direction={{ base: "column", sm: "row" }}
      justify="space-between"
      align="center"
      p={4}
      border="1px"
      borderRadius="md"
      borderColor={useColorModeValue("orange.200", "gray.600")}
      bg={useColorModeValue("orange.50", "gray.700")}>
      <Text>
        <InfoIcon color={useColorModeValue("orange.500", "gray.200")} mr={2} />
        Action Required
      </Text>

      <ButtonGroup>
        <ConfirmationButton
          variant="Button"
          buttonVariant="outline"
          colorScheme="red"
          label="Decline"
          confirmationButtonLabel="Decline"
          confirmationHeader="Decline Subscription"
          children={`Are you sure you want to decline this subscription?`}
          onConfirm={() => declineSubscription(subscription.id)}
        />
        <ConfirmationButton
          variant="Button"
          colorScheme="teal"
          label="Approve"
          confirmationButtonLabel="Approve"
          confirmationHeader="Approve Subscription"
          onConfirm={() => approveSubscription(subscription.id)}>
          <Text>
            Are you sure you want to approve the total amount of{" "}
            <MoneyText
              as={"span"}
              money={Money.min(
                subscription.max_amount_per_payment.times(subscription.payments_per_interval),
                subscription.max_amount_per_interval
              )}
              formatOptions={{ compact: "never" }}
            />{" "}
            per {subscription.interval === "monthly" ? "month" : "year"} for this subscription?
          </Text>
        </ConfirmationButton>
      </ButtonGroup>
    </Stack>
  );
};

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  PurchasePaymentShowData,
  useAcknowledgePurchasePayments,
} from "../../../../api/purchase_payment";
import { MoneyText } from "../../../MoneyText";
import { CollectionTable, FormModal } from "@sciencecorp/helix-components";
import { humanize } from "inflection";

type AcknowledgePaymentsProps = {
  isOpen: boolean;
  onClose: () => void;
  contractPayments?: PurchasePaymentShowData[];
  contractId: number;
  setIsLoadingAcknowledgePayments: (isLoading: boolean) => void;
};

export const AcknowledgePayments = ({
  isOpen,
  onClose,
  contractPayments,
  contractId,
  setIsLoadingAcknowledgePayments,
}: AcknowledgePaymentsProps) => {
  const payments = contractPayments?.filter((payment) => !payment.acknowledged_at);
  const { mutateAsync: acknowledgePurchasePayments, isLoading } =
    useAcknowledgePurchasePayments(contractId);
  const columns = useMemo(
    () => [
      {
        label: "Amount",
        render: (contractPayment: PurchasePaymentShowData) => (
          <MoneyText
            money={contractPayment.amount}
            formatOptions={{
              compact: "never",
            }}
          />
        ),
      },
      {
        label: "Payment Type",
        render: (contractPayment: PurchasePaymentShowData) => (
          <Text>{humanize(contractPayment.payment_type)}</Text>
        ),
      },
      {
        label: "Due Date",
        render: (contractPayment: PurchasePaymentShowData) => (
          <Text>{contractPayment.due_date ? contractPayment.due_date : "-"}</Text>
        ),
      },
      {
        label: "Paid At",
        render: (contractPayment: PurchasePaymentShowData) => (
          <Text>
            {contractPayment.paid_at ? contractPayment.paid_at.toFormat("MM-dd-yyyy") : "-"}
          </Text>
        ),
      },
    ],
    [contractPayments]
  );

  const handleAcknowledge = () => {
    const paymentIds = payments?.map((payment) => payment.id);
    setIsLoadingAcknowledgePayments(true);
    if (paymentIds) {
      acknowledgePurchasePayments(paymentIds).then(() => {
        onClose();
        setIsLoadingAcknowledgePayments(false);
      });
    }
  };

  return (
    <FormModal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      submitButtonColorSchema="teal"
      submitButtonTitle="Acknowledge"
      isLoading={isLoading}
      title="Review and Acknowledge Paid Installments"
      handleSubmit={handleAcknowledge}>
      <Text mb={4}>
        The following installment(s) have been paid under this contract using your budget. Please
        review the details carefully and confirm you have seen them below. If you notice any
        discrepancies or need to make adjustments to this contract, please contact the Operations
        team directly.
      </Text>
      <CollectionTable columns={columns} items={payments || []} />
    </FormModal>
  );
};

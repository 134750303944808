import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useColorModeValue, useToken } from "@chakra-ui/react";

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

export const VendorStatisticsGraph = ({ groupedStatistics, filter }) => {
  const data =
    filter === "count"
      ? groupedStatistics.purchase_count_by_vendor
      : groupedStatistics.average_time_life_cycle_by_vendor;
  const labels = data.map((item) => item.vendor.name);
  const values =
    filter === "count" ? data.map((item) => item.count) : data.map((item) => item.days);

  const gray = useToken("colors", "gray.400");
  const legendColor = useColorModeValue("black", gray);
  const tooltipLabel = filter === "count" ? "Number of Orders" : "Average Time in Days";
  const xlabel = filter === "count" ? "Count" : "Days";

  // Chart.js data
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: tooltipLabel,
        data: values,
        backgroundColor: "teal",
        borderRadius: 4,
      },
    ],
  };

  // Chart.js options
  const options = {
    indexAxis: "y",
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: xlabel,
          color: legendColor,
        },

        beginAtZero: true,
        max: function () {
          const maxValue = Math.max(...values);
          return Math.floor(maxValue * 1.1);
        },
        ticks: {
          color: legendColor,
        },
        grid: {
          color: legendColor,
          borderColor: legendColor,
        },
        border: {
          color: legendColor,
        },
      },
      y: {
        title: {
          display: true,
          text: "Vendor",
          color: legendColor,
        },
        grid: {
          display: false,
        },
        ticks: {
          color: legendColor,
        },
        border: {
          color: legendColor,
        },
      },
    },
    barThickness: 25,
    categorySpacing: 30,
  };

  return <Bar data={chartData} options={options as any} />;
};

import { Flex, FormControl, FormLabel, useToast } from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import React, { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { UserSelect } from "../../../Users/shared/UserSelect";
import { ServiceSubtaskUserCreateParams } from "../../../../api/service_subtask_user";
import { UserMinimalData } from "../../../../api/user";
import { invalidateServiceRequest } from "../../../../api/service_requests";

interface AddUserToSubtaskModalProps {
  serviceRequestId?: number;
  serviceSubtaskId: number;
  isOpen: boolean;
  onClose: () => void;
  serviceUsers: UserMinimalData[];
  onSubmit: (params: ServiceSubtaskUserCreateParams) => Promise<boolean>;
  isLoading: boolean;
}

type AddUserToSubtaskFormValuesType = {
  recipients: number[];
};

const defaultFormValues: AddUserToSubtaskFormValuesType = {
  recipients: [],
};

export const AddUserToSubtaskModal = ({
  serviceRequestId,
  serviceSubtaskId,
  isOpen,
  onClose,
  serviceUsers,
  onSubmit,
  isLoading,
}: AddUserToSubtaskModalProps) => {
  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: defaultFormValues,
  });
  const toast = useToast();
  const invalidateSubtasks = invalidateServiceRequest(serviceRequestId);
  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  const onSubmitHandler = useCallback(
    handleSubmit((data) => {
      if (data.recipients) {
        onSubmit({
          service_subtask_id: serviceSubtaskId,
          user_ids: data.recipients,
        }).then(() => {
          invalidateSubtasks();
          toast({
            title: "Recipients updated",
            status: "success",
          });
          handleClose();
        });
      }
    }),
    [serviceSubtaskId, onSubmit, handleClose, toast]
  );

  return (
    <FormModal
      size={"md"}
      title={`Add User to Subtask`}
      isOpen={isOpen}
      submitButtonColorSchema="teal"
      onClose={handleClose}
      isLoading={isLoading}
      handleSubmit={onSubmitHandler}>
      <Flex direction="column" w="100%" gap={4}>
        <Controller
          name="recipients"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Who should fulfill this subtask?</FormLabel>
              <UserSelect
                setValue={(value) => setValue("recipients", value)}
                alreadyAddedUsers={serviceUsers}
              />
            </FormControl>
          )}
        />
      </Flex>
    </FormModal>
  );
};

import React, { useEffect } from "react";
import { FiDownload } from "react-icons/fi";
import { Button, Box, useToast, ButtonProps } from "@chakra-ui/react";
import { set } from "zod";
import {
  BackgroundTask,
  downloadBackgroundTaskCsv,
  useTask,
  useScheduleTask,
} from "../../api/background_tasks";
import { DateTime, Duration } from "luxon";

interface BackgroundReportButtonProps extends ButtonProps {
  taskName: string;
  args: any[];
  title: string;
  buttonName: string;
  maxAttempts?: number;
  waitTime?: number;
  noIcon?: boolean;
}

export const BackgroundReportButton: React.FC<BackgroundReportButtonProps> = ({
  title,
  taskName,
  args,
  buttonName,
  noIcon = false,
  maxAttempts = 24, // 1s + 2s + 3s... + 24s = 300 seconds, or 5 minutes
  waitTime = 1000,
  ...props
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [taskId, setTaskId] = React.useState<number | null>(null);
  const taskQuery = useTask(taskId);
  const { mutateAsync: scheduleTask } = useScheduleTask();
  const toast = useToast();

  const downloadReport = async (taskId: number) => {
    if (!taskId) return;
    const blob = await downloadBackgroundTaskCsv(taskId);
    const url = window.URL.createObjectURL(new Blob([blob], { type: "text/csv" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", title);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    setIsLoading(false);
  };

  const pollFn = async (
    taskId: number,
    attempt: number,
    status: BackgroundTask["status"] | null
  ) => {
    if (attempt >= maxAttempts) {
      toast({
        title: "Report creation timed out",
        description: "Please try again later",
        status: "error",
      });
      setIsLoading(false);
    } else if (status === "completed") {
      downloadReport(taskId);
      setIsLoading(false);
    } else if (status === "failed") {
      toast({
        title: "Unable to create report",
        description: "Encountered an error while creating the report, please try again later",
        status: "error",
      });
      console.error("Encountered an error while creating the report:", taskQuery.data);
      setIsLoading(false);
    } else {
      const result = await taskQuery.refetch();
      setTimeout(pollFn, waitTime * attempt, taskId, attempt + 1, result.data?.status);
    }
  };

  const handleClick = async () => {
    try {
      setIsLoading(true);
      const task = await scheduleTask({ task_name: taskName, args: args });
      setTaskId(task.id);
      setTimeout(pollFn, 0, task.id, 0, task.status);
    } catch (error) {
      toast({
        title: "Unable to create report",
        description: "Please try again later",
        status: "error",
      });
      console.error(error);
    }
  };

  return (
    <Box padding={0} margin={0}>
      <Button
        leftIcon={noIcon ? undefined : <FiDownload />}
        onClick={handleClick}
        isLoading={isLoading}
        {...props}>
        {buttonName}
      </Button>
    </Box>
  );
};

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { indexParams, searchParams } from "./collection_types";
import { formSchema } from "./form_builder";
import { serviceInventoryBomSchema } from "./service_inventory_bom";
import { serviceSubtaskSchemaWithUsers } from "./service_subtasks";
import { serviceAssignedUserSchema, serviceSubscribedUserSchema } from "./service_users";
import { zodParse } from "./zodParse";

export const SERVICES_BASE_URL = "services";

export const servicePartialSchema = z.object({
  id: z.number(),
  name: z.string(),
  team_id: z.number(),
  description: z.string(),
  support_text: z.string(),
  external_url: z.string(),
  spending_authority_required: z.boolean(),
  team: z.object({ id: z.number(), name: z.string() }),
  service_request_count: z.number(),
  visibility: z.string(),
  status: z.string(),
  service_assigned_users: z.array(serviceAssignedUserSchema),
  service_subscribed_users: z.array(serviceSubscribedUserSchema),
  service_inventory_boms: z.array(serviceInventoryBomSchema),
  service_subtasks: z.array(serviceSubtaskSchemaWithUsers),
  form: formSchema,
});

export const serviceShowSchema = z.object({
  id: z.number(),
  name: z.string(),
  team_id: z.number(),
  description: z.string(),
  team: z.object({ id: z.number(), name: z.string() }),
});

export const serviceCreateSchema = z.object({
  team_id: z.number(),
});

export type ServicePartialData = z.infer<typeof servicePartialSchema>;
export type ServiceCreateData = z.infer<typeof serviceCreateSchema>;

//api queries

export const getAllServices = async () => {
  const result = await api.get(`${SERVICES_BASE_URL}`);
  return result.data;
};

export const getService = async (id: number | undefined) => {
  const result = await api.get(`${SERVICES_BASE_URL}/${id}`);
  return zodParse(servicePartialSchema, result.data);
};

export const getUniqueTeamsWithServices = async () => {
  const result = await api.get(`${SERVICES_BASE_URL}/teams`);
  return result.data;
};

export const searchService = async ({ aggs, filters, term, order, pagination }) => {
  const path = [SERVICES_BASE_URL, "search"];
  const index = indexParams({ order, pagination });
  const search = searchParams({ aggs, filters, term });
  const result = await api.post(path.join("/"), { ...search, ...index });
  return result.data;
};

export const createService = async (service: ServiceCreateData) => {
  const result = await api.post(`${SERVICES_BASE_URL}`, { service });
  return zodParse(servicePartialSchema.pick({ id: true }), result.data);
};

export const updateService = async (service: Partial<ServicePartialData>) => {
  const result = await api.put(`${SERVICES_BASE_URL}/${service.id}`, { service });
  return zodParse(servicePartialSchema, result.data);
};

export const deleteService = async (id: number) => {
  await api.delete(`${SERVICES_BASE_URL}/${id}`);
};
export const invalidateService = (id?: number, subquery?: string) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [SERVICES_BASE_URL];
  if (id) queryKey.push(id);
  if (subquery) queryKey.push(subquery);

  return async () => {
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
  };
};

export const useGetAllServicesQuery = () => {
  return useQuery({
    queryKey: [SERVICES_BASE_URL],
    queryFn: () => getAllServices(),
  });
};

export const useGetServiceQuery = (id: number | undefined) => {
  return useQuery({
    queryKey: [SERVICES_BASE_URL, id],
    queryFn: () => getService(id),
    enabled: !!id,
  });
};

export const useSearchServices = (params) => {
  return useQuery({
    queryKey: [SERVICES_BASE_URL, params],
    queryFn: () => searchService(params),
  });
};

export const useGetUniqueTeamWithServicesQuery = () => {
  return useQuery({
    queryKey: [SERVICES_BASE_URL, "team"],
    queryFn: () => getUniqueTeamsWithServices(),
  });
};

export const useCreateService = () => {
  return useMutation({
    mutationFn: createService,
    onSuccess: invalidateService(),
  });
};

export const useUpdateService = (id?: number) => {
  return useMutation({
    mutationFn: updateService,
    onSuccess: invalidateService(id),
  });
};

export const useDeleteService = () => {
  return useMutation({
    mutationFn: deleteService,
    onSuccess: invalidateService(),
  });
};

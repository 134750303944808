import {
  CheckIcon,
  ChevronDownIcon,
  CloseIcon,
  SmallAddIcon,
  SmallCloseIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  HStack,
  IconButton,
  Input,
  MenuItem,
  MenuButton,
  MenuList,
  Menu,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  VStack,
  useColorModeValue,
  useToast,
  Tag,
  useDisclosure,
  ButtonGroup,
  Spinner,
} from "@chakra-ui/react";
import {
  Attribute,
  AttributesTable,
  EditableDate,
  EditableSelect,
  RecordLink,
  SelectOption,
  StatusSelect,
} from "@sciencecorp/helix-components";
import React, { useRef, useState } from "react";
import {
  invalidateServiceRequest,
  ServiceRequestShowData,
  useCreateAssignedUser,
  useDeleteAssignedUser,
  useGetAllServiceRequestCategoriesQuery,
  useGetAllServiceRequestInventoryBomsQuery,
  useUpdateServiceRequest,
} from "../../../api/service_requests";
import { ServicePartialData, useGetAllServicesQuery } from "../../../api/services";
import { UserLoggedInData } from "../../../api/user";
import { ImLink } from "react-icons/im";
import { UserRequestPill, UserServiceRequestPill, useMinimalUserOptions } from "../utils";
import { BomLineItem } from "./DetailPage/BomLineItem";
import { VisibilityOptions } from "./DetailPage/ServiceVisibility";
import { useDeleteServiceRequestInventoryBom } from "../../../api/service_request_inventory_boms";
import { ServiceRequestInventoryBom } from "./ServiceRequest/ServiceRequestInventoryBom";
import { useTeamCategoriesQuery } from "../../../api/team";
import { IoIosFlag } from "react-icons/io";
import { AddCategoryPopover } from "./AddCategoryPopover";
import { useDeleteServiceRequestCategory } from "../../../api/service_request_categories";
import { humanize } from "inflection";
import { ChangeFulfillingTeamModal } from "../ChangeFulfillingTeamModal";
import { useServiceOptions } from "../../../api/options";
import { RiCheckFill, RiCloseFill, RiEdit2Line } from "react-icons/ri";
import { Select } from "chakra-react-select";

export const PRIORITY_OPTIONS = [
  { label: "4", value: 4, color: "gray" },
  { label: "3", value: 3, color: "green" },
  { label: "2", value: 2, color: "orange" },
  { label: "1", value: 1, color: "red" },
];

const VISIBILITY_OPTIONS = VisibilityOptions.map((option) => ({
  label: option.label,
  value: option.value,
  color: option.colorScheme,
}));

type ServiceRequestDetailsSidebarProps = {
  serviceRequest: ServiceRequestShowData;
  service: ServicePartialData;
  currentUser: UserLoggedInData;
  isServiceAdmin: boolean;
};

export const ServiceRequestDetailsSidebar = ({
  serviceRequest,
  service,
  isServiceAdmin,
}: ServiceRequestDetailsSidebarProps) => {
  const { mutateAsync: updateServiceRequest } = useUpdateServiceRequest(serviceRequest.id);
  const { mutateAsync: createAssignedUser } = useCreateAssignedUser(serviceRequest.id);
  const { mutateAsync: deleteAssignedUser } = useDeleteAssignedUser(serviceRequest.id);
  const { mutateAsync: deleteServiceRequestCategory } = useDeleteServiceRequestCategory();
  const { mutateAsync: deleteServiceRequestInventoryBom, isLoading: isLoadingDeleteBom } =
    useDeleteServiceRequestInventoryBom();
  const assigneeOptions = useMinimalUserOptions(
    serviceRequest.service_requests_assigned_users.map((ele) => ele.assigned_user.id)
  );
  const [filteredAssigneeOptions, setFilteredAssigneeOptions] = useState(assigneeOptions);
  const [searchAssignees, setSearchAssignees] = useState("");
  const { data: inventoryBoms } = useGetAllServiceRequestInventoryBomsQuery(serviceRequest.id);
  const invalidateBom = invalidateServiceRequest(serviceRequest.id);
  const handleDelete = (user) => {
    deleteAssignedUser(user.id);
  };
  const { data: teamCategories } = useTeamCategoriesQuery(service?.team.id);
  const { data: serviceRequestCategories } = useGetAllServiceRequestCategoriesQuery(
    serviceRequest.id
  );
  const SERVICE_OPTIONS: SelectOption[] = useServiceOptions();
  const editable = serviceRequest.user_in_fulfilling_team || isServiceAdmin;
  const toast = useToast();
  const bgColor = useColorModeValue("gray.50", "gray.700");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedService, setSelectedService] = useState<number>(serviceRequest.service_id);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLSelectElement>(null);

  const handleEditClick = () => {
    setIsEditing(true);
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const handleModalOpen = (serviceId: number) => {
    setSelectedService(serviceId);
    onOpen();
  };

  return (
    <Stack direction="column" spacing={4}>
      <ChangeFulfillingTeamModal
        isOpen={isOpen}
        onClose={onClose}
        serviceRequestId={serviceRequest.id}
        oldServiceId={serviceRequest.service_id}
        newServiceId={selectedService}
      />
      <Box
        bg={bgColor}
        borderRadius="md"
        width={"100%"}
        border="1px"
        borderColor="chakra-border-color"
        p={4}>
        <AttributesTable
          title=""
          attributes={
            [
              { label: "Request ID", value: serviceRequest.id },
              {
                label: "Site",
                value: serviceRequest.site.name,
              },
              {
                label: "Fulfilled by",
                value: (
                  <RecordLink
                    identifier={service.team_id ? service.team.name : "Unassigned"}
                    link={`/teams/${service.team_id}/readme`}
                  />
                ),
              },
              {
                label: "Service Type",
                value: (
                  <HStack width="100%">
                    {isEditing ? (
                      <>
                        <Select
                          useBasicStyles
                          size="sm"
                          value={SERVICE_OPTIONS.find((opt) => opt.value === selectedService)}
                          options={SERVICE_OPTIONS}
                          onChange={(e) => {
                            setSelectedService(Number(e?.value));
                          }}
                        />
                        <IconButton
                          aria-label="Save Edits"
                          size="xs"
                          icon={<RiCheckFill />}
                          onClick={() => {
                            setIsEditing(false);
                            if (selectedService !== serviceRequest.service_id) {
                              handleModalOpen(selectedService);
                            }
                          }}
                        />
                        <IconButton
                          aria-label="Cancel Edits"
                          icon={<RiCloseFill />}
                          size="xs"
                          onClick={() => {
                            setSelectedService(serviceRequest.service_id);
                            setIsEditing(false);
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <Text>{service.name}</Text>
                        <IconButton
                          size="xs"
                          aria-label="Edit"
                          icon={<RiEdit2Line />}
                          onClick={handleEditClick}
                        />
                      </>
                    )}
                  </HStack>
                ),
              },
              {
                label: "Est. Completion Date",
                value: (
                  <EditableDate
                    defaultValue={
                      serviceRequest.estimated_completion_date
                        ? serviceRequest.estimated_completion_date.toFormat("yyyy-MM-dd")
                        : undefined
                    }
                    disabled={!editable}
                    persistentEdit
                    onSubmit={(date) => {
                      serviceRequest &&
                        date &&
                        updateServiceRequest(
                          {
                            id: serviceRequest.id,
                            estimated_completion_date: date,
                          },
                          {
                            onSuccess: () => {
                              toast({
                                title: "Successful!",
                                description: "You updated the request completion date",
                                status: "success",
                                duration: 5000,
                                isClosable: true,
                              });
                            },
                          }
                        );
                    }}
                  />
                ),
              },
              editable && {
                label: "Privacy",
                value: (
                  <StatusSelect
                    options={VISIBILITY_OPTIONS}
                    isDisabled={!editable}
                    variant="tag"
                    type="subtle"
                    status={
                      serviceRequest.visibility
                        ? VISIBILITY_OPTIONS.find((ele) => ele.value === serviceRequest.visibility)
                        : undefined
                    }
                    onSubmit={(option) => {
                      option &&
                        updateServiceRequest(
                          {
                            id: serviceRequest.id,
                            visibility: option.toString(),
                          },
                          {
                            onSuccess: () =>
                              toast({
                                title: "Successful!",
                                description: "You updated the visibility",
                                status: "success",
                                duration: 5000,
                                isClosable: true,
                              }),
                          }
                        );
                    }}
                  />
                ),
              },
              editable && {
                label: "Priority",
                value: (
                  <Menu>
                    <MenuButton
                      size="sm"
                      as={Button}
                      leftIcon={
                        <IoIosFlag
                          color={
                            PRIORITY_OPTIONS.find(
                              (ele) => ele.value === serviceRequest.priority_level
                            )?.color
                          }
                        />
                      }
                      rightIcon={<ChevronDownIcon />}>
                      {serviceRequest.priority_level}
                    </MenuButton>
                    <MenuList>
                      {PRIORITY_OPTIONS.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          onClick={() => {
                            updateServiceRequest({
                              id: serviceRequest.id,
                              priority_level: option.value,
                            }).then(() => {
                              toast({
                                title: "Successful!",
                                description: "You updated the priority level",
                                status: "success",
                                duration: 5000,
                                isClosable: true,
                              });
                            });
                          }}>
                          <IoIosFlag color={option.color} />
                          {option.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                ),
              },
              editable &&
                teamCategories && {
                  label: "Category",
                  value: (
                    <HStack flexWrap={"wrap"} gap={1}>
                      {serviceRequestCategories &&
                        serviceRequestCategories.length > 0 &&
                        serviceRequestCategories.map((category) => (
                          <Tag key={category.id} gap={1}>
                            {humanize(category.team_service_category.name)}
                            {editable && (
                              <SmallCloseIcon
                                cursor={"pointer"}
                                onClick={() => {
                                  deleteServiceRequestCategory(category.id);
                                }}
                              />
                            )}
                          </Tag>
                        ))}
                      <AddCategoryPopover
                        teamCategories={teamCategories ?? []}
                        serviceRequestId={serviceRequest.id}
                        serviceRequestCategories={
                          serviceRequestCategories?.map((ele) => ele.team_service_category.id) ?? []
                        }
                      />
                    </HStack>
                  ),
                },
            ].filter(Boolean) as Attribute[]
          }
        />
      </Box>
      <VStack p={4} border="1px" bg={bgColor} borderRadius="md" borderColor={"chakra-border-color"}>
        <HStack width="100%" justify="space-between">
          <Text fontSize="xs" textTransform="uppercase" fontWeight="bold">
            Assigned Users
          </Text>
          <Popover placement="bottom-end">
            <PopoverTrigger>
              <IconButton
                isDisabled={!serviceRequest.can_edit_service_request}
                p={0}
                bg="transparent"
                aria-label="add-link"
                size="sm"
                icon={<SmallAddIcon />}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverCloseButton />
              <PopoverBody maxH={52} overflow="auto">
                <VStack align="start">
                  <Text fontWeight="semibold">Assign someone to this task</Text>
                  <Input
                    size="sm"
                    placeholder="Search users"
                    value={searchAssignees}
                    onChange={(e) => {
                      setSearchAssignees(e.target.value);
                      setFilteredAssigneeOptions(
                        assigneeOptions.filter((ele) =>
                          ele.user.name.toLowerCase().includes(e.target.value.toLowerCase())
                        )
                      );
                    }}
                  />
                  {filteredAssigneeOptions.map((member) => (
                    <Button
                      key={`${member.label} ${member.value}`}
                      cursor="pointer"
                      justifyContent="start"
                      bg="transparent"
                      width="100%"
                      onClick={() =>
                        createAssignedUser(
                          {
                            assigned_user_id: member.value,
                            service_request_id: serviceRequest.id,
                          },
                          {
                            onSuccess: () => {
                              setSearchAssignees("");
                              setFilteredAssigneeOptions(assigneeOptions);
                            },
                          }
                        )
                      }>
                      <UserServiceRequestPill user={member.user} />
                    </Button>
                  ))}
                </VStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </HStack>
        {serviceRequest.service_requests_assigned_users.length
          ? serviceRequest.service_requests_assigned_users.map((user) => (
              <UserRequestPill
                key={`assigned_${user.id}`}
                user={user}
                handleDelete={editable ? handleDelete : undefined}
              />
            ))
          : null}
      </VStack>
      <VStack
        p={4}
        border="1px"
        borderRadius="md"
        spacing={2}
        borderColor={useColorModeValue("gray.200", "gray.600")}>
        <Stack
          gap={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%">
          <HStack>
            <Box
              bgColor={useColorModeValue("green.100", "green.500")}
              height={"100%"}
              width={"max-content"}
              color={useColorModeValue("green.700", "green.100")}
              p={"2"}
              marginRight={"2"}
              borderRadius={"md"}>
              <ImLink size={"20px"} />
            </Box>
            <Text fontSize={"sm"} textColor={useColorModeValue("gray.700", "auto")}>
              This request is linked to:
            </Text>
          </HStack>
          {inventoryBoms && (
            <ServiceRequestInventoryBom
              inventoryBoms={inventoryBoms}
              serviceRequestId={serviceRequest.id}
              canEditServiceRequest={editable}
            />
          )}
        </Stack>
        <Divider />
        {inventoryBoms && inventoryBoms.length > 0 ? (
          <Stack direction="column" alignItems="flex-start" padding={2} width="100%">
            {inventoryBoms.map((bom) => (
              <BomLineItem
                bom={bom}
                deleteBom={() => deleteServiceRequestInventoryBom(bom.id)}
                isLoadingDeleteBom={isLoadingDeleteBom}
                invalidateBom={invalidateBom}
              />
            ))}
          </Stack>
        ) : (
          <Text fontSize="sm" color="gray.500">
            No Bill of Materials associated with this request
          </Text>
        )}
      </VStack>
    </Stack>
  );
};

import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Textarea,
  RadioGroup,
  Stack,
  Radio,
  CheckboxGroup,
  Checkbox,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Box,
} from "@chakra-ui/react";
import { Control, Controller } from "react-hook-form";
import React from "react";
import { FormFieldData } from "../../../../api/form_builder";

export const FormFieldSubmission = ({
  formField,
  control,
  isPreview = false,
}: {
  formField: FormFieldData;
  control?: Control;
  isPreview?: boolean;
}) => {
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, onChange) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);

      const promises = files.map((file) => {
        return new Promise<{ name: string; file: string }>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.result) {
              resolve({
                name: file.name,
                file: reader.result as string,
              });
            } else reject(new Error("File couldn't be read"));
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });

      Promise.all(promises)
        .then((encodedFileObjects) => onChange(encodedFileObjects))
        .catch((error) => console.error(error));
    }
  };

  switch (formField.type) {
    case "Form::Field::ShortText":
      return (
        <Controller
          name={formField.id.toString()}
          control={control}
          disabled={isPreview}
          render={({ field }) => (
            <FormControl isRequired={formField.is_required}>
              <FormLabel>{formField.label}</FormLabel>
              <Input {...field} placeholder="Type here" />
              <FormHelperText>{formField.description}</FormHelperText>
            </FormControl>
          )}
        />
      );
    case "Form::Field::LongText":
      return (
        <Controller
          name={formField.id.toString()}
          control={control}
          disabled={isPreview}
          render={({ field }) => (
            <FormControl isRequired={formField.is_required}>
              <FormLabel>{formField.label}</FormLabel>
              <Textarea {...field} placeholder="Type here..." />
              <FormHelperText>{formField.description}</FormHelperText>
            </FormControl>
          )}
        />
      );
    case "Form::Field::SingleSelect":
      return (
        <Controller
          name={formField.id.toString()}
          control={control}
          disabled={isPreview}
          render={({ field }) => (
            <FormControl isRequired={formField.is_required}>
              <FormLabel>{formField.label}</FormLabel>
              <FormHelperText>{formField.description}</FormHelperText>
              <RadioGroup
                {...field}
                value={field.value?.toString()}
                onChange={(value) => field.onChange(Number(value))}
                mt={2}
                colorScheme="teal">
                <Stack direction="column">
                  {formField.options?.map((option) => (
                    <Radio
                      key={`field-${formField.id}-option-${option.id}`}
                      value={option.id.toString()}>
                      {option.label}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            </FormControl>
          )}
        />
      );
    case "Form::Field::MultiSelect":
      return (
        <Controller
          name={formField.id.toString()}
          control={control}
          disabled={isPreview}
          render={({ field }) => (
            <FormControl isRequired={formField.is_required}>
              <FormLabel>{formField.label}</FormLabel>
              <FormHelperText mb={2}>{formField.description}</FormHelperText>
              <CheckboxGroup
                {...field}
                onChange={(e) => {
                  field.onChange(e.map(Number));
                }}
                colorScheme="teal">
                <Stack direction="column">
                  {formField.options?.map((option) => (
                    <Checkbox key={`field-${formField.id}-option-${option.id}`} value={option.id}>
                      {option.label}
                    </Checkbox>
                  ))}
                </Stack>
              </CheckboxGroup>
            </FormControl>
          )}
        />
      );
    case "Form::Field::Number":
      return (
        <Controller
          name={formField.id.toString()}
          control={control}
          disabled={isPreview}
          render={({ field }) => (
            <FormControl isRequired={formField.is_required}>
              <FormLabel>{formField.label}</FormLabel>
              <NumberInput
                {...field}
                value={field.value || ""}
                onChange={(value) => field.onChange(value.length ? Number(value) : "")}
                allowMouseWheel={false}>
                <NumberInputField placeholder={"0"} />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <FormHelperText>{formField.description}</FormHelperText>
            </FormControl>
          )}
        />
      );

    case "Form::Field::Date":
      return (
        <Controller
          name={formField.id.toString()}
          control={control}
          disabled={isPreview}
          render={({ field }) => (
            <FormControl isRequired={formField.is_required}>
              <FormLabel>{formField.label}</FormLabel>
              <Input type="date" {...field} />
              <FormHelperText>{formField.description}</FormHelperText>
            </FormControl>
          )}
        />
      );
    case "Form::Field::File":
      return (
        <Controller
          name={formField.id.toString()}
          control={control}
          disabled={isPreview}
          render={({ field }) => (
            <FormControl isRequired={formField.is_required}>
              <FormLabel>{formField.label}</FormLabel>
              <Box
                width="100%"
                border="1px"
                p={2}
                borderColor={"chakra-border-color"}
                borderRadius="base">
                <input
                  type="file"
                  multiple={true}
                  onChange={(e) => handleFileChange(e, field.onChange)}
                  disabled={isPreview}
                />
              </Box>
              <FormHelperText>{formField.description}</FormHelperText>
            </FormControl>
          )}
        />
      );

    default:
      return <></>;
  }
};

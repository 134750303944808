import { SmallAddIcon } from "@chakra-ui/icons";
import { IconButton, Text, MenuItem, Menu, MenuList, MenuButton } from "@chakra-ui/react";
import React from "react";
import { ServiceRequestInventoryBomData } from "../../../../api/service_request_inventory_boms";
import { useInventoryBomOptions } from "../../../../api/options";
import { invalidateServiceRequest } from "../../../../api/service_requests";
import { useCreateServiceRequestInventoryBom } from "../../../../api/service_request_inventory_boms";

type ServiceRequestInventoryBomProps = {
  inventoryBoms: ServiceRequestInventoryBomData[];
  serviceRequestId: number;
  canEditServiceRequest: boolean;
};

export const ServiceRequestInventoryBom = ({
  inventoryBoms,
  serviceRequestId,
  canEditServiceRequest,
}: ServiceRequestInventoryBomProps) => {
  const inventoryBomOptions = useInventoryBomOptions({
    excludeIds: inventoryBoms.map((bom) => bom.inventory_bom_id),
  });
  const { mutateAsync: createServiceRequestInventoryBom, isLoading: isLoadingCreateInventoryBom } =
    useCreateServiceRequestInventoryBom();
  const invalidateBom = invalidateServiceRequest(serviceRequestId);
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            isActive={isOpen}
            size="sm"
            as={IconButton}
            icon={<SmallAddIcon />}
            isDisabled={!canEditServiceRequest}
          />
          <MenuList>
            {inventoryBomOptions?.length > 0 ? (
              inventoryBomOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  cursor="pointer"
                  _hover={{ bg: "gray.100" }}
                  justifyContent="start"
                  bg="transparent"
                  width="100%"
                  onClick={() => {
                    createServiceRequestInventoryBom({
                      service_request_id: serviceRequestId,
                      inventory_bom_id: parseInt(option.value, 10),
                    })
                      .then(invalidateBom)
                      .catch((error) => {
                        console.error(
                          "Error: Failed to create service request inventory BOM.",
                          error
                        );
                      });
                  }}>
                  {option.label}
                </MenuItem>
              ))
            ) : (
              <Text p={4} textAlign="center">
                No Bill of Materials left to add
              </Text>
            )}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

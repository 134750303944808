import {
  Divider,
  Flex,
  Heading,
  Stack,
  Tag,
  Text,
  HStack,
  useDisclosure,
  Tooltip,
  useColorModeValue,
  Box,
  IconButton,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { EditIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { invalidatePurchases, PurchaseShowData, useUpdatePurchase } from "../../api/purchase";
import { MinimalSpendingAuthority, SpendingAuthorityShowData } from "../../api/spending_authority";
import { SpendingAuthorityChangeRequestData } from "../../api/spending_authority_change_requests";
import { ChangeSpendingAuthorityModal } from "../Purchasing/Purchase/ChangeSpendingAuthorityModal";
import { SpendingAuthorityPreview } from "../Purchasing/SpendingAuthoritySelectTree";
import { BudgetItemBody } from "./BudgetItemBody";
import { RecordLink } from "@sciencecorp/helix-components";
import { Money } from "../../helpers/Money";
import {
  invalidateServiceRequest,
  ServiceRequestShowData,
  useUpdateServiceRequest,
} from "../../api/service_requests";

export interface ApprovalFlowSpendingAuthorityInfoProps {
  spendingAuthority?: MinimalSpendingAuthority | null;
  expenditure: PurchaseShowData | ServiceRequestShowData;
  type: "Purchase" | "ServiceRequest";
  currentUser: any;
  userCanSubmit?: boolean;
  pendingAmount: Money;
  rootSpendingAuthority?: SpendingAuthorityShowData | null;
}

export const toShowApprovalFlow = (spendingAuthorityRequest) => {
  return (
    spendingAuthorityRequest &&
    spendingAuthorityRequest.approval_state !== "declined" &&
    spendingAuthorityRequest.approval_state !== "approved"
  );
};
export const ApprovalFlowSpendingAuthorityInfo: React.FC<
  ApprovalFlowSpendingAuthorityInfoProps
> = ({
  spendingAuthority,
  pendingAmount,
  rootSpendingAuthority,
  expenditure,
  currentUser,
  type,
}) => {
  const spendingAuthorityRequest: SpendingAuthorityChangeRequestData =
    expenditure.spending_authority_change_requests[
      expenditure.spending_authority_change_requests.length - 1
    ];

  const approvedStates = ["approved", "awaiting_payment", "awaiting_purchase", "delivered"];
  const pastEditableState = useMemo(
    () => approvedStates.includes(expenditure.approval_state),
    [expenditure.approval_state]
  );

  const isPurchase = (
    expenditure: PurchaseShowData | ServiceRequestShowData
  ): expenditure is PurchaseShowData => type === "Purchase";

  const expenditureAmount = (expenditure: PurchaseShowData | ServiceRequestShowData): Money =>
    isPurchase(expenditure) ? expenditure.amount : expenditure.total_cost;

  const { mutateAsync: updatePurchase } = useUpdatePurchase();
  const { mutateAsync: updateServiceRequest } = useUpdateServiceRequest(expenditure.id);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const updateExpenditure = () => {
    return type === "Purchase" ? updatePurchase : updateServiceRequest;
  };
  const invPurFn = useMemo(() => invalidatePurchases(expenditure.id), [expenditure.id]);
  const invSRFn = useMemo(() => invalidateServiceRequest(expenditure.id), [expenditure.id]);

  const spendingAuthorityToShow = toShowApprovalFlow(spendingAuthorityRequest)
    ? spendingAuthorityRequest.spending_authority
    : spendingAuthority;

  const rootSpendingAuthorityToShow = toShowApprovalFlow(spendingAuthorityRequest)
    ? spendingAuthorityRequest.root_spending_authority
    : rootSpendingAuthority;

  const renderSpendingAuthorityChanges = () => {
    let spendingAuthorityType, spendingAuthorityId;

    if (!toShowApprovalFlow(spendingAuthorityRequest)) {
      return (
        expenditure.spending_authority &&
        expenditure.root_spending_authority && (
          <Box marginTop={4}>
            <SpendingAuthorityPreview
              spendingAuthorityType={expenditure.spending_authority.type}
              spendingAuthorityId={expenditure.spending_authority.id}
              rootSpendingAuthorityId={expenditure.root_spending_authority.id}
              rootSpendingAuthorityType={expenditure.root_spending_authority.type}
              outsideOfAttributesTable
            />
          </Box>
        )
      );
    } else if (toShowApprovalFlow(spendingAuthorityRequest)) {
      if (spendingAuthorityRequest.spending_authority) {
        spendingAuthorityType = spendingAuthorityRequest.spending_authority.type;
        spendingAuthorityId = spendingAuthorityRequest.spending_authority.id;
        return (
          <Flex direction={"column"} width={"100%"}>
            <HStack marginTop={"4"}>
              <Text color={useColorModeValue("gray.500", "auto")} fontSize={"xs"}>
                From
              </Text>
              {expenditure.spending_authority ? (
                <SpendingAuthorityPreview
                  spendingAuthorityType={expenditure.spending_authority.type}
                  spendingAuthorityId={expenditure.spending_authority.id}
                  maxWidth={"27ch"}
                  outsideOfAttributesTable
                />
              ) : (
                <RecordLink
                  type=""
                  disabled
                  identifier={"No previous spending authority"}></RecordLink>
              )}
            </HStack>
            <HStack marginTop={"4"}>
              <Text color={useColorModeValue("gray.500", "auto")} fontSize={"xs"}>
                To
              </Text>
              <SpendingAuthorityPreview
                spendingAuthorityType={spendingAuthorityType}
                spendingAuthorityId={spendingAuthorityId}
                maxWidth={"27ch"}
                outsideOfAttributesTable
              />
            </HStack>
          </Flex>
        );
      }
    }
  };

  return (
    <>
      <Flex
        flex="1"
        direction="column"
        p={6}
        border={"1px"}
        width={"100%"}
        borderColor="chakra-border-color"
        borderRadius="md">
        <HStack>
          <Flex>
            <Text
              fontSize="lg"
              fontWeight="bold"
              marginRight={spendingAuthorityRequest ? "2" : "0"}
              py={2}>
              Spending Authority
            </Text>
            {toShowApprovalFlow(spendingAuthorityRequest) && (
              <Tag>
                {" "}
                <InfoOutlineIcon
                  color={useColorModeValue("gray.800", "auto")}
                  marginRight={"1.5"}
                />{" "}
                <Text color={useColorModeValue("gray.800", "auto")}>Changes Requested</Text>
              </Tag>
            )}
          </Flex>
          {expenditure.status === "declined" ? (
            <Tooltip label="This purchase has been declined and the spending authority can no longer be changed.">
              <EditIcon cursor={"none"} opacity={0.5} />
            </Tooltip>
          ) : (
            <IconButton size="xs" aria-label="edit-request" icon={<EditIcon />} onClick={onOpen} />
          )}
        </HStack>
        <Stack spacing={4}>
          {renderSpendingAuthorityChanges()}
          <Divider />
          {spendingAuthorityToShow?.type === "BudgetItem" ? (
            <BudgetItemBody
              spendingAuthority={spendingAuthorityToShow}
              pendingAmount={pendingAmount}
            />
          ) : rootSpendingAuthorityToShow && rootSpendingAuthorityToShow.type === "BudgetItem" ? (
            <BudgetItemBody
              spendingAuthority={rootSpendingAuthorityToShow}
              pendingAmount={pendingAmount}
            />
          ) : rootSpendingAuthorityToShow && rootSpendingAuthorityToShow.type === "RevenueItem" ? (
            <Tag colorScheme="green">Attributed to Revenue Item</Tag>
          ) : (
            <Tag colorScheme="red">Root spending authority not found</Tag>
          )}
        </Stack>
      </Flex>
      <ChangeSpendingAuthorityModal
        isOpen={isOpen}
        onClose={onClose}
        pastEditableState={pastEditableState}
        expenditure_id={expenditure.id}
        expenditure_type={type}
        spending_authority={spendingAuthority || null}
        currentUser={currentUser}
        amount={expenditureAmount(expenditure)}
        updateExpenditure={updateExpenditure()}
        invalidateExpenditure={isPurchase(expenditure) ? invPurFn : invSRFn}
      />
    </>
  );
};

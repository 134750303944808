import {
  Box,
  Flex,
  Heading,
  HStack,
  Spinner,
  Tab,
  TabList,
  Tabs,
  Tag,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import Big from "big.js";
import React, { useMemo, useState } from "react";
import { MinimalSpendingAuthority, useSpendingAuthorityCard } from "../../api/spending_authority";
import { useCurrency } from "../../contexts/CurrencyContext";
import { Money } from "../../helpers/Money";
import { MoneyText } from "../MoneyText";
import { MultiProgressBar } from "./MultiProgressBar";

const nameMap = {
  BudgetItem: "Budget Item",
  BudgetGroup: "Budget Group",
};
const getPrettyName = (type: string | undefined | null) => {
  return nameMap[type || "NO_TYPE"] || type;
};

export const BudgetItemBody = (props: {
  spendingAuthority: MinimalSpendingAuthority;
  pendingAmount: Money;
  session?: number;
}) => {
  const currency = useCurrency();
  const { spendingAuthority, pendingAmount, session } = props;

  const spendingAuthorityCardQuery = useSpendingAuthorityCard(
    spendingAuthority?.id,
    spendingAuthority?.type,
    pendingAmount
  );

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const gray = useColorModeValue("gray.100", "gray.400");
  const orange = useColorModeValue("orange.200", "orange.500");
  const teal = useColorModeValue("teal.500", "teal.300");
  const red = useColorModeValue("red.200", "red.500");

  const activeImpactChain = useMemo(
    () => spendingAuthorityCardQuery.data?.impact_chain[activeTabIndex],
    [activeTabIndex, spendingAuthorityCardQuery.data]
  );

  const totalSpent = activeImpactChain?.spent_amount || Money.zero(currency);
  const totalBudget = activeImpactChain?.funding_amount || Money.zero(currency);
  const totalCommitted = activeImpactChain?.committed_amount || Money.zero(currency);
  const totalRequested = activeImpactChain?.requested_amount || Money.zero(currency);
  const totalAvailable = activeImpactChain?.remaining_amount || Money.zero(currency);

  const totalSpentPercentage = Money.pct(totalSpent, totalBudget);
  const totalCommittedPercentage = Money.pct(totalCommitted, totalBudget);
  const totalRequestedPercentage = Money.pct(totalRequested, totalBudget);
  const remainingBudgetPercentage = new Big(100).sub(
    totalSpentPercentage.add(totalCommittedPercentage)
  );

  const spendingProgressBarElements: { value: number; color: string }[] = [
    {
      value: totalSpentPercentage.toNumber(),
      color: teal,
    },
    {
      value: totalCommittedPercentage.toNumber(),
      color: orange,
    },
    {
      value: totalRequestedPercentage.toNumber(),
      color: red,
    },
    {
      value: remainingBudgetPercentage.toNumber(),
      color: gray,
    },
  ];

  if (spendingAuthorityCardQuery.isLoading) {
    return (
      <Flex justify="center" align="center" w="100%" h="100%">
        <Spinner />
      </Flex>
    );
  } else if (spendingAuthorityCardQuery.isSuccess) {
    return (
      <VStack width="100%" align="start" pb={3}>
        <Tabs
          onChange={(index) => setActiveTabIndex(index)}
          colorScheme="teal"
          w="100%"
          size="sm"
          mb={4}>
          <TabList>
            {spendingAuthorityCardQuery.data?.impact_chain.map((ic) => (
              <Tab key={ic.id}>Impact on {getPrettyName(ic.type)}</Tab>
            ))}
          </TabList>
        </Tabs>
        <Heading size="sm" mb={2}>
          {getPrettyName(activeImpactChain?.type)}: {activeImpactChain?.name}
        </Heading>
        <HStack w="100%" spacing={10}>
          <VStack w="100%">
            <MultiProgressBar
              segments={spendingProgressBarElements}
              boxProps={{ height: 2, width: "100%" }}
            />
            <HStack justifyContent="space-between" width="100%">
              <VStack align="start" spacing={0}>
                <MoneyText money={totalSpent} />
                <Text fontSize="sm" textColor={useColorModeValue("gray.500", "auto")}>
                  Spent
                </Text>
              </VStack>
              <VStack align="end" spacing={0}>
                <MoneyText money={totalAvailable} />
                <Text fontSize="sm" textColor={useColorModeValue("gray.500", "auto")}>
                  Available
                </Text>
              </VStack>
            </HStack>
          </VStack>
          <VStack align="start" justify="start" h="100%">
            <Tooltip label="Total amount approved but unpaid for this spending authority, representing all pending transactions.">
              <HStack spacing={2}>
                <Box w="8px" h="8px" borderRadius="full" bg={orange} />
                <Text fontSize="xs">Committed:</Text>
                <MoneyText fontSize={"xs"} money={totalCommitted} />
              </HStack>
            </Tooltip>
            <Tooltip label="Funds that have been requested for this specific purchase and not yet paid. If this purchase has been completed, the number should be zero.">
              <HStack spacing={2}>
                <Box w="8px" h="8px" borderRadius="full" bg={red} />
                <Text fontSize="xs">Requested:</Text>
                <MoneyText fontSize={"xs"} money={totalRequested} />
              </HStack>
            </Tooltip>
          </VStack>
        </HStack>
      </VStack>
    );
  } else {
    return <Tag colorScheme="red">Not Budgeted</Tag>;
  }
};

import { AddIcon, ArrowForwardIcon, MinusIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Divider,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { Select, OptionBase } from "chakra-react-select";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  InventoryItemGroupedByLotData,
  LocationTreeKeyValue,
  LocationTreeValue,
} from "../../../../api/inventory_location";
import {
  InventoryShowData,
  useBulkCheckoutInventory,
  useInventoryLocationsWithInventoryOptions,
} from "../../../../api/inventory";
import { initial } from "lodash";
import { TreeSelect, findSelectedOption } from "../../../shared/TreeSelect";
import { SpendingAuthoritySelect } from "../../../Purchasing/SpendingAuthoritySelectTree";
import { MinimalSpendingAuthority } from "../../../../api/spending_authority";
import { SelectCheckoutPurpose } from "../../Checkout/CheckoutModalComponents/SelectCheckoutPurpose";
import { MoneyText } from "../../../MoneyText";
import { WarnIfSpendingAuthorityIrrelevant } from "../../../shared/WarnIfSpendingAuthorityIrrelevant";

export type RemoveStockModalValuesType = {
  inventory_location_id: number;
  spending_authority: MinimalSpendingAuthority | null;
  lot_number: InventoryItemGroupedByLotData | null;
  quantity: number;
  purpose: "spending_authority" | "waste" | "used";
};

type RemoveStockModalProps = {
  isOpen: boolean;
  onClose: () => void;
  inventory: InventoryShowData;
};

interface LotOption extends OptionBase {
  label: string;
  value: string;
  remaining_quantity: number;
  expiration_date: string | null;
}

export const RemoveStockModal = ({ isOpen, onClose, inventory }: RemoveStockModalProps) => {
  const [locationSearch, setLocationSearch] = useState<string | undefined>();

  const { data: options, isLoading: isLoadingOptions } = useInventoryLocationsWithInventoryOptions(
    inventory.id,
    locationSearch
  );

  const toast = useToast();

  const { mutateAsync: bulkCheckoutInventory, isLoading: isLoadingBulkCheckout } =
    useBulkCheckoutInventory();

  const { watch, reset, handleSubmit, setValue, control } = useForm<RemoveStockModalValuesType>({
    defaultValues: {
      inventory_location_id: 0,
      spending_authority: null,
      lot_number: null,
      quantity: 0,
    },
  });
  const [location, setLocation] = useState<LocationTreeValue | null>(null);
  const [pathToLocation, setPathToLocation] = useState<LocationTreeKeyValue[] | undefined>();
  const [showPreview, setShowPreview] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (location) {
      if (location.lot_numbers?.length === 1) {
        setValue("lot_number", location.lot_numbers[0]);
      }
    }
  }, [location]);
  const formValues = watch();

  const disableProceedButton = (formValues: RemoveStockModalValuesType) => {
    if (currentStep === 0) return !formValues.purpose;
    if (currentStep === 1)
      if (location && (location?.lot_numbers?.length || 0) > 0)
        return (
          !formValues.lot_number || formValues.quantity <= 0 || !formValues.inventory_location_id
        );
    if (formValues.purpose === "spending_authority")
      return (
        !formValues.spending_authority?.id ||
        formValues.quantity <= 0 ||
        !formValues.inventory_location_id
      );
    else if (formValues.purpose === "used") return Number(formValues.quantity) <= 0;
  };

  const onSubmit = (data: RemoveStockModalValuesType) => {
    const removeStockData = {
      inventory_checkouts: [
        {
          id: inventory.id,
          quantity: data.quantity,
          location_id: data.inventory_location_id,
          lot_number: data.lot_number?.lot_number || null,
        },
      ],
      spending_authority_id: data.spending_authority?.id || null,
      spending_authority_type: data.spending_authority?.type || null,
      purpose: data.purpose,
    };
    bulkCheckoutInventory(removeStockData).then(() => {
      handleClose();
      toast({
        title: "Success",
        description: "Stock removed successfully",
        status: "success",
      });
    });
  };

  const handleNextButtonClick = () => {
    if (currentStep === 1) handleSubmit(() => onSubmit(formValues))();
    else setCurrentStep((prev) => ++prev);
  };

  useEffect(() => {
    if (location && options) {
      const path = findSelectedOption(options, location);
      setPathToLocation(initial(path));
      setShowPreview(true);
    }
  }, [options, location]);

  const handleClose = () => {
    setCurrentStep(0);
    setLocation(null);
    setShowPreview(false);
    reset();
    onClose();
  };

  const bgColor = useColorModeValue("gray.50", "gray.800");
  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} size="xl" closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove from Stock</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" gap={4} w="100%">
              {currentStep === 0 && (
                <SelectCheckoutPurpose
                  control={control}
                  setValue={setValue}
                  showUsed={inventory.is_consumable}
                />
              )}
              {currentStep === 1 && (
                <>
                  <Controller
                    name="inventory_location_id"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormControl isRequired>
                        <FormLabel>Choose Location</FormLabel>
                        {showPreview ? (
                          <Stack
                            onClick={() => setShowPreview(false)}
                            direction="row"
                            flexWrap="wrap"
                            px={4}
                            py={2}
                            border="1px"
                            borderRadius="md"
                            borderColor="chakra-border-color">
                            {pathToLocation?.map((location, idx) => {
                              const isLast = idx === pathToLocation?.length - 1;
                              return (
                                <Box key={`destination-${location.label}-${location.value?.id}`}>
                                  <HStack>
                                    <Text
                                      color={isLast ? "teal.500" : "auto"}
                                      fontWeight={isLast ? "semibold" : "normal"}>
                                      {location.label}
                                    </Text>
                                    {idx < (pathToLocation?.length || 1) - 1 && (
                                      <ArrowForwardIcon />
                                    )}
                                  </HStack>
                                </Box>
                              );
                            })}
                          </Stack>
                        ) : (
                          <Box>
                            <TreeSelect
                              options={options || []}
                              placeholder="Select Location"
                              onSearchChanged={(value) => {
                                setLocationSearch(value);
                              }}
                              defaultValue={location}
                              onChange={(value) => {
                                if (value) {
                                  setLocation(value);
                                  field.onChange(value.id);
                                }
                              }}
                            />
                          </Box>
                        )}
                      </FormControl>
                    )}
                  />
                  {location && (location?.lot_numbers?.length || 1) > 1 && (
                    <Controller
                      name="lot_number"
                      control={control}
                      render={({ field }) => (
                        <FormControl>
                          <FormLabel>Choose Lot</FormLabel>
                          <Select
                            {...field}
                            useBasicStyles
                            options={
                              location?.lot_numbers?.map((lot) => ({
                                label: lot.lot_number,
                                value: lot.lot_number,
                                lot_number: lot.lot_number,
                                remaining_quantity: lot.remaining_quantity,
                                expiration_date: lot.expiration_date,
                                amount: lot.amount,
                              })) || []
                            }
                            placeholder="Choose"
                          />
                        </FormControl>
                      )}
                    />
                  )}
                  {formValues.purpose === "spending_authority" && (
                    <Controller
                      name="spending_authority"
                      control={control}
                      render={({ field }) => (
                        <FormControl isRequired>
                          <FormLabel>Choose Spending Authority</FormLabel>
                          <SpendingAuthoritySelect
                            onChange={(spendingAuthority) => {
                              setValue("spending_authority", spendingAuthority);
                            }}
                            spendingAuthority={field.value || null}
                          />
                          <WarnIfSpendingAuthorityIrrelevant
                            id={field.value?.id}
                            type={field.value?.type}
                          />
                        </FormControl>
                      )}
                    />
                  )}
                  <Controller
                    name="quantity"
                    control={control}
                    render={({ field }) => (
                      <FormControl>
                        <FormLabel>Quantity</FormLabel>
                        <Box bgColor={bgColor} width="100%" p={4} borderRadius="md">
                          <Center>
                            <Flex align="center" justify="space-between" minW={40} width="40%">
                              <IconButton
                                icon={<MinusIcon />}
                                aria-label="decrement"
                                colorScheme="red"
                                variant="outline"
                                borderRadius="full"
                                size="xs"
                                isDisabled={field.value <= 0}
                                onClick={() => field.onChange(field.value - 1)}
                              />
                              <Editable
                                defaultValue={field.value.toString()}
                                value={field.value.toString()}
                                isDisabled={false}>
                                <Heading size="3xl">
                                  <EditablePreview />
                                </Heading>
                                <Box>
                                  <EditableInput
                                    fontSize="4xl"
                                    type="number"
                                    step="0.1"
                                    w={32}
                                    max={formValues?.lot_number?.remaining_quantity || 0}
                                    onInput={(e) => {
                                      const value = e.currentTarget.value;
                                      const maxQuantity =
                                        formValues.lot_number?.remaining_quantity || 0;
                                      if (value && !isNaN(Number(value))) {
                                        const numValue = Number(value);
                                        if (numValue > maxQuantity) {
                                          field.onChange(maxQuantity);
                                          e.currentTarget.value = maxQuantity.toString();
                                        } else {
                                          field.onChange(numValue);
                                        }
                                      } else if (value === "") {
                                        field.onChange("");
                                      } else {
                                        e.currentTarget.value = field.value.toString();
                                      }
                                    }}
                                  />
                                </Box>
                              </Editable>
                              <IconButton
                                icon={<AddIcon />}
                                aria-label="increment"
                                colorScheme="teal"
                                variant="outline"
                                borderRadius="full"
                                size="xs"
                                isDisabled={
                                  field.value >= (formValues.lot_number?.remaining_quantity || 0)
                                }
                                onClick={() => field.onChange(field.value + 1)}
                              />
                            </Flex>
                          </Center>
                          {formValues.quantity > 0 &&
                            formValues.purpose === "spending_authority" && (
                              <Text textAlign="center" my={3} fontSize="sm">
                                <MoneyText
                                  as="span"
                                  fontWeight="semibold"
                                  formatOptions={{ compact: "never" }}
                                  money={formValues?.lot_number?.amount?.times(formValues.quantity)}
                                />{" "}
                                will be charged to the spending authority
                              </Text>
                            )}

                          <Divider mt={3} />
                          <Stack
                            direction="row"
                            justify={inventory.track_expiration_dates ? "space-between" : "center"}
                            mt={4}>
                            <Text fontSize="sm">
                              Available Quantity: {formValues.lot_number?.remaining_quantity}{" "}
                              {inventory.unit_of_measurement}
                            </Text>
                            {inventory.track_expiration_dates && (
                              <Text fontSize="sm">
                                Expires:{" "}
                                {formValues.lot_number?.expiration_date?.toLocaleString() || "N/A"}
                              </Text>
                            )}
                          </Stack>
                        </Box>
                      </FormControl>
                    )}
                  />
                </>
              )}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup w="100%" justifyContent={currentStep === 0 ? "end" : "space-between"}>
              {currentStep !== 0 && (
                <Button onClick={() => setCurrentStep((prev) => --prev)} variant="ghost">
                  Back
                </Button>
              )}
              <Button
                justifySelf="flex-end"
                colorScheme="teal"
                onClick={handleNextButtonClick}
                isLoading={currentStep === 1 && isLoadingBulkCheckout}
                isDisabled={disableProceedButton(formValues)}>
                {currentStep === 1 ? "Confirm" : "Next"}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

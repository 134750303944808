import { z } from "zod";
import { api } from ".";
import { useMutation } from "@tanstack/react-query";
import { invalidateService } from "./services";
import { serviceUserBulkCreateSchema } from "./service_users";
import { userMinimalSchema } from "./user";

export const SERVICE_REQUEST_SUBTASK_USER_BASE_URL = "/service_request_subtask_users";

export const serviceRequestSubtaskUserSchema = z.object({
  id: z.number(),
  service_request_subtask_id: z.number(),
  user: z.lazy(() => userMinimalSchema),
});

export const serviceRequestSubtaskUserCreateSchema = z.object({
  user_ids: z.array(z.number()),
  service_request_subtask_id: z.number(),
});

export type ServiceRequestSubtaskUserCreateParams = z.infer<
  typeof serviceRequestSubtaskUserCreateSchema
>;

export const bulkCreateServiceRequestSubtaskUser = async (
  service_request_subtask_users: ServiceRequestSubtaskUserCreateParams
) => {
  const result = await api.post(`${SERVICE_REQUEST_SUBTASK_USER_BASE_URL}/bulk`, {
    service_request_subtask_users,
  });
  return result.status === 201;
};

export const deleteServiceRequestSubtaskUser = async (id: number) => {
  await api.delete(`${SERVICE_REQUEST_SUBTASK_USER_BASE_URL}/${id}`);
};

/** hooks */

export const useBulkCreateServiceRequestSubtaskUser = (id?: number) => {
  return useMutation({
    mutationFn: bulkCreateServiceRequestSubtaskUser,
    onSuccess: invalidateService(id),
  });
};

export const useDeleteServiceRequestSubtaskUser = (id?: number) => {
  return useMutation({
    mutationFn: deleteServiceRequestSubtaskUser,
    onSuccess: invalidateService(id),
  });
};

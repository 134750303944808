import { Flex, Stack, Heading, Text, Box, Switch } from "@chakra-ui/react";
import React from "react";
import { ServicePartialData, useUpdateService } from "../../../../api/services";

export const SpendingAuthorityRequired = ({ service }: { service: ServicePartialData }) => {
  const { mutateAsync: updateService, isLoading: isUpdatingService } = useUpdateService();
  return (
    <Box w={"100%"} border="1px" borderColor="chakra-border-color" p={6} gap={4} borderRadius="md">
      <Flex justifyContent="space-between" alignItems="center">
        <Stack gap={2} width="100%">
          <Heading size="md">Spending Authority Required</Heading>
          <Stack direction={"row"} gap={2} justifyContent={"space-between"} width="100%">
            <Text textColor="gray.500">
              Enabling this will require users to set a spending authority for requests.
            </Text>
            <Switch
              colorScheme="teal"
              isChecked={service?.spending_authority_required}
              onChange={(value) => {
                updateService({
                  spending_authority_required: value.target.checked,
                  id: Number(service.id),
                });
              }}
            />
          </Stack>
        </Stack>
      </Flex>
    </Box>
  );
};

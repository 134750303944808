import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { zodParse } from "./zodParse";
import { moneySchema } from "../helpers/Money";
import { dateTimeSchema } from "../helpers/dateTime";
import { BUDGET_GROUPS_BASE_URL } from "./budget_groups";

export const BUDGET_GROUP_SCHEDULES_BASE_URL = "budget_group_schedules";

export const budgetGroupScheduleSchema = z.object({
  id: z.number(),
  amount: moneySchema,
  schedule_type: z.enum(["one_time", "monthly"]),
  memo: z.string(),
  start_date: dateTimeSchema,
  end_date: dateTimeSchema,
});

export type BudgetGroupScheduleData = z.infer<typeof budgetGroupScheduleSchema>;

export type BudgetGroupScheduleCreateParams = Omit<BudgetGroupScheduleData, "id"> & {
  budget_group_id: number;
};

export const createBudgetGroupSchedule = async (
  budget_group_schedule: BudgetGroupScheduleCreateParams
) => {
  const result = await api.post(BUDGET_GROUP_SCHEDULES_BASE_URL, { budget_group_schedule });
  return zodParse(budgetGroupScheduleSchema, result.data);
};

export const updateBudgetGroupSchedule = async (
  budget_group_schedule: Partial<BudgetGroupScheduleData>
) => {
  const result = await api.put(`${BUDGET_GROUP_SCHEDULES_BASE_URL}/${budget_group_schedule.id}`, {
    budget_group_schedule,
  });
  return zodParse(budgetGroupScheduleSchema, result.data);
};

export const invalidateBudgetGroupSchedule = (id?: number, subQuery?: string) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [BUDGET_GROUPS_BASE_URL];
  if (id) queryKey.push(id);
  if (subQuery) queryKey.push(subQuery);

  return () =>
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
};

export const useCreateBudgetGroupSchedule = () => {
  return useMutation({
    mutationFn: createBudgetGroupSchedule,
    onSettled: invalidateBudgetGroupSchedule(),
  });
};

export const useUpdateBudgetGroupSchedule = (id?: number) => {
  return useMutation({
    mutationFn: updateBudgetGroupSchedule,
    onSettled: invalidateBudgetGroupSchedule(id),
  });
};

import { z } from "zod";
import { api } from ".";
import { useMutation } from "@tanstack/react-query";
import { invalidateService } from "./services";
import { serviceAssignedUserSchema, serviceUserBulkCreateSchema } from "./service_users";

export const SERVICE_ASSIGNED_USER_BASE_URL = "/service_assigned_users";

export type ServiceAssignedUserCreateParams = z.infer<typeof serviceUserBulkCreateSchema>;
export type ServiceAssignedUserData = z.infer<typeof serviceAssignedUserSchema>;

export const bulkCreateServiceAssignedUser = async (
  service_assigned_users: ServiceAssignedUserCreateParams
) => {
  const result = await api.post(`${SERVICE_ASSIGNED_USER_BASE_URL}/bulk`, {
    service_assigned_users,
  });
  return result.status === 201;
};

export const deleteServiceAssignedUser = async (id: number) => {
  await api.delete(`${SERVICE_ASSIGNED_USER_BASE_URL}/${id}`);
};

/** hooks */

export const useBulkCreateServiceAssignedUser = (id?: number) => {
  return useMutation({
    mutationFn: bulkCreateServiceAssignedUser,
    onSuccess: invalidateService(id),
  });
};

export const useDeleteServiceAssignedUser = (id?: number) => {
  return useMutation({
    mutationFn: deleteServiceAssignedUser,
    onSuccess: invalidateService(id),
  });
};

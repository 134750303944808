import { useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { zodParse } from "./zodParse";
import { invalidateUser } from "./user";

export const USER_PREFERENCES_BASE_URL = "user_preferences";

export const userPreferenceSchema = z.object({
  hide_archived_services: z.boolean(),
});

export type UserPreference = z.infer<typeof userPreferenceSchema>;

export const updateUserPreference = async (userPreference: UserPreference) => {
  const result = await api.put(`${USER_PREFERENCES_BASE_URL}`, { user_preference: userPreference });
  return zodParse(userPreferenceSchema, result.data);
};

export const useUpdateUserPreference = (id?: number) => {
  return useMutation({
    mutationFn: updateUserPreference,
    onSuccess: invalidateUser(id),
  });
};

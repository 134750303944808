import { CollectionTable, Column, RecordLink } from "@sciencecorp/helix-components";
import React, { useMemo } from "react";
import { BudgetGroupIndexData } from "../../../../api/budget_groups";
import { MoneyText } from "../../../MoneyText";
import { BudgetItemProgressBar } from "../../../Budgets/BudgetItemProgressBar";
import { Skeleton, Stack, Tag, VStack } from "@chakra-ui/react";

type BudgetGroupListProps = {
  budgetGroups?: BudgetGroupIndexData[];
  isLoading: boolean;
};

export const BudgetGroupList = ({ budgetGroups, isLoading }: BudgetGroupListProps) => {
  const columns: Column<BudgetGroupIndexData>[] = useMemo(
    () => [
      {
        label: "Group",
        render: (budgetGroup) => (
          <VStack align="start">
            <RecordLink
              identifier={budgetGroup.name}
              link={`/finance/budgets/budget_group/${budgetGroup.id}`}
            />
            {budgetGroup.archived_at && <Tag colorScheme="red">Archived</Tag>}
          </VStack>
        ),
      },

      {
        label: "Total Funding",
        render: (budgetGroup) => (
          <MoneyText
            as="span"
            money={budgetGroup.summary.funding_amount}
            formatOptions={{ compact: "never" }}
          />
        ),
      },
      {
        label: "Average Spend",
        render: (budgetGroup) => (
          <MoneyText
            as="span"
            money={budgetGroup.summary.funding_amount.div(3)}
            formatOptions={{ compact: "never" }}
          />
        ),
      },
      {
        label: "",
        hideOnBreakpoint: ["base", "sm", "md"],
        render: (budgetGroup) => <BudgetItemProgressBar summary={budgetGroup.summary} />,
      },
    ],
    [budgetGroups]
  );

  return (
    <Skeleton isLoaded={!isLoading}>
      <CollectionTable items={budgetGroups || []} columns={columns} />
    </Skeleton>
  );
};

import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalCloseButton,
  VStack,
  ModalBody,
  Tag,
  ModalContent,
  ModalOverlay,
  Text,
  HStack,
  Button,
  Icon,
  Stack,
  useToast,
} from "@chakra-ui/react";
import {
  decryptReviewFeedback,
  newOnboardingFeedback,
  updateReviewFeedback,
} from "../../../api/review_feedback";
import { useCurrentUserQuery } from "../../../api/user";
import type { ReviewFeedbackPartialData } from "../../../api/review_feedback";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { SubmitPinModal } from "./SubmitPinModal";

type FeedbackModalProps = {
  isOpen: boolean;
  onClose: () => void;
  encryptedFeedback: ReviewFeedbackPartialData | null;
  reviewFeedbackQuery?: () => void;
  onboardingReviewFeedbackRefetch?: () => void;
};

export const OnboardingFeedbackModal: React.FC<FeedbackModalProps> = ({
  isOpen,
  onClose,
  encryptedFeedback,
  reviewFeedbackQuery,
  onboardingReviewFeedbackRefetch,
}) => {
  const currentUserQuery = useCurrentUserQuery();
  const [pin, setPin] = useState<string>("");
  const [decryptedScore, setDecryptedScore] = useState<string>("");
  const [decryptedContent, setDecryptedContent] = useState<{
    self_review: string;
    lead_review: string;
  } | null>(null);
  const [decryptionError, setDecryptionError] = useState<boolean>(false);
  const toast = useToast();

  const closeModal = () => {
    setPin("");
    setDecryptedContent(null);
    setDecryptionError(false);
    onClose();
    reviewFeedbackQuery && reviewFeedbackQuery();
  };

  const submitPin = () => {
    setDecryptionError(false);
    if (encryptedFeedback && currentUserQuery.data) {
      decryptReviewFeedback(encryptedFeedback, currentUserQuery.data, pin)
        .then((decryptedFeedback) => {
          if (!decryptedFeedback) {
            setPin("");
            setDecryptionError(true);
            return;
          }
          setDecryptionError(false);
          setDecryptedContent(decryptedFeedback.feedback);
          setDecryptedScore(decryptedFeedback.score);
        })
        .then(() => {
          if (encryptedFeedback.viewed !== undefined && !encryptedFeedback.viewed) {
            updateReviewFeedback({ id: encryptedFeedback.id, viewed: true });
          }
        })
        .catch((err) => {
          console.error(err);
          setPin("");
          setDecryptionError(true);
        });
    }
  };

  const bgColorForStatus = (status: string) => {
    switch (status) {
      case "green":
        return "green";
      case "yellow":
        return "yellow";
      case "orange":
        return "orange";
      case "red":
        return "red";
      default:
        return "gray";
    }
  };

  const labelForStatus = (status: string) => {
    switch (status) {
      case "green":
        return "You're doing great!";
      case "yellow":
      case "orange":
        return "You're doing okay for now.";
      case "red":
        return "Let's discuss";
      default:
        return "Score is missing";
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      size={decryptedContent && !decryptionError ? "xl" : "md"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {decryptedContent && !decryptionError
            ? `${encryptedFeedback?.occasion} Review for ${encryptedFeedback?.onboarding_user?.name}`
            : "PIN"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {decryptedContent && !decryptionError ? (
            <VStack spacing={8} mb={4} align="start">
              <Tag
                size="4xl"
                p={3}
                width={"100%"}
                textAlign={"center"}
                variant="solid"
                colorScheme={bgColorForStatus(decryptedScore)}>
                {labelForStatus(decryptedScore)}
              </Tag>
              <VStack align="start" spacing={2}>
                <Text fontSize="sm" lineHeight="shorter" fontWeight={"bold"}>
                  Lead's Feedback
                </Text>
                <Text fontSize="sm" lineHeight="shorter">
                  {decryptedContent.lead_review.split("\n").map((line, key) => (
                    <Text key={key}>{line}</Text>
                  ))}
                </Text>
              </VStack>
              <VStack align="start" spacing={2}>
                <Text fontSize="sm" lineHeight="shorter" fontWeight={"bold"}>
                  {encryptedFeedback?.onboarding_user?.name}'s Self Review
                </Text>
                <Text lineHeight="shorter" fontSize="sm">
                  {decryptedContent.self_review.split("\n").map((line, key) => (
                    <Text key={key}>{line}</Text>
                  ))}
                </Text>
              </VStack>
              {currentUserQuery.data?.id !== encryptedFeedback?.onboarding_user?.id ? (
                !encryptedFeedback?.is_sent ? (
                  <Stack
                    align={["none", "center"]}
                    width="100%"
                    justify="space-between"
                    direction={["column", "row"]}>
                    <VStack width="xs" align="start" spacing={2}>
                      <Text fontSize="sm" lineHeight="shorter" fontWeight={"bold"}>
                        Share Results with {encryptedFeedback?.onboarding_user?.name}
                      </Text>
                      <Text fontSize="sm" lineHeight="shorter">
                        You should only do this after your conversation with{" "}
                        {encryptedFeedback?.onboarding_user?.name}
                      </Text>
                    </VStack>
                    <Button
                      colorScheme="teal"
                      size="sm"
                      onClick={async () => {
                        if (
                          encryptedFeedback &&
                          encryptedFeedback.onboarding_user &&
                          encryptedFeedback.occasion
                        ) {
                          await newOnboardingFeedback({
                            reviewee_id: encryptedFeedback.onboarding_user.id,
                            keyring_message_id: encryptedFeedback.keyring_message.id,
                            onboarding_user_id: encryptedFeedback.onboarding_user.id,
                            review_feedback_type: encryptedFeedback.review_feedback_type,
                            occasion: encryptedFeedback.occasion,
                          });
                          await updateReviewFeedback({ id: encryptedFeedback.id, is_sent: true });
                          onboardingReviewFeedbackRefetch && onboardingReviewFeedbackRefetch();
                          closeModal();
                          toast({
                            title: "Feedback shared",
                            description: `Your feedback has been shared with ${encryptedFeedback?.onboarding_user?.name}`,
                            status: "success",
                            duration: 5000,
                            isClosable: true,
                            position: "bottom-left",
                          });
                        }
                      }}>
                      Share Result
                    </Button>
                  </Stack>
                ) : (
                  <HStack>
                    <Icon as={CheckCircleIcon} color="green.500" />
                    <Text fontWeight="semibold" fontSize="sm">
                      Sent to {encryptedFeedback?.onboarding_user?.name}
                    </Text>
                  </HStack>
                )
              ) : null}
            </VStack>
          ) : (
            <SubmitPinModal
              pin={pin}
              setPin={setPin}
              decryptionError={decryptionError}
              submitPin={submitPin}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

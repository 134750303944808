import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import {
  CollectionTable,
  Column,
  ConfirmationButton,
  EditableNumber,
  EditableText,
} from "@sciencecorp/helix-components";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { BsBoxSeam, BsTruck } from "react-icons/bs";
import { MdOutlineRemoveCircleOutline, MdOutlineFeed } from "react-icons/md";
import { RiArrowGoBackLine } from "react-icons/ri";
import { closedStatuses, PurchaseShowData, useGetPurchaseReturns } from "../../../../api/purchase";
import {
  PurchaseLineItemCreateParams,
  PurchaseLineItemData,
  PurchaseLineItemShowData,
} from "../../../../api/purchase_line_item";
import { Money } from "../../../../helpers/Money";
import { EditableMoney } from "../../../EditableMoney";
import { MoneyInput } from "../../../MoneyInput";
import { MoneyText } from "../../../MoneyText";
import { EmptyState } from "../../../shared/EmptyState";
import { updateLineItemsQuantity } from "../util";
import { EditableLink } from "./EditableLink";
import { PurchaseLineItemEditData } from "./PurchaseLineItemList";
import { ReturnModal } from "./ReturnModal";
import { useCurrency } from "../../../../contexts/CurrencyContext";
import {
  EditablePurchaseLineItemSelect,
  InventoryPurchaseLineItemInput,
} from "./InventoryPurchaseLineItemInput";
import { isShippingOrTaxOrDiscount } from "../util";

export const canBeReturnedStatuses = ["delivered", "complete"];

type PurchaseLineItemTableProps = {
  purchase?: PurchaseShowData;
  lineItems: PurchaseLineItemEditData[];
  createLineItem: (lineItem: PurchaseLineItemCreateParams) => void;
  updateLineItem: (data: Partial<PurchaseLineItemData> & Pick<PurchaseLineItemData, "id">) => void;
  deleteLineItem: (id: number) => void;
  mode: "edit" | "create";
  isPurchasingAdmin?: boolean;
};

export const PurchaseLineItemTable = ({
  mode,
  lineItems,
  purchase,
  updateLineItem: inputUpdateLineItem,
  deleteLineItem,
  isPurchasingAdmin,
}: PurchaseLineItemTableProps) => {
  const deleteBg = useColorModeValue("gray.50", "auto");
  const deleteColor = useColorModeValue("gray.500", "auto");
  const editingDisabled =
    purchase?.is_deleted ||
    (mode == "edit" && !isPurchasingAdmin && closedStatuses.includes(purchase?.status || "NONE"));
  const [selectedId, setSelectedId] = useState<number>();

  const updateLineItem = useCallback(
    (updateData: Partial<PurchaseLineItemShowData> & Pick<PurchaseLineItemData, "id">) => {
      inputUpdateLineItem(updateData);
    },
    [inputUpdateLineItem]
  );
  const { data: purchaseReturns } = useGetPurchaseReturns(purchase?.id);
  const cantBeLargerThanThis = purchase?.amount;
  const closedReturnLineItems = (purchaseReturns || [])
    .filter((ret) => ret.service_request_id)
    .flatMap((ret) => ret.purchase_return_line_items);

  const lineItemsWithRemainingQty = updateLineItemsQuantity(lineItems, closedReturnLineItems);
  const lineItemsWithRemainingQtyIds = lineItemsWithRemainingQty.map((li) => li.id);

  const returnStatusesByLineItem: Record<number, string[]> = (purchaseReturns || [])
    .flatMap((pReturn) =>
      pReturn.purchase_return_line_items.map((prli) => ({
        purchaseLineItemId: prli.purchase_line_item_id,
        status: pReturn.status,
      }))
    )
    .reduce((acc, { purchaseLineItemId, status }) => {
      if (acc[purchaseLineItemId]) {
        acc[purchaseLineItemId].push(status);
      } else {
        acc[purchaseLineItemId] = [status];
      }
      return acc;
    }, {});
  // Ensure we maintain original sorting order but keep Shipping Cost and Tax at the bottom
  const sortedLineItems = _.sortBy(lineItems, (l) => {
    if (l.item_name === "Shipping" || l.item_name === "Shipping Cost") {
      return Number.MAX_SAFE_INTEGER - 2; // Third to last
    } else if (l.item_name === "Tax") {
      return Number.MAX_SAFE_INTEGER - 1; // Second to last
    } else if (l.item_name === "Discounts" || l.item_name === "Discount") {
      return Number.MAX_SAFE_INTEGER; // Last
    } else {
      return l.id;
    }
  });
  const handleTotalPriceChange = (lineItemId: number, value: Money) => {
    updateLineItem({ id: lineItemId, unit_amount: value });
  };

  const returnReqeustIconBackgroundColor = (status: string) => {
    if (status === "in_progress" || status === "submitted") {
      return useColorModeValue("orange.100", "orange.100");
    } else if (status === "refunded" || status === "sold") {
      return useColorModeValue("green.100", "green.100");
    }
  };

  const returnRequestedTooltip = (status: string) => {
    if (status === "in_progress" || status === "submitted") {
      return "Return Requested";
    } else if (status === "refunded") {
      return "Returned";
    } else if (status === "sold") {
      return "Sold";
    }
  };
  const theme = useTheme();
  const isDiscount = (lineItem: PurchaseLineItemEditData) => {
    if (lineItem.item_name === "Discounts" || lineItem.item_name === "Discount") {
      return true;
    }
    return false;
  };
  const returnRequestedIconColor = (status: string) => {
    if (status === "in_progress" || status === "submitted") {
      return theme.colors.orange[500];
    } else if (status === "refunded" || status === "sold") {
      return theme.colors.green[500];
    }
  };
  const maxDiscountAmount = purchase?.line_items_sum?.subtract(purchase?.discount_items_sum).abs();

  const {
    isOpen: isOpenReturnModal,
    onOpen: onOpenReturnModal,
    onClose: onCloseReturnModal,
  } = useDisclosure();

  const openReturnModal = (id: number) => {
    setSelectedId(id);
    onOpenReturnModal();
  };

  const currency = useCurrency();

  const columns: (Omit<Column<PurchaseLineItemEditData>, "label"> & {
    label: JSX.Element | string;
  })[] = useMemo(
    () => [
      {
        label: "Item Name",
        weight: 4,
        render: (purchaseLineItem) => {
          let iconDiv = <Icon as={BsBoxSeam} />;
          if (
            purchaseLineItem.item_name === "Discounts" ||
            purchaseLineItem.item_name === "Discount"
          ) {
            iconDiv = <Icon as={MdOutlineRemoveCircleOutline} />;
          } else if (
            purchaseLineItem.item_name === "Shipping" ||
            purchaseLineItem.item_name === "Shipping Cost"
          ) {
            iconDiv = <Icon as={BsTruck} />;
          } else if (purchaseLineItem.item_name === "Tax") {
            iconDiv = <Icon as={MdOutlineFeed} />;
          }

          return (
            <HStack width={"100%"}>
              {mode == "create" ? (
                <InventoryPurchaseLineItemInput
                  purchaseLineItem={purchaseLineItem}
                  updateLineItem={updateLineItem}
                />
              ) : !isShippingOrTaxOrDiscount.includes(purchaseLineItem.item_name) ? (
                <EditablePurchaseLineItemSelect
                  defaultValue={purchaseLineItem}
                  onSubmit={updateLineItem}
                />
              ) : (
                <HStack>
                  {iconDiv}
                  <EditableText
                    data-testid="purchase-line-item-name-input"
                    defaultValue={purchaseLineItem.item_name || ""}
                    disabled={true}
                    onSubmit={(value) => {
                      if (value) {
                        updateLineItem({ id: purchaseLineItem.id, item_name: value });
                      }
                    }}
                  />
                </HStack>
              )}
              {(returnStatusesByLineItem[purchaseLineItem.id] || []).map((status: string) => {
                return (
                  <Tooltip
                    label={returnRequestedTooltip(status)}
                    key={`return-line-item-status-${purchaseLineItem.id}`}>
                    <Box
                      display="inline-block"
                      padding={"1"}
                      bgColor={returnReqeustIconBackgroundColor(status)}
                      borderRadius={"md"}>
                      <RiArrowGoBackLine color={returnRequestedIconColor(status)} />
                    </Box>
                  </Tooltip>
                );
              })}
            </HStack>
          );
        },
      },
      {
        label: (
          <Text w={["auto", "full"]} textAlign={["start", "start", "end"]} pr={6}>
            Qty
          </Text>
        ),
        weight: 1,
        render: (purchaseLineItem) => {
          if (mode == "create") {
            return (
              <FloatNumberInput
                purchaseLineItem={purchaseLineItem}
                updateLineItem={updateLineItem}
              />
            );
          } else {
            return (
              <HStack w="100%" display="flex" justifyContent={["start", "flex-end"]}>
                <EditableNumber
                  max={999}
                  defaultValue={purchaseLineItem.quantity}
                  disabled={editingDisabled}
                  onSubmit={(value) => {
                    if (value) {
                      updateLineItem({ id: purchaseLineItem.id, quantity: Number(value) });
                    }
                  }}
                />
              </HStack>
            );
          }
        },
      },
      {
        label: (
          <Text w={["auto", "full"]} textAlign={["start", "start", "end"]} pr={6}>
            Unit Price
          </Text>
        ),
        weight: 3,
        render: (purchaseLineItem) => {
          if (mode == "create") {
            return (
              <Box w="100%" display="flex" justifyContent={["start", "flex-end"]}>
                <MoneyInput
                  size={"sm"}
                  min={
                    isDiscount(purchaseLineItem)
                      ? Money.fromMinorUnits(-100000, currency)
                      : Money.zero(currency)
                  }
                  max={
                    isDiscount(purchaseLineItem)
                      ? maxDiscountAmount
                      : Money.fromMinorUnits(1000000000, currency)
                  }
                  value={purchaseLineItem.unit_amount}
                  onChange={(value) =>
                    isDiscount(purchaseLineItem)
                      ? handleTotalPriceChange(purchaseLineItem.id, value.abs().negate())
                      : handleTotalPriceChange(purchaseLineItem.id, value)
                  }
                />
              </Box>
            );
          } else {
            return (
              <Box w="100%" display="flex" justifyContent={["start", "flex-end"]}>
                <EditableMoney
                  min={
                    isDiscount(purchaseLineItem)
                      ? Money.fromMinorUnits(-100000, currency)
                      : Money.zero(currency)
                  }
                  max={
                    isDiscount(purchaseLineItem)
                      ? maxDiscountAmount
                      : Money.fromMinorUnits(1000000000, currency)
                  }
                  defaultValue={purchaseLineItem.unit_amount}
                  disabled={editingDisabled}
                  onSubmit={(value) =>
                    updateLineItem({
                      id: purchaseLineItem.id,
                      unit_amount: isDiscount(purchaseLineItem) ? value.abs().negate() : value,
                    })
                  }
                />
              </Box>
            );
          }
        },
      },
      {
        label: (
          <Text w={["max-content", "full"]} textAlign={["start", "end", "end"]}>
            Total Price
          </Text>
        ),
        weight: 2,
        render: (purchaseLineItem) => (
          <Text mt={{ base: "2", md: "auto" }} w="100%" textAlign={["start", "end"]}>
            {purchaseLineItem.unit_amount && purchaseLineItem.quantity ? (
              <MoneyText
                money={purchaseLineItem.unit_amount.times(purchaseLineItem.quantity)}
                formatOptions={{ compact: "never" }}
              />
            ) : (
              "$0"
            )}
          </Text>
        ),
      },
      {
        label: "",
        weight: 2,
        render: (purchaseLineItem) => (
          <Flex justifyContent={"flex-end"} width={"100%"} alignItems={"center"}>
            <EditableLink
              pr={2}
              url={purchaseLineItem.url}
              disabled={editingDisabled || false}
              onSubmit={(value) => {
                updateLineItem({ id: purchaseLineItem.id, url: value });
              }}
            />
            {mode === "create" ? (
              <IconButton
                aria-label="delete purchase line item"
                icon={<DeleteIcon />}
                size="sm"
                onClick={() => deleteLineItem(purchaseLineItem.id)}
              />
            ) : (
              <Tooltip
                label="Item is associated with an inventory item and cannot be deleted"
                isDisabled={!purchaseLineItem.has_inventory_items}>
                <Box>
                  <ConfirmationButton
                    size="sm"
                    variant="IconButton"
                    aria-label="delete purchase line item"
                    isDisabled={
                      editingDisabled ||
                      !!purchaseLineItem.has_inventory_items ||
                      !!returnStatusesByLineItem[purchaseLineItem.id]
                    }
                    icon={<DeleteIcon />}
                    label="Delete Line Item"
                    confirmationButtonLabel="Delete"
                    colorScheme="red"
                    textColor={deleteColor}
                    bg={deleteBg}
                    children="Are you sure you want to delete this line item?"
                    onConfirm={() => deleteLineItem(purchaseLineItem.id)}
                  />
                </Box>
              </Tooltip>
            )}
            {canBeReturnedStatuses.includes(purchase?.status || "NONE") &&
              !isShippingOrTaxOrDiscount.includes(purchaseLineItem.item_name) &&
              lineItemsWithRemainingQtyIds.includes(purchaseLineItem.id) && (
                <Tooltip label="Return Item">
                  <IconButton
                    bg={"transparent"}
                    isDisabled={!!purchaseLineItem.has_inventory_items}
                    aria-label="return purchase line item"
                    size="sm"
                    icon={<RiArrowGoBackLine />}
                    onClick={() => openReturnModal(purchaseLineItem.id)}
                  />
                </Tooltip>
              )}
          </Flex>
        ),
      },
    ],
    [lineItems]
  );

  return (
    <>
      <Flex p={4} flexDirection="column" width="100%" gap={4}>
        {sortedLineItems.length ? (
          <>
            <CollectionTable
              items={sortedLineItems}
              columns={columns as Column<PurchaseLineItemEditData>[]}
            />
            {mode === "create" && (
              <>
                <HStack alignSelf={"flex-end"} marginRight={4}>
                  <Text>Total Cost: </Text>{" "}
                  <MoneyText
                    fontWeight={"bold"}
                    formatOptions={{
                      compact: "never",
                    }}
                    money={sortedLineItems.reduce(
                      (acc, item) => acc.add(item.unit_amount.times(item.quantity)),
                      Money.zero(currency)
                    )}
                  />
                </HStack>
              </>
            )}
          </>
        ) : (
          <EmptyState title="There are no items added yet" size="3xs" />
        )}
      </Flex>
      {purchaseReturns && (
        <ReturnModal
          isOpen={isOpenReturnModal}
          onClose={onCloseReturnModal}
          purchaseLineItems={lineItemsWithRemainingQty}
          previousReturns={purchaseReturns}
          selectedId={selectedId}
        />
      )}
    </>
  );
};

const FloatNumberInput = ({ purchaseLineItem, updateLineItem }) => {
  const [value, setValue] = useState("1");

  return (
    <NumberInput
      allowMouseWheel={false}
      size={"sm"}
      value={value}
      onChange={(value) => {
        setValue(value);
        updateLineItem({
          id: purchaseLineItem.id,
          quantity: Number(value),
        });
      }}>
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
};

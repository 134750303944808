import { ArrowBackIcon, ArrowForwardIcon, LinkIcon, SmallCloseIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Heading,
  IconButton,
  Spinner,
  Stack,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import {
  ConfirmationButton,
  ConfirmationModal,
  EditableText,
  Facets,
  FileDownloadButton,
  RecordLink,
  Header,
  RichTextEditor,
  SplitPage,
} from "@sciencecorp/helix-components";
import { humanize, titleize } from "inflection";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ApprovableData } from "../../../api/approvals";
import { useDeleteFile } from "../../../api/blob_files";
import {
  invalidatePurchases,
  useApprovePurchase,
  useDeclinePurchase,
  useGetPurchase,
  useGetPurchaseFinancials,
  useGetPurchaseLineItems,
  useGetPurchaseReturns,
  useGetPurchaseTimelineEvents,
  usePostComment,
  useUpdatePurchase,
} from "../../../api/purchase";
import {
  SpendingAuthorityChangeRequestData,
  useApproveSpendingAuthorityChangeRequest,
  useDeclineSpendingAuthorityChangeRequest,
} from "../../../api/spending_authority_change_requests";
import { TimelineEventData } from "../../../api/timeline_events";
import { useCurrentUserQuery, userHasRole } from "../../../api/user";
import { Money } from "../../../helpers/Money";
import { ApprovalFlowSpendingAuthorityInfo } from "../../shared/ApprovalFlowSpendingAuthorityInfo";
import { ApprovalFlowTable, SpendingAuthorityApprovalFlow } from "../../shared/ApprovalFlowTable";
import { BlobUploadButton } from "../../shared/BlobUploadButton";
import { DeleteableFileDownload } from "../../shared/DeleteableFileDownload";
import { TimelineTable } from "../../shared/TimelineTable";
import { LinkifyText, purchaseRequestStatusColor } from "../util";
import { PurchaseReturnRequiredAlert } from "./PurchaseReturnRequiredAlert";
import { OrderTrackingProgress } from "./components/OrderTracking";
import { PaymentDetails } from "./components/PaymentDetails";
import { PurchaseAttributeTable } from "./components/PurchaseAttributesTable";
import { canBeReturnedStatuses } from "./components/PurchaseLineItemTable";
import { SubmittedReturnsTable } from "./components/SubmittedReturnsTable";
import { CurrencyProvider } from "../../../contexts/CurrencyContext";
import { useUserMentionOptions } from "../../../api/options";
import { isShippingOrTaxOrDiscount } from "./util";

const pendingStatuses = ["new", "needs_approval", "status_not_supplied"];

const defaultTimelineFacets = {
  event_type: {
    options: [
      { key: "comment", value: "comment", selected: false },
      { key: "approval", value: "approved", selected: false },
      { key: "Updated Line Item", value: "updated_line_item", selected: false },
      { key: "Created Line Item", value: "created_line_item", selected: false },
      { key: "Status Update", value: "updated_status", selected: false },
    ],
  },
};

const commentSelectedTimelineFacets = {
  event_type: {
    options: [
      { key: "comment", value: "comment", selected: true },
      { key: "approval", value: "approved", selected: false },
      { key: "Updated Line Item", value: "updated_line_item", selected: false },
      { key: "Created Line Item", value: "created_line_item", selected: false },
      { key: "Status Update", value: "updated_status", selected: false },
    ],
  },
};

export const PurchasePage = () => {
  const { id } = useParams();
  if (!id) return <Alert status="error">Invalid Purchase ID</Alert>;

  const [session, setSession] = useState<number>(0);
  const [content, setContent] = useState<string>("");
  const [plainText, setPlainText] = useState<string>("");
  const [timelineFacets, setTimelineFacets] = useState<Facets>(defaultTimelineFacets);
  const [timelineEvents, setTimelineEvents] = useState<TimelineEventData[]>([]);
  const [isSmallScreen] = useMediaQuery("(max-width: 767px)");

  const minimalUsers = useUserMentionOptions();

  const currentUser = useCurrentUserQuery();
  const isPurchasingAdmin = userHasRole(currentUser, "purchasing_admin") || false;
  const navigate = useNavigate();
  const eventsQuery = useGetPurchaseTimelineEvents(+id);

  const { mutateAsync: postComment } = usePostComment(+id);

  const {
    data: purchase,
    isSuccess: isSuccessPurchase,
    isLoading: isLoadingPurchase,
    isRefetching: isRefetchingPurchase,
  } = useGetPurchase(+id);

  const purchaseLineItemsQuery = useGetPurchaseLineItems(purchase?.id);
  const purchaseFinancialsQuery = useGetPurchaseFinancials(purchase?.id);

  const { data: purchaseReturns } = useGetPurchaseReturns(+id);

  const { mutate: updatePurchase, isLoading } = useUpdatePurchase(() => invalidatePurchases(+id));

  const { mutateAsync: approvePurchase, isLoading: isLoadingApprove } = useApprovePurchase();
  const { mutateAsync: declinePurchase, isLoading: isLoadingDecline } = useDeclinePurchase();

  const {
    mutateAsync: approveSpendingAuthorityChangeRequest,
    isLoading: approveSpendingAuthorityChangeRequestIsLoading,
  } = useApproveSpendingAuthorityChangeRequest();
  const {
    mutateAsync: declineSpendingAuthorityChangeRequest,
    isLoading: declineSpendingAuthorityChangeRequestIsLoading,
  } = useDeclineSpendingAuthorityChangeRequest();

  const { mutate: deleteFile } = useDeleteFile(() => invalidatePurchases(+id));
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const createComment = useCallback(() => {
    postComment({ richText: content, plainText: plainText }).then(() =>
      setSession((prev) => prev + 1)
    );
  }, [content, plainText, postComment]);

  const handlePrevPurchase = () => {
    navigate(`/services/purchasing/${purchase?.previous_active_purchase_id}`);
  };

  const handleNextPurchase = () => {
    navigate(`/services/purchasing/${purchase?.next_active_purchase_id}`);
  };

  const facetOnChange = useCallback(
    (facets) => {
      setTimelineFacets(facets);
      if (eventsQuery.data) {
        let filteredEvents: TimelineEventData[] = eventsQuery.data;
        for (const facet in facets) {
          const selectedOptions = facets[facet]?.options?.filter((option) => option.selected) ?? [];

          if (selectedOptions.length > 0) {
            const selectedValues = selectedOptions.map((option) => option.value);
            filteredEvents = filteredEvents.filter((event) =>
              selectedValues.includes(event[facet])
            );
          }
        }
        setTimelineEvents(filteredEvents);
      }
    },
    [eventsQuery.data]
  );

  useEffect(() => {
    if (eventsQuery.data) {
      if (isPurchasingAdmin) facetOnChange(commentSelectedTimelineFacets);
      else setTimelineEvents(eventsQuery.data);
    }
  }, [eventsQuery.data, isPurchasingAdmin, facetOnChange]);

  const handleConfirmCancel = () => {
    if (purchase && currentUser.data) {
      updatePurchase(
        { id: purchase.id, is_deleted: true },
        {
          onSuccess: () => {
            onClose();
            toast({
              title: "Request successfully canceled.",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
            navigate("/services/purchasing", { state: { needsRefresh: true } });
          },
        }
      );
    }
  };

  const editable =
    (isPurchasingAdmin || currentUser.data?.id === purchase?.user.id) && !purchase?.is_deleted;

  const spendingAuthorityRequest: SpendingAuthorityChangeRequestData | undefined =
    purchase?.spending_authority_change_requests[
      purchase.spending_authority_change_requests.length - 1
    ];

  const renderApprovalFlow = useCallback(
    ({ purchase, currentUser }) => {
      if (
        !purchase.root_spending_authority &&
        !purchase.spending_authority &&
        spendingAuthorityRequest
      ) {
        return (
          currentUser.isSuccess && (
            <ApprovalFlowTable
              approvable={spendingAuthorityRequest}
              expenditureType="Purchase"
              currentUser={currentUser.data}
              approve={approveSpendingAuthorityChangeRequest}
              decline={declineSpendingAuthorityChangeRequest}
              isApproveLoading={approveSpendingAuthorityChangeRequestIsLoading}
              isDeclineLoading={declineSpendingAuthorityChangeRequestIsLoading}
              userCanSubmit={purchase.user_can_submit}
            />
          )
        );
      }
      if (
        spendingAuthorityRequest &&
        spendingAuthorityRequest.approval_state !== "approved" &&
        spendingAuthorityRequest.approval_state !== "declined"
      ) {
        let approvedApprovalFlowData: ApprovableData[] = [];
        if (purchase?.approval_state === "approved") {
          approvedApprovalFlowData.push(purchase);
        }
        if (!purchase?.spending_authority_change_requests) {
          return approvedApprovalFlowData;
        } else {
          for (let i = purchase.spending_authority_change_requests.length - 1; i >= 0; i--) {
            if (purchase.spending_authority_change_requests[i]) {
              approvedApprovalFlowData.push(purchase.spending_authority_change_requests[i]);
              break;
            }
          }
        }

        return (
          currentUser.isSuccess && (
            <SpendingAuthorityApprovalFlow
              expenditureType="Purchase"
              approvedApprovalFlowData={approvedApprovalFlowData}
              currentUser={currentUser.data}
              approve={approveSpendingAuthorityChangeRequest}
              decline={declineSpendingAuthorityChangeRequest}
              isApproveLoading={approveSpendingAuthorityChangeRequestIsLoading}
              isDeclineLoading={declineSpendingAuthorityChangeRequestIsLoading}
            />
          )
        );
      } else {
        return (
          currentUser.isSuccess && (
            <ApprovalFlowTable
              currentUser={currentUser.data}
              approvable={purchase}
              expenditureType="Purchase"
              approve={approvePurchase}
              decline={declinePurchase}
              showSubmission={purchase.status === "new"}
              isApproveLoading={isLoadingApprove}
              isDeclineLoading={isLoadingDecline}
              userCanSubmit={purchase.user_can_submit}
            />
          )
        );
      }
    },
    [
      purchase,
      isLoadingApprove,
      isLoadingDecline,
      approvePurchase,
      declinePurchase,
      approveSpendingAuthorityChangeRequest,
      declineSpendingAuthorityChangeRequest,
    ]
  );

  const inUserCancelableState = ["new", "needs_approval", "awaiting_purchase"].includes(
    purchase?.status || "MISSING STATUS"
  );
  const inAdminCancelableState =
    ["awaiting_payment", "awaiting_delivery", "delivered"].includes(
      purchase?.status || "MISSING STATUS"
    ) && isPurchasingAdmin;

  const canBeCancelled =
    (inUserCancelableState || inAdminCancelableState) &&
    purchase &&
    purchase.purchase_payments_paid.isZero();

  if (
    isLoadingPurchase ||
    currentUser.isLoading ||
    purchaseLineItemsQuery.isLoading ||
    purchaseFinancialsQuery.isLoading
  ) {
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    );
  } else if (
    isSuccessPurchase &&
    currentUser.isSuccess &&
    purchaseLineItemsQuery.isSuccess &&
    purchaseFinancialsQuery.isSuccess
  ) {
    const currency = purchase.currency;

    const purchaseLineItems = purchaseLineItemsQuery.data;
    const purchaseFinancials = purchaseFinancialsQuery.data;

    const lineItemsWithPurchaseId = purchaseLineItems.map((item) => ({
      ...item,
      purchase_id: +id,
    }));

    const requiredLineItems = purchaseLineItems.filter(
      (item) => !isShippingOrTaxOrDiscount.includes(item.item_name)
    );

    const someItemsHavePrices = requiredLineItems.some((item) => item.unit_amount);

    return (
      <>
        <CurrencyProvider currency={currency}>
          <Header
            title={`Purchase ${id}`}
            crumbs={[{ label: "Purchasing", url: "/services/purchasing" }]}
            crumbsColor="teal.500"
            badge={{
              label: titleize(humanize(purchase.status)),
              colorScheme: purchaseRequestStatusColor(purchase.status),
            }}
            actions={
              isPurchasingAdmin
                ? [
                    <ConfirmationButton
                      isDisabled={!editable || !canBeCancelled}
                      label={isSmallScreen ? "Cancel" : "Cancel Request"}
                      buttonVariant="outline"
                      leftIcon={<SmallCloseIcon />}
                      variant="Button"
                      colorScheme="red"
                      confirmationButtonLabel="Cancel Request"
                      children="Are you sure you want to cancel this purchase? This action cannot be undone."
                      confirmationHeader={`Cancel Purchase ${id}`}
                      onConfirm={() => {
                        if (currentUser.data) {
                          updatePurchase({ id: +id, is_deleted: true });
                        }
                      }}
                    />,
                    <Flex justifyContent="space-between" alignItems="center">
                      <IconButton
                        icon={<ArrowBackIcon />}
                        aria-label="Previous Purchase"
                        onClick={handlePrevPurchase}
                        isDisabled={parseInt(id) <= 1}
                        mr={1}></IconButton>
                      <IconButton
                        icon={<ArrowForwardIcon />}
                        aria-label="Next Purchase"
                        onClick={handleNextPurchase}
                        isDisabled={Number(purchase.id) >= purchase.last_purchase_id}></IconButton>
                    </Flex>,
                  ]
                : []
            }
          />
          {purchase.is_deleted ? (
            <Alert status="error" mb={4}>
              <AlertIcon />
              This purchase has been closed.
            </Alert>
          ) : purchase.status === "declined" ? (
            <Alert status="error" mb={4}>
              <AlertIcon />
              This purchase has been declined.
            </Alert>
          ) : purchase.status === "delivered" ? (
            <Alert status="success" mb={4}>
              <AlertIcon />
              This purchase has been delivered.
            </Alert>
          ) : (
            <>
              {!requiredLineItems?.length ||
                (requiredLineItems?.length && !someItemsHavePrices && (
                  <Alert mb={4}>
                    <AlertIcon />
                    To proceed with this purchase, ensure at least one line item has a price
                    entered.
                  </Alert>
                ))}
              {!purchase.spending_authority && (
                <Alert status="warning" mb={4}>
                  <AlertIcon />
                  This purchase needs to be matched with a budgetary spending authority before it
                  can be approved and purchased.
                </Alert>
              )}
              {!purchase.pending_within_budget && purchase.spending_authority && (
                <Alert status="warning" mb={4}>
                  <AlertIcon />
                  This purchase exceeds the remaining funding for the selected spending authority.
                </Alert>
              )}
            </>
          )}
          <Box overflowX="auto" overflowY="hidden">
            <SplitPage
              breakpoint="lg"
              sidebarWidth="350px"
              sidebarWidthXL="450px"
              sidebar={
                <VStack spacing={3} align="start" w="100%">
                  {purchase.spending_authority?.type === "ServiceRequest" && (
                    <VStack
                      align="start"
                      p={4}
                      width="100%"
                      borderRadius="md"
                      border="1px"
                      spacing={6}
                      borderColor="chakra-border-color">
                      <Box p={2} bg={useColorModeValue("green.200", "green.500")} borderRadius="md">
                        <LinkIcon boxSize={6} />
                      </Box>
                      <VStack spacing={3} align="start">
                        <Text fontSize="sm">This purchase is linked to</Text>
                        <RecordLink
                          type={"Service Request"}
                          identifier={purchase.spending_authority?.name || ""}
                          link={`/services/requests/${purchase.spending_authority?.id}`}
                        />
                      </VStack>
                    </VStack>
                  )}
                  <Box w="100%">
                    <PurchaseAttributeTable
                      purchase={purchase}
                      isPurchasingAdmin={isPurchasingAdmin}
                      currentUser={currentUser?.data}
                    />
                  </Box>
                  {renderApprovalFlow({ purchase, currentUser })}
                </VStack>
              }
              main={
                <VStack maxWidth="1280" align="start" spacing={6}>
                  {!purchaseLineItems?.length ? (
                    <Alert status="info" mb={4}>
                      <AlertIcon />
                      To get started, add price estimates for all items to be bought.
                    </Alert>
                  ) : null}
                  {isPurchasingAdmin &&
                    currentUser.data &&
                    purchaseReturns &&
                    purchaseReturns.length > 0 &&
                    !purchaseReturns[purchaseReturns.length - 1].service_request_id && (
                      <PurchaseReturnRequiredAlert
                        user={purchaseReturns[purchaseReturns.length - 1].user}
                        purchase={purchase}
                        purchaseReturnLineItems={
                          purchaseReturns[purchaseReturns.length - 1].purchase_return_line_items
                        }
                      />
                    )}
                  <Flex gap={4} direction={{ base: "column", lg: "row" }} width="100%">
                    <Flex
                      flex="1"
                      direction="column"
                      p={6}
                      gap={4}
                      w={{ base: "100%", lg: "50%" }}
                      border={"1px"}
                      borderColor="chakra-border-color"
                      borderRadius="md">
                      <Heading size="md">Details</Heading>
                      <Box width="100%">
                        <EditableText
                          multiline
                          disabled={!editable}
                          defaultValue={purchase.description || undefined}
                          preview={
                            <LinkifyText text={purchase.description || ""} maxLinkChars={40} />
                          }
                          onSubmit={(value) => {
                            if (value) updatePurchase({ id: +id, description: value });
                          }}
                        />
                      </Box>
                      {currentUser.data?.id === purchase.user.id ? (
                        <VStack align="start">
                          <Heading size="md">Attachments</Heading>
                          <Stack direction="row" flexWrap="wrap" align="center">
                            {purchase.uploaded_files.map((file) => (
                              <DeleteableFileDownload
                                key={file.filename}
                                file={file}
                                deleteFile={deleteFile}
                              />
                            ))}
                            <BlobUploadButton
                              fileableColumn="uploaded_files"
                              recordId={+id}
                              recordType="Purchase"
                              onSuccessCallback={() => invalidatePurchases(+id)}
                            />
                          </Stack>
                        </VStack>
                      ) : purchase.uploaded_files.length ? (
                        <VStack align="start">
                          <Heading size="md">Attachments</Heading>
                          <HStack flexWrap="wrap">
                            {purchase.uploaded_files.map((file) => (
                              <FileDownloadButton
                                key={file.id}
                                maxW="100%"
                                file={file.app_href}
                                filename={file.filename}
                              />
                            ))}
                          </HStack>
                        </VStack>
                      ) : null}
                    </Flex>
                    <Flex w={{ base: "100%", lg: "50%" }}>
                      <ApprovalFlowSpendingAuthorityInfo
                        expenditure={purchase}
                        type="Purchase"
                        currentUser={currentUser.data}
                        spendingAuthority={purchase.spending_authority || null}
                        rootSpendingAuthority={purchase.root_spending_authority}
                        pendingAmount={
                          pendingStatuses.includes(purchase.status)
                            ? purchase.line_items_sum
                            : Money.zero(currency)
                        }
                        userCanSubmit={purchase.user_can_submit}
                      />
                    </Flex>
                  </Flex>
                  {purchase.fully_approved && (
                    <Flex
                      flex="1"
                      direction="column"
                      p={6}
                      gap={4}
                      border={"1px"}
                      borderColor="chakra-border-color"
                      borderRadius="md"
                      h="100%"
                      w="100%">
                      <Heading size="md">Order Tracking</Heading>
                      {currentUser?.data && (
                        <OrderTrackingProgress
                          isPurchasingAdmin={isPurchasingAdmin}
                          purchase={purchase}
                          currentUser={currentUser.data}
                          onSuccessCallback={() => invalidatePurchases(+id)}
                        />
                      )}
                    </Flex>
                  )}
                  <Divider />
                  {currentUser.data && purchaseFinancials && purchaseLineItems && (
                    <PaymentDetails
                      purchase={purchase}
                      isRefetchingPurchase={isRefetchingPurchase}
                      purchaseFinancials={purchaseFinancials}
                      isPurchasingAdmin={isPurchasingAdmin}
                      currentUser={currentUser.data}
                    />
                  )}

                  {currentUser.data &&
                    purchaseReturns &&
                    lineItemsWithPurchaseId &&
                    canBeReturnedStatuses.includes(purchase.status) && (
                      <SubmittedReturnsTable
                        returns={purchaseReturns}
                        user={currentUser.data}
                        lineItems={lineItemsWithPurchaseId}
                        purchase={purchase}
                        isPurchasingAdmin={isPurchasingAdmin}
                      />
                    )}

                  <Box alignSelf="end">
                    <Facets variant="button" facets={timelineFacets} onChange={facetOnChange} />
                  </Box>
                  {eventsQuery.isSuccess ? (
                    <TimelineTable
                      timelineable_id={purchase.id}
                      timelineable_type={"Purchase"}
                      events={timelineEvents}
                      onComment={invalidatePurchases(purchase.id, "events")}
                      disableCommentBox={true}
                    />
                  ) : (
                    <Spinner />
                  )}
                  <Divider />
                  <VStack width="100%" spacing={0}>
                    <HStack align="baseline" width="100%">
                      <Avatar size="sm" src={currentUser.data?.picture_uri} />
                      <Box flex="1" key={`comment-${session}`}>
                        <RichTextEditor
                          mentionMap={{ "@": minimalUsers }}
                          onChange={(raw, rich) => {
                            setContent(raw);
                            setPlainText(rich ?? "");
                          }}
                          placeholder="Leave a comment..."
                        />
                      </Box>
                    </HStack>
                    <HStack width="full" justify="flex-end" my={4}>
                      <Button
                        colorScheme="red"
                        onClick={onOpen}
                        isDisabled={
                          !editable ||
                          !["new", "needs_approval", "awaiting_purchase"].includes(purchase?.status)
                        }>
                        Cancel Request
                      </Button>

                      <ConfirmationModal
                        colorScheme="red"
                        header="Cancel Purchase Request"
                        confirmText="Confirm Cancel"
                        isOpen={isOpen}
                        onClose={onClose}
                        onClick={handleConfirmCancel}>
                        Are you sure you want to cancel this purchase? This action cannot be undone.
                      </ConfirmationModal>

                      <Button onClick={() => createComment()} alignSelf="end">
                        Comment
                      </Button>
                    </HStack>
                  </VStack>
                </VStack>
              }
            />
          </Box>
        </CurrencyProvider>
      </>
    );
  } else {
    return <Alert>Error Loading Purchase</Alert>;
  }
};

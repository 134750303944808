import { AddIcon, ChevronUpIcon, ChevronDownIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  useDisclosure,
  Flex,
  Badge,
  Button,
  VStack,
  Icon,
  HStack,
  IconButton,
  Center,
  Box,
  Text,
  Tooltip,
  Checkbox,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import { ConfirmationButton, EditableText } from "@sciencecorp/helix-components";
import React, { useState } from "react";
import { useDebounced } from "../../../hooks/useDebouncedUpdate";
import { PreviewUserSelect } from "../../../Users/shared/UserSelect";
import { AddUserToSubtaskModal } from "../DetailPage/AddUserToSubtask";
import { DebouncedFunc } from "lodash";
import {
  invalidateServiceRequest,
  ServiceRequestShowData,
  useGetAllServiceRequestSubtasksQuery,
  useResetServiceRequestSubtasks,
} from "../../../../api/service_requests";
import { ServicePartialData } from "../../../../api/services";
import { BsGrid3X3 } from "react-icons/bs";
import {
  useCreateServiceRequestSubtask,
  useDeleteServiceRequestSubtask,
  useMarkAsCompleteServiceRequestSubtask,
  useMarkAsIncompleteServiceRequestSubtask,
  useReorderServiceRequestSubtask,
  useUpdateServiceRequestSubtask,
} from "../../../../api/service_request_subtasks";
import {
  useBulkCreateServiceRequestSubtaskUser,
  useDeleteServiceRequestSubtaskUser,
} from "../../../../api/service_request_subtask_users";
import { FaRegTrashAlt } from "react-icons/fa";
export type ServiceRequestSubtasksProps = {
  service: ServicePartialData;
  serviceRequest: ServiceRequestShowData;
  isServiceCreation: boolean;
};

export const ServiceRequestSubtasks = ({
  service,
  serviceRequest,
  isServiceCreation,
}: ServiceRequestSubtasksProps) => {
  const {
    data: serviceRequestSubtasks,
    isLoading: isLoadingGetAllServiceRequestSubtasks,
    isError: isErrorGetAllServiceRequestSubtasks,
  } = useGetAllServiceRequestSubtasksQuery(serviceRequest.id);
  const invalidateSubtasks = invalidateServiceRequest(serviceRequest.id);
  const [subtaskId, setSubtaskId] = useState<number | null>(null);
  const { mutateAsync: createServiceRequestSubtask, isLoading: isCreatingServiceRequestSubtask } =
    useCreateServiceRequestSubtask(serviceRequest.id);
  const { mutateAsync: updateServiceRequestSubtask, isLoading: isUpdatingServiceRequestSubtask } =
    useUpdateServiceRequestSubtask(serviceRequest.id);
  const { mutateAsync: deleteServiceRequestSubtask, isLoading: isDeletingServiceRequestSubtask } =
    useDeleteServiceRequestSubtask(serviceRequest.id);
  const {
    mutateAsync: reorderServiceRequestSubtask,
    isLoading: isReorderingServiceRequestSubtask,
  } = useReorderServiceRequestSubtask(serviceRequest.id);
  const reorderServiceRequestSubtaskDebounced: DebouncedFunc<typeof reorderServiceRequestSubtask> =
    useDebounced(reorderServiceRequestSubtask, [service.id], 1000);
  const {
    mutateAsync: markAsCompleteServiceRequestSubtask,
    isLoading: isMarkingAsCompleteServiceRequestSubtask,
  } = useMarkAsCompleteServiceRequestSubtask(serviceRequest.id);
  const {
    mutateAsync: markAsIncompleteServiceRequestSubtask,
    isLoading: isMarkingAsIncompleteServiceRequestSubtask,
  } = useMarkAsIncompleteServiceRequestSubtask(serviceRequest.id);
  const { mutateAsync: resetServiceRequestSubtasks, isLoading: isResettingServiceRequestSubtasks } =
    useResetServiceRequestSubtasks(serviceRequest.id);

  const handleAddSubtask = () => {
    createServiceRequestSubtask({
      service_request_id: serviceRequest.id,
      name: `Subtask ${serviceRequestSubtasks?.length ?? 0 + 1}`,
      description: null,
      order: serviceRequestSubtasks?.length ?? 0 + 1,
    });
  };

  const handleDeleteSubtask = (id: number) => {
    deleteServiceRequestSubtask(id);
  };

  const handleUpArrowSubtask = (id: number) => {
    if (!serviceRequestSubtasks) return;
    const index = serviceRequestSubtasks.findIndex((task) => task.id === id);
    if (index <= 0) return;
    const newSubtasks = [...serviceRequestSubtasks];
    [newSubtasks[index], newSubtasks[index - 1]] = [newSubtasks[index - 1], newSubtasks[index]];
    reorderServiceRequestSubtaskDebounced({ id, order: newSubtasks.map((task) => task.id) });
  };

  const handleDownArrowSubtask = (id: number) => {
    if (!serviceRequestSubtasks) return;
    const index = serviceRequestSubtasks.findIndex((task) => task.id === id);
    if (index === -1 || index === serviceRequestSubtasks.length - 1) return;
    const newSubtasks = [...serviceRequestSubtasks];
    [newSubtasks[index], newSubtasks[index + 1]] = [newSubtasks[index + 1], newSubtasks[index]];
    reorderServiceRequestSubtaskDebounced({ id, order: newSubtasks.map((task) => task.id) });
  };

  const handleResetSubtasks = () => {
    resetServiceRequestSubtasks(serviceRequest.id);
  };

  const {
    isOpen: isOpenAssignEmployeesModal,
    onOpen: onOpenAssignEmployeesModal,
    onClose: onCloseAssignEmployeesModal,
  } = useDisclosure();
  const {
    mutateAsync: bulkCreateServiceRequestSubtaskUser,
    isLoading: isCreatingServiceRequestSubtaskUser,
  } = useBulkCreateServiceRequestSubtaskUser(serviceRequest.id);
  const {
    mutateAsync: deleteServiceRequestSubtaskUser,
    isLoading: isDeletingServiceRequestSubtaskUser,
  } = useDeleteServiceRequestSubtaskUser(serviceRequest.id);

  if (isLoadingGetAllServiceRequestSubtasks) return <Spinner />;
  if (isErrorGetAllServiceRequestSubtasks) return <Text>Error loading subtasks</Text>;
  const bgColor = useColorModeValue("gray.100", "gray.400");
  const checkedBgColor = useColorModeValue("teal.50", "teal.800");
  const checkedBorderColor = useColorModeValue("teal.500", "teal.100");
  return (
    <Box w={"100%"} border="1px" borderColor="chakra-border-color" p={6} gap={4} borderRadius="md">
      {subtaskId && (
        <AddUserToSubtaskModal
          serviceRequestId={serviceRequest.id}
          serviceSubtaskId={subtaskId}
          isOpen={isOpenAssignEmployeesModal}
          onClose={onCloseAssignEmployeesModal}
          serviceUsers={
            serviceRequestSubtasks
              ?.find((subtask) => subtask.id === subtaskId)
              ?.service_request_subtask_users?.map((user) => user.user) ?? []
          }
          onSubmit={(params) =>
            bulkCreateServiceRequestSubtaskUser({
              service_request_subtask_id: params.service_subtask_id,
              user_ids: params.user_ids,
            })
          }
          isLoading={isCreatingServiceRequestSubtaskUser}
        />
      )}
      <Flex
        justify="space-between"
        alignItems={{ base: "flex-start", md: "center" }}
        mb={4}
        direction={{ base: "column", md: "row" }}
        gap={4}>
        <Text fontWeight="bold" fontSize="lg" gap={2}>
          Subtasks
          <Badge
            colorScheme={
              serviceRequestSubtasks?.every((subtask) => subtask.completed)
                ? "green"
                : serviceRequestSubtasks?.some((subtask) => subtask.completed)
                ? "teal"
                : "gray"
            }
            marginLeft={2}>{`${
            serviceRequestSubtasks?.filter((subtask) => subtask.completed).length
          } OF ${serviceRequestSubtasks?.length} COMPLETED`}</Badge>
        </Text>

        {!isServiceCreation && serviceRequest.can_edit_service_request && (
          <HStack>
            {!serviceRequestSubtasks?.every((subtask) => subtask.from_default_subtasks) && (
              <ConfirmationButton
                variant={"Button"}
                label="Reset Subtasks"
                colorScheme="teal"
                buttonVariant="ghost"
                confirmationButtonLabel="Reset"
                size="sm"
                aria-label="Reset Subtasks"
                children="Reset will return the subtasks to the default state defined by the team lead. This action cannot be undone."
                onConfirm={handleResetSubtasks}
              />
            )}
            {serviceRequest.can_edit_service_request && (
              <Tooltip label="Add Subtask">
                <IconButton
                  onClick={handleAddSubtask}
                  icon={<AddIcon />}
                  size="xs"
                  aria-label="Add Subtask">
                  Add Subtask
                </IconButton>
              </Tooltip>
            )}
          </HStack>
        )}
      </Flex>
      {isServiceCreation && serviceRequestSubtasks?.length > 0 && (
        <Text mt={-2} mb={4}>
          Add tasks that you want the assigned employee to complete when fulfilling the request
        </Text>
      )}
      <VStack spacing={0} align="stretch">
        {serviceRequestSubtasks?.length === 0 ? (
          !serviceRequest.can_edit_service_request ? (
            <Text mt={-2} mb={4}>
              This service request has no subtasks associated with it.
            </Text>
          ) : (
            <VStack spacing={4} align="center">
              <Icon as={BsGrid3X3} color={bgColor} boxSize={14} />
              <Text color="gray.500" textAlign="center" fontWeight="semibold" w="50%">
                Add tasks that you want the assigned employee to complete when fulfilling the
                request
              </Text>
              <Button
                onClick={handleAddSubtask}
                leftIcon={<AddIcon />}
                variant="solid"
                isLoading={isCreatingServiceRequestSubtask}>
                Add Subtasks
              </Button>
            </VStack>
          )
        ) : (
          serviceRequestSubtasks?.map((task, idx) => (
            <Box
              key={task.id}
              px={{ base: 0, md: 4 }}
              py={{ base: 2, md: 4 }}
              backgroundColor={task.completed ? checkedBgColor : "transparent"}
              borderBottom={task.completed ? "2px" : "1px"}
              borderColor={task.completed ? checkedBorderColor : "gray.100"}>
              <Flex
                justify="space-between"
                align="center"
                gap={4}
                direction={{ base: "column", md: "row" }}>
                <VStack align="start" width={{ base: "100%", md: "70%" }} spacing={0}>
                  <HStack>
                    <Box fontWeight="semibold">
                      <EditableText
                        isLoading={isUpdatingServiceRequestSubtask}
                        defaultValue={task.name}
                        onSubmit={(value) => {
                          if (value) {
                            updateServiceRequestSubtask({
                              id: task.id,
                              name: value,
                            });
                          }
                        }}
                      />
                    </Box>
                    {task.from_default_subtasks && (
                      <Badge colorScheme="teal" fontSize="xs">
                        Default
                      </Badge>
                    )}
                  </HStack>
                  <EditableText
                    minWidth="100%"
                    isLoading={isUpdatingServiceRequestSubtask}
                    preview={
                      <Text
                        noOfLines={3}
                        fontSize="sm"
                        color={useColorModeValue("gray.500", "gray.200")}>
                        {task.description ?? "Enter short description"}
                      </Text>
                    }
                    defaultValue={task.description ?? ""}
                    onSubmit={(value) => {
                      if (value) {
                        updateServiceRequestSubtask({
                          id: task.id,
                          description: value,
                        });
                      }
                    }}
                  />
                </VStack>
                <HStack width={{ base: "100%", md: "30%" }}>
                  {serviceRequest.can_edit_service_request && (
                    <>
                      <IconButton
                        size="sm"
                        aria-label="Move Up"
                        isDisabled={idx === 0 || isReorderingServiceRequestSubtask}
                        icon={<ChevronUpIcon />}
                        variant="ghost"
                        onClick={() => handleUpArrowSubtask(task.id)}
                      />
                      <IconButton
                        size="sm"
                        aria-label="Move Down"
                        isDisabled={
                          idx === serviceRequestSubtasks?.length - 1 ||
                          isReorderingServiceRequestSubtask
                        }
                        icon={<ChevronDownIcon />}
                        variant="ghost"
                        onClick={() => handleDownArrowSubtask(task.id)}
                      />

                      <IconButton
                        aria-label="Delete Subtask"
                        icon={<FaRegTrashAlt />}
                        size="sm"
                        isLoading={isDeletingServiceRequestSubtask}
                        onClick={() => {
                          handleDeleteSubtask(task.id);
                          invalidateSubtasks();
                        }}
                        sx={{
                          bg: useColorModeValue("red.50", "red.800"),
                          color: useColorModeValue("red.500", "red.100"),
                          _hover: {
                            bg: useColorModeValue("red.100", "red.700"),
                          },
                        }}
                      />
                    </>
                  )}
                </HStack>
                <HStack width={{ base: "100%", md: "30%" }}>
                  <PreviewUserSelect
                    handleDelete={deleteServiceRequestSubtaskUser}
                    addedUsers={
                      task.service_request_subtask_users?.map((user) => ({
                        id: user.id,
                        user: user.user,
                      })) ?? []
                    }
                    additionalUsersTitle="Subtask User"
                    isDisabled={false}
                    isLoading={isDeletingServiceRequestSubtaskUser}
                    invalidate={invalidateSubtasks}
                  />
                  {serviceRequest.can_edit_service_request && (
                    <>
                      <Tooltip label="Add Assignee">
                        <IconButton
                          marginLeft={8}
                          icon={<AddIcon />}
                          aria-label="Add Assignee"
                          size="xs"
                          onClick={() => {
                            setSubtaskId(task.id);
                            onOpenAssignEmployeesModal();
                          }}
                        />
                      </Tooltip>
                      <Checkbox
                        colorScheme="teal"
                        size="lg"
                        marginLeft={8}
                        isChecked={task.completed}
                        isDisabled={
                          isMarkingAsCompleteServiceRequestSubtask ||
                          isMarkingAsIncompleteServiceRequestSubtask
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            markAsCompleteServiceRequestSubtask(task.id);
                          } else {
                            markAsIncompleteServiceRequestSubtask(task.id);
                          }
                        }}
                      />
                    </>
                  )}
                </HStack>
              </Flex>
            </Box>
          ))
        )}
      </VStack>
      {serviceRequestSubtasks &&
        serviceRequestSubtasks.length > 0 &&
        serviceRequest.can_edit_service_request && (
          <Center>
            <Box>
              <Button
                marginTop={4}
                onClick={handleAddSubtask}
                colorScheme="gray"
                leftIcon={<AddIcon />}
                isLoading={isCreatingServiceRequestSubtask}>
                Add New Subtask
              </Button>
            </Box>
          </Center>
        )}
    </Box>
  );
};

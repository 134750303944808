import { z } from "zod";
import { api } from ".";
import { useMutation } from "@tanstack/react-query";
import { invalidateService } from "./services";

export const SERVICE_INVENTORY_BOM_BASE_URL = "/service_inventory_boms";

export const serviceInventoryBomSchema = z.object({
  id: z.number(),
  service_id: z.number(),
  inventory_bom_id: z.number(),
  inventory_bom_name: z.string(),
});

export type ServiceInventoryBomData = z.infer<typeof serviceInventoryBomSchema>;
export type CreateServiceInventoryBomData = Omit<
  ServiceInventoryBomData,
  "id" | "inventory_bom_name"
>;

export const createServiceInventoryBom = async (
  service_inventory_bom: CreateServiceInventoryBomData
) => {
  const result = await api.post(`${SERVICE_INVENTORY_BOM_BASE_URL}`, {
    service_inventory_bom,
  });
  return result.status === 201;
};

export const deleteServiceInventoryBom = async (id: number) => {
  await api.delete(`${SERVICE_INVENTORY_BOM_BASE_URL}/${id}`);
};

/** hooks */

export const useCreateServiceInventoryBom = () => {
  return useMutation({
    mutationFn: createServiceInventoryBom,
    onSuccess: invalidateService(),
  });
};

export const useDeleteServiceInventoryBom = (id?: number) => {
  return useMutation({
    mutationFn: deleteServiceInventoryBom,
    onSuccess: invalidateService(id),
  });
};

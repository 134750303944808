import { Box, Checkbox, HStack, Spacer, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { DateTime } from "luxon";
import React from "react";
import { useUpdateCandidate } from "../../../api/candidates";
import { useUpdateNotification } from "../../../api/notifications";
import { useApprovePurchase, useDeclinePurchase } from "../../../api/purchase";
import { useApproveSubscription, useDeclineSubscription } from "../../../api/subscription";
import { NotificationContent, NotificationItemProps } from "../util";
import { getActionButtons } from "./ActionButtons";
import { getRecordLink } from "./ModelRecordLink";
import { useApproveReimbursement, useDeclineReimbursement } from "../../../api/reimbursement";
import {
  useApproveSpendingAuthorityChangeRequest,
  useDeclineSpendingAuthorityChangeRequest,
} from "../../../api/spending_authority_change_requests";

export const ActionNotificationItem = ({
  notification,
  currentUser,
  setSelectedNotifications,
  selectedNotifications,
}: NotificationItemProps) => {
  const now = DateTime.now();
  const createdDateFromISO = DateTime.fromISO(notification.created_at);
  const handleNotificationSelection = () => {
    const index = selectedNotifications.findIndex((n) => n.id === notification.id);

    if (index > -1) {
      const newSelectedNotifications = [...selectedNotifications];
      newSelectedNotifications.splice(index, 1);
      setSelectedNotifications(newSelectedNotifications);
    } else {
      setSelectedNotifications([...selectedNotifications, notification]);
    }
  };

  const bgColor = selectedNotifications.some((n) => n.id === notification.id)
    ? useColorModeValue("teal.50", "teal.800")
    : useColorModeValue("white", "gray.700");

  const diffText = createdDateFromISO.toRelative({ base: now, style: "long" });
  const approvePurchase = useApprovePurchase();
  const declinePurchase = useDeclinePurchase();
  const { mutate: updateNotification } = useUpdateNotification();
  const updateCandidate = useUpdateCandidate();
  const approveSubscription = useApproveSubscription();
  const declineSubscription = useDeclineSubscription();
  const approveReimbursement = useApproveReimbursement();
  const declineReimbursement = useDeclineReimbursement();
  const approveSACR = useApproveSpendingAuthorityChangeRequest();
  const declineSACR = useDeclineSpendingAuthorityChangeRequest();
  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      justify="space-between"
      align={{ lg: "center" }}
      width="100%"
      borderLeft={notification.read ? "none" : "4px"}
      borderBottom="1px"
      borderLeftColor="red.500"
      py={3}
      px={6}
      borderBottomColor="chakra-border-color"
      bg={bgColor}>
      <HStack align="start" flexGrow={1} w={{ base: "100%", md: "40%" }}>
        <Checkbox
          colorScheme="teal"
          isChecked={selectedNotifications.some((n) => n.id === notification.id)}
          onChange={handleNotificationSelection}
        />
        <Box>
          <NotificationContent notification={notification} currentUser={currentUser} />
        </Box>
      </HStack>
      <Spacer />
      <Box alignSelf="center" mb={2}>
        {getRecordLink(notification)}
      </Box>
      <Spacer />
      <Stack
        w={{ base: "100%", lg: "30%" }}
        justify="space-between"
        direction="row"
        align={{ base: "end", lg: "center" }}>
        <Text fontSize="sm" color={useColorModeValue("gray.500", "gray.400")} fontWeight="medium">
          {diffText}
        </Text>

        {getActionButtons({
          notification,
          currentUser,
          approvePurchase,
          declinePurchase,
          approveSubscription,
          declineSubscription,
          updateNotification,
          updateCandidate,
          approveReimbursement,
          declineReimbursement,
          approveSACR,
          declineSACR,
        })}
      </Stack>
    </Stack>
  );
};

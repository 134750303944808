import { useMutation, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import bcrypt from "bcryptjs";
import forge from "node-forge";
import { z } from "zod";
import { api } from ".";
import { featureFlagPartialSchema } from "./feature_flag";
import { rolePartialSchema } from "./role";
import {
  buildSpendingAuthorityOptions,
  SpendingAuthorityTree,
  spendingAuthorityTreeSchema,
} from "./spending_authority_tree";
import { teamPartialSchema } from "./team";
import { userCredentialShowSchema } from "./user_credentials";
import { isNewDeploymentVersion } from "../helpers/isNewDeploymentVersion";
import { zodParse } from "./zodParse";
import { userPreferenceSchema } from "./user_preferences";

export const USERS_BASE_URL = "users";

const userMembershipSchema = z.object({
  id: z.number(),
  team: z.lazy(() => teamPartialSchema),
  is_lead: z.boolean(),
});

export const userMinimalSchema = z.object({
  id: z.number(),
  name: z.string(),
  picture_uri: z.string().url(),
  app_href: z.string().url(),
});

export const userPartialSchema = userMinimalSchema.extend({
  id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  name: z.string(),
  email: z.string().email(),
  picture_uri: z.string().url(),
  public_key: z.string().nullable().optional(),
  joined_at: z.string().nullable().optional(),
  employee_status: z.string().nullable().optional(),
  employee_title: z.string().nullable().optional(),
  biography: z.record(z.unknown()).nullable(),
  birth_date: z.string().nullable().optional(),
  desk_preference: z.string().nullable().optional(),
  onboarding_lead_id: z.number().nullable().optional(),
  orcid: z.string().nullable().optional(),
  show_on_homepage: z.boolean(),
  budget_id: z.number().nullable(),
  roles: z.array(rolePartialSchema).optional(),
  default_currency: z.string().nullable().optional(),
});

export const userIndexSchema = z.array(userPartialSchema);
export const userShowSchema = userPartialSchema.extend({
  team_memberships: z.array(userMembershipSchema),
  leads: z.array(userPartialSchema),
  direct_reports: z.array(userPartialSchema),
  user_preference: z.array(userPreferenceSchema),
});
export const userLoggedInSchema = userPartialSchema.extend({
  has_notifications: z.boolean(),
  public_key: z.string().nullable(),
  encrypted_private_key: z.string().optional().nullable(),
  feature_flags: z.array(featureFlagPartialSchema),
  assistant_url: z.string().optional(),
  roles: z.array(z.lazy(() => rolePartialSchema)),
  leads: z.array(userPartialSchema),
  team_memberships: z.array(userMembershipSchema),
  has_outstanding_prompts: z.boolean(),
  top_level_team_lead: z.boolean(),
  budget_or_budget_group_lead: z.boolean(),
  projects: z.array(
    z.object({
      id: z.number(),
      title: z.string(),
      arc_title: z.string(),
    })
  ),
  detectedNewDeploymentVersion: z.boolean().optional(),
  user_preference: z.array(userPreferenceSchema),
});

export const userUserCredentialSchema = z.object({
  active_credentials: z.array(userCredentialShowSchema),
  unfulfilled_credentials: z.array(userCredentialShowSchema),
  expired_credentials: z.array(userCredentialShowSchema),
});

export const userEventsSchema = z.object({
  work_anniversaries: z.array(userPartialSchema),
  birthdays: z.array(userPartialSchema),
});

export type UserPartialData = z.infer<typeof userPartialSchema>;
export type UserIndexData = z.infer<typeof userIndexSchema>;
export type UserShowData = z.infer<typeof userShowSchema>;
export type UserLoggedInData = z.infer<typeof userLoggedInSchema>;
export type UserEvents = z.infer<typeof userEventsSchema>;
export type UserMinimalData = z.infer<typeof userMinimalSchema>;

/** queries */
export const getCurrentUser = async () => {
  const result = await api.get(`${USERS_BASE_URL}/me`);
  let detectedNewDeploymentVersion = isNewDeploymentVersion({ result: result });
  let userPreference = result.data.user_preference || [];
  return zodParse(userLoggedInSchema, {
    ...result.data,
    detectedNewDeploymentVersion: detectedNewDeploymentVersion,
    user_preference: userPreference,
  });
};

export const getSpendingAuthority = async (
  searchTerm?: string
): Promise<SpendingAuthorityTree[]> => {
  const result = await api.get(
    `${USERS_BASE_URL}/me/spending_authority_tree`,
    (searchTerm && {
      params: {
        search_term: searchTerm,
      },
    }) ||
      undefined
  );
  return zodParse(z.array(spendingAuthorityTreeSchema), result.data);
};

export const getRecentUsers = async () => {
  const result = await api.get(`${USERS_BASE_URL}/recent_hires`);
  return zodParse(z.array(userLoggedInSchema), result.data);
};

export const getEvents = async () => {
  const result = await api.get(`${USERS_BASE_URL}/events`);
  return zodParse(userEventsSchema, result.data);
};

export const getUsers = async () => {
  const result = await api.get(USERS_BASE_URL);
  return zodParse(userIndexSchema, result.data);
};

export const getMinimalUsers = async () => {
  const result = await api.get(`${USERS_BASE_URL}/minimal`);
  return zodParse(z.array(userMinimalSchema), result.data);
};

export const getUser = async (id: number | null | undefined) => {
  const result = await api.get(`${USERS_BASE_URL}/${id}`);
  return zodParse(userShowSchema, result.data);
};

export const getUserUserCredentials = async (id: number | null | undefined) => {
  const result = await api.get(`${USERS_BASE_URL}/${id}/user_credentials`);
  return zodParse(userUserCredentialSchema, result.data);
};

export const updateUser = async (
  user: Partial<UserPartialData>,
  params?: {
    create_key_pair?: {
      encrypted_private_key: string;
      public_key: string;
    };
  }
) => {
  const result = await api.put(`${USERS_BASE_URL}/${user.id}`, {
    user: { ...user, ...(params || {}) },
  });
  return result.data;
};
export const toggleFeatureForUser = async (name: string, userId: number) => {
  const result = await api.post(`${USERS_BASE_URL}/${userId}/toggle_feature`, {
    feature_flag: { name },
  });
  return result.data;
};

/** hooks */
export const useCurrentUserQuery = (refetchOnWindowFocus?: boolean) => {
  return useQuery({
    queryKey: [USERS_BASE_URL + "me"],
    queryFn: getCurrentUser,
    refetchOnWindowFocus: refetchOnWindowFocus,
  });
};

export const useUserSpendingAuthorityOptions = (searchTerm?: string) => {
  return useQuery({
    queryKey: [USERS_BASE_URL + "me/spending_authority", searchTerm],
    queryFn: async () => {
      {
        const spendingAuthorities = await getSpendingAuthority(searchTerm);
        return spendingAuthorities.map(buildSpendingAuthorityOptions);
      }
    },
  });
};

export const useUsersQuery = () => {
  return useQuery({
    queryKey: [USERS_BASE_URL],
    queryFn: getUsers,
  });
};

export const useMinimalUsersQuery = () => {
  return useQuery({
    queryKey: [USERS_BASE_URL, "minimal"],
    queryFn: getMinimalUsers,
  });
};

export const useActiveUsersQuery = () => {
  const result = useUsersQuery();
  if (!result.data) {
    return result;
  } else {
    return {
      ...result,
      data: result.data.filter((user) => user.employee_status === "active"),
    };
  }
};

export const useGetRecentUsersQuery = () => {
  return useQuery({
    queryKey: [USERS_BASE_URL, "recent"],
    queryFn: getRecentUsers,
  });
};

export const useEventsQuery = () => {
  return useQuery({
    queryKey: [USERS_BASE_URL, "events"],
    queryFn: getEvents,
  });
};

export const useActiveUsersWithRolesQuery = (...roles: string[]) => {
  const result = useUsersQuery();
  if (!result.data) {
    return result;
  } else {
    return {
      ...result,
      data: result.data.filter(
        (user) =>
          user.employee_status === "active" &&
          (user.roles || []).some((role) => roles.includes(role.name))
      ),
    };
  }
};

export const useUserQuery = (id: number | null | undefined) => {
  return useQuery({
    queryKey: [USERS_BASE_URL, USERS_BASE_URL + id],
    queryFn: () => getUser(id),
    enabled: !!id,
  });
};

export const useUserUserCredentialQuery = (id: number | null | undefined) => {
  return useQuery({
    queryKey: [USERS_BASE_URL + id + "user_credentials"],
    queryFn: () => getUserUserCredentials(id),
    enabled: id !== null || id !== undefined,
  });
};

const isUserQuery = (query: any): query is UseQueryResult<UserLoggedInData> => {
  if (query.isSuccess || query.isError || query.isLoading) {
    return true;
  }
  return false;
};

export const userHasFeatureFlag = (
  userQuery: UseQueryResult<UserLoggedInData> | UserLoggedInData,
  flag: string
): boolean => {
  const user = isUserQuery(userQuery) ? userQuery.data : userQuery;
  return user?.feature_flags.some((f) => f.name === flag) || false;
};

export const userHasRole = (
  userQuery: UseQueryResult<UserLoggedInData> | UserLoggedInData,
  role: string
): boolean => {
  const user = isUserQuery(userQuery) ? userQuery.data : userQuery;
  return user?.roles.some((r) => r.name === role) || false;
};

export function generateUserKeyPair(pin: string) {
  const keypair = forge.pki.rsa.generateKeyPair();
  const publicKey = forge.pki.publicKeyToPem(keypair.publicKey);
  const encryptedPrivateKey = forge.pki.encryptRsaPrivateKey(keypair.privateKey, pin);
  const hashedPin = bcrypt.hashSync(pin, 10);
  return { publicKey, encryptedPrivateKey, hashedPin };
}
export function decryptUserKeyPair(encryptedPrivateKey: string, pin: string) {
  return forge.pki.decryptRsaPrivateKey(encryptedPrivateKey, pin);
}

export const invalidateUser = (id?: number | null) => {
  const queryClient = useQueryClient();
  return () =>
    queryClient.invalidateQueries({
      queryKey: [USERS_BASE_URL],
    });
};

export const useUpdateUser = (id: number | null) => {
  return useMutation({
    mutationFn: updateUser,
    onSuccess: invalidateUser(id),
  });
};

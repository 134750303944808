import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { BackgroundReportButton } from "../../shared/BackgroundReportButton";
import { searchParams } from "../../../api/collection_types";
import { DateTime } from "luxon";

export const PaymentTransactionModal = ({ isOpen, onClose }) => {
  const [startDate, setStartDate] = useState<string | undefined>("2020-01-01");
  const [endDate, setEndDate] = useState<string | undefined>();

  const disableSubmitButton =
    !startDate || !endDate || DateTime.fromISO(endDate).toMillis() > DateTime.now().toMillis();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Payment Transactions by Date Range</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap={4}>
            <Text>
              Please specify for what date range you would like to export transaction records
            </Text>
            <HStack>
              <Text>Start Date:</Text>
              <Box>
                <Input
                  min={"2020-01-01"}
                  max={DateTime.now().toFormat("yyyy-MM-dd")}
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Box>
            </HStack>
            <HStack>
              <Text>End Date:</Text>
              <Box>
                <Input
                  max={DateTime.now().toFormat("yyyy-MM-dd")}
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Box>
            </HStack>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Cancel
          </Button>
          <BackgroundReportButton
            isDisabled={disableSubmitButton}
            noIcon
            colorScheme="teal"
            taskName="Reports::Payments"
            args={[
              searchParams({
                term: "*",
                filters: {
                  paid_at: {
                    gte: startDate || "2020-01-01",
                    lte: endDate,
                  },
                },
              }),
              "USD",
            ]}
            buttonName="Export"
            title="payments-by-month"
            loadingText="Generating Report..."
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

import { RecordLink } from "@sciencecorp/helix-components";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSearchServiceRequestsQuery } from "../../../../../../api/service_requests";
import { MoneyText } from "../../../../../MoneyText";
import { LinkifyText } from "../../../../../Purchasing/util";
import { UserRequestPill } from "../../../../../Requests/utils";
import { buildTabComponent } from "../../../../../TabbedTable";

export const ServiceRequestTab = buildTabComponent({
  label: "Service Requests",
  columns: [
    {
      label: "Created",
      orderOptions: { orderKey: "created_at" },
      render: (request) => request.created_at.toFormat("LLL dd yyyy"),
    },
    {
      label: "Request",
      orderOptions: { orderKey: "id" },
      render: (request) => (
        <RecordLink identifier={`# ${request.id}`} link={`/services/requests/${request.id}`} />
      ),
    },
    {
      label: "Requesting User",
      render: (request) =>
        request?.requesting_user && (
          <RouterLink to={`/users/${request.requesting_user?.id}`}>
            <UserRequestPill user={request.requesting_user} />
          </RouterLink>
        ),
    },
    {
      label: "Total Paid",
      orderOptions: { orderKey: "total_paid_cents" },
      render: (request) => {
        return <MoneyText money={request.total_paid} formatOptions={{ compact: "never" }} />;
      },
    },
    {
      label: "Cost",
      orderOptions: { orderKey: "total_cost_cents" },
      render: (request) => {
        return <MoneyText money={request.total_cost} formatOptions={{ compact: "never" }} />;
      },
    },
  ],
  query: useSearchServiceRequestsQuery,
});

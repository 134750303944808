import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  HStack,
  Heading,
  Spinner,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { Header, SplitPage } from "@sciencecorp/helix-components";
import React, { useEffect, useMemo, useState } from "react";
import { BiFile, BiStore } from "react-icons/bi";
import { BsGear } from "react-icons/bs";
import { MdAttachMoney } from "react-icons/md";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useGetTeamMemberships, useTeamQuery } from "../../../api/team";
import { useCurrentUserQuery, userHasRole } from "../../../api/user";
import { TabDefinition } from "../../types";
import { TeamCard } from "../teamCard";
import { AsanaLink, GithubLink, SlackLink } from "../util";
import { SubTeamsList } from "./components/SubTeamsList";
import { TeamBudget } from "./components/TeamBudget";
import { TeamMemberCard } from "./components/TeamMemberCard";
import { TeamMilestones } from "./components/TeamMilestones";
import { TeamReadme } from "./components/TeamReadme";
import { TeamServices } from "./components/TeamServices";
import { TeamSettings } from "./components/TeamSettings";

export const Team = () => {
  const { id, tab } = useParams();
  if (!id) return null;
  const { data: team, isLoading, isError } = useTeamQuery(+id);
  const userQuery = useCurrentUserQuery();
  const navigate = useNavigate();

  const { data: teamMembers } = useGetTeamMemberships(+id);
  const teamMemberships = teamMembers?.team_memberships || [];
  const leads = useMemo(() => {
    return (teamMemberships || [])
      .filter((membership) => membership.is_lead)
      .filter((membership) => membership.user.id !== teamMembers?.budget_lead?.user.id)
      .sort((a, b) => a.user.name.localeCompare(b.user.name));
  }, [teamMembers]);

  const members = (teamMemberships || []).filter((membership) => !membership.is_lead);

  const isArchived = team?.is_archived || false;
  const editable =
    team?.can_act_as_lead ||
    userHasRole(userQuery, "hr_admin") ||
    userHasRole(userQuery, "finance_admin") ||
    false;
  const isFinanceAdmin = userHasRole(userQuery, "finance_admin");
  const isCeo = userHasRole(userQuery, "ceo") || false;
  const userOnTeam =
    userQuery?.data?.team_memberships.some((membership) => membership.team.id === team?.id) ||
    false;
  const bgColor = useColorModeValue("gray.50", "gray.700");
  const subTeamLead =
    userQuery.data?.team_memberships
      .filter((membership) => membership.is_lead)
      .some((membership) => team?.all_sub_team_ids.includes(membership.team.id)) || false;

  const tabs = useMemo<TabDefinition[]>(() => {
    if (!team) return [];
    const tabs: (TabDefinition & { hidden: boolean })[] = [
      {
        label: "Readme",
        value: "readme",
        icon: <BiFile />,
        Component: () => <TeamReadme team={team} isTeamLead={editable} />,
        hidden: false,
      },
      {
        label: "Services",
        value: "services",
        icon: <BiStore />,
        Component: () => <TeamServices team={team} />,
        hidden: team.team_type === "project",
      },
      {
        label: "Budget",
        value: "budget",
        icon: <MdAttachMoney />,
        Component: () => (
          <TeamBudget team={team} editable={editable || isFinanceAdmin} isCeo={isCeo} />
        ),
        hidden: !userOnTeam && !editable && !isFinanceAdmin && !subTeamLead,
      },
      {
        label: "Settings",
        value: "settings",
        icon: <BsGear />,
        Component: () => <TeamSettings team={team} />,
        hidden: !editable,
      },
    ];
    return tabs.filter((tab) => !tab.hidden);
  }, [team, userQuery?.data]);

  const getActiveTab = () => {
    return tab?.toString().toLowerCase() || tabs[0]?.value;
  };

  const [activeTab, setActiveTab] = useState(getActiveTab());

  useEffect(() => setActiveTab(getActiveTab()), [tab]);

  if (!team) return null;
  if (isLoading || userQuery.isLoading) return <Spinner />;
  if (isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertDescription>Error loading team</AlertDescription>
      </Alert>
    );
  }
  const generateCrumbs = (team) => {
    const crumbs = [{ label: "Teams", url: "/teams" }];

    if (team.parent_team) {
      crumbs.push({
        label: team.parent_team.name,
        url: `/teams/${team.parent_team.id}/readme`,
      });
    }

    return crumbs;
  };

  return (
    <>
      <HStack mb={4} justify="space-between">
        <Box width="100%">
          <Header
            title={team.name}
            badge={{ label: team.team_type || "" }}
            crumbs={generateCrumbs(team)}
            crumbsColor="teal.500"
          />
        </Box>
      </HStack>
      {isArchived && (
        <Alert status="warning" mb={4}>
          <AlertIcon />
          <VStack align="start" spacing={0}>
            <AlertTitle>Archived</AlertTitle>
            <AlertDescription>
              This team has been archived and is no longer active.
            </AlertDescription>
          </VStack>
        </Alert>
      )}
      <Box overflow="scroll">
        <SplitPage
          sidebarWidth="250px"
          sidebarWidthXL="300px"
          breakpoint="lg"
          sidebar={
            <VStack width="100%" spacing={3}>
              {tabs.map(({ label, value, icon }) => (
                <Button
                  size={"md"}
                  width="100%"
                  justifyContent="start"
                  colorScheme={activeTab === value ? "teal" : "gray"}
                  bg={activeTab === value ? "teal.500" : "transparent"}
                  leftIcon={icon}
                  onClick={() => {
                    setActiveTab(value);
                    navigate(`/teams/${id}/${value}`);
                  }}
                  key={value}>
                  {label}
                </Button>
              ))}
              <VStack
                bg={bgColor}
                p={5}
                spacing={3}
                mb={3}
                border="1px"
                align="start"
                width="100%"
                borderColor="chakra-border-color"
                borderRadius="md">
                <Heading size="md" fontWeight="semibold" marginBlockEnd={4}>
                  Team Members
                </Heading>
                {teamMembers?.budget_lead && (
                  <TeamMemberCard
                    team_id={team.id}
                    key={teamMembers.budget_lead.user.name}
                    membership={teamMembers.budget_lead}
                    editable={false}
                  />
                )}
                {leads.map((lead) => (
                  <TeamMemberCard
                    team_id={team.id}
                    key={lead.id}
                    membership={lead}
                    editable={editable}
                  />
                ))}
                {members.map((membership) => (
                  <TeamMemberCard
                    team_id={team.id}
                    key={membership.id}
                    membership={membership}
                    editable={editable}
                  />
                ))}
              </VStack>
              {team.parent_team && (
                <VStack
                  p={5}
                  bg={useColorModeValue("gray.50", "gray.700")}
                  width="100%"
                  align="start"
                  borderRadius="md"
                  spacing={4}
                  border="1px"
                  borderColor={useColorModeValue("gray.200", "gray.600")}>
                  <Heading size="md">Parent Team</Heading>
                  <TeamCard team={team.parent_team} location="parent" />
                </VStack>
              )}

              {(editable || team.sub_teams?.length) && (
                <VStack
                  p={5}
                  bg={useColorModeValue("gray.50", "gray.700")}
                  width="100%"
                  align="start"
                  border="1px"
                  borderRadius="md"
                  borderColor={useColorModeValue("gray.200", "gray.600")}>
                  <SubTeamsList team={team} editable={editable} />
                </VStack>
              )}

              {(team.asana_team_link || team.github_team || team.slack_channel) && (
                <VStack
                  mb={12}
                  spacing={4}
                  width="100%"
                  align="start"
                  bg={useColorModeValue("gray.50", "gray.700")}
                  border="1px"
                  borderRadius="md"
                  borderColor={useColorModeValue("gray.200", "gray.600")}
                  p={5}>
                  <Heading size="md">Links</Heading>
                  <AsanaLink asanaLink={team.asana_team_link} />
                  <SlackLink slackChannel={team.slack_channel} />
                  <GithubLink githubTeam={team.github_team} />
                </VStack>
              )}
            </VStack>
          }
          main={
            <>
              <Box key={team.id} w={"100%"}>
                {activeTab === "readme" && <TeamReadme team={team} isTeamLead={editable} />}
                {activeTab === "milestones" && <TeamMilestones team={team} editable={editable} />}
                {activeTab === "services" && <TeamServices team={team} />}
                {(userOnTeam || editable || isFinanceAdmin || subTeamLead) &&
                  activeTab === "budget" && (
                    <TeamBudget team={team} editable={editable || isFinanceAdmin} isCeo={isCeo} />
                  )}
                {editable && activeTab === "settings" && <TeamSettings team={team} />}
              </Box>
            </>
          }
        />
      </Box>
    </>
  );
};

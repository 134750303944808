import { EditIcon, DeleteIcon, AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  useColorModeValue,
  Text,
  VStack,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Input,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  Avatar,
  HStack,
  Tag,
} from "@chakra-ui/react";
import {
  AttributesTable,
  Header,
  RichTextEditor,
  SplitPage,
  StatusSelect,
} from "@sciencecorp/helix-components";
import React, { useState } from "react";
import { DateTime } from "luxon";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UserServiceRequestPill } from "../Requests/utils";
import { UserMinimalData, useActiveUsersQuery, useCurrentUserQuery } from "../../api/user";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  MeetingNoteDataWithAttendees,
  useDeleteMeetingNotes,
  useGetMeetingNote,
  useUpdateMeetingNotes,
} from "../../api/meeting_notes";
import { AttendeeCard } from "./AttendeeCard";
import { EditMeetingModal } from "./EditMeetingModal";
import { useCreateMeetingAttendee } from "../../api/meeting_attendees";
import { useMeetingNoteVisibilityOptions } from "../../api/options";

const emptyRichText = JSON.stringify({
  root: {
    type: "root",
    format: "",
    indent: 0,
    version: 1,
    children: [
      {
        type: "paragraph",
        format: "",
        indent: 0,
        version: 1,
        children: [
          {
            mode: "normal",
            text: "",
            type: "text",
            style: "",
            detail: 0,
            format: 0,
            version: 1,
          },
        ],
        direction: "ltr",
      },
    ],
    direction: "ltr",
  },
});

export type AttendeeOption = { user_name: string; user_id: number; user: UserMinimalData };

const getAttendeeOptions = (
  allAttendees: UserMinimalData[],
  meetingAttendees: { id: number; user: UserMinimalData }[] = []
): AttendeeOption[] => {
  const meetingAttendeeIds = new Set(meetingAttendees.map((user) => user.user.id));
  return allAttendees.reduce((acc: AttendeeOption[], user) => {
    if (!acc.some((item) => item.user_id === user.id) && !meetingAttendeeIds.has(user.id)) {
      acc.push({
        user_name: user.name,
        user_id: user.id,
        user,
      });
    }
    return acc;
  }, []);
};

interface ActionMenuProps {
  editable: boolean;
  meeting: MeetingNoteDataWithAttendees;
  onOpenEditMeetingModal: () => void;
  deleteMeetingNote: (id: number) => Promise<void>;
}

const ActionMenu = ({
  meeting,
  editable,
  onOpenEditMeetingModal,
  deleteMeetingNote,
}: ActionMenuProps) => {
  const navigate = useNavigate();
  const disclosure = useDisclosure();

  return (
    <Menu key="action-menu" {...disclosure}>
      <MenuButton
        isDisabled={!editable}
        as={IconButton}
        icon={<BsThreeDotsVertical />}
        size="md"
        aria-label="More actions"
        isActive={disclosure.isOpen}
      />
      <MenuList zIndex={"100"}>
        <MenuItem onClick={onOpenEditMeetingModal} icon={<EditIcon />}>
          Edit Meeting Information
        </MenuItem>
        <MenuItem
          color="red.600"
          onClick={() => {
            if (meeting) {
              deleteMeetingNote(meeting.id)
                .then(() => {
                  navigate("/meeting_notes");
                })
                .catch((error) => {
                  console.error("Failed to delete meeting note:", error);
                });
            }
          }}>
          <DeleteIcon marginRight="4" />
          Delete Note
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export const MeetingNotesPage = () => {
  const { id } = useParams();
  if (!id) return null;
  const [filteredUsers, setFilteredUsers] = useState<UserMinimalData[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { data: users } = useActiveUsersQuery();
  const current_user = useCurrentUserQuery();
  const { data: meeting } = useGetMeetingNote(+id);
  const { mutateAsync: editMeetingNote, isLoading: editIsLoading } = useUpdateMeetingNotes(+id);
  const {
    isOpen: isOpenEditMeetingModal,
    onOpen: onOpenEditMeetingModal,
    onClose: onCloseEditMeetingModal,
  } = useDisclosure();
  const { mutateAsync: deleteMeetingNote } = useDeleteMeetingNotes();
  const { mutateAsync: createMeetingAttendee } = useCreateMeetingAttendee();
  const dateTime = meeting?.meeting_time;
  const date = dateTime && dateTime.toLocaleString(DateTime.DATE_SHORT);
  const time = dateTime && dateTime.toLocaleString(DateTime.TIME_SIMPLE);
  const editable = current_user.data?.id === meeting?.user.id;
  const visibilityOptions = useMeetingNoteVisibilityOptions();
  const attendeeOptions = getAttendeeOptions(
    filteredUsers,
    meeting?.meeting_attendees.map((attendee) => ({ id: attendee.id, user: attendee.user }))
  );
  return (
    <>
      {meeting && (
        <EditMeetingModal
          isOpen={isOpenEditMeetingModal}
          onClose={onCloseEditMeetingModal}
          meeting_note_id={meeting.id}
        />
      )}
      <Header
        title={meeting?.title || ""}
        crumbs={[{ label: "Meeting Notes", url: "/meeting_notes" }]}
        crumbsColor="teal.500"
        actions={[
          meeting ? (
            <ActionMenu
              key="action-menu"
              meeting={meeting}
              editable={editable}
              onOpenEditMeetingModal={onOpenEditMeetingModal}
              deleteMeetingNote={deleteMeetingNote}
            />
          ) : (
            <Box key="no-meeting"></Box>
          ),
        ]}
      />

      <SplitPage
        breakpoint="lg"
        sidebarWidth="350px"
        sidebarWidthXL="450px"
        sidebar={
          <>
            <Box
              bg={useColorModeValue("gray.50", "gray.700")}
              borderRadius="md"
              maxWidth={"100%"}
              border="1px"
              borderColor="chakra-border-color"
              p={4}
              marginTop={4}>
              <AttributesTable
                title="Meeting Details"
                attributes={[
                  {
                    label: "Date",
                    value: date,
                  },
                  {
                    label: "Time",
                    value: time,
                  },
                  {
                    label: "Visibility",
                    value: (
                      <HStack>
                        <StatusSelect
                          options={visibilityOptions}
                          status={
                            visibilityOptions.find(
                              (option) => option.value === meeting?.visibility
                            ) ?? visibilityOptions[0]
                          }
                          variant="tag"
                          isDisabled={editIsLoading || !editable}
                          onSubmit={(value) => {
                            if (value && typeof value === "string") {
                              editMeetingNote({
                                id: +id,
                                visibility: value,
                              });
                            }
                          }}
                        />
                      </HStack>
                    ),
                  },
                  {
                    label: "Created by",
                    value: (
                      <Link to={`/users/${meeting?.user.id}`}>
                        <HStack marginTop={["2", "0"]}>
                          <Avatar src={meeting?.user.picture_uri} size="sm" />
                          <Box>{meeting?.user.name}</Box>
                        </HStack>
                      </Link>
                    ),
                  },
                ]}
              />
            </Box>
            <VStack
              bg={useColorModeValue("gray.50", "gray.700")}
              p={5}
              spacing={3}
              mb={3}
              mt={3}
              border="1px"
              align="start"
              width="100%"
              borderColor={useColorModeValue("gray.200", "gray.600")}
              borderRadius="md">
              <Flex alignItems={"center"} marginBlockEnd={4}>
                <Heading size="md" fontWeight="semibold">
                  Attendees
                </Heading>
                <Tag marginLeft={"2"}>{meeting?.meeting_attendees.length}</Tag>
              </Flex>
              {meeting?.meeting_attendees.map((attendee) => (
                <AttendeeCard
                  key={attendee.id}
                  editable={editable}
                  attendee={attendee.user}
                  current_user_id={current_user?.data?.id ?? 0}
                  meeting_user_id={attendee.id}
                />
              ))}

              {current_user.data?.id === meeting?.user.id && (
                <Popover placement="bottom-end">
                  <PopoverTrigger>
                    <Button
                      isDisabled={!editable}
                      variant="link"
                      sx={{
                        "&>div": {
                          transition: "background-color, color",
                          transitionDuration: "fast",
                        },
                      }}
                      _hover={{
                        "&>div": {
                          backgroundColor: "green.500",
                        },
                      }}>
                      <Flex
                        boxSize={10}
                        alignItems="center"
                        justifyContent="center"
                        background={useColorModeValue("blackAlpha.100", "gray.400")}
                        marginInlineEnd={4}
                        borderRadius={"full"}>
                        <AddIcon color={useColorModeValue("blackAlpha.500", "gray.100")} />
                      </Flex>
                      Add Attendee
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverCloseButton />
                    <PopoverBody maxH={52} overflowY="scroll">
                      <VStack align="start">
                        <Text fontWeight="semibold">Add Attendee to Meeting</Text>
                        {users && (
                          <Input
                            placeholder="Search for a member..."
                            size="sm"
                            value={searchTerm}
                            onChange={(e) => {
                              setSearchTerm(e.target.value);
                              setFilteredUsers(
                                users.filter((user) =>
                                  user.name.toLowerCase().includes(e.target.value.toLowerCase())
                                )
                              );
                            }}
                          />
                        )}
                        {attendeeOptions.map((option: AttendeeOption) => (
                          <Button
                            key={option.user_id}
                            cursor="pointer"
                            justifyContent="start"
                            bg="transparent"
                            width="100%"
                            onClick={() => {
                              createMeetingAttendee({
                                meeting_note_id: +id,
                                user_id: option.user_id,
                              })
                                .then(() => {
                                  setSearchTerm("");
                                  setFilteredUsers(users ?? []);
                                })
                                .catch((error) => {
                                  console.error("Failed to create attendee:", error);
                                });
                            }}>
                            <UserServiceRequestPill user={option.user} />
                          </Button>
                        ))}
                      </VStack>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
            </VStack>
          </>
        }
        main={
          <Box marginTop={"4"}>
            <RichTextEditor
              autoSaveTimeout={1000}
              editable={editable}
              width="100%"
              minHeight="5vh"
              defaultValue={meeting?.content ? JSON.stringify(meeting.content) : emptyRichText}
              onSave={async (rich) => {
                return editMeetingNote({
                  id: +id,
                  content: JSON.parse(rich),
                })
                  .then(() => true)
                  .catch(() => false);
              }}
              isSaving={editIsLoading}
            />
          </Box>
        }
      />
    </>
  );
};

import { z } from "zod";
import { api } from ".";
import { useMutation } from "@tanstack/react-query";
import { invalidateService } from "./services";
import { userMinimalSchema } from "./user";

export const SERVICE_SUBTASK_USER_BASE_URL = "/service_subtask_users";

export const serviceSubtaskUserSchema = z.object({
  id: z.number(),
  service_subtask_id: z.number(),
  user: z.lazy(() => userMinimalSchema),
});

export const serviceSubtaskUserCreateSchema = z.object({
  user_ids: z.array(z.number()),
  service_subtask_id: z.number(),
});

export type ServiceSubtaskUserCreateParams = z.infer<typeof serviceSubtaskUserCreateSchema>;

export const bulkCreateServiceSubtaskUser = async (
  service_subtask_users: ServiceSubtaskUserCreateParams
) => {
  const result = await api.post(`${SERVICE_SUBTASK_USER_BASE_URL}/bulk`, {
    service_subtask_users,
  });
  return result.status === 201;
};

export const deleteServiceSubtaskUser = async (id: number) => {
  await api.delete(`${SERVICE_SUBTASK_USER_BASE_URL}/${id}`);
};

/** hooks */

export const useBulkCreateServiceSubtaskUser = (id?: number) => {
  return useMutation({
    mutationFn: bulkCreateServiceSubtaskUser,
    onSuccess: invalidateService(id),
  });
};

export const useDeleteServiceSubtaskUser = (id?: number) => {
  return useMutation({
    mutationFn: deleteServiceSubtaskUser,
    onSuccess: invalidateService(id),
  });
};

import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DeleteIcon,
  SmallAddIcon,
} from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import {
  BadgeSelect,
  ConfirmationModal,
  Header,
  RichTextEditor,
  SplitPage,
} from "@sciencecorp/helix-components";
import React, { useCallback, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiCheckCircle } from "react-icons/fi";
import { useNavigate, useParams } from "react-router";
import { useDeleteFile } from "../../api/blob_files";
import { useUserMentionOptions } from "../../api/options";
import {
  invalidateServiceRequest,
  useCreateServiceRequestSubscriber,
  useDeleteServiceRequestSubscriber,
  useGetAllServiceRequestReimbursementsQuery,
  useGetServiceRequestEventsQuery,
  useGetServiceRequestPurchases,
  useGetServiceRequestQuery,
  usePostComment,
  useUpdateServiceRequest,
} from "../../api/service_requests";
import { deleteService, useGetServiceQuery } from "../../api/services";
import {
  useApproveSpendingAuthorityChangeRequest,
  useDeclineSpendingAuthorityChangeRequest,
} from "../../api/spending_authority_change_requests";
import { useCurrentUserQuery, userHasRole } from "../../api/user";
import { useCurrency } from "../../contexts/CurrencyContext";
import { Money } from "../../helpers/Money";
import { ApprovalFlowSpendingAuthorityInfo } from "../shared/ApprovalFlowSpendingAuthorityInfo";
import { SpendingAuthorityApprovalFlow } from "../shared/ApprovalFlowTable";
import { TimelineTable } from "../shared/TimelineTable";
import { PreviewUserSelect } from "../Users/shared/UserSelect";
import { AddUsersToServiceRequestModal } from "./AddUsersToServiceRequestModal";
import { ServiceRequestResponses } from "./components/ServiceRequest/ServiceRequestResponses";
import { ServiceRequestSubtasks } from "./components/ServiceRequest/ServiceRequestSubtasks";
import { ServiceRequestCosts } from "./components/ServiceRequestCosts";
import { ServiceRequestDetailsSidebar } from "./components/ServiceRequestDetailsSidebar";
import { ServiceRequestStatus } from "./components/ServiceRequestStatus";
import { LinkToPurchase } from "./LinkToPurchase";

const closeablePurchaseStatuses = ["new", "needs_approval", "declined", "awaiting_purchase"];

const serviceStatusMap = [
  { label: "New", value: "new", colorScheme: "blue" },
  { label: "Needs Approval", value: "needs_approval", colorScheme: "red" },
  { label: "Declined", value: "declined", colorScheme: "red" },
  { label: "In Progress", value: "in_progress", colorScheme: "orange" },
  { label: "Abandoned", value: "abandoned", colorScheme: "gray" },
  { label: "Complete", value: "complete", colorScheme: "green" },
];

export const ServiceRequestDetails = () => {
  const serviceCurrency = useCurrency();
  const { id } = useParams();
  if (!id) return null;
  const {
    data: serviceRequest,
    isLoading: isLoadingServiceRequest,
    isRefetching: isRefetchingServiceRequest,
    isError,
  } = useGetServiceRequestQuery(Number(id));
  const { data: service, isLoading: isLoadingService } = useGetServiceQuery(
    serviceRequest?.service_id!
  );
  const currentUserQuery = useCurrentUserQuery();
  const isServiceAdmin = userHasRole(currentUserQuery, "services_admin") || false;
  const currentUser = currentUserQuery?.data;
  const { data: purchases } = useGetServiceRequestPurchases(serviceRequest?.id!);
  const { data: reimbursements } = useGetAllServiceRequestReimbursementsQuery(serviceRequest?.id!);
  const minimalUsers = useUserMentionOptions();
  const eventsQuery = useGetServiceRequestEventsQuery(+id);
  const { mutate: updateServiceRequest, isLoading: isLoadingUpdate } = useUpdateServiceRequest(+id);
  const { mutate: deleteFile } = useDeleteFile(() => invalidateServiceRequest(+id));
  const [content, setContent] = useState("");
  const [plainText, setPlainText] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutateAsync: postComment } = usePostComment(+id);
  const navigate = useNavigate();

  const {
    mutateAsync: approveSpendingAuthorityChangeRequest,
    isLoading: approveSpendingAuthorityChangeRequestIsLoading,
  } = useApproveSpendingAuthorityChangeRequest();
  const {
    mutateAsync: declineSpendingAuthorityChangeRequest,
    isLoading: declineSpendingAuthorityChangeRequestIsLoading,
  } = useDeclineSpendingAuthorityChangeRequest();

  const purchaseCurrencies = new Set(
    purchases?.map((purchase) => purchase.line_items_sum.currency)
  );
  const currency = purchaseCurrencies.size === 1 ? [...purchaseCurrencies][0] : serviceCurrency;

  const createComment = useCallback(() => {
    postComment({ richText: content, plainText: plainText }).then(() =>
      setSession((prev) => prev + 1)
    );
  }, [content, plainText, postComment]);
  const purchasesInCloseableState =
    purchases?.every((purchase) => closeablePurchaseStatuses.includes(purchase.status)) || false;
  const canClose =
    serviceRequest?.user_in_fulfilling_team ||
    currentUser?.id === serviceRequest?.requesting_user.id ||
    isServiceAdmin;
  const [session, setSession] = useState(0);
  const {
    isOpen: isOpenAssignEmployeesModal,
    onOpen: onOpenAssignEmployeesModal,
    onClose: onCloseAssignEmployeesModal,
  } = useDisclosure();
  const { mutate: deleteSubscriber, isLoading: isDeletingSubscriber } =
    useDeleteServiceRequestSubscriber(serviceRequest?.id!);
  const {
    mutateAsync: createServiceRequestSubscriber,
    isLoading: isCreatingServiceRequestSubscriber,
  } = useCreateServiceRequestSubscriber(Number(id));
  if (isLoadingServiceRequest || isLoadingService) {
    return <Spinner />;
  }

  if (isError) {
    return <Text>Failed to load service request</Text>;
  }

  const handlePrevServiceRequest = () => {
    navigate(`/services/requests/${serviceRequest?.previous_active_service_request_id}`);
  };

  const handleNextServiceRequest = () => {
    navigate(`/services/requests/${serviceRequest?.next_active_service_request_id}`);
  };
  const pendingSpendingAuthorityChangeRequests =
    serviceRequest.spending_authority_change_requests.filter((request) => !request.fully_approved);

  return (
    <Box pb={40}>
      {serviceRequest?.id && (
        <AddUsersToServiceRequestModal
          serviceRequestId={serviceRequest.id}
          isOpen={isOpenAssignEmployeesModal}
          onClose={onCloseAssignEmployeesModal}
          serviceUsers={serviceRequest?.service_request_subscribers ?? []}
          onSubmit={createServiceRequestSubscriber}
          isLoading={isCreatingServiceRequestSubscriber}
          typeOfUser="subscriber"
        />
      )}
      <Header
        title={`${service?.name} #${serviceRequest.id}`}
        badgeDropdown={
          <Box zIndex={1000}>
            <BadgeSelect
              options={
                serviceRequest.status === "needs_approval"
                  ? serviceStatusMap.filter(
                      (item) =>
                        item.value === "in_progress" ||
                        item.value === "new" ||
                        item.value === "declined"
                    )
                  : serviceStatusMap.filter((item) => item.value !== "declined")
              }
              selectedOption={serviceRequest.status}
              handleOptionChange={(value) => {
                updateServiceRequest({ id: serviceRequest.id, status: value });
              }}
            />
          </Box>
        }
        actions={[
          <Box key="subscribers" paddingRight={3}>
            <PreviewUserSelect
              handleDelete={deleteSubscriber}
              addedUsers={serviceRequest?.service_request_subscribers ?? []}
              additionalUsersTitle="Subscribers"
              isDisabled={!serviceRequest.can_edit_service_request}
              isLoading={isDeletingSubscriber}
            />
          </Box>,
          <Tooltip label="Add Subscribers" key="add-subscriber">
            <IconButton
              icon={<SmallAddIcon />}
              isDisabled={!serviceRequest.can_edit_service_request}
              aria-label="Add Subscribers"
              size="sm"
              onClick={onOpenAssignEmployeesModal}
            />
          </Tooltip>,

          <Divider orientation="vertical" height="35px" key="divider" />,
          service?.status === "draft" ? (
            <Menu key="menu">
              <MenuButton
                as={IconButton}
                icon={<Icon as={BsThreeDotsVertical} />}
                isDisabled={!serviceRequest.can_edit_service_request}
              />
              <MenuList>
                <MenuItem
                  color="red"
                  icon={<DeleteIcon />}
                  onClick={() =>
                    deleteService(Number(id)).then(() =>
                      navigate(`/teams/${service?.team.id}/services`)
                    )
                  }>
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          ) : (
            <></>
          ),
          <IconButton
            size="sm"
            key="prev-service"
            icon={<ChevronLeftIcon />}
            isDisabled={!serviceRequest?.previous_active_service_request_id}
            aria-label="Back"
            onClick={handlePrevServiceRequest}
          />,
          <IconButton
            size="sm"
            key="next-service"
            icon={<ChevronRightIcon />}
            isDisabled={!serviceRequest?.next_active_service_request_id}
            aria-label="Next"
            onClick={handleNextServiceRequest}
          />,
        ]}
        crumbs={[
          { label: "Teams", url: "/teams" },
          {
            label: `${service?.team.name}`,
            url: `/teams/${service?.team.id}/services`,
          },
        ]}
        crumbsColor="teal.500"
      />
      <Box>
        <SplitPage
          sidebarWidth="350px"
          sidebarWidthXL="450px"
          breakpoint="lg"
          sidebar={
            <>
              {serviceRequest.purchase_id && (
                <LinkToPurchase purchaseId={serviceRequest.purchase_id} />
              )}
              {serviceRequest && currentUser && service && (
                <ServiceRequestDetailsSidebar
                  serviceRequest={serviceRequest}
                  isServiceAdmin={isServiceAdmin}
                  currentUser={currentUser}
                  service={service}
                />
              )}
            </>
          }
          main={
            <Stack>
              <Flex
                gap={4}
                direction={{ base: "column", lg: "row" }}
                width="100%"
                marginBottom={"2"}>
                <VStack
                  flex="1"
                  align="flex-start"
                  border={"1px"}
                  borderRadius={"md"}
                  borderColor={"chakra-border-color"}
                  padding={"4"}>
                  <ServiceRequestResponses
                    serviceRequest={serviceRequest}
                    currentUser={currentUser}
                  />
                </VStack>
              </Flex>
              {service && (
                <ServiceRequestSubtasks
                  service={service}
                  serviceRequest={serviceRequest}
                  isServiceCreation={false}
                />
              )}
              <Stack direction={{ base: "column", md: "row" }}>
                <Box flex="1">
                  <ApprovalFlowSpendingAuthorityInfo
                    spendingAuthority={serviceRequest.spending_authority}
                    rootSpendingAuthority={serviceRequest.root_spending_authority}
                    currentUser={currentUser}
                    pendingAmount={serviceRequest.total_cost}
                    expenditure={serviceRequest}
                    type="ServiceRequest"
                  />
                </Box>
                {pendingSpendingAuthorityChangeRequests.length > 0 && (
                  <Box flex="1">
                    <SpendingAuthorityApprovalFlow
                      hideExpenditureApproval={true}
                      expenditureType="ServiceRequest"
                      approvedApprovalFlowData={[
                        serviceRequest,
                        ...pendingSpendingAuthorityChangeRequests,
                      ]}
                      currentUser={currentUser!}
                      approve={approveSpendingAuthorityChangeRequest}
                      decline={declineSpendingAuthorityChangeRequest}
                      isApproveLoading={approveSpendingAuthorityChangeRequestIsLoading}
                      isDeclineLoading={declineSpendingAuthorityChangeRequestIsLoading}
                    />
                  </Box>
                )}
              </Stack>
              {serviceRequest && (
                <>
                  {eventsQuery.isSuccess ? (
                    <TimelineTable
                      timelineable_id={serviceRequest.id}
                      timelineable_type={"ServiceRequest"}
                      events={eventsQuery.data || []}
                      onComment={invalidateServiceRequest(serviceRequest.id, "events")}
                      disableCommentBox={true}
                    />
                  ) : (
                    <Spinner />
                  )}
                  {currentUser && (
                    <>
                      <ServiceRequestCosts
                        serviceRequest={serviceRequest}
                        currentUser={currentUser}
                        purchases={purchases || []}
                        reimbursements={reimbursements || []}
                        isServicesAdmin={isServiceAdmin}
                      />
                      <ServiceRequestStatus
                        serviceRequest={serviceRequest}
                        isServicesAdmin={isServiceAdmin}
                        currentUser={currentUser}
                        reimbursementCost={Money.sum(
                          Money.zero(currency),
                          ...(reimbursements || []).map((r) => r.amount)
                        )}
                        purchaseCost={Money.sum(
                          Money.zero(currency),
                          ...(purchases || []).map((p) => p.line_items_sum)
                        )}
                      />
                    </>
                  )}
                </>
              )}
              <Stack direction="column" width="100%">
                <HStack align="baseline" width="100%">
                  <Avatar size="sm" src={currentUser?.picture_uri} />
                  <Box flex="1" key={`comment-${session}`}>
                    <RichTextEditor
                      mentionMap={{ "@": minimalUsers }}
                      onChange={(raw, rich) => {
                        setContent(raw);
                        setPlainText(rich ?? "");
                      }}
                      placeholder="Leave a comment..."
                    />
                  </Box>
                </HStack>
                <HStack justify="flex-end" my={4}>
                  {serviceRequest.status !== "complete" && serviceRequest.status !== "abandoned" ? (
                    <Menu placement="bottom-end">
                      <MenuButton
                        isLoading={isLoadingUpdate || isRefetchingServiceRequest}
                        as={Button}
                        variant="outline"
                        isDisabled={!canClose}
                        leftIcon={<FiCheckCircle />}
                        rightIcon={<ChevronDownIcon />}>
                        Close Request
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          isDisabled={isLoadingUpdate}
                          icon={<CheckCircleIcon color="green.600" boxSize={4} />}
                          onClick={() => {
                            updateServiceRequest({
                              id: serviceRequest.id,
                              status: "complete",
                            });
                          }}>
                          Close request as complete
                        </MenuItem>
                        <MenuItem
                          isDisabled={isLoadingUpdate}
                          icon={<CheckCircleIcon color="orange.600" boxSize={4} />}
                          onClick={() => onOpen()}>
                          Close request as abandoned
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  ) : (
                    <Button
                      leftIcon={<FiCheckCircle />}
                      isLoading={isLoadingUpdate || isRefetchingServiceRequest}
                      onClick={() => {
                        updateServiceRequest({
                          id: serviceRequest.id,
                          status: "review",
                        });
                      }}>
                      Reopen Request
                    </Button>
                  )}
                  <Button
                    onClick={createComment}
                    isDisabled={
                      serviceRequest.status === "completed" || serviceRequest.status === "abandoned"
                    }>
                    Comment
                  </Button>
                </HStack>
              </Stack>
              <ConfirmationModal
                colorScheme="red"
                isOpen={isOpen}
                onClose={onClose}
                header="Close Request"
                confirmText={purchasesInCloseableState ? "Abandon Request" : "Review Purchases"}
                onClick={() => {
                  if (purchasesInCloseableState) {
                    updateServiceRequest(
                      {
                        id: serviceRequest.id,
                        status: "abandoned",
                      },
                      { onSuccess: onClose }
                    );
                  } else {
                    onClose();
                  }
                }}>
                {purchasesInCloseableState ? (
                  <Text>
                    Are you sure you want to abandon this request? Abandoning the request will also
                    cancel any associated purchase requests.
                  </Text>
                ) : (
                  <Text>
                    To abandon this request, please review all associated purchases and reassign
                    them to a different spending authority.
                  </Text>
                )}
              </ConfirmationModal>
            </Stack>
          }
        />
      </Box>
    </Box>
  );
};

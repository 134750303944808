import { Avatar, Box, Flex, Link, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import { Pagination } from "@sciencecorp/helix-components";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { NotificationData } from "../../api/notifications";
import { UserLoggedInData } from "../../api/user";
import { RenderTemplateValue, TimelineEventText } from "../shared/TimelineEventText";

export type NotificationTabsProps = {
  notifications: NotificationData[];
  currentUser: UserLoggedInData;
  savedTab?: boolean;
  pagination: Pagination;
  onPagination: (pagination: Pagination) => void;
};

export type NotificationItemProps = {
  notification: NotificationData;
  currentUser: UserLoggedInData;
  setSelectedNotifications: React.Dispatch<React.SetStateAction<NotificationData[]>>;
  selectedNotifications: NotificationData[];
};

export const getRecordPath = (notification: NotificationData) => {
  switch (notification.notifiable_type) {
    case "Purchase":
      return `/services/purchasing/${notification.notifiable_id}`;
    case "NewsPost":
      return `/news/${notification.notifiable_id}`;
    case "CandidateFeedback":
      return `/hiring/feedback/${notification.notifiable_id}`;
    case "Candidate":
      if (notification.notification_type === "candidate_references_assigned")
        return `/hiring/candidates/${notification.notifiable_id}/references`;
      else return `/hiring/candidates/${notification.notifiable_id}`;
    case "ReviewPrompt":
      return "/reviews";
    case "ReviewFeedback":
      return "/reviews";
    case "Contract":
      return `/services/contracts/${notification.notifiable_id}`;
    case "Training":
      return `/trainings/${notification.notifiable_id}`;
    case "UserCredential":
      return `/credentials/me/${notification.notifiable_id}`;
    case "ServiceRequest":
      return `/services/requests/${notification.notifiable_id}`;
    case "ServiceRequestApproval":
      return `/services/requests/${notification.parent_model?.model_id}`;
    case "SubscriptionApproval":
      return `/services/subscriptions/${notification.parent_model?.model_id}`;
    case "SubscriptionPayment":
      return `/services/subscriptions/${notification.parent_model?.model_id}`;
    case "Reimbursement":
      return `/services/reimbursements/${notification.notifiable_id}`;
    case "SpendingAuthorityChangeRequest":
      return `/services/purchasing/${notification.parent_model?.model_id}`;
    case "PurchaseReturn":
      return `/services/purchasing/${notification.parent_model?.model_id}`;
    case "PurchaseRefund":
      return `/services/purchasing/${notification.parent_model?.model_id}`;
    default:
      return "/";
  }
};

type NotificationContentProps = {
  notification: NotificationData;
  currentUser: UserLoggedInData;
};
export const NotificationContent = ({ notification, currentUser }: NotificationContentProps) => {
  const content = notification.rendered_content;
  const comment = notification.rendered_content.find((part) => part.type === "comment");

  return (
    <VStack align="start">
      <Flex gap={1}>
        {!notification.triggering_event &&
          (notification?.sender ? (
            <Link as={RouterLink} to={`/users/${notification.sender.id}`}>
              <Avatar
                size="xs"
                name={notification.sender.name}
                src={notification.sender.picture_uri}
              />
            </Link>
          ) : (
            <Link as={RouterLink} to={`/users/${currentUser.id}`}>
              <Avatar size="xs" name={currentUser.name} src={currentUser.picture_uri} />
            </Link>
          ))}
        <Text as={"span"} fontSize="sm">
          {notification.triggering_event !== null ? (
            notification.triggering_event.event_type === "comment" ? (
              <NotificationCommentEvent notification={notification} />
            ) : (
              <TimelineEventText
                event={notification.triggering_event}
                avatarSize="xs"
                templateType={notification.action_required ? "call_to_action" : "notification"}
              />
            )
          ) : (
            content.map((part, index) => {
              switch (part.type) {
                case "sender_user_avatar":
                  return notification.sender ? (
                    <Link as={RouterLink} to={`/users/${notification.sender.id}`}>
                      <Avatar
                        size="sm"
                        name={notification.sender.name}
                        src={notification.sender.picture_uri}
                      />
                    </Link>
                  ) : null;
                case "sender_user_name":
                  return notification.sender ? (
                    <Link
                      as={RouterLink}
                      to={`/users/${notification.sender.id}`}
                      verticalAlign="middle"
                      fontWeight="semibold">
                      {part.content}
                    </Link>
                  ) : null;
                case "text":
                  // For text, check if a specific style is needed
                  if (part.style === "bold") {
                    return (
                      <Box as="span" fontWeight="bold" key={index} verticalAlign="middle">
                        {part.content}{" "}
                      </Box>
                    );
                  } else if (part.style === "italic") {
                    return (
                      <Box
                        as="i"
                        key={index}
                        verticalAlign="middle"
                        color={useColorModeValue("gray.500", "gray.400")}>
                        {part.content}{" "}
                      </Box>
                    );
                  } else {
                    return (
                      <Box as="span" key={index} verticalAlign="middle">
                        {part.content}{" "}
                      </Box>
                    );
                  }
                default:
                  return null;
              }
            })
          )}
        </Text>
      </Flex>
      {comment && (
        <Text
          border="1px"
          width="100%"
          borderRadius="md"
          borderColor="chakra-border-color"
          fontSize="sm"
          py={2}
          px={4}>
          {comment.content.length > 100 ? `${comment.content.slice(0, 100)}..."` : comment.content}
        </Text>
      )}
    </VStack>
  );
};

const NotificationCommentEvent = ({ notification }) => {
  return (
    <Box width="100%" maxW="3xl">
      {notification.triggering_event.notification_template.map((ele, idx) => (
        <RenderTemplateValue
          templateValue={ele}
          avatarSize="xs"
          shouldCapitalize={idx === 0}
          event={notification.triggering_event}
        />
      ))}
      <Text
        mt={2}
        border="1px"
        width="100%"
        borderRadius="md"
        borderColor="chakra-border-color"
        fontSize="sm"
        py={2}
        px={4}>
        {notification.triggering_event.event_data?.plain_text.length > 100
          ? `${notification.triggering_event.event_data?.plain_text.slice(0, 100)}..."`
          : notification.triggering_event.event_data?.plain_text}
      </Text>
    </Box>
  );
};

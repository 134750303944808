import { RecordLink } from "@sciencecorp/helix-components";
import React from "react";
import { NotificationData } from "../../../api/notifications";
import { getRecordPath } from "../util";
import { SpendingAuthorityPreview } from "../../Purchasing/SpendingAuthoritySelectTree";

export const getRecordLink = (notification: NotificationData) => {
  const candidateNotificationTypes = [
    "candidate_feedback_all_completed",
    "candidate_references_notes",
    "candidate_request_to_hire",
  ];

  const subscriptionNotificationTypes = [
    "subscription_payment_created",
    "subscription_approval_created",
  ];

  if (candidateNotificationTypes.includes(notification.notification_type)) {
    return (
      <RecordLink
        maxWidth="14ch"
        identifier={`${notification.content}`}
        link={getRecordPath(notification)}
        size="small"
      />
    );
  } else if (subscriptionNotificationTypes.includes(notification.notification_type)) {
    return (
      <RecordLink
        maxWidth="14ch"
        identifier={`${notification.content}`}
        link={getRecordPath(notification)}
        size="small"
      />
    );
  } else if (notification.notifiable_type === "Purchase") {
    return (
      <RecordLink
        maxWidth="14ch"
        type="Purchase"
        identifier={`${notification.notifiable_id}`}
        link={getRecordPath(notification)}
      />
    );
  } else if (notification.notifiable_type === "PurchaseReturn") {
    return (
      <RecordLink
        maxWidth="14ch"
        type="Purchase"
        identifier={notification.parent_model?.model_id || ""}
        link={getRecordPath(notification)}
      />
    );
  } else if (
    notification.notifiable_type === "ReviewPrompt" ||
    notification.notifiable_type === "ReviewFeedback"
  ) {
    return (
      <RecordLink
        maxWidth="14ch"
        identifier="Performance Reviews"
        link={getRecordPath(notification)}
        size="small"
      />
    );
  } else if (notification.notifiable_type === "Reimbursement") {
    return (
      <RecordLink
        maxWidth="14ch"
        type="Reimbursement"
        identifier={`${notification.notifiable_id}`}
        link={getRecordPath(notification)}
      />
    );
  } else if (notification.notifiable_type === "Contract") {
    return (
      <RecordLink
        maxWidth="14ch"
        type="Contract"
        identifier={notification.notifiable_id}
        link={getRecordPath(notification)}
      />
    );
  } else if (notification.notifiable_type === "SpendingAuthorityChangeRequest") {
    return (
      <RecordLink
        maxWidth="14ch"
        type="Purchase"
        identifier={notification.parent_model?.model_id || ""}
        link={getRecordPath(notification)}
      />
    );
  } else if (notification.notifiable_type === "PurchaseRefund") {
    return (
      <RecordLink
        maxWidth="14ch"
        type="Purchase"
        identifier={notification.parent_model?.model_id || ""}
        link={getRecordPath(notification)}
      />
    );
  } else if (notification.notifiable_type === "Training") {
    const trainingNameContent = notification.rendered_content.find((part) => part.style === "bold");
    return (
      <RecordLink
        maxWidth="14ch"
        identifier={`${trainingNameContent?.content || "Training"}`}
        link={getRecordPath(notification)}
        size="small"
      />
    );
  } else if (notification.notification_type === "user_credential_status") {
    const credentialName = notification.rendered_content.find((part) => part.style === "bold");
    return (
      <RecordLink
        maxWidth="14ch"
        identifier={`${credentialName?.content || "Credential"}`}
        link={getRecordPath(notification)}
        size="small"
      />
    );
  } else if (notification.notifiable_type === "ServiceRequest") {
    return (
      <RecordLink
        maxWidth="14ch"
        type="Service Request"
        identifier={notification.notifiable_id}
        link={getRecordPath(notification)}
        size="small"
      />
    );
  } else if (notification.notifiable_type === "ServiceRequestApproval") {
    return (
      <RecordLink
        maxWidth="14ch"
        type="Service Request"
        identifier={notification.parent_model?.model_id || ""}
        link={getRecordPath(notification)}
        size="small"
      />
    );
  } else if (notification.notifiable_type === "Contract") {
    return (
      <RecordLink
        maxWidth="14ch"
        type="Contract"
        identifier={notification?.notifiable_id || ""}
        link={getRecordPath(notification)}
      />
    );
  } else if (notification.notifiable_type === "NewsPost") {
    return (
      <RecordLink
        maxWidth="14ch"
        identifier={`${notification.content}`}
        link={getRecordPath(notification)}
        size="small"
      />
    );
  } else if (notification.notifiable_type === "BudgetItem") {
    return (
      <SpendingAuthorityPreview
        spendingAuthorityId={notification.notifiable_id}
        spendingAuthorityType="BudgetItem"
        maxWidth="14ch"
      />
    );
  } else return null;
};

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  HStack,
  AccordionIcon,
  AccordionPanel,
  Text,
  Box,
  Link,
  Stack,
  useColorModeValue,
  Flex,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { invalidateServiceRequest, ServiceRequestShowData } from "../../../../api/service_requests";

import { DeleteableFileDownload } from "../../../shared/DeleteableFileDownload";
import { UserServiceRequestPill } from "../../utils";
import { UserLoggedInData } from "../../../../api/user";
import { useDeleteFile } from "../../../../api/blob_files";
import { FormResponseData } from "../../../../api/form_builder";
import { EditIcon } from "@chakra-ui/icons";
import { TeamServiceRequestModal } from "../NewServiceRequestModal";

type ServiceRequestResponsesProps = {
  serviceRequest: ServiceRequestShowData;
  currentUser?: UserLoggedInData | null;
};

export const ServiceRequestResponses = ({
  serviceRequest,
  currentUser,
}: ServiceRequestResponsesProps) => {
  const { mutate: deleteFile } = useDeleteFile(() => invalidateServiceRequest(serviceRequest.id));
  const bgColor = useColorModeValue("gray.50", "gray.700");
  const isRequester = currentUser?.id === serviceRequest.requesting_user.id;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Accordion width="100%" allowToggle defaultIndex={[0]}>
      <AccordionItem border="none">
        <TeamServiceRequestModal
          service={serviceRequest.service}
          isOpen={isOpen}
          onClose={onClose}
          serviceRequest={serviceRequest}
        />
        <AccordionButton padding={0}>
          <HStack as="span" flex="1" textAlign="left">
            <Link href={`/users/${serviceRequest.requesting_user.id}`}>
              <UserServiceRequestPill user={serviceRequest.requesting_user} location="attributes" />
            </Link>
            <Text fontSize="sm">requested: </Text>
            {isRequester && (
              <IconButton
                size="xs"
                aria-label="edit-request"
                icon={<EditIcon />}
                onClick={(e) => {
                  e.stopPropagation();
                  onOpen();
                }}
              />
            )}
          </HStack>
          <AccordionIcon />
        </AccordionButton>

        <AccordionPanel p={0} paddingBlockStart={4}>
          <Stack flexWrap="wrap" direction="row" justify="stretch">
            {serviceRequest.form_submission?.responses.map((response) => {
              if (!validResponse(response)) return null;
              return (
                <Flex
                  direction="column"
                  borderLeft="4px"
                  borderColor="chakra-border-color"
                  bg={bgColor}
                  p={4}
                  flex="1 1 0"
                  minW="300px"
                  maxW="100%">
                  <Text fontWeight="semibold">{response.field_label}</Text>
                  <ResponseBlock response={response} deleteFile={deleteFile} />
                </Flex>
              );
            })}
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

const validResponse = (response: FormResponseData) => {
  if (
    response.type === "Form::Submission::Response::File" ||
    response.type === "Form::Submission::Response::MultiSelect"
  ) {
    return response.value.length > 0;
  }
  return response.value !== null && response.value !== "";
};

const ResponseBlock = ({
  response,
  deleteFile,
}: {
  response: FormResponseData;
  deleteFile: any;
}) => {
  switch (response.type) {
    case "Form::Submission::Response::File":
      return (
        <>
          {response.value.map((file) => (
            <Box key={file.filename} w="max-content">
              <DeleteableFileDownload file={file} deleteFile={deleteFile} />
            </Box>
          ))}
        </>
      );
    case "Form::Submission::Response::Number":
      return <Text>{response.value}</Text>;
    case "Form::Submission::Response::Text":
      return (
        <Flex direction="column">
          {response.value?.split("\n").map((line, index) => (
            <Text key={index}>{line}</Text>
          ))}
        </Flex>
      );
    case "Form::Submission::Response::Date":
      return <Text>{response.value?.toFormat("LLL dd, yyyy")}</Text>;
    case "Form::Submission::Response::SingleSelect":
      return <Text>{response.value_label}</Text>;
    case "Form::Submission::Response::MultiSelect":
      return <Text>{response.value_label.join(", ")}</Text>;
    default:
      return <></>;
  }
};

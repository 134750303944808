import React from "react";
import {
  Flex,
  Box,
  Switch,
  Text,
  IconButton,
  Checkbox,
  Radio,
  Stack,
  Icon,
  HStack,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { EditableText } from "@sciencecorp/helix-components";
import {
  FormFieldData,
  useCopyTemplateField,
  useCreateTemplateFieldOption,
  useDeleteTemplateField,
  useDeleteTemplateFieldOption,
  useUpdateTemplateField,
  useUpdateTemplateFieldOption,
} from "../../../../api/form_builder";
import { formFieldOptions } from "./FormBuilder";
import { Select } from "chakra-react-select";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CopyIcon,
  DeleteIcon,
  SmallAddIcon,
  WarningIcon,
  WarningTwoIcon,
} from "@chakra-ui/icons";
import { NetworkError } from "../../../types";
import { ServicePartialData } from "../../../../api/services";

type FormFieldProps = {
  service: ServicePartialData;
  field: FormFieldData;
  canReorderUp: boolean;
  canReorderDown: boolean;
  canEdit: boolean;
  handleReorderUp: () => void;
  handleReorderDown: () => void;
  isLoadingReorder: boolean;
  errors: string[];
};

export const FormField = ({
  service,
  field,
  canReorderUp,
  canReorderDown,
  handleReorderDown,
  handleReorderUp,
  isLoadingReorder,
  canEdit,
  errors,
}: FormFieldProps) => {
  const { mutateAsync: updateField } = useUpdateTemplateField(service.id);
  const { mutateAsync: copyField } = useCopyTemplateField(service.id);
  const { mutateAsync: deleteField } = useDeleteTemplateField(service.id);
  const toast = useToast();

  const optionConfig = formFieldOptions.find((o) => o.value === field.type);

  const isSelect =
    optionConfig?.value === "Form::Field::SingleSelect" ||
    optionConfig?.value === "Form::Field::MultiSelect";

  return (
    <Flex
      direction="column"
      border="1px"
      borderColor="chakra-border-color"
      borderRadius="md"
      w="100%">
      <Stack
        p={4}
        direction={{ base: "column", md: "row" }}
        w="100%"
        justify="space-between"
        borderBottom="1px"
        borderColor="chakra-border-color">
        <Stack direction="row" align="center">
          <Flex
            bg={optionConfig?.color}
            borderRadius="md"
            justify="center"
            align="center"
            boxSize={7}>
            <Icon as={optionConfig?.icon} boxSize={5} color="white" />
          </Flex>
          {canEdit ? (
            <Select
              useBasicStyles
              isDisabled={!canEdit}
              options={formFieldOptions}
              value={optionConfig}
              onChange={(value) => {
                value && updateField({ fieldId: field.id, data: { type: value.value } });
              }}
            />
          ) : (
            <Text fontWeight="semibold">{optionConfig?.label}</Text>
          )}
        </Stack>
        <Stack direction="row" align="center">
          <Switch
            isDisabled={!canEdit}
            colorScheme="teal"
            isChecked={field.is_required}
            onChange={(e) =>
              updateField({ fieldId: field.id, data: { is_required: e.target.checked } })
            }
          />
          <Text>Required</Text>
          <IconButton
            aria-label="move up"
            onClick={handleReorderUp}
            isDisabled={!canReorderUp || !canEdit}
            icon={<ChevronUpIcon />}
            variant="ghost"
            isLoading={isLoadingReorder}
            size="sm"
          />
          <IconButton
            aria-label="move down"
            onClick={handleReorderDown}
            isDisabled={!canReorderDown || !canEdit}
            icon={<ChevronDownIcon />}
            isLoading={isLoadingReorder}
            size="sm"
            variant="ghost"
          />
          <IconButton
            aria-label="copy field"
            icon={<CopyIcon />}
            size="sm"
            isDisabled={!canEdit}
            onClick={() =>
              copyField({ fieldId: field.id }).then(() =>
                toast({
                  title: "Field successfully copied",
                  status: "success",
                })
              )
            }
          />
          <IconButton
            colorScheme="red"
            isDisabled={!canEdit}
            variant="outline"
            aria-label="delete"
            icon={<DeleteIcon />}
            size="sm"
            onClick={() => {
              deleteField({ fieldId: field.id }).then(() =>
                toast({
                  title: "Field successfully deleted",
                  status: "success",
                })
              );
            }}
          />
        </Stack>
      </Stack>
      <Flex direction="column" p={4} gap={2}>
        <Box fontSize="lg" fontWeight="semibold">
          <EditableText
            placeholder="Enter Label"
            defaultValue={field.label}
            persistentEdit={canEdit}
            disabled={!canEdit}
            onSubmit={(value) => {
              if (value) updateField({ fieldId: field.id, data: { label: value.toString() } });
            }}
          />
        </Box>

        <Box ml={3} fontSize="sm">
          <EditableText
            persistentEdit={canEdit}
            disabled={!canEdit}
            placeholder="Enter Supporting Text"
            defaultValue={field.description}
            onSubmit={(value) => {
              if (value)
                updateField({ fieldId: field.id, data: { description: value.toString() } });
            }}
          />
        </Box>
        {isSelect && <SelectOptions field={field} service={service} canEdit={canEdit} />}
      </Flex>
      {errors && errors.length > 0 && (
        <Text color={useColorModeValue("red.500", "red.300")} fontSize="sm" p={4}>
          <Icon as={WarningTwoIcon} mr={2} />
          {errors.join(". ")}
        </Text>
      )}
    </Flex>
  );
};

const SelectOptions = ({ field, service, canEdit }) => {
  const { mutate: createFieldOption } = useCreateTemplateFieldOption(service.id);
  const { mutate: updateFieldOption } = useUpdateTemplateFieldOption(service.id);
  const { mutate: deleteFieldOption } = useDeleteTemplateFieldOption(service.id);
  const toast = useToast();

  return (
    <Stack direction="column" gap={2} m={2}>
      {field.options.map((opt, idx) => (
        <Flex key={idx} direction="row" align="center" gap={2}>
          {field.type === "Form::Field::SingleSelect" ? (
            <Radio colorScheme="teal" isDisabled />
          ) : (
            <Checkbox colorScheme="teal" isDisabled />
          )}
          <EditableText
            minWidth="max-content"
            placeholder="Enter Option"
            defaultValue={opt.label}
            persistentEdit={canEdit}
            disabled={!canEdit}
            onSubmit={(value) => {
              if (value) {
                const snake_case_value = value.toString().replace(/\s+/g, "_").toLowerCase();
                updateFieldOption(
                  {
                    optionId: opt.id,
                    data: {
                      label: value.toString(),
                      value: snake_case_value,
                    },
                  },
                  {
                    onError: (error) => {
                      const networkError = error as NetworkError;

                      toast({
                        title: "Error!",
                        description:
                          networkError.response.data.error.message || "An error occurred",
                        status: "error",
                      });
                    },
                  }
                );
              }
            }}
          />
          {canEdit && (
            <IconButton
              size="xs"
              icon={<DeleteIcon />}
              colorScheme="red"
              aria-label="delete option"
              variant="outline"
              onClick={() => deleteFieldOption({ optionId: opt.id })}
            />
          )}
        </Flex>
      ))}
      <HStack>
        <IconButton
          size="xs"
          icon={<SmallAddIcon />}
          aria-label="add option"
          onClick={() => createFieldOption({ fieldId: field.id, data: { label: "", value: "" } })}
        />
      </HStack>
    </Stack>
  );
};

import { z } from "zod";
import { teamServiceCategorySchema } from "./team_service_category";
import { useMutation } from "@tanstack/react-query";
import { api } from ".";
import { invalidateServiceRequest } from "./service_requests";

export const SERVICE_REQUEST_CATEGORY_BASE_URL = "/service_request_categories";

export const serviceRequestCategorySchema = z.object({
  id: z.number(),
  service_request_id: z.number(),
  team_service_category: teamServiceCategorySchema,
});

export type ServiceRequestCategory = z.infer<typeof serviceRequestCategorySchema>;

export const deleteServiceRequestCategory = async (id: number) => {
  return await api.delete(`${SERVICE_REQUEST_CATEGORY_BASE_URL}/${id}`);
};

export const useDeleteServiceRequestCategory = (serviceRequestId?: number) => {
  return useMutation({
    mutationFn: deleteServiceRequestCategory,
    onSuccess: invalidateServiceRequest(serviceRequestId),
  });
};

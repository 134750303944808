import { useMutation, useQuery, UseQueryResult } from "@tanstack/react-query";
import { date, z } from "zod";
import { api } from ".";
import { moneySchema } from "../helpers/Money";
import { blobFileSchema } from "./blob_files";
import * as ct from "./collection_types";
import { indexParams, SearchParams, searchParams } from "./collection_types";
import { invalidatePurchases } from "./purchase";
import { zodParse } from "./zodParse";
import { createSearchResponseSchema } from "./shared";
import _ from "lodash";
import { dateTimeSchema } from "../helpers/dateTime";
import { invalidateContracts } from "./contracts";

export const PURCHASE_PAYMENTS_BASE_URL = "purchase_payments";

export const purchasePaymentSchema = z.object({
  id: z.number(),
  purchase_id: z.number().nullable().optional(),
  payment_schedule_id: z.number().nullable().optional(),
  amount: moneySchema,
  due_date: z.string().nullable(),
  due_date_condition: z.string().nullable(),
  status: z.string(),
  payment_type: z.enum(["wire", "credit", "check"]),
  acknowledged_at: z.string().nullable(),
  paid_at: dateTimeSchema.nullable(),
});

export const purchasePaymentTransactionSchema = z.object({
  id: z.number(),
  payable: z.object({ id: z.number(), type: z.string(), app_href: z.string().url() }),
  amount: moneySchema,
  status: z.string(),
  paid_at: dateTimeSchema.nullable(),
  vendor: z.object({ id: z.number(), name: z.string() }),
});

export const purchasePaymentScorecardResultSchema = z.object({
  vendor: z.object({ id: z.number().nullable(), name: z.string().nullable() }),
  source_of_authority: z
    .object({
      id: z.number(),
      name: z.string(),
      type: z.string(),
      app_href: z.string(),
    })
    .nullable(),
  status: z.string(),
  due_date: z.string().nullable(),
  due_date_condition: z.string().nullable(),
  paid_at: z.string().nullable(),
  record: z.object({ id: z.number(), identifier: z.string(), created_at: z.string() }),
  amount: moneySchema,
});

export const subScorecardSchema = z.object({
  month: z.string(),
  total: moneySchema,
  results: z.array(purchasePaymentScorecardResultSchema),
});
export const purchasePaymentsScorecardsSchema = z.object({
  stats: z.object({
    total_committed: moneySchema,
    fy2024_total: moneySchema,
    next_12_months_total: moneySchema,
    next_24_months_total: moneySchema,
    total_undated: moneySchema,
  }),
  scorecards: z.array(subScorecardSchema),
});

export const purchasePaymentShowSchema = purchasePaymentSchema
  .omit({
    purchase_id: true,
    payment_schedule_id: true,
  })
  .extend({
    uploaded_files: z.array(blobFileSchema),
  });

export const purchasePaymentIndexSchema = z.object({
  results: purchasePaymentsScorecardsSchema,
  aggregations: z.record(z.string(), ct.aggregationSchema).nullable(),
});

export type PurchasePaymentData = z.infer<typeof purchasePaymentSchema>;
export type PurchasePaymentTransactionData = z.infer<typeof purchasePaymentTransactionSchema>;
export type PurchasePaymentScorecardData = z.infer<typeof purchasePaymentScorecardResultSchema>;

export type PurchasePaymentShowData = z.infer<typeof purchasePaymentShowSchema>;

export type PurchasePaymentCreateParams = Pick<
  PurchasePaymentData,
  "purchase_id" | "payment_schedule_id"
> & { payment_type?: "wire" | "credit" | "check" };

export type PurchasePaymentUpdateParams = Partial<PurchasePaymentData> & { id: number };

const paymentSchedulePaymentsSchema = z.array(purchasePaymentSchema);

export type PaymentSchedulePurchasesCreateParams = z.infer<typeof paymentSchedulePaymentsSchema>;
export type PurchasePaymentScorecards = z.infer<typeof purchasePaymentsScorecardsSchema>;
export type PurchasePaymentSubScorecard = z.infer<typeof subScorecardSchema>;
export type PurchasePaymentIndexScorecards = z.infer<typeof purchasePaymentIndexSchema>;

export const getPurchasePaymentsScorecards = async (includePaid: boolean) => {
  const result = await api.get(`${PURCHASE_PAYMENTS_BASE_URL}/scorecards`, {
    params: { include_paid: includePaid },
  });
  return zodParse(purchasePaymentsScorecardsSchema, result.data);
};

export const createPaymentSchedulePayments = async (
  purchase_payment: PaymentSchedulePurchasesCreateParams
) => {
  const result = await api.post(`${PURCHASE_PAYMENTS_BASE_URL}/bulk`, { purchase_payment });
  return result.data;
};

export const createPurchasePayment = async (
  purchase_payment: PurchasePaymentCreateParams
): Promise<PurchasePaymentShowData> => {
  const result = await api.post(PURCHASE_PAYMENTS_BASE_URL, { purchase_payment });
  return zodParse(purchasePaymentShowSchema, result.data);
};

export const updatePurchasePayment = async (
  purchase_payment: PurchasePaymentUpdateParams
): Promise<PurchasePaymentShowData> => {
  const result = await api.put(`${PURCHASE_PAYMENTS_BASE_URL}/${purchase_payment.id}`, {
    purchase_payment,
  });
  return zodParse(purchasePaymentShowSchema, result.data);
};

export const acknowledgePurchasePayments = async (purchase_payment_ids: number[]) => {
  await api.post(`${PURCHASE_PAYMENTS_BASE_URL}/acknowledge`, { purchase_payment_ids });
};

export const deletePurchasePayment = async (id: number): Promise<void> => {
  await api.delete(`${PURCHASE_PAYMENTS_BASE_URL}/${id}`);
};

export const searchPurchasePaymentScorecards = async ({
  term,
  aggs,
  filters,
  pagination,
  order,
  type,
}: SearchParams & { type: "paid_at" | "due_date" }): Promise<PurchasePaymentIndexScorecards> => {
  const search = searchParams({ aggs, filters, term });
  const index = indexParams({ pagination, order });
  const result = await api.post(`${PURCHASE_PAYMENTS_BASE_URL}/search/scorecards`, {
    ...index,
    ...search,
    type,
  });
  return zodParse(purchasePaymentIndexSchema, result.data);
};

export const searchPurchasePayments = async ({
  term,
  aggs,
  filters,
  pagination,
  order,
  currency,
}: SearchParams & { currency?: string | null }) => {
  const search = searchParams({ aggs, filters, term });
  const index = indexParams({ pagination, order });
  const result = await api.post(`${PURCHASE_PAYMENTS_BASE_URL}/search`, {
    currency,
    ...index,
    ...search,
  });
  return zodParse(createSearchResponseSchema(purchasePaymentTransactionSchema), result.data);
};

export const useGetPurchasePaymentScorecards = (includePaid: boolean, options = {}) => {
  return useQuery(
    [PURCHASE_PAYMENTS_BASE_URL, includePaid],
    () => getPurchasePaymentsScorecards(includePaid),
    options
  );
};

export const useCreatePaymentSchedulePayments = (purchaseId: number) => {
  return useMutation({
    mutationFn: createPaymentSchedulePayments,
    onSuccess: invalidatePurchases(purchaseId, "financials"),
  });
};

export const useCreatePurchasePayment = (onSuccessCallback) => {
  return useMutation({
    mutationFn: createPurchasePayment,
    onSuccess: onSuccessCallback(),
  });
};

export const useUpdatePurchasePayment = (onSuccessCallback) => {
  return useMutation({
    mutationFn: updatePurchasePayment,
    onSuccess: onSuccessCallback(),
  });
};

export const useAcknowledgePurchasePayments = (id?: number) => {
  return useMutation({
    mutationFn: acknowledgePurchasePayments,
    onSuccess: invalidateContracts(id),
  });
};

export const useDeletePurchasePayment = (onSuccessCallback) => {
  return useMutation({
    mutationFn: deletePurchasePayment,
    onSuccess: onSuccessCallback(),
  });
};

export const useSearchPurchasePaymentScorecards = (
  params: SearchParams & { type: "paid_at" | "due_date" }
): UseQueryResult<PurchasePaymentIndexScorecards> => {
  return useQuery({
    queryKey: [PURCHASE_PAYMENTS_BASE_URL, "scorecards", params],
    queryFn: () => searchPurchasePaymentScorecards(params),
  });
};

export const useSearchPurchasePayments = (params: SearchParams & { currency?: string | null }) => {
  return useQuery({
    queryKey: [PURCHASE_PAYMENTS_BASE_URL, params],
    queryFn: () => searchPurchasePayments(params),
  });
};

import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  ButtonGroup,
  Text,
  VStack,
  Box,
  Heading,
  HStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { useDeleteTemplate } from "../../../../api/form_builder";
import { ServicePartialData } from "../../../../api/services";

type DraftTemplateConfirmationModalProps = {
  service: ServicePartialData;
  isOpen: boolean;
  onClose: () => void;
};

export const DraftTemplateConfirmationModal = ({
  service,
  isOpen,
  onClose,
}: DraftTemplateConfirmationModalProps) => {
  const navigate = useNavigate();

  const { mutateAsync: deleteTemplate } = useDeleteTemplate(service.id);
  const draft = service.form.draft_template;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Unpublished Changes</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <Text>
              The form on this service has unpublished changes. Would you like to continue editing
              or start over?
            </Text>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup display="flex" justifyContent="space-between" w="100%">
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <HStack>
              <Button
                colorScheme="red"
                variant="outline"
                onClick={() => {
                  draft &&
                    deleteTemplate({ templateId: draft.id }).then(() => {
                      navigate(`/teams/${service.team_id}/services/${service.id}`);
                      onClose();
                    });
                  onClose();
                }}>
                Discard
              </Button>
              <Button
                colorScheme="teal"
                onClick={() => navigate(`/teams/${service.team_id}/services/${service.id}`)}>
                Edit
              </Button>
            </HStack>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { z } from "zod";
import { api } from ".";
import { dateTimeSchema } from "../helpers/dateTime";
import { moneySchema } from "../helpers/Money";
import { indexParams, searchParams, SearchParams } from "./collection_types";
import { invalidateInventories, INVENTORY_BASE_URL, inventorySchema } from "./inventory";
import { createSearchResponseSchema } from "./shared";
import { minimalSpendingAuthoritySchema, spendingAuthorityTypeSchema } from "./spending_authority";
import { userMinimalSchema } from "./user";
import { zodParse } from "./zodParse";
import { barcodeSchema } from "./barcode";

export const INVENTORY_ITEM_BASE_URL = "inventory_items";

export const inventoryItemSchema = z.object({
  id: z.number(),
  expiration_date: dateTimeSchema.nullable(),
  lot_number: z.string().nullable(),
  purchase_line_item_id: z.number().nullable(),
  spending_authority_id: z.number().nullable(),
  spending_authority_type: spendingAuthorityTypeSchema.nullable(),
  amount: moneySchema,
  inventory_location_id: z.number().nullable(),
  inventory_id: z.number(),
  barcode: barcodeSchema.nullable(),
  archived_at: dateTimeSchema.nullable().optional(),
  assignment_date: dateTimeSchema.nullable(),
  user_id: z.number().nullable(),
  unique_id: z.string().nullable(),
  is_backfill: z.boolean(),
  total_quantity: z.number(),
});

export const inventoryItemShowSchema = inventoryItemSchema
  .omit({
    inventory_id: true,
    archived_at: true,
    user_id: true,
    spending_authority_id: true,
    spending_authority_type: true,
  })
  .extend({
    spending_authority: z.lazy(() => minimalSpendingAuthoritySchema).nullable(),
    inventory: z.lazy(() =>
      inventorySchema.pick({
        id: true,
        name: true,
        is_consumable: true,
        unit_of_measurement: true,
      })
    ),
    user: z.lazy(() => userMinimalSchema).nullable(),
    consumable_summary: z
      .object({
        remaining_consumable_quantity: z.number(),
        remaining_amount: moneySchema,
      })
      .optional(),
  });

export const inventoryItemCheckoutSchema = z.object({
  id: z.number(),
  amount: moneySchema,
  is_backfill: z.boolean(),
  total_quantity: z.number(),
  consumable_summary: z.object({
    remaining_consumable_quantity: z.number(),
    remaining_amount: moneySchema,
  }),
});

export const inventoryItemAggregationSchema = z.object({
  id: z.number(),
  is_editable: z.boolean(),
  is_consumable: z.boolean(),
  expiration_date: z.string().nullable(),
  lot_number: z.string().nullable(),
  quantity: z.number(),
  unit_of_measurement: z.string().nullable(),
});

export const bulkCheckoutInventorySchema = z.object({
  items: z.array(z.object({ id: z.number(), quantity_used: z.number() })),
  spending_authority_id: z.number().nullable(),
  spending_authority_type: z.enum(["BudgetItem", "ServiceRequest", "RevenueItem"]).nullable(),
  purpose: z.enum(["spending_authority", "used", "waste"]),
});

export type InventoryItemAggregationData = z.infer<typeof inventoryItemAggregationSchema>;
export type InventoryItemData = z.infer<typeof inventoryItemSchema>;
export type InventoryItemShowData = z.infer<typeof inventoryItemShowSchema>;
export type InventoryItemCheckoutData = z.infer<typeof inventoryItemCheckoutSchema>;
export type InventoryItemCreateParams = Omit<
  InventoryItemData,
  | "id"
  | "spending_authority_id"
  | "spending_authority_type"
  | "barcode"
  | "archived_at"
  | "assignment_date"
  | "unique_id"
  | "user_id"
> & {
  quantity: number;
  purchase_line_item_id?: number | null;
};

export type InventoryItemBulkCheckoutParams = z.infer<typeof bulkCheckoutInventorySchema>;

export type InventoryItemUpdateParams = Partial<InventoryItemData> & {
  id: number;
  user_id?: number | null;
};

type InventoryItemUsedParams = {
  id: number;
  quantity_used: number;
};

// api queries
export const createInventoryItem = async (
  inventory_item: InventoryItemCreateParams
): Promise<InventoryItemData[]> => {
  const result = await api.post(INVENTORY_ITEM_BASE_URL, {
    inventory_item,
  });
  return zodParse(z.array(inventoryItemSchema), result.data);
};

export const getInventoryItems = async () => {
  const result = await api.get(INVENTORY_ITEM_BASE_URL);
  return zodParse(z.array(inventoryItemSchema), result.data);
};

export const getInventoryItem = async (id: number) => {
  const result = await api.get(`${INVENTORY_ITEM_BASE_URL}/${id}`);
  return zodParse(inventoryItemShowSchema, result.data);
};

export const usedInventoryItem = async (inventory_item: InventoryItemUsedParams) => {
  await api.post(`${INVENTORY_ITEM_BASE_URL}/${inventory_item.id}/mark_as_used`, {
    inventory_item,
  });
};

export const searchInventoryItems = async ({
  aggs,
  filters,
  pagination,
  bodyOptions,
  order,
  term,
}: SearchParams) => {
  const path = [INVENTORY_ITEM_BASE_URL, "search"];
  const index = indexParams({ pagination, order });
  const search = searchParams({ bodyOptions, aggs, filters, term });
  const result = await api.post(path.join("/"), { ...index, ...search });
  return zodParse(createSearchResponseSchema(inventoryItemShowSchema), result.data);
};

export const updateInventoryItem = async (inventory_item: InventoryItemUpdateParams) => {
  await api.put(`${INVENTORY_ITEM_BASE_URL}/${inventory_item.id}`, { inventory_item });
};

export const deleteInventoryItem = async (id: number) => {
  await api.delete(`${INVENTORY_ITEM_BASE_URL}/${id}`);
  return id;
};

export const transferInventoryItem = async (inventory_item: {
  id: number;
  spending_authority_id: number;
  spending_authority_type: string;
  occurred_at: DateTime;
  user_id: number | null;
  quantity_used: number;
}): Promise<void> => {
  await api.post(`${INVENTORY_ITEM_BASE_URL}/transfer_spending_authority`, {
    inventory_item: {
      ...inventory_item,
      occurred_at: inventory_item.occurred_at.toISO(),
    },
  });
};

// query hooks

export const invalidateInventoryItems = (id?: number) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [INVENTORY_ITEM_BASE_URL];
  if (id) queryKey.push(id);
  return () => {
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
    queryClient.invalidateQueries({
      queryKey: [INVENTORY_BASE_URL],
    });
  };
};

export const useCreateInventoryItem = (inventoryId?: number) => {
  return useMutation<InventoryItemData[], Error, InventoryItemCreateParams>({
    mutationFn: createInventoryItem,
    onSuccess: invalidateInventories(inventoryId),
  });
};

export const useGetInventoryItems = () => {
  return useQuery({
    queryKey: [INVENTORY_ITEM_BASE_URL],
    queryFn: () => getInventoryItems(),
  });
};

export const useGetInventoryItem = (id: number) => {
  return useQuery({
    queryKey: [INVENTORY_ITEM_BASE_URL, id],
    queryFn: () => getInventoryItem(id),
  });
};

export const useSearchInventoryItems = (params: SearchParams) => {
  return useQuery({
    queryKey: [INVENTORY_ITEM_BASE_URL, params],
    queryFn: () => searchInventoryItems(params),
  });
};

export const useUpdateInventoryItems = () => {
  return useMutation({
    mutationFn: updateInventoryItem,
    onSuccess: invalidateInventoryItems(),
  });
};

export const useDeleteInventoryItem = () => {
  return useMutation({
    mutationFn: deleteInventoryItem,
  });
};

export const useTransferInventoryItem = () => {
  return useMutation({
    mutationFn: transferInventoryItem,
    onSuccess: invalidateInventoryItems(),
  });
};

export const useUsedInventoryItem = () => {
  return useMutation({
    mutationFn: usedInventoryItem,
    onSuccess: invalidateInventoryItems(),
  });
};

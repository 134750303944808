import { z } from "zod";
import { dateTimeSchema } from "../helpers/dateTime";
import { minimalSpendingAuthoritySchema } from "./spending_authority";
import { userMinimalSchema } from "./user";

export const approvalSchema = z
  .object({
    id: z.number(),
    approvable_id: z.number(),
    approvable_type: z.string(),
    user: z.lazy(() => userMinimalSchema),
    created_at: dateTimeSchema,
    is_approved: z.boolean(),
    spending_authority: z.lazy(() => minimalSpendingAuthoritySchema).nullable(),
  })
  .describe("Approval Schema");

export const approvalChainSchema = z
  .object({
    leads: z.lazy(() => z.array(userMinimalSchema)),
    type: z.string().nullable(),
    team_id: z.number().nullable(),
    name: z.string().nullable(),
    approvals: z.array(approvalSchema),
    requires_link_approval: z.boolean(),
    is_approved: z.boolean(),
    is_declined: z.boolean(),
    autonomous_spending: z.boolean().nullable(),
  })
  .describe("Approval Chain Schema");

export const approvableSchema = z
  .object({
    id: z.number(),
    approval_state: z.enum(["approved", "declined", "show_buttons", "show_chain"]),
    approvals: z.array(approvalSchema),
    approval_errors: z.array(z.string()),
    approval_chain: z.array(approvalChainSchema).nullable(),
    remaining_approvers: z.array(approvalChainSchema).nullable(),
    current_user_can_approve: z.boolean(),
    fully_approved: z.boolean(),
    fully_declined: z.boolean(),
    requires_approval: z.boolean(),
    final_approval: approvalSchema.nullable(),
    declining_approval: approvalSchema.nullable(),
  })
  .describe("Approvable Schema");

export type ApprovalData = z.infer<typeof approvalSchema>;
export type ApprovalChainLink = z.infer<typeof approvalChainSchema>;
export type ApprovableData = z.infer<typeof approvableSchema>;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  VStack,
  Flex,
  Heading,
  Stack,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Icon,
  IconButton,
  Divider,
  Tooltip,
  useDisclosure,
  AlertIcon,
  Alert,
  AlertDescription,
  AlertTitle,
} from "@chakra-ui/react";
import { EditableText, Header } from "@sciencecorp/helix-components";
import { ServiceVisibility } from "./ServiceVisibility";
import { Subtasks } from "./Subtasks";
import { FaEye } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useTeamQuery } from "../../../../api/team";
import { useDeleteService, useGetServiceQuery, useUpdateService } from "../../../../api/services";
import { PreviewUserSelect } from "../../../Users/shared/UserSelect";
import { FiArchive } from "react-icons/fi";
import { AssignUsersToServiceModal } from "./AssignEmployeesModal";
import { DefaultAssignees } from "./DefaultAssignees";
import { ExternalURL } from "./ExternalURL";
import { SpendingAuthorityRequired } from "./SpendingAuthorityRequired";
import { AssociatedBillOfMaterials } from "./AssociatedBillOfMaterials";
import {
  useBulkCreateServiceSubscribedUser,
  useDeleteServiceSubscribedUser,
} from "../../../../api/service_subscribed_users";
import { FormBuilder } from "./FormBuilder";
import { PublishFormWidget } from "./PublishFormWidget";
import { useCurrentUserQuery, userHasFeatureFlag } from "../../../../api/user";

export const ServiceStatus = (status: string) => {
  switch (status) {
    case "draft":
      return {
        label: "Draft",
        colorScheme: "gray",
      };
    case "active":
      return {
        label: "Active",
        colorScheme: "green",
      };
    case "archived":
      return {
        label: "Archived",
        colorScheme: "gray",
      };
    default:
      return {
        label: "Draft",
        colorScheme: "gray",
      };
  }
};

export const ServiceDetailsPage = () => {
  const { team_id, id } = useParams();
  const { data: team, isSuccess } = useTeamQuery(Number(team_id));
  const { mutateAsync: updateService, isLoading: isUpdatingService } = useUpdateService(Number(id));
  const { data: service, isSuccess: isServiceSuccess } = useGetServiceQuery(Number(id));
  const { mutateAsync: deleteService } = useDeleteService();
  const [isExternalURL, setIsExternalURL] = useState(false);
  const currentUserQuery = useCurrentUserQuery();
  const canSubmit = () => {
    if (isExternalURL) {
      return {
        canSubmit: service?.external_url && service?.description && service?.name,
        label: "Please fill out all fields before submitting: title, description, and external URL",
      };
    } else {
      return {
        canSubmit: service?.description && service?.name && service?.visibility,
        label: "Please fill out all fields before submitting: title, description, and visibility",
      };
    }
  };
  const { mutateAsync: deleteServiceSubscribedUser, isLoading: isDeletingServiceSubscribedUser } =
    useDeleteServiceSubscribedUser();
  const navigate = useNavigate();
  const {
    isOpen: isOpenAssignEmployeesModal,
    onOpen: onOpenAssignEmployeesModal,
    onClose: onCloseAssignEmployeesModal,
  } = useDisclosure();
  const {
    mutateAsync: bulkCreateServiceSubscribedUser,
    isLoading: isCreatingServiceSubscribedUser,
  } = useBulkCreateServiceSubscribedUser(Number(id));

  return isSuccess && isServiceSuccess ? (
    <Box>
      {service?.status === "draft" && (
        <Alert status="warning" variant="subtle" width="100%" marginBottom={4}>
          <AlertIcon />
          <Stack direction="column" gap={0}>
            <AlertTitle>Draft Mode</AlertTitle>
            <AlertDescription>
              This service is currently in draft mode. Once you submit, it will be visible to all
              users.
            </AlertDescription>
          </Stack>
        </Alert>
      )}
      <AssignUsersToServiceModal
        serviceId={Number(id)}
        isOpen={isOpenAssignEmployeesModal}
        onClose={onCloseAssignEmployeesModal}
        serviceUsers={service?.service_subscribed_users ?? []}
        onSubmit={bulkCreateServiceSubscribedUser}
        isLoading={isCreatingServiceSubscribedUser}
        typeOfUser="subscriber"
      />
      <Header
        title={service?.name || "Service Name"}
        isEditable={true}
        badge={service?.status ? ServiceStatus(service?.status) : undefined}
        editableTextProps={{
          isLoading: isUpdatingService,
          onSubmit: (value) => {
            if (value) updateService({ name: value, id: Number(id) });
          },
          persistentEdit: true,
        }}
        crumbs={[
          {
            url: "/teams",
            label: "Teams",
          },
          {
            url: `/teams/${team_id}/services`,
            label: team?.name || "Team Name",
          },
        ]}
        crumbsColor="teal"
        actions={[
          <Box key="subscribers">
            <PreviewUserSelect
              handleDelete={deleteServiceSubscribedUser}
              addedUsers={service?.service_subscribed_users ?? []}
              additionalUsersTitle="Subscribers"
              isDisabled={false}
              isLoading={isDeletingServiceSubscribedUser}
            />
          </Box>,

          <Tooltip label="Add Subscribers" key="add-subscriber">
            <IconButton
              alignSelf={"center"}
              icon={<AddIcon />}
              aria-label="Add Subscribers"
              size="sm"
              onClick={onOpenAssignEmployeesModal}
            />
          </Tooltip>,
          <Divider orientation="vertical" height="35px" key="divider" />,
          <Button
            leftIcon={<FaEye />}
            key="preview"
            onClick={() => {
              navigate(`/teams/${team_id}/services/${id}/preview`);
            }}>
            Preview
          </Button>,
          service?.status === "draft" ? (
            <Tooltip label={!canSubmit().canSubmit ? canSubmit().label : ""} key="submit">
              <Button
                colorScheme="teal"
                isDisabled={!canSubmit().canSubmit}
                onClick={() => updateService({ status: "active", id: Number(id) })}>
                Publish Service
              </Button>
            </Tooltip>
          ) : (
            <></>
          ),
          <Menu key="menu">
            <MenuButton as={IconButton} icon={<Icon as={BsThreeDotsVertical} />} />
            <MenuList>
              {service?.status === "draft" && (
                <MenuItem
                  color="red"
                  icon={<DeleteIcon />}
                  onClick={() =>
                    deleteService(Number(id)).then(() => navigate(`/teams/${team_id}/services`))
                  }>
                  Delete
                </MenuItem>
              )}
              {service?.status !== "draft" && service?.status !== "archived" && (
                <MenuItem
                  icon={<FiArchive />}
                  onClick={() => updateService({ status: "archived", id: Number(id) })}>
                  Archive
                </MenuItem>
              )}
              {service?.status === "archived" && (
                <MenuItem onClick={() => updateService({ status: "active", id: Number(id) })}>
                  Unarchive
                </MenuItem>
              )}
            </MenuList>
          </Menu>,
        ]}
      />
      <Divider />
      <Box maxWidth="960px" mx="auto" p={4}>
        <VStack spacing={6} align="stretch">
          <Flex
            justifyContent={"start"}
            direction={"column"}
            w={"100%"}
            border="1px"
            borderColor="chakra-border-color"
            p={6}
            gap={4}
            borderRadius="md">
            <Stack flexDir={"column"} width={"100%"} gap={2}>
              <Heading size="md">Description</Heading>
              <EditableText
                multiline
                isLoading={isUpdatingService}
                persistentEdit
                defaultValue={service?.description}
                placeholder="Enter Supporting Text"
                onSubmit={(value) => {
                  if (value) updateService({ description: value, id: Number(id) });
                }}
              />
            </Stack>
          </Flex>

          <ExternalURL
            service={service}
            isExternalURL={isExternalURL}
            setIsExternalURL={setIsExternalURL}
          />

          {!isExternalURL && (
            <>
              <ServiceVisibility service={service} />
              <DefaultAssignees
                assignedUsers={service?.service_assigned_users ?? []}
                serviceId={Number(id)}
              />
              <SpendingAuthorityRequired service={service} />
              <AssociatedBillOfMaterials service={service} />

              <FormBuilder service={service} />
              <Subtasks isServiceCreation={true} service={service} />
            </>
          )}
        </VStack>
      </Box>
      {service.form.draft_template && (
        <PublishFormWidget template={service.form.draft_template} service={service} />
      )}
    </Box>
  ) : (
    <></>
  );
};

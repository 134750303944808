import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { zodParse } from "./zodParse";

export const VIRTUAL_CARDS_BASE_URL = "virtual_cards";

export const virtualCardSchema = z.object({
  id: z.string(),
  cardholder: z.object({
    id: z.string(),
    billing_address: z.object({
      city: z.string(),
      country: z.string(),
      line1: z.string(),
      line2: z.string().nullable(),
      postal_code: z.string(),
      state: z.string(),
    }),
    name: z.string(),
    status: z.string(),
  }),
  status: z.string(),
  exp_month: z.number(),
  exp_year: z.number(),
  last4: z.string(),
  number: z.string(),
  cvc: z.string(),
});

export const getVirtualCardInformationById = async (cardId: number | null | undefined) => {
  const result = await api.get(`${VIRTUAL_CARDS_BASE_URL}/${cardId}`);
  return zodParse(virtualCardSchema, result.data);
};

export const getVirtualCardInformation = async (id: number) => {
  const result = await api.post(`${VIRTUAL_CARDS_BASE_URL}/get_card/${id}`);
  return zodParse(virtualCardSchema, result.data);
};

export const useGetVirtualCard = (id: number | null | undefined, showCard: boolean) => {
  return useQuery({
    queryKey: [VIRTUAL_CARDS_BASE_URL, id],
    queryFn: () => getVirtualCardInformationById(id),
    enabled: !!id && showCard,
  });
};

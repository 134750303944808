import {
  Box,
  Flex,
  Heading,
  HStack,
  IconButton,
  Spinner,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { EmptyState, Select } from "@sciencecorp/helix-components";
import React, { useMemo, useState } from "react";
import { FaEye, FaEyeSlash, FaListUl } from "react-icons/fa";
import { LuLayoutGrid } from "react-icons/lu";
import { useGetAllBudgetGroupsSummary, useGetBudgetGroups } from "../../../api/budget_groups";
import { UnassignedBudgetsTable } from "./BudgetGroup/UnassignedBudgets";
import BudgetActivityBarGraph from "./components/BudgetActivityBarGraph";
import { BudgetActivityLinearBar } from "./components/BudgetActivityLinearBar";
import { BudgetGroupGrid } from "./components/BudgetGroupGrid";
import { BudgetGroupList } from "./components/BudgetGroupList";
import { BudgetGroupModal } from "./components/BudgetGroupModal";

import { summaryDates } from "../utils";

export const CompanyBudgets = ({ admin, budgetGroupLead }) => {
  const [budgetGroupView, setBudgetGroupView] = useState<"list" | "grid">("list");
  const [timeFilter, setTimeFilter] = useState("yearToDate");
  const [showArchived, setShowArchived] = useState(false);

  const { scorePeriodEndDate } = summaryDates(timeFilter);

  const { data: companySummary, isLoading: isLoadingCompanySummary } =
    useGetAllBudgetGroupsSummary(scorePeriodEndDate);

  const {
    data: budgetGroups,
    isLoading: isLoadingBudgetGroups,
    isError: isErrorBudgetGroups,
  } = useGetBudgetGroups();

  const filteredBudgetGroups = useMemo(
    () => budgetGroups?.filter((group) => showArchived || !group.archived_at) || [],
    [budgetGroups, showArchived]
  );

  if (isErrorBudgetGroups) {
    return <Text>Error loading budget groups </Text>;
  }

  return (
    <Flex direction="column" gap={10}>
      {admin ? (
        <Flex
          border="1px"
          borderColor="chakra-border-color"
          borderRadius="md"
          p={6}
          gap={8}
          direction="column">
          <HStack justify="space-between" w="100%">
            <Heading size="md">Budgets</Heading>
            <Select
              size="sm"
              width="200px"
              value={timeFilter}
              onChange={(value: string | number) => setTimeFilter(String(value))}
              placeholder="Select time filter"
              options={[{ value: "yearToDate", label: "Year To Date" }]}></Select>
          </HStack>
          {isLoadingCompanySummary ? (
            <Spinner />
          ) : (
            <>
              <BudgetActivityLinearBar
                summary={companySummary?.spend_summary}
                spendByModel={companySummary?.expenditure_spend_summary}
                highestRecords={companySummary?.highest_spend_and_committed_records}
                showingPreviousPeriod={false}
              />

              {companySummary && (
                <BudgetActivityBarGraph monthlySpend={companySummary.monthly_spend} />
              )}
            </>
          )}
        </Flex>
      ) : null}
      <Flex
        border="1px"
        borderColor="chakra-border-color"
        borderRadius="md"
        p={6}
        gap={8}
        direction="column">
        <HStack justify="space-between" w="100%">
          <Heading size="md">Budget Groups</Heading>
          <Stack direction="row">
            <Tooltip label={showArchived ? "Hide archived" : "Show archived"}>
              <IconButton
                variant="ghost"
                aria-label="toggle-archived"
                icon={showArchived ? <FaEyeSlash /> : <FaEye />}
                onClick={() => setShowArchived((prev) => !prev)}
              />
            </Tooltip>
            <IconButton
              variant={budgetGroupView === "list" ? "solid" : "ghost"}
              aria-label="list-view"
              icon={<FaListUl />}
              onClick={() => setBudgetGroupView("list")}
            />
            <IconButton
              variant={budgetGroupView === "grid" ? "solid" : "ghost"}
              aria-label="grid-view"
              icon={<LuLayoutGrid />}
              onClick={() => setBudgetGroupView("grid")}
            />
            <Box display={["none", "none", "block"]}>{admin && <BudgetGroupModal />}</Box>
          </Stack>
        </HStack>
        <Box display={["block", "none"]}>
          <BudgetGroupModal />
        </Box>
        {(filteredBudgetGroups.length || 0) > 0 ? (
          budgetGroupView === "list" ? (
            <BudgetGroupList
              budgetGroups={filteredBudgetGroups}
              isLoading={isLoadingBudgetGroups}
            />
          ) : (
            <BudgetGroupGrid
              budgetGroups={filteredBudgetGroups}
              isLoading={isLoadingBudgetGroups}
            />
          )
        ) : isLoadingBudgetGroups ? (
          <Spinner />
        ) : (
          <EmptyState title="No budget groups yet" size="xs" />
        )}
      </Flex>
      {admin && <UnassignedBudgetsTable />}
    </Flex>
  );
};

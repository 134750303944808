import { AddIcon } from "@chakra-ui/icons";
import {
  Flex,
  Stack,
  Heading,
  Box,
  IconButton,
  useColorModeValue,
  HStack,
  Divider,
  Button,
  Input,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  VStack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { ImLink } from "react-icons/im";
import { invalidateService, ServicePartialData } from "../../../../api/services";
import { BomLineItem } from "./BomLineItem";
import {
  useCreateServiceInventoryBom,
  useDeleteServiceInventoryBom,
} from "../../../../api/service_inventory_bom";
import { useInventoryBomOptions } from "../../../../api/options";

type AssociatedBillOfMaterialsProps = {
  service: ServicePartialData;
};

export const AssociatedBillOfMaterials = ({ service }: AssociatedBillOfMaterialsProps) => {
  const [searchTerm, setSearchTerm] = useState("");

  const inventoryBomOptions = useInventoryBomOptions({
    excludeIds: service?.service_inventory_boms.map((bom) => bom.inventory_bom_id) ?? [],
    searchTerm: searchTerm,
  });
  const { mutateAsync: deleteServiceInventoryBom, isLoading: isLoadingDeleteBom } =
    useDeleteServiceInventoryBom();

  const { mutateAsync: createServiceInventoryBom } = useCreateServiceInventoryBom();
  return (
    <Box w={"100%"} border="1px" borderColor="chakra-border-color" p={6} gap={4} borderRadius="md">
      <Flex justifyContent="space-between" alignItems="center">
        <Stack
          gap={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%">
          <HStack>
            <Box
              bgColor={useColorModeValue("green.100", "green.500")}
              height={"100%"}
              width={"max-content"}
              color={useColorModeValue("green.700", "green.100")}
              p={"2"}
              marginRight={"2"}
              borderRadius={"md"}>
              <ImLink size={"20px"} />
            </Box>
            <Heading size="md">Associated Bill of Materials</Heading>
          </HStack>

          <Popover placement="bottom-end">
            <PopoverTrigger>
              <IconButton icon={<AddIcon />} aria-label="Add Bill of Materials" size="sm" />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverCloseButton />
              <PopoverBody maxH={52} overflowY="scroll">
                <VStack align="start">
                  <Text fontWeight="semibold">Add Bill of Materials</Text>
                  {inventoryBomOptions && (
                    <Input
                      placeholder="Search for a Bill of Material..."
                      size="sm"
                      value={searchTerm}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                    />
                  )}
                  {inventoryBomOptions?.length > 0 ? (
                    <VStack>
                      {inventoryBomOptions.map((option) => (
                        <Button
                          key={option.value}
                          cursor="pointer"
                          justifyContent="start"
                          bg="transparent"
                          width="100%"
                          onClick={() => {
                            createServiceInventoryBom({
                              service_id: service.id,
                              inventory_bom_id: parseInt(option.value),
                            })
                              .then(() => setSearchTerm(""))
                              .catch((error) => {
                                console.error(
                                  "Failed to create associate bill of materials:",
                                  error
                                );
                              });
                          }}>
                          {option.label}
                        </Button>
                      ))}
                    </VStack>
                  ) : (
                    <Text marginLeft={4}>No Bill of Materials left to add</Text>
                  )}
                </VStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Stack>
      </Flex>
      {service?.service_inventory_boms?.length > 0 && (
        <Divider height="2px" color="gray.500" width="100%" marginTop={4} marginBottom={4} />
      )}
      {service?.service_inventory_boms?.map((bom) => (
        <Stack direction="column" alignItems="flex-start" padding={2}>
          <BomLineItem
            bom={bom}
            deleteBom={deleteServiceInventoryBom}
            isLoadingDeleteBom={isLoadingDeleteBom}
            invalidateBom={invalidateService(service.id)}
          />
        </Stack>
      ))}
    </Box>
  );
};

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FormModal, Select } from "@sciencecorp/helix-components";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { MoneyInput } from "../../../MoneyInput";
import { Money } from "../../../../helpers/Money";
import { useCreateBudgetGroup } from "../../../../api/budget_groups";
import { useUserOptions } from "../../../../api/options";
import { AddIcon, InfoIcon } from "@chakra-ui/icons";
import { DateTime } from "luxon";

type FormValuesType = {
  name: string;
  amount: Money;
  lead_id: number | null;
  start_date: string;
  end_date: string;
};

const defaultFormValues: FormValuesType = {
  name: "",
  amount: Money.zero(),
  lead_id: null,
  start_date: "",
  end_date: "",
};

export const BudgetGroupModal = () => {
  const currency = "USD";
  const userOptions = useUserOptions();
  const toast = useToast();

  const { mutateAsync: createBudgetGroup, isLoading } = useCreateBudgetGroup();

  const { control, watch, reset, handleSubmit } = useForm<FormValuesType>({
    defaultValues: defaultFormValues,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const formValues = watch();
  const disableSubmitButton =
    !formValues.name ||
    formValues.amount === Money.zero() ||
    !formValues.lead_id ||
    !formValues.start_date ||
    !formValues.end_date;

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = (data: FormValuesType) => {
    if (data.lead_id) {
      createBudgetGroup({
        name: data.name,
        budget_group_approver: data.lead_id,
        budget_group_schedule: {
          amount: data.amount,
          schedule_type: "one_time",
          memo: "",
          start_date: DateTime.fromISO(data.start_date),
          end_date: DateTime.fromISO(data.end_date),
        },
      })
        .then(() => {
          toast({
            title: "Success!",
            description: "Budget group created",
            status: "success",
          });
          reset();
          onClose();
        })
        .catch(() => {
          toast({
            title: "Error!",
            description: "Failed to create budget group",
            status: "error",
          });
        });
    }
  };

  return (
    <>
      <Button leftIcon={<AddIcon />} colorScheme="teal" onClick={() => onOpen()}>
        Create Budget Group
      </Button>
      <FormModal
        title="Create Budget Group"
        closeOnOverlayClick={false}
        submitButtonColorSchema="teal"
        submitButtonTitle="Submit"
        size="lg"
        isOpen={isOpen}
        onClose={handleClose}
        isLoading={isLoading}
        submitButtonDisabled={disableSubmitButton}
        handleSubmit={handleSubmit(onSubmit)}>
        <>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl isRequired>
                <FormLabel>Group Name</FormLabel>
                <Input {...field} />
              </FormControl>
            )}
          />

          <Controller
            name="amount"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <FormControl isRequired>
                <FormLabel>Allocation Amount</FormLabel>
                <MoneyInput
                  {...field}
                  min={Money.fromMinorUnits(Number.MIN_SAFE_INTEGER, currency)}
                />
              </FormControl>
            )}
          />

          <Stack direction={{ base: "column", md: "row" }} w="100%">
            <Controller
              name="start_date"
              control={control}
              render={({ field }) => (
                <FormControl isRequired>
                  <FormLabel>Start Date</FormLabel>
                  <Input type="date" {...field} />
                </FormControl>
              )}
            />

            <Controller
              name="end_date"
              control={control}
              render={({ field }) => (
                <FormControl isRequired>
                  <FormLabel>End Date</FormLabel>
                  <Input type="date" {...field} />
                </FormControl>
              )}
            />
          </Stack>
          <Controller
            name="lead_id"
            control={control}
            render={({ field }) => (
              <FormControl isRequired>
                <FormLabel>User</FormLabel>
                <Select options={userOptions} {...field} placeholder="Choose" />
              </FormControl>
            )}
          />
        </>
      </FormModal>
    </>
  );
};

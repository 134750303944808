import {
  Box,
  PopoverBody,
  PopoverCloseButton,
  PopoverHeader,
  PopoverTrigger,
  Popover,
  PopoverContent,
  IconButton,
  Portal,
} from "@chakra-ui/react";
import { humanize } from "inflection";
import React, { useMemo } from "react";
import {
  invalidateServiceRequest,
  useAddCategoryToServiceRequest,
} from "../../../api/service_requests";
import { EmptyState } from "../../shared/EmptyState";
import { TeamServiceCategory } from "../../../api/team_service_category";
import { SmallAddIcon } from "@chakra-ui/icons";

export type AddCategoryPopoverProps = {
  teamCategories: TeamServiceCategory[];
  serviceRequestId: number;
  serviceRequestCategories: number[];
};

export const AddCategoryPopover = ({
  teamCategories,
  serviceRequestId,
  serviceRequestCategories,
}: AddCategoryPopoverProps) => {
  const { mutateAsync: addCategoryToServiceRequest } =
    useAddCategoryToServiceRequest(serviceRequestId);
  const options = useMemo(
    () =>
      teamCategories.filter(
        (category) => !serviceRequestCategories.some((id) => id === category.id)
      ),
    [teamCategories, serviceRequestCategories]
  );
  return (
    <Popover placement="right">
      <PopoverTrigger>
        <IconButton size="xs" aria-label="Add Category" icon={<SmallAddIcon />} />
      </PopoverTrigger>
      <Portal>
        <PopoverContent zIndex={5000}>
          <PopoverHeader>Add Category</PopoverHeader>
          <PopoverBody>
            {options.length > 0 ? (
              options.map((category) => (
                <Box
                  _hover={{ bg: "gray.100" }}
                  cursor="pointer"
                  width="100%"
                  key={category.id}
                  p={2}
                  onClick={() => {
                    addCategoryToServiceRequest({
                      service_request_id: serviceRequestId,
                      team_service_category_id: category.id,
                    });
                  }}>
                  {humanize(category.name)}
                </Box>
              ))
            ) : (
              <EmptyState title="No categories left to add" size="xs" />
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

import { AddIcon } from "@chakra-ui/icons";
import {
  Flex,
  Stack,
  Heading,
  HStack,
  IconButton,
  Text,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import {
  ServiceAssignedUserData,
  useBulkCreateServiceAssignedUser,
  useDeleteServiceAssignedUser,
} from "../../../../api/service_assigned_users";
import { PreviewUserSelect } from "../../../Users/shared/UserSelect";
import { AssignUsersToServiceModal } from "./AssignEmployeesModal";

export type DefaultAssigneesProps = {
  assignedUsers: ServiceAssignedUserData[];
  serviceId: number;
};

export const DefaultAssignees = ({ assignedUsers, serviceId }: DefaultAssigneesProps) => {
  const { mutateAsync: deleteServiceAssignedUser, isLoading: isDeletingServiceAssignedUser } =
    useDeleteServiceAssignedUser();

  const { mutateAsync: bulkCreateServiceAssignedUser, isLoading: isCreatingServiceAssignedUser } =
    useBulkCreateServiceAssignedUser(serviceId);

  const {
    isOpen: isOpenAssignEmployeesModal,
    onOpen: onOpenAssignEmployeesModal,
    onClose: onCloseAssignEmployeesModal,
  } = useDisclosure();

  return (
    <Box w={"100%"} border="1px" borderColor="chakra-border-color" p={6} gap={4} borderRadius="md">
      <AssignUsersToServiceModal
        serviceId={serviceId}
        isOpen={isOpenAssignEmployeesModal}
        onClose={onCloseAssignEmployeesModal}
        serviceUsers={assignedUsers}
        onSubmit={bulkCreateServiceAssignedUser}
        isLoading={isCreatingServiceAssignedUser}
        typeOfUser="assigned"
      />
      <Flex justifyContent="space-between" alignItems="center">
        <Stack gap={2}>
          <Heading size="md">Default Assignees</Heading>
          <Text textColor="gray.500">
            Assign the employees responsible for fulfilling these service requests.
          </Text>
        </Stack>
        <HStack>
          <PreviewUserSelect
            handleDelete={deleteServiceAssignedUser}
            addedUsers={assignedUsers}
            additionalUsersTitle="Assignees"
            isDisabled={false}
            isLoading={isDeletingServiceAssignedUser}
          />
          <IconButton
            marginLeft={8}
            icon={<AddIcon />}
            aria-label="Add Assignee"
            size="sm"
            onClick={onOpenAssignEmployeesModal}
          />
        </HStack>
      </Flex>
    </Box>
  );
};

import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Facets,
  Header,
  SplitPage,
  buildFacets,
  useCollection,
} from "@sciencecorp/helix-components";
import React, { useEffect, useMemo, useState } from "react";
import { BiStore } from "react-icons/bi";
import { useCurrentUserQuery, userHasFeatureFlag, userHasRole } from "../../api/user";
import { FiShoppingCart } from "react-icons/fi";
import { FaRegFileAlt } from "react-icons/fa";
import { CgCreditCard } from "react-icons/cg";
import { HiOutlineBanknotes } from "react-icons/hi2";
import { generateVendorCsv } from "../../api/vendor";
import { IoIosAdd } from "react-icons/io";
import { useSearchPurchases } from "../../api/purchase";
import { useSearchSubscriptions } from "../../api/subscription";
import { useSearchVendors } from "../../api/vendor";
import { NewContractModal } from "../Purchasing/Contracts/components/NewContractModal";
import { NewVendorModal } from "../Purchasing/Vendor/components/NewVendorModal";
import { CsvDownload } from "../shared/csvDownloadButton";
import { PurchasesTab } from "../Purchasing/PurchasesTab";
import { VendorTab } from "../Purchasing/VendorTab";
import { SubscriptionsTab } from "../Purchasing/SubscriptionTab";
import { ContractsTab } from "../Purchasing/ContractsTab";
import { useSearchServices } from "../../api/services";
import {
  generateContractCSV as generateContractCsv,
  useSearchContracts,
} from "../../api/contracts";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { useSearchAllRelatedServiceRequestsQuery } from "../../api/service_requests";
import { NewPurchaseModal } from "../Purchasing/Purchase/components/NewPurchaseModal";
import { NewSubscriptionModal } from "../Purchasing/Subscriptions/components/NewSubscriptionModal";
import { MyReimbursementsTab } from "../Purchasing/MyReimbursementsTab";
import { NewReimbursementModal } from "../Purchasing/Reimbursements/components/NewReimbursementModal";
import { useSearchReimbursements } from "../../api/reimbursement";
import { useDebouncedSearch } from "../hooks/useDebouncedSearch";
import { AllServicesTab } from "./AllServicesTab";
import YourRequestsTab from "./YourRequestsTab";
import { BackgroundReportButton } from "../shared/BackgroundReportButton";
import { SlGraph } from "react-icons/sl";
import { PurchasingDashboard } from "../Purchasing/Dashboard";

type BackgroundReportButtonInfo = {
  filename: string;
  taskName: string;
  args: any[];
  apiCall: undefined;
  buttonName: string;
  active: boolean;
};

type ClassicButtonInfo = {
  filename: string;
  taskName: undefined;
  apiCall: () => Promise<any>;
  buttonName: string;
  active: boolean;
  args: undefined;
};

type DownloadButtonInfo = BackgroundReportButtonInfo | ClassicButtonInfo;

export const Requests = () => {
  const { tab } = useParams();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(tab);

  const userQuery = useCurrentUserQuery();
  const { data: currentUser } = useCurrentUserQuery();
  const teamNames = useMemo(
    () => currentUser?.team_memberships?.map((team) => team.team.name),
    [currentUser]
  );

  const {
    onOpen: onOpenNewPurchase,
    onClose: onCloseNewPurchase,
    isOpen: isOpenNewPurchase,
  } = useDisclosure();
  const {
    onOpen: onOpenNewSubscription,
    onClose: onCloseNewSubscription,
    isOpen: isOpenNewSubscription,
  } = useDisclosure();

  const {
    onOpen: onOpenNewReimbursment,
    onClose: onCloseNewReimbursment,
    isOpen: isOpenNewReimbursment,
  } = useDisclosure();

  // Custom hooks
  const { search, debouncedSearch } = useDebouncedSearch();

  // Computed constants
  const isSubscriptionEnabled =
    !userQuery.isSuccess || userHasFeatureFlag(userQuery, "subscriptions");
  const isPurchasingAdmin = userHasRole(userQuery, "purchasing_admin");
  const isFinanceAdmin = userHasRole(userQuery, "finance_admin");
  const isProcurementSpecialist = userHasRole(userQuery, "procurement_specialist");
  const isTopLevelTeamLead = userQuery.data?.team_memberships.some(
    (tm) => tm.is_lead && tm.team.parent_team_id === null
  );
  const {
    onPagination,
    onOrder,
    order,
    pagination,
    filters,
    facets,
    onFacets,
    resetFacets,
    resetPagination,
  } = useCollection({
    pagination: { per_page: 50 },
    order: { created_at: "desc" },
  });

  //TODO: Remove this once the "subscriptions" feature flag is removed
  useEffect(() => {
    if (tab === "subscriptions" && !isSubscriptionEnabled) {
      navigate("/services/all");
    }
  }, [tab, isSubscriptionEnabled]);

  const TABS = useMemo(
    () =>
      [
        {
          label: "All Services",
          value: "all",
          icon: <BiStore />,
          disabled: false,
        },
        {
          label: "Purchases",
          value: "purchasing",
          icon: <FiShoppingCart />,
          disabled: false,
        },
        {
          label: "Contracts",
          value: "contracts",
          icon: <FaRegFileAlt />,
          disabled: !isPurchasingAdmin && !isTopLevelTeamLead,
        },
        {
          label: "Reimbursements",
          value: "reimbursements",
          icon: <HiOutlineBanknotes />,
          disabled: false,
        },
        {
          label: "Subscriptions",
          value: "subscriptions",
          icon: <CgCreditCard />,
          //TODO: Remove the "isSubscriptionEnabled" check once the "subscriptions" feature flag is removed
          disabled: !isSubscriptionEnabled,
        },
        { label: "Vendors", value: "vendors", icon: <BiStore />, disabled: false },
      ].filter((t) => !t.disabled),
    [isPurchasingAdmin, isTopLevelTeamLead]
  );

  const downloadButtons: DownloadButtonInfo[] = [
    {
      filename: "helix-purchases.csv",
      taskName: "Reports::Purchases",
      args: [],
      apiCall: undefined,
      buttonName: "Download Purchases CSV",
      active: (isFinanceAdmin || isProcurementSpecialist) && activeTab === "purchasing",
    },
    {
      filename: "helix-vendors.csv",
      taskName: undefined,
      args: undefined,
      apiCall: generateVendorCsv,
      buttonName: "Download Vendors CSV",
      active: (isFinanceAdmin || isPurchasingAdmin) && activeTab === "vendors",
    },
    {
      filename: "helix-contracts.csv",
      taskName: "Reports::Contracts",
      args: [],
      apiCall: undefined,
      buttonName: "Download Contracts CSV",
      active: (isFinanceAdmin || isPurchasingAdmin) && activeTab === "contracts",
    },
  ];

  const purchaseBodyOptions = {
    Amount: {
      range: {
        field: "purchases_amount_cents",
        ranges: [
          { from: 0, to: 5000, key: "$0 to $50" },
          { from: 5001, to: 10000, key: "$50 to $100" },
          { from: 10001, to: 20000, key: "$100 to $200" },
          { from: 20001, to: 50000, key: "$200 to $500" },
          { from: 50001, to: 100000, key: "$500 to $1000" },
          { from: 100001, key: "More than $1000" },
        ],
      },
    },
  };

  const reimbursementBodyOptions = {
    Amount: {
      range: {
        field: "amount_cents",
        ranges: [
          { from: 0, to: 5000, key: "$0 to $50" },
          { from: 5001, to: 10000, key: "$50 to $100" },
          { from: 10001, to: 20000, key: "$100 to $200" },
          { from: 20001, to: 50000, key: "$200 to $500" },
          { from: 50001, to: 100000, key: "$500 to $1000" },
          { from: 100001, key: "More than $1000" },
        ],
      },
    },
  };

  const AGGREGATION_QUERY = ["team_name"];
  const INDEX_AGGREGATION_QUERY = ["service_name", "status", "fulfilling_team_name"];

  const bodyOptions = currentUser &&
    teamNames && {
      Association: {
        filters: {
          filters: {
            "Requested by You": { term: { requesting_user_id: currentUser.id } },
            "Assigned to You": { term: { assigned_user_ids: currentUser.id } },
            "Subscribed by You": { term: { subscriber_user_ids: currentUser.id } },
            "Requested from Your Team": {
              terms: {
                service_team_id: currentUser.team_memberships
                  .map((team) => [team.team.id, ...team.team.all_sub_team_ids])
                  .flat(),
              },
            },
          },
        },
      },
      requesting_team_name: {
        filter: { terms: { requesting_team_name: teamNames } },
        aggs: {
          team_names: { terms: { field: "requesting_team_name" } },
        },
      },
    };

  const PURCHASES_AGGREGATION_QUERY = [
    "status",
    "team_name",
    "shipping_destination",
    "purchase_type",
    "ordered",
    "paid",
    "delivered",
    "shipping_priority",
    "has_spending_authority",
    "source_of_authority_name",
    "project_title",
  ];

  const { data, isLoading } =
    activeTab === "all"
      ? useSearchServices({
          term: search || "*",
          aggs: AGGREGATION_QUERY,
          filters: { ...filters, status: ["active"] },
          pagination: { per_page: -1 },
          order: { name: "asc" },
        })
      : activeTab === "your_requests"
      ? useSearchAllRelatedServiceRequestsQuery({
          term: search || "*",
          bodyOptions: bodyOptions,
          aggs: INDEX_AGGREGATION_QUERY,
          filters: filters,
          pagination,
          order,
        })
      : activeTab === "purchasing"
      ? useSearchPurchases({
          term: search || "*",
          aggs: PURCHASES_AGGREGATION_QUERY,
          bodyOptions: purchaseBodyOptions,
          filters,
          pagination,
          order,
        })
      : activeTab === "your_purchases"
      ? useSearchPurchases({
          term: search || "*",
          aggs: PURCHASES_AGGREGATION_QUERY,
          bodyOptions: purchaseBodyOptions,
          filters: {
            ...filters,
            user_id: currentUser?.id,
          },
          pagination,
          order,
        })
      : activeTab === "contracts"
      ? useSearchContracts({
          term: search || "*",
          aggs: ["source_of_authority", "vendor_name", "billing_period"],
          filters: { ...filters, archived_at: null },
          pagination,
          order,
        })
      : //TODO: Remove the "isSubscriptionEnabled" check once the "subscriptions" feature flag is removed
      activeTab === "subscriptions" && isSubscriptionEnabled
      ? useSearchSubscriptions({
          term: search || "*",
          aggs: ["status", "team_name"],
          filters,
          pagination,
          order,
        })
      : activeTab === "reimbursements"
      ? useSearchReimbursements({
          term: search || "*",
          aggs: ["amount", "reimbursement_type", "approval_status", "month_paid_at"],
          bodyOptions: reimbursementBodyOptions,
          filters,
          pagination,
          order,
        })
      : useSearchVendors({
          term: search || "*",
          aggs: ["status", "risk_category", "has_expenditures"],
          filters: { ...filters, archived_at: null },
          pagination,
          order,
        });

  useEffect(() => {
    if (tab && tab !== activeTab) {
      setActiveTab(tab);
    }
  }, [tab]);

  useEffect(() => {
    if (search.length > 0) resetPagination();
  }, [search]);

  const populatedFacets = buildFacets(data?.aggregations || {}, facets);

  return (
    <Box>
      <Header
        title="Requests"
        actions={[
          isPurchasingAdmin ? (
            <Box zIndex={5}>
              <Menu>
                <MenuButton
                  as={Button}
                  leftIcon={<IoIosAdd />}
                  rightIcon={<ChevronDownIcon />}
                  colorScheme="teal"
                  data-testid="new-expense-menu-button">
                  New
                </MenuButton>
                <MenuList>
                  <MenuItem
                    data-testid="new-purchase-menu-item"
                    onClick={onOpenNewPurchase}
                    icon={<FiShoppingCart />}
                    p={3}
                    pl={6}
                    fontWeight="semibold">
                    Purchase
                  </MenuItem>
                  <NewContractModal />
                  {/* TODO: Remove the "isSubscriptionEnabled" check once the "subscriptions" feature flag is removed */}
                  {isSubscriptionEnabled ? (
                    <MenuItem
                      data-testid="new-subscription-menu-item"
                      icon={<CgCreditCard />}
                      onClick={onOpenNewSubscription}
                      p={3}
                      pl={6}
                      fontWeight="semibold">
                      Subscription
                    </MenuItem>
                  ) : (
                    <></>
                  )}
                  <MenuItem
                    data-testid="new-reimbursement-menu-item"
                    onClick={onOpenNewReimbursment}
                    icon={<HiOutlineBanknotes />}
                    p={3}
                    pl={6}
                    fontWeight="semibold">
                    Reimbursement
                  </MenuItem>
                  <NewVendorModal
                    data-testid="new-vendor-menu-item"
                    buttonLabel="Vendor"
                    location="menu"
                  />
                </MenuList>
              </Menu>
            </Box>
          ) : (
            <Box zIndex={5}>
              <Menu>
                <MenuButton
                  as={Button}
                  leftIcon={<IoIosAdd />}
                  rightIcon={<ChevronDownIcon />}
                  colorScheme="teal">
                  New
                </MenuButton>
                <MenuList>
                  <MenuItem
                    data-testid="new-purchase-menu-item"
                    onClick={onOpenNewPurchase}
                    icon={<FiShoppingCart />}
                    p={3}
                    pl={6}
                    fontWeight="semibold">
                    New Purchase
                  </MenuItem>
                  <MenuItem
                    data-testid="new-reimbursement-menu-item"
                    onClick={onOpenNewReimbursment}
                    icon={<HiOutlineBanknotes />}
                    p={3}
                    pl={6}
                    fontWeight="semibold">
                    New Reimbursement
                  </MenuItem>
                  <NewVendorModal buttonLabel="Vendor" location="menu" />
                </MenuList>
              </Menu>
            </Box>
          ),
          ...downloadButtons
            .filter((button) => button.active)
            .map((button) => {
              if (button.apiCall) {
                return (
                  <CsvDownload
                    title={button.filename}
                    apiCall={button.apiCall}
                    buttonName={button.buttonName}
                  />
                );
              } else {
                return (
                  <BackgroundReportButton
                    taskName={button.taskName}
                    title={button.filename}
                    buttonName={button.buttonName}
                    args={[]}
                  />
                );
              }
            }),
        ]}
      />
      <Box overflow="auto">
        <SplitPage
          sidebarWidth="max-content"
          sidebarWidthXL="max-content"
          sidebar={
            <VStack spacing={3}>
              {TABS.map((tabInfo) => (
                <>
                  <Button
                    key={tabInfo.value}
                    leftIcon={tabInfo.icon}
                    width="100%"
                    justifyContent="start"
                    colorScheme={activeTab === tabInfo.value ? "teal" : "gray"}
                    bg={activeTab === tabInfo.value ? "teal" : "transparent"}
                    onClick={() => {
                      navigate(`/services/${tabInfo.value}`);
                      setActiveTab(tabInfo.value);
                    }}>
                    {tabInfo.label}
                  </Button>
                  {tabInfo.value === "all" &&
                    (activeTab === "all" || activeTab === "your_requests") && (
                      <Button
                        key="your_requests"
                        value="your_requests"
                        leftIcon={<BsFillPersonLinesFill />}
                        bg={activeTab === "your_requests" ? "teal" : "transparent"}
                        textColor={activeTab === "your_requests" ? "white" : "default"}
                        width="calc(100% - 20px)"
                        justifyContent="start"
                        marginLeft="20px"
                        onClick={() => {
                          navigate(`/services/your_requests`);
                          setActiveTab("your_requests");
                        }}>
                        Your Requests
                      </Button>
                    )}
                  {tabInfo.value === "purchasing" &&
                    (activeTab === "purchasing" ||
                      activeTab === "your_purchases" ||
                      activeTab === "purchasing_dashboard") && (
                      <Button
                        key="your_purchases"
                        value="your_purchases"
                        leftIcon={<BsFillPersonLinesFill />}
                        bg={activeTab === "your_purchases" ? "teal" : "transparent"}
                        textColor={activeTab === "your_purchases" ? "white" : "default"}
                        width="calc(100% - 20px)"
                        justifyContent="start"
                        marginLeft="20px"
                        onClick={() => {
                          navigate(`/services/your_purchases`);
                          setActiveTab("your_purchases");
                        }}>
                        Your Purchases
                      </Button>
                    )}
                  {tabInfo.value === "purchasing" &&
                    isPurchasingAdmin &&
                    (activeTab === "purchasing" ||
                      activeTab === "your_purchases" ||
                      activeTab === "purchasing_dashboard") && (
                      <Button
                        key="purchasing_dashboard"
                        value="purchasing_dashboard"
                        leftIcon={<SlGraph />}
                        bg={activeTab === "purchasing_dashboard" ? "teal" : "transparent"}
                        textColor={activeTab === "purchasing_dashboard" ? "white" : "default"}
                        width="calc(100% - 20px)"
                        justifyContent="start"
                        marginLeft="20px"
                        onClick={() => {
                          navigate(`/services/purchasing_dashboard`);
                          setActiveTab("purchasing_dashboard");
                        }}>
                        Dashboard
                      </Button>
                    )}
                </>
              ))}
              {activeTab !== "purchasing_dashboard" && (
                <Box>
                  <Box display={["flex", "flex", "none"]} alignSelf="end">
                    <Facets
                      variant="button"
                      defaultIndex={activeTab === "purchasing" ? [9] : []}
                      facets={populatedFacets}
                      onChange={onFacets}
                      search
                      background
                      debouncedSearch={debouncedSearch}
                    />
                  </Box>
                  <Box display={["none", "none", "inline"]} width="max-content" alignSelf="start">
                    <Facets
                      defaultIndex={activeTab === "purchasing" ? [9] : []}
                      facets={populatedFacets}
                      onChange={onFacets}
                      search
                      background
                      debouncedSearch={debouncedSearch}
                    />
                  </Box>
                </Box>
              )}
            </VStack>
          }
          main={
            <Box w="100%">
              {activeTab === "all" ? (
                <AllServicesTab data={data} isLoading={isLoading} />
              ) : activeTab === "your_requests" ? (
                <YourRequestsTab
                  data={data}
                  isLoading={isLoading}
                  pagination={pagination}
                  onPagination={onPagination}
                  order={order}
                  onOrder={onOrder}
                />
              ) : activeTab === "purchasing" ? (
                <PurchasesTab
                  data={data}
                  isLoading={isLoading}
                  pagination={pagination}
                  onPagination={onPagination}
                  order={order}
                  onOrder={onOrder}
                />
              ) : activeTab === "your_purchases" ? (
                <PurchasesTab
                  data={data || []}
                  isLoading={isLoading}
                  pagination={pagination}
                  onPagination={onPagination}
                  order={order}
                  onOrder={onOrder}
                />
              ) : activeTab === "purchasing_dashboard" ? (
                <PurchasingDashboard />
              ) : activeTab === "vendors" ? (
                <VendorTab
                  data={data}
                  isPurchasingAdmin={isPurchasingAdmin}
                  isLoading={isLoading}
                  pagination={pagination}
                  onPagination={onPagination}
                  order={order}
                  onOrder={onOrder}
                />
              ) : //TODO: Remove the "isSubscriptionEnabled" check once the "subscriptions" feature flag is removed
              activeTab === "subscriptions" && isSubscriptionEnabled ? (
                <SubscriptionsTab
                  data={data}
                  isLoading={isLoading}
                  pagination={pagination}
                  onPagination={onPagination}
                  order={order}
                  onOrder={onOrder}
                />
              ) : activeTab === "reimbursements" ? (
                <MyReimbursementsTab
                  data={data}
                  isLoading={isLoading}
                  pagination={pagination}
                  onPagination={onPagination}
                  order={order}
                  onOrder={onOrder}
                />
              ) : (
                activeTab === "contracts" && (
                  <ContractsTab
                    data={data}
                    isLoading={isLoading}
                    pagination={pagination}
                    onPagination={onPagination}
                    order={order}
                    onOrder={onOrder}
                    location={"contracts"}
                  />
                )
              )}
              <NewPurchaseModal isOpen={isOpenNewPurchase} onClose={onCloseNewPurchase} />
              {/* TODO: Remove the "isSubscriptionEnabled" check once the "subscriptions" feature flag is removed */}
              {isSubscriptionEnabled && isPurchasingAdmin && (
                <NewSubscriptionModal
                  isOpen={isOpenNewSubscription}
                  onClose={onCloseNewSubscription}
                />
              )}
              <NewReimbursementModal
                isOpen={isOpenNewReimbursment}
                onClose={onCloseNewReimbursment}
              />
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

import { z } from "zod";
import { api } from ".";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const SERVICE_REQUEST_INVENTORY_BOM_BASE_URL = "/service_request_inventory_boms";

export const serviceRequestInventoryBomSchema = z.object({
  id: z.number(),
  service_request_id: z.number(),
  inventory_bom_id: z.number(),
  inventory_bom_name: z.string(),
});

export type ServiceRequestInventoryBomData = z.infer<typeof serviceRequestInventoryBomSchema>;
export type CreateServiceRequestInventoryBomData = Omit<
  ServiceRequestInventoryBomData,
  "id" | "inventory_bom_name" | "status"
>;

export const createServiceRequestInventoryBom = async (
  service_request_inventory_bom: CreateServiceRequestInventoryBomData
) => {
  const result = await api.post(`${SERVICE_REQUEST_INVENTORY_BOM_BASE_URL}`, {
    service_request_inventory_bom,
  });
  return result.status === 201;
};

export const deleteServiceRequestInventoryBom = async (id: number) => {
  await api.delete(`${SERVICE_REQUEST_INVENTORY_BOM_BASE_URL}/${id}`);
};

/** hooks */

export const invalidateServiceRequestInventoryBom = (id?: number, subquery?: string) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [SERVICE_REQUEST_INVENTORY_BOM_BASE_URL];
  if (id) queryKey.push(id);
  if (subquery) queryKey.push(subquery);

  return async () => {
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
  };
};

export const useCreateServiceRequestInventoryBom = () => {
  return useMutation({
    mutationFn: createServiceRequestInventoryBom,
    onSuccess: invalidateServiceRequestInventoryBom(),
  });
};

export const useDeleteServiceRequestInventoryBom = (id?: number) => {
  return useMutation({
    mutationFn: deleteServiceRequestInventoryBom,
    onSuccess: invalidateServiceRequestInventoryBom(id),
  });
};

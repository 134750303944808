import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { api } from ".";
import { zodParse } from "./zodParse";
import { invalidateServiceRequest } from "./service_requests";
import { serviceRequestSubtaskUserSchema } from "./service_request_subtask_users";
export const SERVICE_REQUEST_SUBTASKS_BASE_URL = "service_request_subtasks";

export const serviceRequestSubtaskSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string().nullable(),
  order: z.number(),
  service_request_id: z.number(),
  completed: z.boolean(),
  completed_at: z.string().nullable(),
  completed_by_id: z.number().nullable(),
  from_default_subtasks: z.boolean().nullable().optional(),
  service_subtask_id: z.number().nullable().optional(),
});

export const createServiceRequestSubtaskSchema = serviceRequestSubtaskSchema.omit({
  id: true,
  completed: true,
  completed_at: true,
  completed_by_id: true,
  from_default_subtasks: true,
  service_subtask_id: true,
});
export const serviceRequestSubtaskSchemaWithUsers = serviceRequestSubtaskSchema.extend({
  service_request_subtask_users: z.array(z.lazy(() => serviceRequestSubtaskUserSchema)).optional(),
});

export const serviceRequestSubtaskReorderSchema = z.object({
  id: z.number(),
  order: z.array(z.number()),
});

export type ServiceRequestSubtaskData = z.infer<typeof serviceRequestSubtaskSchema>;
export type ServiceRequestSubtaskWithUsers = z.infer<typeof serviceRequestSubtaskSchemaWithUsers>;
export type ServiceRequestSubtaskReorderData = z.infer<typeof serviceRequestSubtaskReorderSchema>;
export type ServiceRequestSubtaskCreateData = z.infer<typeof createServiceRequestSubtaskSchema>;
//api queries

export const reorderServiceRequestSubtask = async (
  service_subtask: ServiceRequestSubtaskReorderData
) => {
  await api.put(`${SERVICE_REQUEST_SUBTASKS_BASE_URL}/${service_subtask.id}/reorder`, {
    order: service_subtask.order,
  });
};

export const markAsCompleteServiceRequestSubtask = async (id: number) => {
  await api.put(`${SERVICE_REQUEST_SUBTASKS_BASE_URL}/${id}/complete`);
};

export const markAsIncompleteServiceRequestSubtask = async (id: number) => {
  await api.put(`${SERVICE_REQUEST_SUBTASKS_BASE_URL}/${id}/incomplete`);
};

export const createServiceRequestSubtask = async (
  service_request_subtask: ServiceRequestSubtaskCreateData
) => {
  const result = await api.post(`${SERVICE_REQUEST_SUBTASKS_BASE_URL}`, {
    service_request_subtask,
  });
  return zodParse(serviceRequestSubtaskSchema, result.data);
};

export const updateServiceRequestSubtask = async (
  service_request_subtask: Partial<ServiceRequestSubtaskData>
) => {
  const result = await api.put(
    `${SERVICE_REQUEST_SUBTASKS_BASE_URL}/${service_request_subtask.id}`,
    {
      service_request_subtask,
    }
  );
  return zodParse(serviceRequestSubtaskSchema, result.data);
};

export const deleteServiceRequestSubtask = async (id: number) => {
  await api.delete(`${SERVICE_REQUEST_SUBTASKS_BASE_URL}/${id}`);
};

export const invalidateServiceRequestSubtask = (id?: number, subquery?: string) => {
  const queryClient = useQueryClient();
  const queryKey: (string | number)[] = [SERVICE_REQUEST_SUBTASKS_BASE_URL];
  if (id) queryKey.push(id);
  if (subquery) queryKey.push(subquery);

  return async () => {
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
  };
};

export const useCreateServiceRequestSubtask = (id?: number) => {
  return useMutation({
    mutationFn: createServiceRequestSubtask,
    onSuccess: invalidateServiceRequest(id),
  });
};

export const useUpdateServiceRequestSubtask = (id?: number) => {
  return useMutation({
    mutationFn: updateServiceRequestSubtask,
    onSuccess: invalidateServiceRequest(id),
  });
};

export const useMarkAsCompleteServiceRequestSubtask = (id?: number) => {
  return useMutation({
    mutationFn: markAsCompleteServiceRequestSubtask,
    onSuccess: invalidateServiceRequest(id),
  });
};

export const useMarkAsIncompleteServiceRequestSubtask = (id?: number) => {
  return useMutation({
    mutationFn: markAsIncompleteServiceRequestSubtask,
    onSuccess: invalidateServiceRequest(id),
  });
};

export const useReorderServiceRequestSubtask = (id?: number) => {
  return useMutation({
    mutationFn: reorderServiceRequestSubtask,
    onSuccess: invalidateServiceRequest(id),
  });
};

export const useDeleteServiceRequestSubtask = (id?: number) => {
  return useMutation({
    mutationFn: deleteServiceRequestSubtask,
    onSuccess: invalidateServiceRequest(id),
  });
};

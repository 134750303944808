import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  Textarea,
  useDisclosure,
  useToast,
  Button,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { FormModal } from "@sciencecorp/helix-components";
import React, { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { MoneyInput } from "../../../../MoneyInput";
import { Money } from "../../../../../helpers/Money";
import { useCreateBudgetGroupSchedule } from "../../../../../api/budget_group_schedules";
import { AddIcon } from "@chakra-ui/icons";
import { DateTime } from "luxon";
import { BudgetGroupShowData } from "../../../../../api/budget_groups";

type FormValuesType = {
  amount: Money;
  schedule_type: "one_time" | "monthly" | null;
  memo: string;
  start_date: string;
  end_date: string;
};

const defaultFormValues: FormValuesType = {
  amount: Money.zero(),
  schedule_type: null,
  memo: "",
  start_date: "",
  end_date: "",
};

export const CreateNewFundingPeriod = ({ budgetGroup }: { budgetGroup: BudgetGroupShowData }) => {
  const currency = "USD";
  const toast = useToast();

  const { mutateAsync: createBudgetGroupSchedule, isLoading } = useCreateBudgetGroupSchedule();

  const { control, watch, reset, handleSubmit } = useForm<FormValuesType>({
    defaultValues: defaultFormValues,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const formValues = watch();

  const invalidStartDate = useMemo(() => {
    return budgetGroup.time_range_options.some(
      (range) =>
        DateTime.fromISO(formValues.start_date).toMillis() >= range.start_date.toMillis() &&
        DateTime.fromISO(formValues.start_date).toMillis() <= range.end_date.toMillis()
    );
  }, [budgetGroup, formValues.start_date]);

  const disableSubmitButton =
    formValues.amount === Money.zero() ||
    !formValues.start_date ||
    !formValues.end_date ||
    invalidStartDate;

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = (data: FormValuesType) => {
    createBudgetGroupSchedule({
      budget_group_id: budgetGroup.id,
      amount: data.amount,
      schedule_type: "one_time",
      memo: data.memo,
      start_date: DateTime.fromISO(data.start_date),
      end_date: DateTime.fromISO(data.end_date),
    })
      .then(() => {
        reset();
        onClose();
        toast({
          title: "Success!",
          description: "You added a new funding period",
          status: "success",
        });
      })
      .catch(() => {
        toast({
          title: "Error!",
          description: "Failed to create a new funding period.",
          status: "error",
        });
      });
  };

  return (
    <>
      <Button
        leftIcon={<AddIcon />}
        onClick={onOpen}
        colorScheme="teal"
        aria-label="new-allocation">
        New Funding Period
      </Button>

      <FormModal
        title="New Funding Period"
        closeOnOverlayClick={false}
        submitButtonColorSchema="teal"
        submitButtonTitle="Submit"
        size="lg"
        isOpen={isOpen}
        onClose={handleClose}
        isLoading={isLoading}
        submitButtonDisabled={disableSubmitButton}
        handleSubmit={handleSubmit(onSubmit)}>
        {invalidStartDate && !isLoading && (
          <Alert status="error">
            <AlertIcon />
            The start date is within another funding period's date range. Please choose a different
            date.
          </Alert>
        )}
        <Controller
          name="amount"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => {
            return (
              <FormControl isRequired>
                <FormLabel>Allocation Amount</FormLabel>
                <MoneyInput
                  {...field}
                  min={Money.fromMinorUnits(Number.MIN_SAFE_INTEGER, currency)}
                />
              </FormControl>
            );
          }}
        />

        <Stack direction={{ base: "column", md: "row" }} w="100%">
          <Controller
            name="start_date"
            control={control}
            render={({ field }) => {
              const minDate =
                budgetGroup.budget_group_schedule?.end_date?.plus({ days: 1 }) ||
                DateTime.now().startOf("year");
              return (
                <FormControl isRequired>
                  <FormLabel>Start Date</FormLabel>
                  <Input type="date" min={minDate.toFormat("yyyy-MM-dd")} {...field} />
                </FormControl>
              );
            }}
          />
          <Controller
            name="end_date"
            control={control}
            render={({ field }) => (
              <FormControl isRequired>
                <FormLabel>End Date</FormLabel>
                <Input type="date" min={formValues.start_date} {...field} />
              </FormControl>
            )}
          />
        </Stack>

        <Controller
          name="memo"
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Memo</FormLabel>
              <Textarea {...field} />
            </FormControl>
          )}
        />
      </FormModal>
    </>
  );
};

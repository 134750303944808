import { Alert, AlertIcon, Text, VStack } from "@chakra-ui/react";
import React from "react";
import {
  MinimalSpendingAuthority,
  useGetRelevanceForCurrentUser,
} from "../../api/spending_authority";

interface WarnIfSpendingAuthorityIrrelevantProps {
  id: number | undefined;
  type: MinimalSpendingAuthority["type"] | undefined;
}

export const WarnIfSpendingAuthorityIrrelevant = ({
  id,
  type,
}: WarnIfSpendingAuthorityIrrelevantProps) => {
  const relevanceQuery = useGetRelevanceForCurrentUser(id, type);

  if (relevanceQuery.isSuccess && !relevanceQuery.data.relevant) {
    return (
      <Alert status="warning" marginTop={2}>
        <AlertIcon />
        <Text>
          This Spending Authority does not seem to be relevant to you. Please verify your selection.
        </Text>
      </Alert>
    );
  }
  return <></>;
};

import {
  Alert,
  AlertIcon,
  Box,
  FormControl,
  FormLabel,
  HStack,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spinner,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import { FormModal, Select } from "@sciencecorp/helix-components";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useVendorOptions } from "../../../../api/options";
import { MinimalSpendingAuthority } from "../../../../api/spending_authority";
import {
  SubscriptionShowData,
  useCreateSubscription,
  useUpdateSubscription,
} from "../../../../api/subscription";
import { useNewVendor } from "../../../../api/vendor";
import { useCurrency } from "../../../../contexts/CurrencyContext";
import { Money } from "../../../../helpers/Money";
import { MoneyInput } from "../../../MoneyInput";
import { SpendingAuthoritySelect } from "../../SpendingAuthoritySelectTree";
import { WarnIfSpendingAuthorityIrrelevant } from "../../../shared/WarnIfSpendingAuthorityIrrelevant";

const useDefaultFormValues = (): FormValuesType => {
  const currency = useCurrency();
  return {
    name: "",
    vendor_id: null,
    interval: "monthly",
    description: "",
    payments_per_interval: "1",
    max_amount_per_payment: Money.zero(currency),
    max_amount_per_interval: Money.zero(currency),
    spending_authority: null,
  };
};

type FormValuesType = {
  name: string;
  vendor_id: string | null;
  interval: "monthly" | "annually";
  description: string;
  payments_per_interval: string;
  max_amount_per_payment: Money;
  max_amount_per_interval: Money;
  spending_authority: MinimalSpendingAuthority | null;
};

type NewSubscriptionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  subscription?: SubscriptionShowData;
};

export const NewSubscriptionModal = ({
  subscription,
  isOpen,
  onClose,
}: NewSubscriptionModalProps) => {
  const { mutate: createSubscription, isLoading: isLoadingCreateSubscription } =
    useCreateSubscription();
  const { mutate: createVendor, isLoading: isLoadingCreateVendor } = useNewVendor();
  const { mutate: updateSubscription, isLoading: isLoadingUpdateSubscription } =
    useUpdateSubscription(subscription?.id);
  const vendorOptions = useVendorOptions();
  const navigate = useNavigate();
  const [editedCost, setEditedCost] = useState(false);
  const defaultFormValues = useDefaultFormValues();
  const {
    handleSubmit,
    setValue,
    control,
    reset,
    watch,
    formState: { isValid, errors },
  } = useForm<FormValuesType>({ defaultValues: defaultFormValues, mode: "onChange" });

  useEffect(() => {
    const subscriptionConversion: Partial<FormValuesType> = {
      ...subscription,
      vendor_id: subscription?.vendor.id.toString(),
      payments_per_interval: subscription?.payments_per_interval.toString() || "",
      spending_authority: subscription?.spending_authority || null,
    };
    reset(subscriptionConversion || defaultFormValues);
  }, [subscription, isOpen]);

  const formValues = watch();

  useEffect(() => {
    if (subscription) {
      if (
        formValues.payments_per_interval !== subscription.payments_per_interval.toString() ||
        formValues.max_amount_per_payment !== subscription.max_amount_per_payment ||
        formValues.interval !== subscription.interval ||
        formValues.max_amount_per_interval !== subscription.max_amount_per_interval
      ) {
        setEditedCost(true);
      } else {
        setEditedCost(false);
      }
    }
  }, [
    formValues.payments_per_interval,
    formValues.max_amount_per_payment,
    formValues.interval,
    formValues.max_amount_per_interval,
    formValues.spending_authority,
  ]);

  const handleClose = () => {
    setEditedCost(false);
    onClose();
    reset();
  };

  const onSubmit = ({
    vendor_id,
    payments_per_interval,
    spending_authority,
    ...rest
  }: FormValuesType) => {
    if (vendor_id) {
      const submissionData = {
        ...rest,
        payments_per_interval: Number(payments_per_interval),
        spending_authority_type: spending_authority?.type,
        spending_authority_id: spending_authority?.id,
        vendor_id: Number(vendor_id),
      };

      if (subscription) {
        updateSubscription(submissionData, {
          onSuccess: () => {
            handleClose();
            reset();
          },
        });
      } else {
        createSubscription(submissionData, {
          onSuccess: (response) => {
            handleClose();
            reset();
            navigate(`/services/subscriptions/${response.id}`);
          },
        });
      }
    }
  };

  return (
    <FormModal
      title="New Subscription"
      isOpen={isOpen}
      onClose={handleClose}
      closeOnOverlayClick={false}
      handleSubmit={() => {
        handleSubmit(onSubmit)();
      }}
      submitButtonColorSchema="teal"
      submitButtonTitle={subscription ? "Update Subscription" : "Create Subscription"}
      size="2xl"
      isLoading={isLoadingUpdateSubscription || isLoadingCreateSubscription}>
      <Controller
        name="name"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FormControl isRequired>
            <FormLabel>Name</FormLabel>
            <Input {...field} data-testid="subscription-name" />
          </FormControl>
        )}
      />

      <Stack width="100%" direction={{ base: "column", md: "row" }}>
        <Stack width="100%">
          <Controller
            name="vendor_id"
            rules={{ required: true }}
            control={control}
            render={({ field: { ...field } }) => (
              <FormControl flex="1" isRequired>
                <FormLabel>Vendor</FormLabel>
                <HStack width="100%" data-testid="subscription-vendor">
                  <Select
                    width="100%"
                    isDisabled={isLoadingCreateVendor}
                    {...field}
                    creatable
                    onCreate={(value) => {
                      createVendor(
                        {
                          name: value.toString(),
                          website: "",
                          status: "approved",
                          description: "",
                          address: "",
                          payment_terms: null,
                          tax_id: null,
                          risk_category: "",
                        },
                        {
                          onSuccess: (data) => {
                            setValue("vendor_id", data.id.toString());
                          },
                        }
                      );
                    }}
                    options={vendorOptions || []}
                    placeholder="Choose or Create"
                  />
                  {isLoadingCreateVendor && <Spinner />}
                </HStack>
              </FormControl>
            )}
          />
        </Stack>
        <Stack width={"100%"}>
          <Controller
            name="spending_authority"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <FormControl isRequired>
                  <FormLabel>Spending Authority</FormLabel>
                  <SpendingAuthoritySelect
                    onChange={(spendingAuthority) => {
                      setValue("spending_authority", spendingAuthority);
                    }}
                    spendingAuthority={field.value}
                  />
                  <WarnIfSpendingAuthorityIrrelevant
                    id={field.value?.id}
                    type={field.value?.type}
                  />
                </FormControl>
              );
            }}
          />
        </Stack>
      </Stack>

      <Stack direction={{ base: "column", md: "row" }} w="100%">
        <Controller
          name="interval"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <FormControl flex="1" isRequired>
              <FormLabel>Subscription Interval</FormLabel>
              <Box data-testid="subscription-interval">
                <Select
                  {...field}
                  options={[
                    { label: "Monthly", value: "monthly" },
                    { label: "Annually", value: "annually" },
                  ]}
                />
              </Box>
            </FormControl>
          )}
        />
        <Controller
          name="payments_per_interval"
          rules={{ validate: (value) => Number(value) > 0 }}
          control={control}
          render={({ field: { ...field } }) => (
            <FormControl flex="1" isRequired>
              <FormLabel>Maximum Payments per Interval</FormLabel>
              <NumberInput
                {...field}
                data-testid="subscription-max-payments-per-interval"
                allowMouseWheel={false}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
          )}
        />
      </Stack>
      <Stack direction={{ base: "column", md: "row" }} w="100%">
        <Controller
          name="max_amount_per_payment"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <FormControl flex="1" isRequired>
              <FormLabel>Maximum Amount per Payment</FormLabel>
              <MoneyInput
                {...field}
                data-testid="subscription-max-amount-per-payment"
                onBlur={() => {
                  if (Number(formValues.payments_per_interval) === 1) {
                    setValue("max_amount_per_interval", field.value);
                  }
                }}
                value={field.value}
              />
            </FormControl>
          )}
        />
        <Controller
          name="max_amount_per_interval"
          control={control}
          render={({ field: { ...field } }) => (
            <FormControl flex="1" isRequired={Number(formValues.payments_per_interval) !== 1}>
              <FormLabel>Maximum Amount per Interval</FormLabel>
              <MoneyInput
                {...field}
                data-testid="subscription-max-amount-per-interval"
                isDisabled={Number(formValues.payments_per_interval) === 1}
                value={field.value}
              />
            </FormControl>
          )}
        />
      </Stack>
      <Controller
        name="description"
        control={control}
        render={({ field: { ...field } }) => (
          <FormControl flex="1">
            <FormLabel>Additional Notes</FormLabel>
            <Textarea
              {...field}
              size="sm"
              borderRadius="md"
              data-testid="subscription-additional-notes"
            />
          </FormControl>
        )}
      />

      {subscription && !subscription.fully_approved && editedCost && (
        <Alert status="warning">
          <AlertIcon />
          These changes will require executive approval.
        </Alert>
      )}
    </FormModal>
  );
};

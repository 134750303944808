import React, { useEffect, useMemo, useState } from "react";
import {
  SplitPage,
  Facets,
  Header,
  useCollection,
  buildFacets,
} from "@sciencecorp/helix-components";
import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { IoMedalOutline } from "react-icons/io5";
import { AddIcon, ChevronDownIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import { TbPresentationAnalytics } from "react-icons/tb";
import { useCurrentUserQuery, userHasRole } from "../../api/user";
import { useSearchUserCredentials } from "../../api/user_credentials";
import { useSearchCredentials } from "../../api/credentials";
import { useSearchTrainings } from "../../api/trainings";
import { Trainings } from "./Trainings";
import { AllCredentials } from "./components/AllCredentials";
import { NewTrainingModal } from "./Trainings/components/NewTrainingModal";
import { NewCredentialModal } from "./components/NewCredentialModal";
import { MyCredentialsPage } from "./components/MyCredentialsPage";
import { useDebouncedSearch } from "../hooks/useDebouncedSearch";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import { Hub } from "./Hub";
import { FaRegFileAlt } from "react-icons/fa";
import { NewCredentialPackageModal } from "./Hub/components/NewCredentialPackageModal";

export const Credentials = () => {
  const { tab } = useParams();
  if (!tab) return null;
  const [activeTab, setActiveTab] = useState(tab);

  const {
    facets,
    onFacets,
    filters,
    pagination,
    onPagination,
    resetPagination,
    resetFacets,
    order,
    onOrder,
  } = useCollection({ pagination: { per_page: 50 } });

  useEffect(() => {
    setActiveTab(tab || "me");
    resetFacets();
    resetPagination();
  }, [tab]);

  const { search, debouncedSearch } = useDebouncedSearch();

  const currentUserQuery = useCurrentUserQuery();
  const isCredentialsAdmin = userHasRole(currentUserQuery, "credentials_admin") || false;

  const { data, isLoading } =
    activeTab === "me"
      ? useSearchUserCredentials({
          term: search || "*",
          filters: {
            user_id: currentUserQuery.data?.id,
            active_credential: true,
          },
          order: { expiration_date: "asc" },
        })
      : activeTab === "all"
      ? useSearchCredentials({
          term: search || "*",
          aggs: ["is_public", "is_archived", "is_draft"],
          pagination,
          filters: {
            ...filters,
            is_archived: false,
          },
          order,
        })
      : useSearchTrainings({
          term: search || "*",
          pagination,
          aggs: ["is_draft"],
          filters: {
            ...filters,
            is_archived: false,
            is_draft: isCredentialsAdmin ? undefined : false,
          },
          order,
        });

  const populatedFacets = buildFacets(data?.aggregations || {}, facets);

  const {
    isOpen: isOpenNewTraining,
    onOpen: onOpenNewTraining,
    onClose: onCloseNewTraining,
  } = useDisclosure();

  const {
    isOpen: isOpenNewCredential,
    onOpen: onOpenNewCredential,
    onClose: onCloseNewCredential,
  } = useDisclosure();

  const {
    isOpen: isOpenNewCredentialPackage,
    onOpen: onOpenNewCredentialPackage,
    onClose: onCloseNewCredentialPackage,
  } = useDisclosure();

  const navigate = useNavigate();

  const actions =
    isCredentialsAdmin && activeTab !== "me"
      ? [
          <HStack key="new-actions">
            <Menu>
              <MenuButton
                as={Button}
                leftIcon={<AddIcon />}
                rightIcon={<ChevronDownIcon />}
                colorScheme="teal">
                New
              </MenuButton>
              <MenuList>
                <MenuItem icon={<EditIcon />} onClick={onOpenNewCredential}>
                  New Credential
                </MenuItem>
                <MenuItem icon={<EditIcon />} onClick={onOpenNewCredentialPackage}>
                  New Credential Package
                </MenuItem>
                <MenuItem icon={<EditIcon />} onClick={onOpenNewTraining}>
                  New Training
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>,
        ]
      : [];

  const TABS = useMemo(
    () =>
      [
        isCredentialsAdmin && {
          label: (
            <HStack w="100%" justify="space-between">
              <Text>Hub</Text>
              <Badge colorScheme="teal">Admin</Badge>
            </HStack>
          ),
          value: "hub",
          icon: <AiOutlineHome />,
        },
        isCredentialsAdmin && {
          label: (
            <HStack w="100%" justify="space-between">
              <Text>All Credentials</Text>
              <Badge colorScheme="teal">Admin</Badge>
            </HStack>
          ),
          value: "all",
          icon: <FaRegFileAlt />,
        },
        { label: "Your Credentials", value: "me", icon: <IoMedalOutline /> },
        {
          label: isCredentialsAdmin ? "All Trainings" : "Browse",
          value: "trainings",
          icon: isCredentialsAdmin ? <TbPresentationAnalytics /> : <SearchIcon />,
        },
      ].filter((x) => x) as { label: string; value: string; icon: JSX.Element }[],
    [currentUserQuery?.data, isCredentialsAdmin]
  );

  return (
    <Flex direction="column">
      <Header title="Credentials & Trainings" actions={actions} />
      <Box pt={4} w="100%">
        <SplitPage
          sidebarWidth="250px"
          sidebarWidthXL="300px"
          breakpoint="md"
          sidebar={
            <VStack width="100%" spacing={3}>
              <VStack width="100%">
                {TABS.length &&
                  TABS.map((tab) => (
                    <Button
                      key={tab.value}
                      leftIcon={tab.icon}
                      width="100%"
                      justifyContent="start"
                      colorScheme={activeTab === tab.value ? "teal" : "gray"}
                      bg={activeTab === tab.value ? "teal.500" : "transparent"}
                      onClick={() => {
                        navigate(`/credentials/${tab.value}`);
                      }}>
                      {tab.label}
                    </Button>
                  ))}
              </VStack>

              {activeTab !== "hub" && activeTab !== "me" && (
                <>
                  <Box width="full" display={["none", "none", "inline"]} alignSelf="start">
                    <Facets
                      facets={populatedFacets}
                      onChange={onFacets}
                      debouncedSearch={debouncedSearch}
                      search
                      background
                    />
                  </Box>

                  <Box display={{ base: "flex", md: "none" }} alignSelf="end">
                    <Facets
                      variant="button"
                      facets={populatedFacets}
                      onChange={onFacets}
                      debouncedSearch={debouncedSearch}
                      search
                      background
                    />
                  </Box>
                </>
              )}
            </VStack>
          }
          main={
            <Box w="100%">
              {activeTab === "hub" && <Hub />}
              {activeTab === "all" && (
                <AllCredentials
                  data={data}
                  isLoading={isLoading}
                  onPagination={onPagination}
                  pagination={pagination}
                />
              )}
              {activeTab === "trainings" && (
                <Trainings
                  data={data}
                  order={order}
                  onOrder={onOrder}
                  isLoading={isLoading}
                  onPagination={onPagination}
                  pagination={pagination}
                  isCredentialsAdmin={isCredentialsAdmin}
                />
              )}
              {activeTab === "me" && <MyCredentialsPage data={data} />}
            </Box>
          }
        />
      </Box>
      {isCredentialsAdmin && (
        <>
          <NewTrainingModal isOpen={isOpenNewTraining} onClose={onCloseNewTraining} />
          <NewCredentialModal isOpen={isOpenNewCredential} onClose={onCloseNewCredential} />
          <NewCredentialPackageModal
            isOpen={isOpenNewCredentialPackage}
            onClose={onCloseNewCredentialPackage}
          />
        </>
      )}
    </Flex>
  );
};

import React from "react";
import { FormData, FormTemplateShowData, usePublishTemplate } from "../../../../api/form_builder";
import {
  Button,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { ServicePartialData } from "../../../../api/services";

export const PublishFormWidget = ({
  service,
  template,
}: {
  service: ServicePartialData;
  template: FormTemplateShowData;
}) => {
  const bgColor = useColorModeValue("teal.50", "teal.600");

  const { mutateAsync: publishTemplate, isLoading } = usePublishTemplate(service.id);
  return (
    <HStack
      position="fixed"
      bottom="15px"
      justify="center"
      width="100%"
      zIndex={"overlay"}
      p={[0, 4]}>
      <Stack
        direction={{ base: "column", md: "row" }}
        bg={bgColor}
        p={4}
        justify="space-between"
        align={{ base: "start", md: "center" }}
        borderRadius="md"
        boxShadow="lg"
        minWidth={{ md: "xl", lg: "2xl" }}>
        <Flex direction="column">
          <Heading size="sm">Publish Your Changes</Heading>
          <Text fontSize="sm">
            Your form has unsaved changes. Save now to publish your updates.
          </Text>
        </Flex>
        <Tooltip
          label="You have errors in your form. Please fix them before publishing."
          isDisabled={template.publishing_errors.length === 0}>
          <Button
            colorScheme="teal"
            isLoading={isLoading}
            onClick={() => publishTemplate({ templateId: template.id })}
            isDisabled={template.publishing_errors.length > 0}>
            Save Changes
          </Button>
        </Tooltip>
      </Stack>
    </HStack>
  );
};

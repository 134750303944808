import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Tag,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  AttributesTable,
  ConfirmationModal,
  EditableText,
  Header,
  RecordLink,
  SplitPage,
} from "@sciencecorp/helix-components";
import { humanize, titleize } from "inflection";
import { toWords } from "number-to-words";
import React, { useEffect, useState } from "react";
import { GoGear } from "react-icons/go";
import { IoMdEyeOff } from "react-icons/io";
import { MdOutlineStorefront } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import {
  invalidateSubscription,
  useArchiveSubscription,
  useDeleteSubscription,
  useGetSubscription,
  useGetSubscriptionEvents,
  useUpdateSubscription,
} from "../../../api/subscription";
import { useCurrentUserQuery, userHasFeatureFlag, userHasRole } from "../../../api/user";
import { useCurrency } from "../../../contexts/CurrencyContext";
import { Money } from "../../../helpers/Money";
import { MoneyText } from "../../MoneyText";
import { CardIcon } from "../../Requests/components/NewPurchaseIcons";
import { SpendingAuthorityInfo } from "../../shared/SpendingAuthorityInfo";
import { TimelineTable } from "../../shared/TimelineTable";
import { EditableSpendingAuthoritySelect } from "../SpendingAuthoritySelectTree";
import { ApprovalFlow } from "./components/ApprovalFlow";
import { NewSubscriptionModal } from "./components/NewSubscriptionModal";
import { SubscriptionCardDetails } from "./components/SubscriptionCardDetails";
import { SubscriptionPayments } from "./components/SubscriptionPayments";
import { subscriptionStatusColor } from "./util";

type SubscriptionTimelineProps = {
  subscription_id: number;
};
const SubscriptionTimeline: React.FC<SubscriptionTimelineProps> = ({ subscription_id }) => {
  const timelineEventsQuery = useGetSubscriptionEvents(subscription_id);
  if (timelineEventsQuery.isSuccess) {
    const events = timelineEventsQuery.data;

    return (
      <TimelineTable
        timelineable_id={subscription_id}
        timelineable_type={"Subscription"}
        events={events || []}
        disableCommentBox={true}
        onComment={() => {}}
      />
    );
  } else if (timelineEventsQuery.isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  } else {
    return <Text>Error loading subscription events</Text>;
  }
};

export const SubscriptionPage = () => {
  const { id } = useParams();
  if (!id)
    return (
      <Center h="100vh">
        <Text>No Subscription Found.</Text>
      </Center>
    );
  const currency = useCurrency();
  const subscriptionQuery = useGetSubscription(+id);

  const { mutate: archiveSubscription } = useArchiveSubscription(+id);
  const { mutateAsync: updateSubscription } = useUpdateSubscription();
  const { mutateAsync: deleteSubscription } = useDeleteSubscription();

  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose,
  } = useDisclosure();

  const currentUserQuery = useCurrentUserQuery();
  const [showCard, setShowCard] = useState(false);
  const bgColor = useColorModeValue("gray.50", "gray.700");

  const confirmationColorScheme = "teal";
  //TODO: Remove this useEffect once the "subscriptions" feature flag is removed
  useEffect(() => {
    if (currentUserQuery.isSuccess && !userHasFeatureFlag(currentUserQuery, "subscriptions")) {
      navigate("/services/purchasing");
    }
  }, [currentUserQuery.data]);

  if (subscriptionQuery.isSuccess) {
    const isPurchasingAdmin = userHasRole(currentUserQuery, "purchasing_admin");
    const subscription = subscriptionQuery.data;
    const lastSubscriptionPayment = subscription?.last_subscription_payment;
    const showApprovalFlow = !subscription.archived_at && subscription.current_user_can_approve;

    const actionLabel = lastSubscriptionPayment ? "Archive Subscription" : "Delete Subscription";
    const message = lastSubscriptionPayment
      ? "Are you sure you want to archive this subscription? This will prevent any further payments from being made."
      : "Are you sure you want to delete this subscription? This action cannot be undone.";

    const onConfirm = () => {
      if (lastSubscriptionPayment) {
        archiveSubscription(subscription.id);
      } else {
        handleDeleteSubscription();
      }
    };

    const handleDeleteSubscription = () => {
      deleteSubscription(subscription.id)
        .then(() => {
          navigate("/purchasing/subscriptions");
          invalidateSubscription();
        })
        .catch((e) => console.error(e));
    };

    return (
      <>
        <Flex flexDir="column" width="100%" gap={6}>
          <Header
            title={subscription.name}
            crumbs={[{ label: "Subscription", url: "/services/subscriptions" }]}
            badge={{
              label: humanize(subscription.status),
              colorScheme: subscriptionStatusColor(subscription.status),
            }}
            crumbsColor="teal.500"
            actions={
              isPurchasingAdmin
                ? [
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Subscription Options"
                        size="sm"
                        icon={<GoGear />}
                        isDisabled={subscription.archived_at !== null}
                        data-testid="subscription-options-menu"
                      />
                      <MenuList>
                        <MenuItem
                          onClick={() => onConfirmationOpen()}
                          data-testid="archive-subscription-menu-item">
                          {lastSubscriptionPayment ? "Archive Subscription" : "Delete Subscription"}
                        </MenuItem>
                        <MenuItem onClick={() => onOpen()}>Edit Subscription</MenuItem>
                      </MenuList>
                      <ConfirmationModal
                        colorScheme={confirmationColorScheme}
                        confirmText={actionLabel}
                        header={actionLabel}
                        isOpen={isConfirmationOpen}
                        onClick={() => {
                          onConfirm();
                          onConfirmationClose();
                        }}
                        onClose={onConfirmationClose}>
                        {message}
                      </ConfirmationModal>
                    </Menu>,
                  ]
                : []
            }
          />
          {subscription.last_subscription_payment?.decline_reason && (
            <Alert status="error">
              <AlertIcon />A transaction was declined because the{" "}
              {subscription.last_subscription_payment?.decline_reason}
            </Alert>
          )}

          {showApprovalFlow && <ApprovalFlow subscription={subscription} />}
          {subscription.archived_at && (
            <Alert status="warning" mb={4}>
              <AlertIcon />
              <VStack align="start" spacing={0}>
                <AlertTitle>Archived</AlertTitle>
                <AlertDescription>
                  This subscription has been archived and is no longer active.
                </AlertDescription>
              </VStack>
            </Alert>
          )}
          <SplitPage
            sidebarWidth="350px"
            sidebarWidthXL="450px"
            sidebar={
              <Box
                bg={bgColor}
                borderRadius="md"
                maxWidth={"100%"}
                border="1px"
                overflow={"scroll"}
                borderColor="chakra-border-color"
                p={4}>
                <AttributesTable
                  title="Subscription Details"
                  attributes={[
                    {
                      label: "Summary",
                      value: (
                        <Text fontSize={{ base: "sm", md: "md" }}>
                          {titleize(subscription.interval)} subscription with up to{" "}
                          {toWords(subscription.payments_per_interval)}{" "}
                          <MoneyText
                            as={"span"}
                            fontWeight={"bolld"}
                            textDecoration={"underline"}
                            money={subscription.max_amount_per_payment}
                            formatOptions={{ compact: "never" }}
                          />{" "}
                          payment for a total of no more than{" "}
                          <MoneyText
                            as={"span"}
                            fontWeight={"bolld"}
                            textDecoration={"underline"}
                            money={subscription.max_amount_per_interval}
                            formatOptions={{ compact: "never" }}
                          />{" "}
                          per {subscription.interval === "monthly" ? "month" : "year"}.
                        </Text>
                      ),
                    },
                    {
                      label: "Spending Authority",
                      value: (
                        <>
                          <EditableSpendingAuthoritySelect
                            onSubmit={(spendingAuthority) => {
                              updateSubscription({
                                id: subscription.id,
                                spending_authority_type: spendingAuthority?.type,
                                spending_authority_id: spendingAuthority?.id,
                              });
                            }}
                            spendingAuthority={subscription.spending_authority}
                            disabled={!subscription.archived_at}
                          />
                        </>
                      ),
                    },
                    {
                      label: "Vendor",
                      value: (
                        <>
                          <RecordLink
                            type=""
                            link={`/services/vendors/${subscription.vendor.id}`}
                            identifier={subscription.vendor.name}
                            icon={
                              <Box ml={3}>
                                <MdOutlineStorefront />
                              </Box>
                            }
                          />
                        </>
                      ),
                    },
                    {
                      label: "Additional Notes",
                      value: (
                        <>
                          <EditableText
                            multiline
                            defaultValue={subscription.description || ""}
                            disabled={!isPurchasingAdmin || subscription.archived_at !== null}
                            onSubmit={(value) => {
                              value &&
                                updateSubscription({
                                  id: subscription.id,
                                  description: value,
                                });
                            }}
                          />
                        </>
                      ),
                    },
                  ]}
                />
              </Box>
            }
            main={
              <>
                <Flex gap={4} flexDir="column">
                  <Flex gap={4} direction={{ base: "column", lg: "row" }} width="100%">
                    {isPurchasingAdmin && (
                      <Flex
                        flexDir="column"
                        flex="1"
                        p={6}
                        gap={4}
                        border="1px"
                        borderRadius="md"
                        borderColor="chakra-border-color">
                        <HStack justify="space-between">
                          <HStack>
                            <Heading size={{ base: "sm", md: "md" }}>Card Details</Heading>
                            <Tag>
                              <MoneyText
                                as={"span"}
                                money={subscription.spend_for_interval}
                                formatOptions={{ compact: "never" }}
                              />
                              <Text as="span" paddingLeft={"0.5ch"}>
                                spent this{" "}
                                {subscription.interval === "monthly" ? " month" : " year"}
                              </Text>
                            </Tag>
                          </HStack>
                          <IconButton
                            aria-label="See Card Details"
                            data-testid="toggle-card-details"
                            icon={<IoMdEyeOff />}
                            isDisabled={subscription.status !== "approved"}
                            size="sm"
                            onClick={() => setShowCard(!showCard)}
                          />
                        </HStack>
                        <Divider />
                        {subscription.status === "needs_approval" ? (
                          <VStack spacing={1} flex="1" bg={bgColor} borderRadius="md" p={6}>
                            <CardIcon boxSize={{ base: 32, md: 48 }} />
                            <VStack spacing={1}>
                              <Text fontSize="xs" fontWeight="semibold">
                                A Virtual Card Will Be Created Upon Approval
                              </Text>
                              <Text fontSize="xs">
                                The card details will be revealed to you after the subscription has
                                been approved
                              </Text>
                            </VStack>
                          </VStack>
                        ) : (
                          <SubscriptionCardDetails
                            subscription={subscription}
                            showCard={showCard}
                          />
                        )}
                      </Flex>
                    )}
                    <SpendingAuthorityInfo
                      spendingAuthority={subscription.spending_authority}
                      rootSpendingAuthority={subscription.root_spending_authority}
                      pendingAmount={Money.zero(currency)}
                    />
                  </Flex>
                  <Flex
                    flexDir="column"
                    flex="1"
                    p={6}
                    gap={4}
                    border="1px"
                    borderRadius="md"
                    borderColor="chakra-border-color">
                    <Heading size="md">Payment History</Heading>
                    <Divider />
                    {subscription && <SubscriptionPayments subscription={subscription} />}
                  </Flex>
                </Flex>
                <Divider />
                <SubscriptionTimeline subscription_id={subscription.id} />
                {isPurchasingAdmin && (
                  <NewSubscriptionModal
                    subscription={subscription}
                    isOpen={isOpen}
                    onClose={onClose}
                  />
                )}
              </>
            }
          />
        </Flex>
      </>
    );
  } else if (subscriptionQuery.isLoading)
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    );
  else {
    return <Text>Error loading subscription details</Text>;
  }
};

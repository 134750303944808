import { CheckCircleIcon, InfoIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Alert,
  Avatar,
  Box,
  ButtonGroup,
  Circle,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  VStack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { ConfirmationButton } from "@sciencecorp/helix-components";
import React, { useState } from "react";
import { BsRecordCircle } from "react-icons/bs";
import { FaCircleXmark } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { ApprovableData, ApprovalChainLink } from "../../api/approvals";
import { invalidatePurchases, useSubmitPurchase } from "../../api/purchase";
import { UserLoggedInData, UserMinimalData } from "../../api/user";
import { invalidateServiceRequest } from "../../api/service_requests";

export type ApprovalFlowTableProps = {
  approvable: ApprovableData;
  expenditureType: string;
  currentUser: UserLoggedInData;
  approve: (id: number) => Promise<unknown>;
  decline: (id: number) => Promise<unknown>;
  isApproveLoading: boolean;
  isDeclineLoading: boolean;
  showSubmission?: boolean;
  userCanSubmit?: boolean;
  approvedApprovalFlowData?: ApprovableData[];
};

export type SpendingAuthorityApprovalFlowTableProps = {
  currentUser: UserLoggedInData;
  expenditureType: string;
  approve: (id: number) => Promise<unknown>;
  decline: (id: number) => Promise<unknown>;
  isApproveLoading: boolean;
  isDeclineLoading: boolean;
  showSubmission?: boolean;
  approvedApprovalFlowData?: ApprovableData[];
  hideExpenditureApproval?: boolean;
};

type ApprovalChainBadgesProps = {
  approvable: ApprovableData;
  currentUser: UserLoggedInData;
};

interface SubmitForApprovalProps {
  approvable: ApprovableData;
  userCanSubmit: boolean;
}

export const SubmitForApproval: React.FC<SubmitForApprovalProps> = ({
  approvable,
  userCanSubmit,
}) => {
  const { mutateAsync: submitForApproval, isLoading } = useSubmitPurchase();
  const toast = useToast();
  if (userCanSubmit) {
    return (
      <ConfirmationButton
        variant="Button"
        isLoading={isLoading}
        colorScheme={"teal"}
        width={"100%"}
        marginTop={"2"}
        confirmationHeader="Submit for Approval"
        label="Submit for Approval"
        children="Are you ready to submit this request for approval?"
        confirmationButtonLabel="Yes"
        onConfirm={() => {
          submitForApproval(approvable.id).then(() => {
            toast({ title: "Request Submitted" });
          });
        }}></ConfirmationButton>
    );
  } else {
    return null;
  }
};

export const SpendingAuthorityApprovalFlow: React.FC<SpendingAuthorityApprovalFlowTableProps> = ({
  currentUser,
  approvedApprovalFlowData,
  expenditureType,
  approve,
  decline,
  isApproveLoading,
  isDeclineLoading,
  hideExpenditureApproval,
}) => {
  return (
    <Tabs isFitted width={"100%"} colorScheme="teal">
      <TabList>
        <Tab fontSize={"sm"} fontWeight={"bold"}>
          Change Approval
        </Tab>
        {!hideExpenditureApproval && (
          <Tab fontSize={"sm"} fontWeight={"bold"}>
            {expenditureType} Approval
          </Tab>
        )}
      </TabList>

      <TabPanels>
        <TabPanel>
          {approvedApprovalFlowData && (
            <ApprovalFlowTable
              currentUser={currentUser}
              approvable={approvedApprovalFlowData[1]}
              expenditureType={expenditureType}
              approve={approve}
              decline={decline}
              isApproveLoading={isApproveLoading}
              isDeclineLoading={isDeclineLoading}
            />
          )}
        </TabPanel>
        {!hideExpenditureApproval && (
          <TabPanel>
            <Flex
              width={"100%"}
              borderRadius="md"
              direction={"column"}
              maxWidth={"100%"}
              border="1px"
              borderColor="chakra-border-color"
              p={4}
              pb={2}
              marginTop={4}
              marginBottom={4}>
              <Heading size={"md"} margin={"2"}>
                {expenditureType} Approval
              </Heading>
              <Divider />
              <Flex
                pt={4}
                w={"100%"}
                justifyContent={"start"}
                alignContent={"center"}
                flexDir={"column"}
                gap={4}>
                {approvedApprovalFlowData && (
                  <ApprovalChainBadges
                    approvable={approvedApprovalFlowData[0]}
                    currentUser={currentUser}
                  />
                )}
              </Flex>
            </Flex>
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
};

export const ApprovalFlowTable: React.FC<ApprovalFlowTableProps> = ({
  approvable,
  currentUser,
  showSubmission,
  approve,
  decline,
  isApproveLoading,
  isDeclineLoading,
  userCanSubmit = true,
}) => {
  const [loadingField, setLoadingField] = useState("");
  const toast = useToast();
  const invSerFn = invalidateServiceRequest();
  const invPurFn = invalidatePurchases();

  return (
    <Flex
      width={"100%"}
      borderRadius="md"
      direction={"column"}
      maxWidth={"100%"}
      border="1px"
      borderColor="chakra-border-color"
      p={4}
      pb={2}
      marginTop={4}
      marginBottom={4}>
      <Heading size={"md"} margin={"2"}>
        Approval Flow
      </Heading>

      <Divider />
      <Flex
        pt={4}
        w={"100%"}
        justifyContent={"start"}
        alignContent={"center"}
        flexDir={"column"}
        gap={4}>
        <ApprovalChainBadges approvable={approvable} currentUser={currentUser} />
      </Flex>
      {approvable.approval_errors.length ? (
        <Flex justifyContent={"center"} flexDir={"column"}>
          {approvable.approval_errors.map((error, idx) => {
            return (
              <Box key={`${error}-${idx}`}>
                <Divider />
                <Flex direction={"row"} alignItems={"center"} p={3} gap={2}>
                  <InfoIcon color={useColorModeValue("orange.500", "orange.100")} boxSize={5} />
                  <Text key={error} fontSize="sm">
                    {error}
                  </Text>
                </Flex>
              </Box>
            );
          })}
        </Flex>
      ) : showSubmission ? (
        <SubmitForApproval approvable={approvable} userCanSubmit={userCanSubmit} />
      ) : (
        <></>
      )}

      {approvable.approval_state == "show_buttons" && !showSubmission && (
        <>
          <Divider />
          <Text margin={"2"}>Your approval is required</Text>
          <Flex justifyContent={"center"}>
            <ButtonGroup size="sm" pt={2} width={"100%"}>
              <ConfirmationButton
                variant="Button"
                colorScheme="red"
                buttonVariant="outline"
                width={"50%"}
                size="sm"
                isDisabled={isDeclineLoading || isApproveLoading}
                isLoading={isDeclineLoading && loadingField === "declined_at"}
                onConfirm={() => {
                  setLoadingField("declined_at");
                  decline(approvable.id).then(() => {
                    toast({ title: "Transaction Declined" });
                    invSerFn();
                    invPurFn();
                  });
                }}
                confirmationHeader="Decline Request"
                label="Decline"
                children="Are you sure you want to decline this request? This action cannot be undone."
              />
              <ConfirmationButton
                variant="Button"
                colorScheme="teal"
                width={"50%"}
                size="sm"
                isDisabled={isDeclineLoading || isApproveLoading}
                isLoading={isApproveLoading && loadingField === "approved_at"}
                onConfirm={() => {
                  setLoadingField("approved_at");
                  approve(approvable.id).then(() => {
                    toast({ title: "Transaction Approved" });
                    invSerFn();
                    invPurFn();
                  });
                }}
                confirmationHeader="Approve Transaction"
                label="Approve"
                children="Are you sure you want to approve this transaction? This action cannot be undone."
              />
            </ButtonGroup>
          </Flex>
        </>
      )}
    </Flex>
  );
};

const useBorderColor = (isApproved: boolean, isDeclined: boolean, isPending: boolean) => {
  if (isApproved) {
    return useColorModeValue("green.100", "green.500");
  }
  if (isDeclined) {
    return useColorModeValue("red.100", "red.700");
  }
  if (isPending) {
    return useColorModeValue("yellow.100", "yellow.500");
  }
  return useColorModeValue("gray.100", "gray.500");
};

const LinkStatusBadgeCheckIcon: React.FC<{
  isLinkApproved: boolean;
  isLinkDeclined: boolean;
  isFullyApproved: boolean;
  isFullyDeclined: boolean;
  linkOptional?: boolean;
}> = ({
  isLinkApproved,
  isLinkDeclined,
  isFullyApproved,
  isFullyDeclined,
  linkOptional = false,
}) => {
  if (isLinkApproved) {
    return <CheckCircleIcon color={useColorModeValue("green.600", "green.100")} boxSize={4} />;
  } else if (isLinkDeclined) {
    return <Icon as={FaCircleXmark} color={useColorModeValue("red.500", "red.100")} boxSize={4} />;
  } else if (isFullyDeclined) {
    return (
      <Tooltip label="Purchase has been declined" aria-label="Purchase has been declined">
        <InfoIcon color={useColorModeValue("red.500", "red.100")} boxSize={4} />
      </Tooltip>
    );
  } else if (isFullyApproved) {
    return (
      <Tooltip
        label="Purchase is already approved; these users' approvals are not needed to continue"
        aria-label="Purchase is already approved; these users' approvals are not needed to continue">
        <InfoIcon color={useColorModeValue("green.600", "green.100")} boxSize={4} />
      </Tooltip>
    );
  } else if (linkOptional) {
    return (
      <Tooltip
        label="Purchase is below the approval threshold"
        aria-label="Purchase is below the approval threshold">
        <InfoIcon color={useColorModeValue("green.600", "green.100")} boxSize={4} />
      </Tooltip>
    );
  } else {
    return (
      <Icon as={BsRecordCircle} color={useColorModeValue("gray.500", "gray.100")} boxSize={4} />
    );
  }
};

const LinkStatusBadgeStatus: React.FC<{
  approvalChainLink: ApprovalChainLink;
  linkOptional: boolean;
  fullyApproved: boolean;
  fullyDeclined: boolean;
}> = ({ approvalChainLink: approvalChainLink, fullyApproved, linkOptional, fullyDeclined }) => {
  const textColor = useColorModeValue("gray.500", "gray.10");

  let statusText: string | null = null;

  const firstInterestingApproval = approvalChainLink.approvals.find(
    (approval) => approval.is_approved !== fullyDeclined
  );
  if (firstInterestingApproval) {
    statusText = firstInterestingApproval.created_at.toFormat("MM/dd/yyyy");
  } else if (!fullyDeclined && !linkOptional && !fullyApproved) {
    statusText = "Pending";
  }

  if (statusText) {
    return (
      <Heading size={"xs"} color={textColor} fontWeight={"normal"}>
        - {statusText}
      </Heading>
    );
  } else {
    return <></>;
  }
};

const LinkStatusBadge: React.FC<{
  fullyApproved: boolean;
  approvalChainLink: ApprovalChainLink;
  currentUser: UserLoggedInData;
  isLastLink: boolean;
  approvableIsDeclined: boolean;
  linkOptional?: boolean;
}> = ({
  approvalChainLink,
  currentUser,
  isLastLink,
  approvableIsDeclined,
  linkOptional,
  fullyApproved,
}) => {
  let borderColor: string = useBorderColor(
    approvalChainLink.is_approved,
    approvalChainLink.is_declined || approvableIsDeclined,
    false
  );

  if (approvalChainLink.leads.length === 0 && !isLastLink) {
    return null;
  } else if (approvalChainLink.leads.length === 0 && isLastLink) {
    return <Alert status="error">Configuration Error: There are no users with the last role</Alert>;
  }

  return (
    <HStack width={"100%"} marginLeft={"4"} marginBottom={"4"}>
      {!isLastLink ? (
        <Stack
          position="relative"
          marginRight={"2"}
          justifyContent={"center"}
          alignItems={"center"}>
          <Box
            position="absolute"
            height="200%"
            bg={useColorModeValue("gray.200", "gray.700")}
            width={0.5}
            top={"100%"}
            zIndex={-10}></Box>
          <Circle bgColor={borderColor} borderStyle={"solid"} borderWidth={1} p={2}>
            <LinkStatusBadgeCheckIcon
              isLinkApproved={approvalChainLink.is_approved}
              isLinkDeclined={approvalChainLink.is_declined}
              isFullyApproved={fullyApproved}
              isFullyDeclined={approvableIsDeclined}
              linkOptional={linkOptional}
            />
          </Circle>
        </Stack>
      ) : (
        <Box marginRight={"2"}>
          <Circle bgColor={borderColor} borderStyle={"solid"} borderWidth={1} p={2}>
            <LinkStatusBadgeCheckIcon
              isLinkApproved={approvalChainLink.is_approved}
              isLinkDeclined={approvalChainLink.is_declined}
              isFullyApproved={fullyApproved}
              isFullyDeclined={approvableIsDeclined}
              linkOptional={linkOptional}
            />
          </Circle>
        </Box>
      )}
      <VStack alignItems={"start"}>
        <HStack>
          <Heading size={"xs"}>
            {approvalChainLink.type}: {approvalChainLink.name}
          </Heading>
          <LinkStatusBadgeStatus
            approvalChainLink={approvalChainLink}
            linkOptional={linkOptional || false}
            fullyApproved={fullyApproved}
            fullyDeclined={approvableIsDeclined}
          />
        </HStack>
        <HStack>
          <HStack>
            {approvalChainLink.leads.map((lead, index) => {
              return (
                <LinkStatusBadgeAvatar
                  key={`approval-lead-${approvalChainLink.name}-${index}`}
                  approvalChainLink={approvalChainLink}
                  lead={lead}
                  currentUser={currentUser}
                />
              );
            })}
          </HStack>
          {approvalChainLink.leads.length > 1 && (
            <Tooltip label="Approval can be granted by any of the listed employees">
              <InfoOutlineIcon
                _hover={{ cursor: "pointer" }}
                color={useColorModeValue("gray.500", "gray.100")}
              />
            </Tooltip>
          )}
        </HStack>
      </VStack>
    </HStack>
  );
};

const LinkStatusBadgeAvatar: React.FC<{
  approvalChainLink: ApprovalChainLink;
  lead: UserMinimalData;
  currentUser: UserLoggedInData;
  key: string;
}> = ({ approvalChainLink, lead, currentUser, key }) => {
  const linkApproved = approvalChainLink.is_approved;
  const linkDeclined = approvalChainLink.is_declined;
  const approval = approvalChainLink.approvals.find((approval) => approval.is_approved);
  const leadIsApprover = lead.id === approval?.user.id;

  const borderColor = useBorderColor(
    linkApproved && leadIsApprover,
    linkDeclined && leadIsApprover,
    !linkApproved && !linkDeclined
  );

  return (
    <Tooltip label={lead.name} closeDelay={500} key={key}>
      <Link to={`/users/${lead.id}`}>
        <Avatar
          size="sm"
          name={lead.name}
          src={lead.picture_uri}
          outline={currentUser?.id === lead.id || leadIsApprover ? "solid 2px" : "0px"}
          outlineColor={borderColor}
          borderRadius={"full"}
        />
      </Link>
    </Tooltip>
  );
};

const ApprovalChainBadges: React.FC<ApprovalChainBadgesProps> = ({ approvable, currentUser }) => {
  const unfilteredChainLength = approvable.approval_chain?.length ?? 0;
  const filteredChain =
    approvable.approval_chain?.filter(
      (ele, index) => ele.leads.length > 0 || unfilteredChainLength - 1 === index
    ) ?? [];

  return (
    <>
      {filteredChain.map((authLink, index) => (
        <Box width={"100%"} key={index}>
          <LinkStatusBadge
            fullyApproved={approvable.fully_approved}
            approvalChainLink={authLink}
            currentUser={currentUser}
            isLastLink={filteredChain.length - 1 === index}
            linkOptional={!authLink.requires_link_approval}
            approvableIsDeclined={approvable.approval_state === "declined"}
          />
        </Box>
      ))}
    </>
  );
};

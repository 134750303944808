import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  Text,
  Button,
  ModalFooter,
  HStack,
  Checkbox,
  Image,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useUpdateUserPreference } from "../../../api/user_preferences";
import { useCurrentUserQuery } from "../../../api/user";
import ArchiveImage from "../../../../assets/images/archive_modal_image.png";

export const ArchiveSuccessfulPopup = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { data: currentUser } = useCurrentUserQuery();
  const [hideArchivedServices, setHideArchivedServices] = useState(false);
  const { mutateAsync: updateUserPreference } = useUpdateUserPreference(currentUser?.id);

  const handleClose = () => {
    updateUserPreference({ hide_archived_services: hideArchivedServices });
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Successful</ModalHeader>
        <ModalBody>
          <Text>
            You have successfully archived this service. Use the "Show Archive" toggle to view
            archived services.
          </Text>
          <Image src={ArchiveImage} />
        </ModalBody>
        <ModalFooter>
          <HStack justify="end">
            <Checkbox
              colorScheme="teal"
              isChecked={hideArchivedServices}
              onChange={(e) => setHideArchivedServices(e.target.checked)}>
              Don't show this again
            </Checkbox>
            <Button onClick={handleClose}>Close</Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

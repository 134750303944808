import {
  AddIcon,
  ChevronRightIcon,
  DeleteIcon,
  EditIcon,
  ExternalLinkIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  Grid,
  GridItem,
  HStack,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Link,
  Stack,
  Switch,
  Tag,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
  useToast,
  Tab,
  TabPanel,
  TabPanels,
  TabList,
  Tabs,
  Badge,
  Skeleton,
  Icon,
} from "@chakra-ui/react";
import {
  Collection,
  Column,
  EmptyState,
  Facets,
  RecordLink,
  buildFacets,
  useCollection,
} from "@sciencecorp/helix-components";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { TeamServiceModal } from "../../../Requests/components/TeamServiceModal";
import { TeamServiceRequestModal } from "../../../Requests/components/NewServiceRequestModal";
import { UserRequestPill, serviceStatusMap } from "../../../Requests/utils";
import { useDebouncedSearch } from "../../../hooks/useDebouncedSearch";
import { humanize, titleize } from "inflection";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaUndo } from "react-icons/fa";
import { FiArchive, FiEye, FiEyeOff } from "react-icons/fi";
import {
  ServiceRequestIndexItemData,
  useSearchServiceRequestsQuery,
} from "../../../../api/service_requests";
import {
  ServicePartialData,
  useCreateService,
  useDeleteService,
  useSearchServices,
  useUpdateService,
} from "../../../../api/services";
import { BsGear } from "react-icons/bs";
import { useCurrentUserQuery, userHasRole } from "../../../../api/user";
import { DeleteModal } from "../../../Hiring/components/DeleteModal";
import React, { useEffect, useMemo, useState } from "react";
import { ServiceStatus } from "../../../Requests/components/DetailPage/ServiceDetailsPage";
import { ArchiveSuccessfulPopup } from "../../../Requests/components/ArchiveSuccessfulPopup";
import { DraftTemplateConfirmationModal } from "./DraftTemplateConfirmationModal";
import { ManageCategoriesModal } from "./ManageCategoriesModal";
import { IoIosFlag } from "react-icons/io";
import { PRIORITY_OPTIONS } from "../../../Requests/components/ServiceRequestDetailsSidebar";

export const TeamServices = ({ team }) => {
  const [showArchived, setShowArchived] = useState(false);
  const { debouncedSearch, search } = useDebouncedSearch();
  const { onPagination, onOrder, order, pagination, facets, onFacets, filters } = useCollection();
  const toast = useToast();
  const {
    isOpen: isManageCategoriesOpen,
    onOpen: onManageCategoriesOpen,
    onClose: onManageCategoriesClose,
  } = useDisclosure();
  const {
    isOpen: isNewServiceOpen,
    onOpen: onNewServiceOpen,
    onClose: onNewServiceClose,
  } = useDisclosure();
  const {
    isOpen: isServiceRequestOpen,
    onOpen: onServiceRequestOpen,
    onClose: onServiceRequestClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteServiceOpen,
    onOpen: onDeleteServiceOpen,
    onClose: onDeleteServiceClose,
  } = useDisclosure();
  const [categoryFilter, setCategoryFilter] = useState<string | null>(null);
  const { mutate: deleteService } = useDeleteService();

  const AGGREGATION_QUERY = [
    "status",
    "assigned_user_name",
    "service_name",
    "requesting_user_name",
    "priority_level",
  ];
  const { data, isLoading } = useSearchServiceRequestsQuery({
    aggs: AGGREGATION_QUERY,
    term: search || "*",
    filters: {
      ...filters,
      service_team_id: team.id,
      ...(categoryFilter && { service_request_categories_id: categoryFilter }),
    },
    pagination,
    order,
  });

  const { data: allServices, isLoading: isLoadingAllServices } = useSearchServices({
    aggs: ["status"],
    term: search || "*",
    filters: {
      ...filters,
      team_id: team.id,
    },
    pagination: { per_page: -1 },
    order,
  });
  const populatedFacets = buildFacets(data?.aggregations || {}, facets);
  const currentUser = useCurrentUserQuery();
  const servicesAdmin = userHasRole(currentUser, "services_admin");
  const { isOpen: isArchiveOpen, onOpen: onArchiveOpen, onClose: onArchiveClose } = useDisclosure();
  const [serviceModal, setServiceModal] = useState<ServicePartialData | null>(null);
  const [requestModal, setRequestModal] = useState<ServicePartialData | null>(null);

  const navigate = useNavigate();
  const { mutateAsync: createService, isLoading: isCreatingService } = useCreateService();

  const activeServices = useMemo(
    () => allServices?.results?.filter((service) => service?.status === "active") || [],
    [allServices]
  );

  const draftServices = useMemo(
    () => allServices?.results?.filter((service) => service?.status === "draft") || [],
    [allServices]
  );

  const archivedServices = useMemo(
    () => allServices?.results?.filter((service) => service?.status === "archived") || [],
    [allServices]
  );

  const { isOpen: isOpenDraft, onOpen: onOpenDraft, onClose: onCloseDraft } = useDisclosure();

  const [showClosed, setShowClosed] = useState(false);

  const filteredData = useMemo(() => {
    if (!data?.results) return [];
    return data.results.filter((item) => {
      if (!showClosed) {
        return item.status !== "complete" && item.status !== "abandoned";
      }
      return true;
    });
  }, [data, showClosed]);

  useEffect(() => {
    if (archivedServices?.length === 0) {
      setShowArchived(false);
    }
  }, [allServices]);

  const columns: Column<ServiceRequestIndexItemData>[] = [
    {
      label: "Requests",
      orderOptions: { orderKey: "id" },
      render: (item) => (
        <Box>
          <RecordLink link={`/services/requests/${item.id}`} identifier={item.id} type="REQUEST" />
        </Box>
      ),
    },
    {
      label: "Requested By",
      render: (item) => (
        <Link as={RouterLink} to={`/users/${item.id}`}>
          <UserRequestPill user={item.requesting_user} />
        </Link>
      ),
    },
    {
      label: "Service",
      orderOptions: { orderKey: "service_name" },
      render: (item) => item.service_name,
    },
    {
      label: "Preview of Request",
      render: (item) => (
        <Text
          maxHeight={["10ch", "10ch", "15ch", "15ch", "15ch"]}
          maxWidth={["50ch", "20ch", "20ch", "25ch", "30ch", "40ch"]}
          isTruncated={true}>
          {item.preview}
        </Text>
      ),
    },
    {
      label: "Created",
      orderOptions: { orderKey: "created_at" },
      render: (item) => item.created_at.toFormat("LLL dd yyyy"),
    },
    {
      label: "Priority",
      orderOptions: { orderKey: "priority_level" },
      render: (item) =>
        item.can_see_priority_level ? (
          <HStack>
            {item.priority_level && (
              <IoIosFlag
                color={
                  PRIORITY_OPTIONS.find((option) => option.value === item.priority_level)?.color
                }
              />
            )}
            <Text>{item.priority_level}</Text>
          </HStack>
        ) : (
          <Text>--</Text>
        ),
    },
    {
      label: "Status",
      render: (item) => (
        <VStack w={"max-content"}>
          <Tag
            colorScheme={
              serviceStatusMap.find((ele) => ele.value === item.status)?.color || undefined
            }>
            {titleize(humanize(item.status))}
          </Tag>
        </VStack>
      ),
    },
  ];
  return (
    <Stack spacing={8} width="100%">
      {serviceModal && (
        <DraftTemplateConfirmationModal
          service={serviceModal}
          isOpen={isOpenDraft}
          onClose={() => {
            onCloseDraft();
            setServiceModal && setServiceModal(null);
          }}
        />
      )}
      <ManageCategoriesModal
        isOpen={isManageCategoriesOpen}
        onClose={onManageCategoriesClose}
        teamCategories={team.team_service_categories}
        teamId={team.id}
      />
      {!currentUser.data?.user_preference[0]?.hide_archived_services && (
        <ArchiveSuccessfulPopup isOpen={isArchiveOpen} onClose={onArchiveClose} />
      )}
      <VStack align="start" spacing={6} marginBottom={10}>
        <Stack
          width="100%"
          justify="space-between"
          direction={{ base: "column", md: "row" }}
          align="center">
          <Heading size="md">Services</Heading>
          {(team.can_act_as_lead || servicesAdmin) && (
            <HStack justify="end" display={{ base: "none", md: "flex" }}>
              <HStack>
                <Text>Show Archive</Text>
                <Switch
                  id="archived"
                  colorScheme="teal"
                  onChange={() => setShowArchived((prev) => !prev)}
                  isChecked={showArchived}
                  isDisabled={archivedServices?.length === 0}
                />
              </HStack>
              {!team.is_archived && (
                <Button
                  marginLeft={4}
                  isLoading={isCreatingService}
                  onClick={() =>
                    createService({ team_id: team.id }).then((data) =>
                      navigate(`/teams/${team.id}/services/${data.id}`)
                    )
                  }
                  leftIcon={<AddIcon />}
                  colorScheme="teal">
                  New Service
                </Button>
              )}
            </HStack>
          )}
        </Stack>

        {showArchived && archivedServices?.length > 0 && (
          <>
            <Heading size="md">Archived Services</Heading>
            <Grid templateColumns="repeat(3, 1fr)" gap={3} width="100%">
              {archivedServices?.map((service) => (
                <ServiceItem
                  key={service.id}
                  service={service}
                  onServiceOpen={onServiceRequestOpen}
                  onNewServiceOpen={onNewServiceOpen}
                  onDeleteOpen={onDeleteServiceOpen}
                  location="team"
                  archived={true}
                  onOpenDraft={onOpenDraft}
                  setServiceModal={setServiceModal}
                  setRequestModal={setRequestModal}
                  editable={team.can_act_as_lead || servicesAdmin}
                />
              ))}
            </Grid>
          </>
        )}
        <>
          {showArchived && <Heading size="lg">Active Services</Heading>}
          <Grid templateColumns="repeat(3, 1fr)" gap={3} width="100%">
            {(activeServices && activeServices.length) ||
            (draftServices && draftServices.length) ? (
              <>
                {activeServices.map((service) => (
                  <ServiceItem
                    key={service.id}
                    service={service}
                    onServiceOpen={onServiceRequestOpen}
                    onNewServiceOpen={onNewServiceOpen}
                    onDeleteOpen={onDeleteServiceOpen}
                    onArchiveOpen={onArchiveOpen}
                    onOpenDraft={onOpenDraft}
                    location="team"
                    archived={false}
                    setServiceModal={setServiceModal}
                    setRequestModal={setRequestModal}
                    editable={team.can_act_as_lead || servicesAdmin}
                  />
                ))}
                {draftServices.map((service) => (
                  <ServiceItem
                    key={service.id}
                    service={service}
                    onOpenDraft={onOpenDraft}
                    onServiceOpen={onServiceRequestOpen}
                    onNewServiceOpen={onNewServiceOpen}
                    onDeleteOpen={onDeleteServiceOpen}
                    location="team"
                    archived={false}
                    setServiceModal={setServiceModal}
                    setRequestModal={setRequestModal}
                    editable={team.can_act_as_lead || servicesAdmin}
                  />
                ))}
              </>
            ) : (
              <GridItem colSpan={3}>
                <EmptyState title="This team doesn't have any services yet." size="2xs" />
              </GridItem>
            )}
          </Grid>
        </>
        {serviceModal ? (
          <TeamServiceModal
            isOpen={isNewServiceOpen}
            onClose={onNewServiceClose}
            service={serviceModal}
            team={team}
            setActiveService={setServiceModal}
          />
        ) : (
          <TeamServiceModal isOpen={isNewServiceOpen} onClose={onNewServiceClose} team={team} />
        )}
        {requestModal && (
          <TeamServiceRequestModal
            service={requestModal}
            isOpen={isServiceRequestOpen}
            onClose={onServiceRequestClose}
            setActiveService={setRequestModal}
          />
        )}
        <DeleteModal
          title="Delete Service?"
          isOpen={isDeleteServiceOpen}
          onClose={onDeleteServiceClose}
          onSubmit={() =>
            serviceModal &&
            deleteService(serviceModal.id, {
              onSuccess: () => {
                onDeleteServiceClose();
                setServiceModal(null);
                toast({
                  title: "Delete Successful.",
                  description: `You have deleted ${serviceModal.name} successfully.`,
                  status: "success",
                  duration: 5000,
                  isClosable: true,
                });
              },
            })
          }
        />
      </VStack>
      <Divider />
      <HStack width="100%" justifyContent="space-between">
        <Stack
          w={"100%"}
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "start", md: "center" }}
          spacing={4}
          justifyContent={"space-between"}>
          <Heading size="md">Request Queue</Heading>
          <HStack>
            <HStack
              pr={4}
              display={{ base: "none", md: "flex" }}
              borderRight="1px solid "
              borderColor="chakra-border-color">
              <Button
                leftIcon={<Icon as={showClosed ? FiEyeOff : FiEye} />}
                variant="ghost"
                colorScheme="gray"
                onClick={() => setShowClosed(!showClosed)}>
                {showClosed ? "Hide Closed" : "Show Closed"}
              </Button>
            </HStack>
            <Button
              onClick={onManageCategoriesOpen}
              leftIcon={<BsGear />}
              colorScheme="teal"
              variant="ghost"
              display={{ base: "none", md: "flex" }}>
              Manage Categories
            </Button>
            <Facets
              facets={populatedFacets}
              onChange={onFacets}
              variant={"button"}
              search
              debouncedSearch={debouncedSearch}
            />
          </HStack>
        </Stack>
      </HStack>
      <Tabs colorScheme="teal">
        <TabList
          overflowX={{ base: "auto", md: "visible" }}
          sx={{
            "&::-webkit-scrollbar": {
              display: "none", // Hide scrollbar for WebKit browsers
            },
            "-ms-overflow-style": "none", // Hide scrollbar for IE and Edge
            "scrollbar-width": "none", // Hide scrollbar for Firefox
          }}>
          <Tab onClick={() => setCategoryFilter(null)}>
            All
            {categoryFilter === null && (
              <Badge ml={2} colorScheme="teal">
                {filteredData.length}
              </Badge>
            )}
          </Tab>
          {team.team_service_categories?.map((category) => (
            <Tab key={category.id} onClick={() => setCategoryFilter(category.id)}>
              {category.name}
              {categoryFilter === category.name && (
                <Badge ml={2} colorScheme="teal">
                  {filteredData.length}
                </Badge>
              )}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <TabPanel>
            <Skeleton isLoaded={!isLoading}>
              <Box overflowX="auto">
                <Collection
                  columns={columns}
                  isLoading={isLoading}
                  items={filteredData}
                  pagination={data?.pagination || pagination}
                  onPagination={onPagination}
                  order={order}
                  onOrder={onOrder}
                />
              </Box>
            </Skeleton>
          </TabPanel>
          {team.team_service_categories?.map((category) => (
            <TabPanel key={category.id}>
              <Skeleton isLoaded={!isLoading}>
                <Box overflowX="auto">
                  {filteredData.length ? (
                    <Collection
                      columns={columns}
                      isLoading={isLoading}
                      items={filteredData}
                      pagination={data?.pagination || pagination}
                      onPagination={onPagination}
                      order={order}
                      onOrder={onOrder}
                    />
                  ) : (
                    <EmptyState title="No services found under this category" size="2xs" />
                  )}
                </Box>
              </Skeleton>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Stack>
  );
};

type ServiceRequestItemProps = {
  service: ServicePartialData;
  onServiceOpen: () => void;
  setRequestModal: React.Dispatch<React.SetStateAction<ServicePartialData | null>>;
  location: string;
  archived: boolean;
  setServiceModal?: React.Dispatch<React.SetStateAction<ServicePartialData | null>>;
  onNewServiceOpen?: () => void;
  editable?: boolean;
  onDeleteOpen?: () => void;
  onArchiveOpen?: () => void;
  onOpenDraft?: () => void;
};

export const ServiceItem = ({
  service,
  onServiceOpen,
  onDeleteOpen,
  location,
  archived,
  setServiceModal,
  setRequestModal,
  onNewServiceOpen,
  onOpenDraft,
  editable,
  onArchiveOpen,
}: ServiceRequestItemProps) => {
  const toast = useToast();
  const { mutateAsync: updateService } = useUpdateService();
  const navigate = useNavigate();

  const handleRequestOpen = () => {
    if (service.status === "draft") {
      toast({
        title: "Draft services cannot be fulfilled.",
        status: "error",
        duration: 2000,
      });
    } else if (archived) {
      toast({
        title: "Archived services cannot be fulfilled.",
        status: "error",
        duration: 2000,
      });
    } else if (service.external_url) {
      window.open(service.external_url, "_blank");
    } else {
      setRequestModal(service);
      onServiceOpen();
    }
  };

  return (
    <GridItem colSpan={1} width="100%">
      <Card
        as={Button}
        p={0}
        width="100%"
        height="100%"
        variant="outline"
        bg={useColorModeValue("gray.50", "gray.700")}
        onClick={handleRequestOpen}>
        <CardBody width="100%" p={5}>
          <HStack alignItems="center" justify="space-between" height="100%">
            <VStack
              height="100%"
              width="90%"
              alignItems="flex-start"
              justifyContent={"space-between"}
              textAlign="left">
              <Heading size="sm" w="100%" whiteSpace="normal">
                {service.name || "No name"}
              </Heading>
              <Text
                isTruncated
                fontSize="sm"
                fontWeight="medium"
                opacity="0.7"
                whiteSpace="normal"
                noOfLines={2}>
                {service.description || "No description"}
              </Text>
              {editable && (
                <Tag key={service.id} colorScheme={ServiceStatus(service.status).colorScheme}>
                  {humanize(service.status)}
                </Tag>
              )}
              {location === "services" && (
                <>
                  <Divider my={2} />
                  <Tag variant="outline" colorScheme="gray">
                    {service.team.name}
                  </Tag>
                </>
              )}
            </VStack>
            {editable && onDeleteOpen && onNewServiceOpen ? (
              <Menu placement="bottom-end">
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<BsThreeDotsVertical size={20} />}
                  size="sm"
                  variant="ghost"
                  onClick={(e) => e.stopPropagation()}
                />
                <MenuList alignItems="center">
                  {!archived ? (
                    <>
                      <MenuItem
                        icon={<EditIcon />}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (service.form.draft_template) {
                            setServiceModal && setServiceModal(service);
                            onOpenDraft && onOpenDraft();
                          } else {
                            navigate(`/teams/${service.team_id}/services/${service.id}`);
                          }
                        }}>
                        Edit
                      </MenuItem>
                      {service.service_request_count || service.status === "active" ? (
                        <MenuItem
                          icon={<FiArchive />}
                          onClick={(e) => {
                            e.stopPropagation();
                            updateService({ id: service.id, status: "archived" }).then(
                              onArchiveOpen
                            );
                          }}>
                          Archive
                        </MenuItem>
                      ) : null}
                      {!service.service_request_count && service.status !== "active" && (
                        <MenuItem
                          opacity="1"
                          icon={<DeleteIcon color="red.500" />}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (setServiceModal) setServiceModal(service);
                            onDeleteOpen();
                          }}>
                          Delete
                        </MenuItem>
                      )}
                    </>
                  ) : (
                    <MenuItem
                      icon={<FaUndo />}
                      isDisabled={false}
                      onClick={(e) => {
                        e.stopPropagation();
                        updateService({ id: service.id, status: "active" });
                      }}>
                      Unarchive
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            ) : service.external_url ? (
              <ExternalLinkIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </HStack>
        </CardBody>
      </Card>
    </GridItem>
  );
};

import { ArrowForwardIcon, EditIcon, InfoIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Radio,
  RadioGroup,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FormModal, FormModalProps } from "@sciencecorp/helix-components";
import { default as React, useState } from "react";
import { useMostRecentExchangeRate } from "../api/exchange_rate";
import { Money } from "../helpers/Money";
import { CurrencySelect } from "./CurrencySelect";
import { MoneyText } from "./MoneyText";
import { set } from "zod";

interface ExchangeRateExplainerProps {
  to?: string;
  total: Money;
  showHelper?: boolean;
}

const ExchangeRateExplainer: React.FC<ExchangeRateExplainerProps> = ({
  to,
  total,
  showHelper = false,
}) => {
  const exchangeRate = useMostRecentExchangeRate(total.currency.code, to);
  if (to === undefined) {
    return null;
  } else if (exchangeRate.isLoading) {
    return <Spinner />;
  } else if (exchangeRate.isSuccess) {
    const fxRate = exchangeRate.data;
    return showHelper ? (
      <VStack width={"100%"} align="center" gap={4} textAlign="center">
        <VStack spacing={4} width="100%" fontSize="sm">
          <HStack width="100%" justify="space-between">
            <Text fontWeight="semibold" textTransform="uppercase">
              Before
            </Text>
            <MoneyText as={"span"} money={total} formatOptions={{ compact: "never" }} />
          </HStack>
          <Divider />
          <HStack width="100%" justify="space-between">
            <Text fontWeight="semibold" textTransform="uppercase">
              after conversion
            </Text>
            <MoneyText
              as={"span"}
              money={total.convert(fxRate)}
              formatOptions={{ compact: "never" }}
            />
          </HStack>
        </VStack>
      </VStack>
    ) : null;
  } else {
    return (
      <Alert status="error">
        <AlertIcon />
        Unable to get the most recent exchange rate from {total.currency.code} to {to}.
      </Alert>
    );
  }
};

export interface UpdateCurrencyButtonProps
  extends Omit<
    FormModalProps,
    "title" | "isOpen" | "onOpen" | "onClose" | "children" | "handleSubmit"
  > {
  total: Money;
  model?: string;
  title?: string;
  previousCurrency: string;
  isOpen?: boolean;
  onClose?: () => void;
  handleSubmit: (newCurrency: string, convertValues: boolean, onClose: () => void) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export const UpdateCurrencyButton: React.FC<UpdateCurrencyButtonProps> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formModalProps: Omit<FormModalProps, "children" | "handleSubmit"> = {
    title: "Update Currency",
    size: "xl",
    submitButtonTitle: "Confirm",
    submitButtonColorSchema: "teal",
    isOpen,
    onClose,
    ...props,
  };
  const [newCurrency, setNewCurrency] = useState<string | undefined>();
  const [convertValues, setConvertValues] = useState<boolean>(false);
  return (
    <>
      <FormModal
        {...formModalProps}
        closeOnOverlayClick={false}
        submitButtonDisabled={!newCurrency}
        isLoading={props.isLoading}
        handleSubmit={() => {
          if (newCurrency) {
            props.handleSubmit(newCurrency, convertValues, () => {
              setNewCurrency(undefined);
              setConvertValues(false);
              onClose();
            });
          }
        }}>
        <VStack width={"100%"} spacing={4}>
          <Text align="start">
            When updating the currency, you can choose to either recalculate all values using the
            estimated exchange rate or keep the existing values as they are. Select your preferred
            option below.
          </Text>

          <FormControl>
            <FormLabel>New Currency</FormLabel>
            <CurrencySelect
              value={newCurrency}
              excludedCurrencies={[props.total.currency.code]}
              onChange={(value) => {
                setNewCurrency(value);
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Conversion Option</FormLabel>
            <RadioGroup
              colorScheme="teal"
              onChange={(value) => setConvertValues(value === "convert")}
              value={convertValues ? "convert" : "keep"}>
              <Flex direction="column" gap={2}>
                <Radio value="keep">Keep existing values as is</Radio>
                <Radio value="convert">Recalculate values using the estimated exchange rate</Radio>
              </Flex>
            </RadioGroup>
          </FormControl>
          <ExchangeRateExplainer to={newCurrency} total={props.total} showHelper={convertValues} />
        </VStack>
      </FormModal>
      <HStack>
        <Text fontWeight={"medium"}>{props.total.currency.code}</Text>
        <IconButton
          isDisabled={props.isDisabled || false}
          size={"xs"}
          onClick={() => onOpen()}
          aria-label="Update Currency"
          icon={<EditIcon />}
        />
      </HStack>
    </>
  );
};

import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Flex,
  HStack,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ConfirmationButton } from "@sciencecorp/helix-components";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
  PurchaseShowData,
  closedStatuses,
  invalidatePurchases,
  useGetPurchaseLineItems,
  useGetPurchaseReturns,
} from "../../../../api/purchase";
import {
  PurchaseLineItemCreateParams,
  PurchaseLineItemData,
  useBulkCreatePurchaseLineItems,
  useCreatePurchaseLineItem,
  useDeletePurchaseLineItem,
  useUpdatePurchaseLineItem,
} from "../../../../api/purchase_line_item";
import { useCurrentUserQuery, userHasRole } from "../../../../api/user";
import { useCurrency } from "../../../../contexts/CurrencyContext";
import { Money } from "../../../../helpers/Money";
import { MoneyText } from "../../../MoneyText";
import { PurchaseLineItemTable } from "./PurchaseLineItemTable";
import { InventoryItemFromPurchaseLineItemModal } from "./RestockInventoryModal/InventoryItemFromPurchaseLineItem";
import { isShippingOrTaxOrDiscount } from "../util";
export interface CreatePurchaseLineItemListHook {
  lineItems: PurchaseLineItemEditData[];
  setLineItems: React.Dispatch<React.SetStateAction<PurchaseLineItemEditData[]>>;
  isValid: boolean;
}
export const useCreatePurchaseLineItemList = (): CreatePurchaseLineItemListHook => {
  const [lineItems, setLineItems] = useState<PurchaseLineItemEditData[]>([]);
  const isValid = useMemo(() => {
    let valid = lineItems.length > 0;
    lineItems.forEach((item) => {
      valid = Boolean(
        valid &&
          item.item_name &&
          item.item_name.length > 0 &&
          item.unit_amount != undefined &&
          item.unit_amount != null &&
          item.unit_amount.gte(Money.zero(item.unit_amount.currency)) &&
          item.quantity != undefined &&
          item.quantity != null &&
          item.quantity > 0
      );
    });
    return valid;
  }, [lineItems]);
  return {
    lineItems,
    setLineItems,
    isValid,
  };
};

type EditPurchaseLineItemProps = {
  purchase: PurchaseShowData;
};
export const EditPurchaseLineItemList = ({ purchase }: EditPurchaseLineItemProps) => {
  const lineItemsQuery = useGetPurchaseLineItems(purchase.id);
  const userQuery = useCurrentUserQuery();
  const invalidatePurchasesFn = invalidatePurchases(purchase.id);
  const isPurchasingAdmin = userHasRole(userQuery, "purchasing_admin");
  const isInventoryManager = userHasRole(userQuery, "inventory_manager");

  const { mutate: createPurchaseLineItem } = useCreatePurchaseLineItem(() => {
    return invalidatePurchasesFn;
  });
  const { mutate: updatePurchaseLineItem } = useUpdatePurchaseLineItem(purchase.id);
  const { mutate: deletePurchaseLineItem } = useDeletePurchaseLineItem(purchase.id);

  if (userQuery.isSuccess && lineItemsQuery.isSuccess) {
    return (
      <PurchaseLineItemCard
        mode="edit"
        purchase={purchase}
        purchaseId={purchase.id}
        purchaseStatus={purchase.status}
        lineItems={lineItemsQuery.data.map((item) => {
          return { purchase_id: purchase.id, ...item };
        })}
        isPurchasingAdmin={isPurchasingAdmin || false}
        isInventoryManager={isInventoryManager || false}
        createLineItem={createPurchaseLineItem}
        updateLineItem={updatePurchaseLineItem}
        deleteLineItem={deletePurchaseLineItem}
      />
    );
  } else if (userQuery.isLoading || lineItemsQuery.isLoading) {
    return <Text>Loading...</Text>;
  } else {
    return <Text>Error loading user data</Text>;
  }
};

export type PurchaseLineItemEditData = PurchaseLineItemData & { has_inventory_items?: boolean };

type CreatePurchaseLineItemProps = {
  lineItems: PurchaseLineItemEditData[];
  setLineItems: React.Dispatch<React.SetStateAction<PurchaseLineItemEditData[]>>;
  approvedAmount?: Money;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit?: () => void;
  purchaseId?: number;
  isLoading?: boolean;
};
export const CreatePurchaseLineItemList = ({
  lineItems,
  setLineItems,
  setEditMode,
  approvedAmount,
  handleSubmit,
  purchaseId,
  isLoading,
}: CreatePurchaseLineItemProps) => {
  const [createdLineItems, setCreatedLineItems] = useState<number>(0);
  const currency = useCurrency();
  const createLineItem = () => {
    setCreatedLineItems((prev) => prev + 1);
    setLineItems((prev) => [
      ...prev,
      {
        purchase_id: purchaseId || -1,
        id: -10000 + createdLineItems, // Hack to make sure the id is negative and in the expected order
        quantity: 1,
        item_name: "",
        accounting_category: null,
        unit_amount: Money.zero(currency),
        url: null,
        return_line_item_id: null,
        has_inventory_items: false,
        inventory_id: null,
      },
    ]);
  };

  const updateLineItem = (
    data: Partial<PurchaseLineItemData> & Pick<PurchaseLineItemData, "id">
  ) => {
    setLineItems((prev) => {
      return prev.map((item) => {
        if (item.id === data.id) {
          return {
            ...item,
            ...data,
          };
        } else {
          return item;
        }
      });
    });
  };

  const deleteLineItem = (id: number) => {
    setLineItems((prev) => {
      return prev.flatMap((item) => {
        if (item.id === id) {
          return [];
        } else {
          return [item];
        }
      });
    });
  };
  const currentTotal = useMemo(
    () => Money.sum(Money.zero(currency), ...lineItems.map((l) => l.unit_amount.times(l.quantity))),
    [lineItems]
  );

  return (
    <Flex direction="column" gap={4} w="100%">
      <PurchaseLineItemCard
        mode={"create"}
        purchaseId={purchaseId || -1}
        lineItems={lineItems}
        createLineItem={createLineItem}
        updateLineItem={updateLineItem}
        deleteLineItem={deleteLineItem}
      />
      {setEditMode && (
        <Flex direction="column">
          <Stack direction={["column", "row"]} fontSize="sm" justify="center">
            <HStack borderRight={["none", "1px solid"]} justify="center" pr={2}>
              <Text fontWeight="bold"> Approved Total:</Text>
              <MoneyText money={approvedAmount} />
            </HStack>
            <HStack borderRight={["none", "1px solid"]} justify="center" pr={2}>
              <Text fontWeight="bold">Current Total: </Text>
              <MoneyText money={currentTotal} />
            </HStack>
            <HStack justify="center">
              <Text fontWeight="bold">Remaining Balance:</Text>
              <MoneyText money={approvedAmount?.subtract(currentTotal)} />
            </HStack>
          </Stack>
          <ButtonGroup justifyContent="center" size="sm" py={4}>
            <Button onClick={() => setEditMode(false)} flexShrink={1}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              isDisabled={
                !currentTotal.subtract(approvedAmount || Money.zero(currentTotal)).isZero()
              }
              isLoading={isLoading}
              onClick={handleSubmit}
              flexShrink={1}>
              Save Changes
            </Button>
          </ButtonGroup>
        </Flex>
      )}
    </Flex>
  );
};

type BasePurchaseLineItemTableProps = {
  purchaseId: number;
  purchase?: PurchaseShowData;
  lineItems: PurchaseLineItemEditData[];
  createLineItem: (lineItem: PurchaseLineItemCreateParams) => void;
  updateLineItem: (data: Partial<PurchaseLineItemData> & Pick<PurchaseLineItemData, "id">) => void;
  deleteLineItem: (id: number) => void;
};

type PurchaseLineItemCardCreateProps = BasePurchaseLineItemTableProps & {
  mode: "create";
  isPurchasingAdmin?: undefined;
  isInventoryManager?: undefined;
  purchaseStatus?: undefined;
};

type PurchaseLineItemCardEditProps = BasePurchaseLineItemTableProps & {
  mode: "edit";
  purchase: PurchaseShowData;
  isPurchasingAdmin: boolean;
  purchaseStatus: string;
  isInventoryManager: boolean;
};

type PurchaseLineItemCardProps = PurchaseLineItemCardEditProps | PurchaseLineItemCardCreateProps;
const PurchaseLineItemCard = ({
  purchaseId,
  mode,
  lineItems,
  createLineItem,
  purchase,
  updateLineItem: inputUpdateLineItem,
  deleteLineItem,
  isPurchasingAdmin,
  purchaseStatus,
}: PurchaseLineItemCardProps) => {
  const currency = useCurrency();
  const { mutate: bulkCreatePurchaseLineItems, isLoading: isLoadingBulk } =
    useBulkCreatePurchaseLineItems(purchaseId);

  const baseTableDisabled =
    purchase?.is_deleted ||
    (mode == "edit" && !isPurchasingAdmin && closedStatuses.includes(purchaseStatus || "NONE"));

  const { data: purchaseReturn } = useGetPurchaseReturns(purchaseId);

  // Ensure we maintain original sorting order but keep Shipping Cost and Tax at the bottom
  const sortedLineItems = _.sortBy(lineItems, (l) => {
    if (l.item_name === "Shipping" || l.item_name === "Shipping Cost") {
      return Number.MAX_SAFE_INTEGER - 1;
    } else if (l.item_name === "Tax") {
      return Number.MAX_SAFE_INTEGER - 2;
    } else if (l.item_name === "Discounts" || l.item_name === "Discount") {
      return Number.MAX_SAFE_INTEGER;
    } else {
      return l.id;
    }
  });

  const borderColor = useColorModeValue("gray.100", "gray.600");
  const bg = useColorModeValue("gray.50", "gray.800");
  const [editMode, setEditMode] = useState(false);
  const editableLineItems = useMemo(
    () =>
      lineItems.filter(
        (l) => isShippingOrTaxOrDiscount.includes(l.item_name) && !!!l.has_inventory_items
      ),
    [lineItems]
  );

  const [lineItemState, setLineItemState] = useState<PurchaseLineItemEditData[]>(editableLineItems);

  const approvedCost = purchase?.line_items_sum;

  useEffect(() => {
    setLineItemState(editableLineItems);
  }, [editMode]);

  const handleSubmit = () => {
    bulkCreatePurchaseLineItems(
      {
        purchase_line_item: lineItemState,
      },
      { onSuccess: () => setEditMode(false) }
    );
  };

  return !editMode ? (
    <Card width={"100%"} bg={bg} variant={"outline"}>
      <Flex
        p={4}
        direction={["column", "row"]}
        justify="space-between"
        align={{ base: "start", md: "center" }}
        width="100%"
        gap={2}
        borderBottom={1}
        borderColor={borderColor}
        borderStyle={"solid"}>
        {purchase?.status === "delivered" ? (
          <Text fontWeight="semibold">
            Items Purchased <Badge ml={2}>{lineItems?.length}</Badge>
          </Text>
        ) : (
          <Text fontWeight="semibold">
            Items to Buy <Badge ml={2}>{lineItems?.length}</Badge>
          </Text>
        )}
        {purchase && purchase.delivered_at !== null && (
          <InventoryItemFromPurchaseLineItemModal purchase={purchase} />
        )}
      </Flex>
      <Flex p={4} flexDirection="column" width="100%" gap={4}>
        <PurchaseLineItemTable
          purchase={purchase}
          lineItems={sortedLineItems}
          createLineItem={createLineItem}
          updateLineItem={inputUpdateLineItem}
          deleteLineItem={deleteLineItem}
          isPurchasingAdmin={isPurchasingAdmin}
          mode={mode}
        />

        <Flex alignItems={"center"} justifyContent={"center"} width={"100%"}>
          {!baseTableDisabled ? (
            <Button
              size="sm"
              isDisabled={baseTableDisabled}
              leftIcon={<AddIcon />}
              onClick={() => createLineItem({ purchase_id: purchaseId })}
              flexShrink={1}>
              New Item
            </Button>
          ) : (
            purchaseReturn?.length === 0 && (
              <ConfirmationButton
                size="sm"
                variant="Button"
                leftIcon={<EditIcon />}
                aria-label="Edit Line Items"
                icon={<DeleteIcon />}
                label="Edit Itemization"
                confirmationButtonLabel="Confirm"
                colorScheme="teal"
                buttonVariant="outline"
                onConfirm={() => setEditMode(true)}>
                Do you want to edit the line items for this purchase? The sum of all items must be
                equal to the approved amount of the purchase.
              </ConfirmationButton>
            )
          )}
        </Flex>
      </Flex>
    </Card>
  ) : (
    <CreatePurchaseLineItemList
      lineItems={lineItemState}
      setLineItems={setLineItemState}
      setEditMode={setEditMode}
      purchaseId={purchaseId}
      approvedAmount={approvedCost}
      handleSubmit={handleSubmit}
      isLoading={isLoadingBulk}
    />
  );
};

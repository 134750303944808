import {
  Flex,
  Stack,
  Heading,
  Switch,
  IconButton,
  Box,
  Text,
  EditableInput,
} from "@chakra-ui/react";
import { EditableText } from "@sciencecorp/helix-components";
import React, { useState } from "react";
import { ServicePartialData, useUpdateService } from "../../../../api/services";

export type ExternalURLProps = {
  service: ServicePartialData;
  isExternalURL: boolean;
  setIsExternalURL: (value: boolean) => void;
};

export const ExternalURL = ({ service, isExternalURL, setIsExternalURL }: ExternalURLProps) => {
  const { mutateAsync: updateService, isLoading: isUpdatingService } = useUpdateService();

  return (
    <Box w={"100%"} border="1px" borderColor="chakra-border-color" p={6} gap={2} borderRadius="md">
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <Stack width="100%">
          <Heading size="md">External URL</Heading>
          <Stack direction={"row"} gap={2} justifyContent={"space-between"}>
            <Text textColor="gray.500">
              If you have an external page that you would like the users to redirect to, enter the
              URL here.
            </Text>
            <Switch
              colorScheme="teal"
              isChecked={isExternalURL}
              onChange={() => setIsExternalURL(!isExternalURL)}
            />
          </Stack>
          {isExternalURL && (
            <Box
              width="100%"
              border="1px"
              borderColor="chakra-border-color"
              p={2}
              borderRadius="md">
              <EditableText
                isLoading={isUpdatingService}
                defaultValue={service?.external_url || ""}
                placeholder="Enter External URL"
                onSubmit={(value) => {
                  if (value) updateService({ external_url: value, id: Number(service.id) });
                }}
              />
            </Box>
          )}
        </Stack>
      </Flex>
    </Box>
  );
};

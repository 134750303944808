import { RecordLink } from "@sciencecorp/helix-components";
import React from "react";
import { ServiceInventoryBomData } from "../../../../api/service_inventory_bom";
import { humanize } from "inflection";
import { Box, Stack, IconButton } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { BsReverseLayoutTextSidebarReverse } from "react-icons/bs";
import { ServiceRequestInventoryBomData } from "../../../../api/service_request_inventory_boms";

export type BomLineItemProps = {
  bom: ServiceRequestInventoryBomData | ServiceInventoryBomData;
  deleteBom: (id: number) => Promise<void>;
  isLoadingDeleteBom: boolean;
  invalidateBom: () => Promise<void>;
};

export const BomLineItem = ({
  bom,
  deleteBom,
  isLoadingDeleteBom,
  invalidateBom,
}: BomLineItemProps) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
      <Box width="100%">
        <RecordLink
          identifier={humanize(bom.inventory_bom_name)}
          type={"Inventory Bom"}
          link={`/inventory/boms/${bom.inventory_bom_id}`}
          icon={<BsReverseLayoutTextSidebarReverse />}
          iconPlacement="left"
        />
      </Box>
      <IconButton
        icon={<DeleteIcon />}
        colorScheme="red"
        variant="outline"
        aria-label="Delete"
        size="sm"
        onClick={() => deleteBom(bom.id).then(invalidateBom)}
        isLoading={isLoadingDeleteBom}
      />
    </Stack>
  );
};

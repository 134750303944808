import {
  Alert,
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  Tooltip,
  VStack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { CollectionTable, Column, EmptyState, RecordLink } from "@sciencecorp/helix-components";
import React, { useMemo } from "react";
import {
  CapitalEquipmentShowData,
  useSearchCapitalEquipments,
} from "../../../../api/capital_equipment";
import { TeamShowData } from "../../../../api/team";
import { useCurrency } from "../../../../contexts/CurrencyContext";
import { Money } from "../../../../helpers/Money";
import { NewCapitalEquipment } from "../../../CapitalEquipments/components/NewCapitalEquipmentModal";
import { MoneyText } from "../../../MoneyText";
import { AddIcon } from "@chakra-ui/icons";

interface TeamEquipmentCardProps {
  team: TeamShowData;
}
export const TeamEquipmentCard = ({ team }: TeamEquipmentCardProps): JSX.Element => {
  const currency = useCurrency();
  const { data, isLoading, isSuccess } = useSearchCapitalEquipments({
    term: "*",
    filters: { team_id: [team.id, ...team.all_sub_team_ids], is_archived: false },
    pagination: { per_page: -1 },
    order: { name: "asc" },
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const statBgColor = useColorModeValue("gray.50", "gray.700");
  const statBorderColor = useColorModeValue("gray.200", "gray.600");

  const columns: Column<CapitalEquipmentShowData>[] = useMemo(
    () => [
      {
        label: "Equipment",
        weight: 3,
        render: (equipment: CapitalEquipmentShowData) => (
          <Tooltip label={equipment.name}>
            <Box w={"100%"}>
              <RecordLink link={`/equipment/${equipment.id}/details`} identifier={equipment.name} />
            </Box>
          </Tooltip>
        ),
      },
      {
        label: "Team",
        weight: 3,
        render: (equipment) => (
          <RecordLink
            type={"Team"}
            link={`/teams/${equipment.team?.id}/budget`}
            identifier={equipment.team?.name || ""}
          />
        ),
      },
      {
        label: "Spent",
        weight: 3,
        render: (equipment) => (
          <MoneyText money={equipment.summary?.spent_amount} formatOptions={{ compact: "never" }} />
        ),
      },
    ],
    []
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (isSuccess) {
    const equipment = data.results;

    const allTeamEquipmentSpent: Money = Money.sum(
      Money.zero(currency),
      ...equipment.map((equip) => equip.summary?.spent_amount || Money.zero(currency))
    );

    return (
      <Box flex={1}>
        <NewCapitalEquipment teamId={team.id} isOpen={isOpen} onClose={onClose} />
        <VStack
          spacing={4}
          w={"100%"}
          border="1px"
          borderColor={useColorModeValue("gray.200", "gray.600")}
          p={6}
          borderRadius="md">
          <HStack w={"100%"} justifyContent={"space-between"}>
            <Heading size="md">Team Equipment</Heading>
            <Button leftIcon={<AddIcon />} onClick={onOpen} colorScheme="teal">
              Add New
            </Button>
          </HStack>
          <Divider />
          <HStack
            w={"100%"}
            justifyContent={"space-between"}
            bg={statBgColor}
            p={6}
            borderRadius={"md"}
            borderWidth={"1px"}
            borderColor={statBorderColor}>
            <Stat>
              <StatLabel>Total Affiliated Equipment Spent Amount</StatLabel>
              <StatNumber>
                <MoneyText money={allTeamEquipmentSpent} formatOptions={{ compact: "never" }} />
              </StatNumber>
            </Stat>
          </HStack>

          {equipment.length > 0 ? (
            <CollectionTable items={equipment} columns={columns} />
          ) : (
            <EmptyState size="sm" title={`This team is not affiliated with any equipment.`} />
          )}
        </VStack>
      </Box>
    );
  } else {
    return <Alert status="error">Failed to load equipment</Alert>;
  }
};

import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Input,
  Stack,
  Switch,
  Flex,
  Tag,
  Text,
  Textarea,
  VStack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { FormModal, Option } from "@sciencecorp/helix-components";
import { Select } from "chakra-react-select";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import type { ServicePartialData } from "../../../api/services";
import { useCreateService, useUpdateService } from "../../../api/services";
import { TeamData } from "../../../api/team";

type TeamServiceModalProps = {
  isOpen: boolean;
  onClose: () => void;
  team: TeamData;
  service?: ServicePartialData;
  setActiveService?: React.Dispatch<React.SetStateAction<ServicePartialData | null>>;
};

const defaultServiceValues = {
  name: "",
  description: "",
  support_text: "",
  spending_authority: null,
  external_url: "",
  spending_authority_required: false,
};

// making new services
export const TeamServiceModal = ({
  isOpen,
  onClose,
  team,
  service,
  setActiveService,
}: TeamServiceModalProps) => {
  const { mutate: createService } = useCreateService();
  const { mutate: updateService } = useUpdateService();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: defaultServiceValues,
  });

  useEffect(() => {
    if (service) {
      reset({
        name: service.name,
        description: service.description,
        support_text: service.support_text,
        external_url: service.external_url,
        spending_authority_required: service.spending_authority_required,
      });
    }
  }, [service, reset]);

  const handleClose = () => {
    onClose();
    reset(defaultServiceValues);
    setActiveService && setActiveService(null);
  };

  const onSubmit = (data) => {
    if (service) {
      updateService(
        {
          id: service.id,
          ...data,
        },
        {
          onSuccess: () => {
            handleClose();
          },
        }
      );
    } else {
      createService(
        { ...data, team_id: team.id },
        {
          onSuccess: () => {
            handleClose();
          },
        }
      );
    }
  };
  return (
    <FormModal
      title={service ? "Edit Service" : "Create a New Service"}
      isOpen={isOpen}
      onClose={handleClose}
      size="lg"
      submitButtonColorSchema="teal"
      submitButtonTitle={service ? "Done" : "Add Service"}
      handleSubmit={handleSubmit(onSubmit)}>
      <VStack width="100%" gap={4} px={2}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Service Name</FormLabel>
              <Input {...field} placeholder="e.g Facility Repairs" />
            </FormControl>
          )}
        />

        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Service Description</FormLabel>
              <Textarea size="sm" {...field} placeholder="Type Here..." />
            </FormControl>
          )}
        />
        <Controller
          name="support_text"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <FormLabel>Instructions to Requestor</FormLabel>
              <Textarea {...field} size="sm" placeholder="Type Here..." />
              <FormHelperText>
                Describe the information users need to provide when submitting a request.
              </FormHelperText>
            </FormControl>
          )}
        />
        <Controller
          name="spending_authority_required"
          control={control}
          render={({ field }) => (
            <FormControl isRequired>
              <Flex
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center">
                <Flex direction="column" gap={0}>
                  <FormLabel mb={0}>Spending Authority Required</FormLabel>
                  <FormHelperText mt={1}>
                    Toggling this on will require the user to set a spend authority when submitting
                    a request.
                  </FormHelperText>
                </Flex>
                <Switch
                  colorScheme="teal"
                  isChecked={field.value === true}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              </Flex>
            </FormControl>
          )}
        />
        <Controller
          name="external_url"
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>External URL</FormLabel>
              <Input {...field} placeholder="https://science.xyz/foundry" />
              <FormHelperText>
                If you have an external page that you would like users to redirect to, paste the
                link here.
              </FormHelperText>
            </FormControl>
          )}
        />
      </VStack>
    </FormModal>
  );
};

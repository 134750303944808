import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  VStack,
  HStack,
  IconButton,
  Badge,
  useColorModeValue,
  Icon,
  useDisclosure,
  Tooltip,
  Center,
} from "@chakra-ui/react";
import { AddIcon, ChevronDownIcon, ChevronUpIcon, DeleteIcon } from "@chakra-ui/icons";
import { BsGrid3X3 } from "react-icons/bs";
import { EditableText } from "@sciencecorp/helix-components";
import {
  useCreateServiceSubtask,
  useDeleteServiceSubtask,
  useReorderServiceSubtask,
  useUpdateServiceSubtask,
} from "../../../../api/service_subtasks";
import { ServicePartialData } from "../../../../api/services";
import { PreviewUserSelect } from "../../../Users/shared/UserSelect";
import {
  useBulkCreateServiceSubtaskUser,
  useDeleteServiceSubtaskUser,
} from "../../../../api/service_subtask_user";
import { AddUserToSubtaskModal } from "./AddUserToSubtask";
import { useDebounced } from "../../../hooks/useDebouncedUpdate";
import { DebouncedFunc } from "lodash";

type SubtasksProps = {
  isServiceCreation: boolean;
  service: ServicePartialData;
};

export const Subtasks = ({ isServiceCreation, service }: SubtasksProps) => {
  const { mutateAsync: createServiceSubtask, isLoading: isCreatingServiceSubtask } =
    useCreateServiceSubtask(service.id);
  const { mutateAsync: updateServiceSubtask, isLoading: isUpdatingServiceSubtask } =
    useUpdateServiceSubtask(service.id);
  const { mutateAsync: deleteServiceSubtask, isLoading: isDeletingServiceSubtask } =
    useDeleteServiceSubtask(service.id);
  const [subtaskId, setSubtaskId] = useState<number | null>(null);
  const { mutateAsync: reorderServiceSubtask, isLoading: isReorderingServiceSubtask } =
    useReorderServiceSubtask(service.id);
  const reorderSubtask: DebouncedFunc<typeof reorderServiceSubtask> = useDebounced(
    reorderServiceSubtask,
    [service.id],
    1000
  );

  const handleAddSubtask = () => {
    createServiceSubtask({
      service_id: service.id,
      name: `Subtask ${service.service_subtasks.length + 1}`,
      description: null,
      order: service.service_subtasks.length + 1,
    });
  };

  const handleDeleteSubtask = (id: number) => {
    deleteServiceSubtask(id);
  };

  const handleUpArrowSubtask = (id: number) => {
    if (!service.service_subtasks.length) return;
    const index = service.service_subtasks.findIndex((task) => task.id === id);
    if (index <= 0) return;
    const newSubtasks = [...service.service_subtasks];
    [newSubtasks[index], newSubtasks[index - 1]] = [newSubtasks[index - 1], newSubtasks[index]];
    reorderSubtask({ id, order: newSubtasks.map((task) => task.id) });
  };

  const handleDownArrowSubtask = (id: number) => {
    if (!service.service_subtasks.length) return;
    const index = service.service_subtasks.findIndex((task) => task.id === id);
    if (index === -1 || index === service.service_subtasks.length - 1) return;
    const newSubtasks = [...service.service_subtasks];
    [newSubtasks[index], newSubtasks[index + 1]] = [newSubtasks[index + 1], newSubtasks[index]];
    reorderSubtask({ id, order: newSubtasks.map((task) => task.id) });
  };

  const {
    isOpen: isOpenAssignEmployeesModal,
    onOpen: onOpenAssignEmployeesModal,
    onClose: onCloseAssignEmployeesModal,
  } = useDisclosure();
  const { mutateAsync: bulkCreateServiceSubtaskUser, isLoading: isCreatingServiceSubtaskUser } =
    useBulkCreateServiceSubtaskUser(service.id);
  const { mutateAsync: deleteServiceSubtaskUser, isLoading: isDeletingServiceSubtaskUser } =
    useDeleteServiceSubtaskUser(service.id);
  return (
    <Box
      w={"100%"}
      border="1px"
      borderColor={useColorModeValue("gray.200", "gray.600")}
      p={6}
      gap={4}
      borderRadius="md">
      {subtaskId && (
        <AddUserToSubtaskModal
          serviceSubtaskId={subtaskId}
          isOpen={isOpenAssignEmployeesModal}
          onClose={onCloseAssignEmployeesModal}
          serviceUsers={
            service.service_subtasks
              .find((subtask) => subtask.id === subtaskId)
              ?.service_subtask_users.map((user) => user.user) ?? []
          }
          onSubmit={bulkCreateServiceSubtaskUser}
          isLoading={isCreatingServiceSubtaskUser}
        />
      )}
      <Flex justify="space-between" alignItems="center" mb={4}>
        <Text fontWeight="bold" fontSize="lg">
          Subtasks
          <Badge marginLeft={2}>{service.service_subtasks.length}</Badge>
        </Text>
        {!isServiceCreation && (
          <Button
            onClick={handleAddSubtask}
            leftIcon={<AddIcon />}
            colorScheme="teal"
            variant="solid">
            Add Subtask
          </Button>
        )}
      </Flex>
      {isServiceCreation && service.service_subtasks.length > 0 && (
        <Text mt={-2} mb={4}>
          Add tasks that you want the assigned employee to complete when fulfilling the request
        </Text>
      )}

      {service.service_subtasks.length === 0 ? (
        <VStack spacing={4} align="center">
          <Icon as={BsGrid3X3} color="gray.100" boxSize={14} />
          <Text color="gray.500" textAlign="center" fontWeight="semibold" w="50%">
            Add tasks that you want the assigned employee to complete when fulfilling the request
          </Text>
          <Button
            onClick={handleAddSubtask}
            leftIcon={<AddIcon />}
            colorScheme="teal"
            variant="solid"
            isLoading={isCreatingServiceSubtask}>
            Add Subtasks
          </Button>
        </VStack>
      ) : (
        <VStack spacing={4} align="stretch">
          {service.service_subtasks.map((task, idx) => (
            <>
              <Box
                key={task.id}
                border="1px"
                p={4}
                borderRadius="md"
                borderColor={"chakra-border-color"}>
                <Flex justify="space-between" align="center">
                  <VStack align="start" width="30%">
                    <EditableText
                      isLoading={isUpdatingServiceSubtask}
                      defaultValue={task?.name || ""}
                      onSubmit={(value) => {
                        if (value) {
                          updateServiceSubtask({
                            id: task.id,
                            name: value,
                          });
                        }
                      }}
                    />
                    <EditableText
                      isLoading={isUpdatingServiceSubtask}
                      preview={<Text>{task.description ?? "Enter short description"}</Text>}
                      defaultValue={task.description ?? ""}
                      onSubmit={(value) => {
                        if (value) {
                          updateServiceSubtask({
                            id: task.id,
                            description: value,
                          });
                        }
                      }}
                    />
                  </VStack>
                  <HStack spacing={2} width="30%">
                    <IconButton
                      aria-label="Move Up"
                      isDisabled={idx === 0 || isReorderingServiceSubtask}
                      icon={<ChevronUpIcon />}
                      variant="ghost"
                      onClick={() => handleUpArrowSubtask(task.id)}
                    />
                    <IconButton
                      aria-label="Move Down"
                      isDisabled={
                        idx === service.service_subtasks.length - 1 || isReorderingServiceSubtask
                      }
                      icon={<ChevronDownIcon />}
                      variant="ghost"
                      onClick={() => handleDownArrowSubtask(task.id)}
                    />
                    <IconButton
                      aria-label="Delete Subtask"
                      icon={<DeleteIcon />}
                      size="sm"
                      colorScheme="red"
                      isLoading={isDeletingServiceSubtask}
                      onClick={() => handleDeleteSubtask(task.id)}
                    />
                  </HStack>
                  <HStack spacing={2} width={"20%"}>
                    <PreviewUserSelect
                      handleDelete={deleteServiceSubtaskUser}
                      addedUsers={task.service_subtask_users}
                      additionalUsersTitle="Assignees"
                      isDisabled={false}
                      isLoading={isDeletingServiceSubtaskUser}
                    />
                    <Tooltip label="Add Assignee">
                      <IconButton
                        marginLeft={8}
                        icon={<AddIcon />}
                        aria-label="Add Assignee"
                        size="sm"
                        onClick={() => {
                          setSubtaskId(task.id);
                          onOpenAssignEmployeesModal();
                        }}
                      />
                    </Tooltip>
                  </HStack>
                </Flex>
              </Box>
            </>
          ))}

          <Center>
            <Box>
              <Button
                onClick={handleAddSubtask}
                colorScheme="gray"
                leftIcon={<AddIcon />}
                isLoading={isCreatingServiceSubtask}>
                Add New Subtask
              </Button>
            </Box>
          </Center>
        </VStack>
      )}
    </Box>
  );
};

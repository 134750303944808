import { Box, Flex, HStack, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { Option } from "@sciencecorp/helix-components";
import { pmt } from "financial";
import { humanize } from "inflection";
import React from "react";
import { PurchaseShowData, ShippingPriority } from "../../../api/purchase";
import { PurchaseLineItemData } from "../../../api/purchase_line_item";
import {
  PurchaseReturnLineItemsData,
  PurchaseReturnsShowData,
} from "../../../api/purchase_returns";
import { Money } from "../../../helpers/Money";

export const paymentFrequencyOptions = [
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
];

export const isShippingOrTaxOrDiscount = [
  "Shipping",
  "Shipping Cost",
  "Tax",
  "Discounts",
  "Discount",
  "shipping",
  "shipping cost",
  "tax",
  "discount",
  "discounts",
];

export const interestRateOptions = [
  { label: "Federal Discount Rate", value: "5.5" },
  { label: "Fed Funds Rate", value: "5.33" },
  { label: "WSJ Prime Rate", value: "8.5" },
];

export const endOfLeaseOptions = [
  { label: "Fair Market Value", value: "fair_market_value" },
  { label: "One Dollar", value: "one_dollar" },
];

export type ShippingPriorityOption = Option & { label: string; value: ShippingPriority };
export const shippingPriorityOptions: ShippingPriorityOption[] = [
  { label: "N/A", value: "n/a" },
  { label: "Standard", value: "standard" },
  { label: "Expedited", value: "expedited" },
  { label: "Overnight/ASAP", value: "overnight" },
];
export const isShippingPriority = (value: any): value is ShippingPriority => {
  return ["n/a", "standard", "expedited", "overnight"].includes(value);
};

export const calculatePayment = (
  principal: Money,
  annualInterestRate: number,
  paymentTerm: number,
  paymentFrequency
): Money => {
  const interestRate = annualInterestRate / 100;
  const totalPayments = paymentFrequency === "weekly" ? paymentTerm : paymentTerm / 4.33;

  const weeklyInterestRate = interestRate / 52;
  const monthlyInterestRate = interestRate / 12;

  const payment = Money.fromMinorUnits(
    pmt(
      paymentFrequency === "weekly" ? weeklyInterestRate : monthlyInterestRate,
      totalPayments,
      principal.times(-1).cents.toNumber()
    ),
    principal.currency
  );
  return payment;
};

// Function to calculate total interest payable
export const calculateTotalInterest = (
  baseAmount: Money,
  advancePayment: Money,
  additionalFees: Money,
  payAdditionalFeesUpfront: boolean,
  annualInterestRate: number,
  paymentTerm: number,
  paymentFrequency: string
): { monthlyPayment: Money; totalInterest: Money; totalAmount: Money } => {
  const principal = baseAmount.subtract(
    advancePayment.add(
      payAdditionalFeesUpfront ? additionalFees : Money.zero(additionalFees.currency)
    )
  );
  const monthlyPayment = calculatePayment(
    principal,
    annualInterestRate,
    paymentTerm,
    paymentFrequency
  );
  const totalPayments = paymentFrequency === "weekly" ? paymentTerm : paymentTerm / 4.33;

  const totalInterest = monthlyPayment.times(totalPayments).subtract(principal);
  const totalAmount = principal.add(totalInterest);
  return {
    monthlyPayment,
    totalInterest,
    totalAmount,
  };
};

const calculatePaymentVariableInterest = (
  principal: Money,
  frequencyMultiplier: number,
  interestRate: number,
  compoundingPeriodsPerYear: number,
  paymentTerm: number
): Money => {
  const totalPayments = paymentTerm * compoundingPeriodsPerYear * frequencyMultiplier;

  const payment = pmt(interestRate, totalPayments, principal.times(-1).cents.toNumber());

  return Money.fromMinorUnits(payment, principal.currency);
};

export const calculateTotalInterestVariableInterest = (
  baseAmount: Money,
  advancePayment: Money,
  additionalFees: Money,
  payAdditionalFeesUpfront: boolean,
  referenceRate: number,
  margin: number,
  compoundingPeriodsPerYear: number,
  paymentTerm: number,
  paymentFrequency: string
) => {
  const principal = baseAmount
    .subtract(advancePayment)
    .add(payAdditionalFeesUpfront ? additionalFees : Money.zero(baseAmount.currency));

  const frequencyMultiplier = paymentFrequency === "weekly" ? 52 : 12;

  const interestRate = (referenceRate + margin) / (compoundingPeriodsPerYear * frequencyMultiplier);
  const totalPayments = paymentTerm * compoundingPeriodsPerYear * frequencyMultiplier;

  const monthlyPayment = calculatePaymentVariableInterest(
    principal,
    frequencyMultiplier,
    interestRate,
    compoundingPeriodsPerYear,
    paymentTerm
  );

  const totalAmount = monthlyPayment.times(totalPayments);
  const totalInterest = totalAmount.subtract(principal);

  return {
    monthlyPayment: monthlyPayment,
    totalInterest: totalInterest,
    totalAmount: totalAmount,
  };
};

export const disableAddNewPayment = (purchase: PurchaseShowData) => {
  const paidInFull = purchase.line_items_sum.eq(purchase.purchase_payments_paid);
  const needsApproval =
    purchase.status === "needs_approval" ||
    purchase.status === "declined" ||
    purchase.status === "new";

  return paidInFull || needsApproval;
};

export const PaymentOption = ({ title, value, icon, background, onChange, selectedValue }) => (
  <HStack
    p={3}
    width="100%"
    bg={
      selectedValue === value
        ? useColorModeValue("teal.50", "teal.500")
        : useColorModeValue("gray.50", "gray.700")
    }
    justifyContent="space-between"
    border="1px"
    borderColor={
      selectedValue === value ? useColorModeValue("teal.200", "teal.400") : "chakra-border-color"
    }
    _hover={{ cursor: "pointer" }}
    borderRadius="md"
    onClick={() => onChange(selectedValue === value ? null : value)}>
    <HStack spacing={6}>
      <Flex bg={background} p={2} borderRadius="lg">
        <Icon as={icon} color={useColorModeValue("white", "gray.100")} />
      </Flex>
      <Text fontSize="xs" fontWeight="semibold">
        {title}
      </Text>
    </HStack>
    {selectedValue === value ? (
      <div></div>
    ) : (
      <Box border="2px" borderColor="chakra-border-color" borderRadius="full" boxSize={6} />
    )}
  </HStack>
);

export const formatReasonForReturn = (reason: string) => {
  return humanize(reason.replace(/_/g, " "));
};

export const refundStatusColor = (status: string) => {
  switch (status) {
    case "refunded":
      return "blue";
    case "in_progress":
      return "teal";
    case "submitted":
      return "gray";
    case "sold":
      return "blue";
  }
  return "gray";
};

export const updateLineItemsQuantity = (
  purchaseLineItems: PurchaseLineItemData[],
  returnedLineItems: PurchaseReturnLineItemsData[]
) => {
  const updatedPurchaseLineItems = purchaseLineItems.map((lineItem) => {
    const totalReturnedQuantity = returnedLineItems.reduce((total, returnedItem) => {
      if (returnedItem.purchase_line_item_id === lineItem.id) {
        return total + returnedItem.quantity;
      }
      return total;
    }, 0);

    const updatedQuantity = lineItem.quantity - totalReturnedQuantity;

    return {
      ...lineItem,
      quantity: updatedQuantity,
    };
  });

  return updatedPurchaseLineItems.filter((lineItem) => lineItem.quantity > 0);
};

export const updateReturnLineItems = (returns: PurchaseReturnsShowData[]) => {
  return returns.filter((purchaseReturn) => {
    return purchaseReturn.service_request_id !== null && !purchaseReturn.purchase_refund;
  });
};

import React, { useMemo, useState } from "react";
import {
  useCollection,
  Collection,
  Facets,
  RecordLink,
  buildFacets,
  ConfirmationModal,
  ConfirmationButton,
  Searchbar,
} from "@sciencecorp/helix-components";
import {
  VStack,
  Button,
  Tag,
  HStack,
  useDisclosure,
  Heading,
  Badge,
  Box,
  Text,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Icon,
  Stack,
  Divider,
} from "@chakra-ui/react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlinePersonRemove, MdOutlinePersonAddAlt } from "react-icons/md";
import { useActiveUsersQuery } from "../../../api/user";
import {
  useSearchAssociatedUserCredentials,
  useDeleteAssociatedUserCredentials,
} from "../../../api/credentials";
import {
  useDeleteUserCredential,
  useUpdateUserCredential,
  useNewUserCredential,
  UserCredentialShowData,
} from "../../../api/user_credentials";
import { useGetUserCredentialUserIds } from "../../../api/credentials";
import { NewUserCredentialModal } from "./NewUserCredentialModal";
import { userCredentialStatusMap } from "../util";
import { useDebouncedSearch } from "../../hooks/useDebouncedSearch";
import { DateTime } from "luxon";
import { humanize, titleize } from "inflection";
import { SmallAddIcon } from "@chakra-ui/icons";

export const UserCredentialTable = ({ credential }) => {
  const { data: userCredentialUserIds } = useGetUserCredentialUserIds(credential?.id);
  const { onPagination, onOrder, order, pagination, filters, facets, onFacets } = useCollection({
    pagination: { per_page: 100 },
  });
  const { search, debouncedSearch } = useDebouncedSearch();
  const { mutate: deleteUserCredential } = useDeleteUserCredential(credential.id);
  const {
    mutate: deleteAssociatedUserCredentials,
    isLoading: isLoadingDeleteAssociatedUserCredentials,
  } = useDeleteAssociatedUserCredentials();
  const { mutate: updateUserCredential } = useUpdateUserCredential();
  const { data: users } = useActiveUsersQuery();
  const { mutate: newUserCredential, isLoading: isLoadingNewUserCredential } = useNewUserCredential(
    credential.id
  );
  const [activeUserCredential, setActiveUserCredential] = useState<UserCredentialShowData | null>(
    null
  );

  const allUsersToAssign = useMemo(
    () => users?.filter((ele) => !userCredentialUserIds?.includes(ele.id)).map((user) => user.id),
    [users, userCredentialUserIds]
  );

  const {
    isOpen: isOpenNewUserCredential,
    onOpen: onOpenNewUserCredential,
    onClose: onCloseNewUserCredential,
  } = useDisclosure();

  const {
    isOpen: isOpenUnassignUserCredential,
    onOpen: onOpenUnassignUserCredential,
    onClose: onCloseUnassignUserCredential,
  } = useDisclosure();

  const {
    isOpen: isOpenSetActiveUserCredential,
    onOpen: onOpenSetActiveUserCredential,
    onClose: onCloseSetActiveUserCredential,
  } = useDisclosure();

  const { data, isLoading } = useSearchAssociatedUserCredentials({
    id: credential.id,
    term: search || "*",
    aggs: ["status"],
    pagination,
    filters,
    order,
  });

  const populatedFacets = buildFacets(data?.aggregations || {}, facets);

  const columns = useMemo(
    () => [
      {
        label: "Employee",
        orderOptions: { orderKey: "user_name" },
        render: (userCredential: UserCredentialShowData) => (
          <Box textAlign="end">
            <RecordLink
              type=""
              identifier={userCredential.user.name}
              link={`/users/${userCredential.user.id}/profile`}
            />
          </Box>
        ),
      },
      {
        label: "Status",
        orderOptions: { orderKey: "status" },
        render: (userCredential: UserCredentialShowData) => (
          <Box textAlign="end">
            <Tag colorScheme={userCredentialStatusMap[userCredential.status]}>
              {titleize(humanize(userCredential.status))}
            </Tag>
          </Box>
        ),
      },
      {
        label: "Date Awarded",
        orderOptions: { orderKey: "date_issued" },
        render: (userCredential: UserCredentialShowData) => (
          <Text textAlign="end">
            {userCredential.date_issued ? userCredential.date_issued.toFormat("LLL dd yyyy") : "-"}
          </Text>
        ),
      },
      {
        label: "Expiration Date",
        orderOptions: { orderKey: "expiration_date" },
        render: (userCredential: UserCredentialShowData) => (
          <Text textAlign="end">
            {userCredential.expiration_date
              ? userCredential.expiration_date.toFormat("LLL dd yyyy")
              : "-"}
          </Text>
        ),
      },
      {
        label: "",
        render: (userCredential: UserCredentialShowData) => (
          <Box textAlign="end">
            <UserCredentialActions
              userCredential={userCredential}
              setActiveUserCredential={setActiveUserCredential}
              onOpenUnassignUserCredential={onOpenUnassignUserCredential}
              onOpenSetActiveUserCredential={onOpenSetActiveUserCredential}
            />
          </Box>
        ),
      },
    ],
    [data]
  );

  return (
    <>
      <VStack
        width="100%"
        align="start"
        spacing={4}
        border="1px"
        borderColor="chakra-border-color"
        borderRadius="md"
        p={4}>
        <Stack width="100%" direction={{ base: "column", md: "row" }} justify="space-between">
          <HStack>
            <Heading size="sm">Assigned Employees</Heading>
            <Badge>{data?.pagination.total || 0}</Badge>
          </HStack>
          <Stack direction={{ base: "column", md: "row" }} align="center">
            <HStack>
              <Searchbar
                placeholder="Search employees..."
                onChange={(query: string) => debouncedSearch(query)}
                onSelect={() => {}}
              />
            </HStack>
            <Stack direction="row">
              {!credential?.expiration_months && (
                <ConfirmationButton
                  label="Assign Credential to All Employees"
                  confirmationButtonLabel="Confirm"
                  variant="Button"
                  buttonVariant="ghost"
                  colorScheme="teal"
                  confirmationHeader="Assign Credential to All Employees"
                  children={
                    <>
                      Are you sure you want to assign{" "}
                      <Text as="span" fontWeight="bold">
                        {credential.name}
                      </Text>{" "}
                      to{" "}
                      <Text as="span" fontWeight="bold">
                        {allUsersToAssign?.length || 0}
                      </Text>{" "}
                      employees
                    </>
                  }
                  toolTipLabel="Assign this credential to all employees."
                  onConfirm={() =>
                    allUsersToAssign &&
                    newUserCredential({
                      credential_id: credential.id,
                      user_ids: allUsersToAssign,
                      date_issued: null,
                    })
                  }
                />
              )}
              {data?.results.length && (
                <ConfirmationButton
                  isDisabled={isLoadingDeleteAssociatedUserCredentials}
                  label="Unassign All"
                  buttonVariant="ghost"
                  variant="Button"
                  colorScheme="red"
                  confirmationButtonLabel="Yes"
                  children="Are you sure you want to unassign all the employees assigned to this credential? You can't undo this action."
                  onConfirm={() => deleteAssociatedUserCredentials(credential.id)}
                />
              )}
            </Stack>
            <Stack direction="row">
              <Facets
                facets={populatedFacets}
                onChange={onFacets}
                debouncedSearch={debouncedSearch}
                search
                background
                variant="button"
              />

              <IconButton
                icon={<SmallAddIcon />}
                onClick={() => onOpenNewUserCredential()}
                aria-label="add-employee"
              />
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <Box width="100%">
          <Collection
            columns={columns}
            items={data?.results ?? []}
            isLoading={
              isLoading || isLoadingDeleteAssociatedUserCredentials || isLoadingNewUserCredential
            }
            pagination={data?.pagination || pagination}
            onPagination={onPagination}
            order={order}
            onOrder={onOrder}
          />
        </Box>
      </VStack>
      {userCredentialUserIds && (
        <NewUserCredentialModal
          isOpen={isOpenNewUserCredential}
          onClose={onCloseNewUserCredential}
          credential={credential}
          userCredentialUserIds={userCredentialUserIds}
        />
      )}
      {activeUserCredential && (
        <>
          <ConfirmationModal
            colorScheme="red"
            isOpen={isOpenUnassignUserCredential}
            onClose={onCloseUnassignUserCredential}
            confirmText="Yes"
            header="Unassign Employee"
            children="Are you sure you want to unassign this employee from this credential?"
            onClick={() => deleteUserCredential(activeUserCredential.id)}
          />
          <ConfirmationModal
            colorScheme="teal"
            isOpen={isOpenSetActiveUserCredential}
            onClose={onCloseSetActiveUserCredential}
            confirmText="Set as Active"
            header="Set Credential as Active"
            children={`Set ${activeUserCredential.user.name}'s status as active for this credential?`}
            onClick={() =>
              updateUserCredential({
                id: activeUserCredential.id,
                status: "active",
                date_issued: DateTime.now(),
              })
            }
          />
        </>
      )}
    </>
  );
};

export const UserCredentialActions = ({
  userCredential,
  setActiveUserCredential,
  onOpenUnassignUserCredential,
  onOpenSetActiveUserCredential,
}) => {
  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={IconButton}
        icon={<Icon as={BsThreeDotsVertical} />}
        variant="ghost"
        size="sm"
      />
      <MenuList fontSize="md">
        {userCredential.status !== "active" && (
          <MenuItem
            icon={<Icon as={MdOutlinePersonAddAlt} boxSize={5} />}
            onClick={() => {
              setActiveUserCredential(userCredential);
              onOpenSetActiveUserCredential();
            }}>
            Set Status to Active
          </MenuItem>
        )}
        <MenuItem
          icon={<Icon as={MdOutlinePersonRemove} color="red.500" boxSize={5} />}
          onClick={() => {
            setActiveUserCredential(userCredential);
            onOpenUnassignUserCredential();
          }}>
          Unassign Employee
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

import React, { useRef } from "react";
import { IconButton } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useFileUpload } from "../../../hooks/useFileUpload";

export const FileUploadButton = ({ record, updateFunction, isLoading }) => {
  // Function to trigger the file input dialog
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const { handleFileChange } = useFileUpload({
    onUpload: (encodedFileObjects) =>
      updateFunction({ id: record.id, files: [...record.files, ...encodedFileObjects] }),
  });

  return (
    <>
      <IconButton
        size="sm"
        icon={<AddIcon />}
        onClick={handleClick}
        aria-label="Upload file"
        isLoading={isLoading}
      />
      <input
        type="file"
        ref={hiddenFileInput}
        multiple={true}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </>
  );
};

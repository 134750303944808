import React, { useState } from "react";
import { Box, Flex, Text, useColorModeValue, Heading, Stack, Spinner } from "@chakra-ui/react";
import { BadgeSelect, BadgeSelectOption } from "@sciencecorp/helix-components";
import { ServicePartialData, useUpdateService } from "../../../../api/services";

export type ServiceVisibilityProps = {
  service: ServicePartialData;
};

export const VisibilityOptions: BadgeSelectOption[] = [
  { label: "Public", value: "public", colorScheme: "blue" },
  { label: "Private: Team + Requestor", value: "team_only", colorScheme: "purple" },
  { label: "Private: Admin + Requestor", value: "private", colorScheme: "purple" },
];

export const ServiceVisibility = ({ service }: ServiceVisibilityProps) => {
  const [selectedOption, setSelectedOption] = useState(() => {
    const defaultOption = VisibilityOptions.find((option) => option.value === service?.visibility);
    return defaultOption || null;
  });
  const { mutateAsync: updateService, isLoading: isUpdatingService } = useUpdateService(
    Number(service?.id)
  );

  const handleOptionChange = (value: string) => {
    if (value) updateService({ id: Number(service?.id), visibility: value });
    setSelectedOption(VisibilityOptions.find((option) => option.value === value) || null);
  };

  const getSubtitle = () => {
    switch (selectedOption?.value) {
      case "public":
        return "Visible to all users.";
      case "team_only":
        return "Visible to the requesting user and fulfilling team only.";
      case "private":
        return "Visible to the requesting user and service admins only.";
      default:
        return "";
    }
  };

  return (
    <Box
      w={"100%"}
      border="1px"
      borderColor={useColorModeValue("gray.200", "gray.600")}
      p={6}
      gap={4}
      borderRadius="md">
      <Flex justifyContent="space-between" alignItems="center">
        <Stack gap={2}>
          <Heading size="md">Visibility</Heading>
          <Text color="gray.500">{getSubtitle()}</Text>
        </Stack>
        {!isUpdatingService ? (
          <Box zIndex={1000}>
            <BadgeSelect
              options={VisibilityOptions}
              selectedOption={selectedOption?.value || ""}
              handleOptionChange={handleOptionChange}
            />
          </Box>
        ) : (
          <Spinner />
        )}
      </Flex>
    </Box>
  );
};

import {
  FormControl,
  FormLabel,
  Text,
  Flex,
  HStack,
  Icon,
  Spacer,
  useColorModeValue,
  RadioGroup,
  VStack,
  Radio,
  Textarea,
  NumberInput,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputField,
  NumberInputStepper,
  Stack,
  useToast,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { EmptyState, FormModal } from "@sciencecorp/helix-components";
import React, { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { PurchaseLineItemData } from "../../../../api/purchase_line_item";
import { BsBoxSeam } from "react-icons/bs";
import {
  PurchaseReturnsShowData,
  useNewPurchaseReturn,
  useUpdatePurchaseReturn,
} from "../../../../api/purchase_returns";
import { useCurrentUserQuery } from "../../../../api/user";
import _ from "lodash";
import { isShippingOrTaxOrDiscount } from "../util";

type FormValuesReturnModalType = {
  itemQuantities: { [key: number]: number };
  reason_for_return: string;
  additional_information: string;
};

type CreatePurchaseReturnLineItemArguements = {
  purchase_line_item_id: number;
  quantity: number;
};

type ReturnModalProps = {
  isOpen: boolean;
  onClose: () => void;
  purchaseLineItems: PurchaseLineItemData[];
  selectedId?: number;
  previousReturns: PurchaseReturnsShowData[] | null;
};

export const ReturnModal = ({
  isOpen,
  onClose,
  purchaseLineItems,
  selectedId,
  previousReturns,
}: ReturnModalProps) => {
  const currentUser = useCurrentUserQuery();
  const lastOpenReturn = useMemo(
    () =>
      previousReturns && previousReturns.length
        ? _.last(previousReturns.filter((u) => u.service_request_id === null))
        : null,
    [previousReturns]
  );

  const { setValue, control, watch, reset } = useForm<FormValuesReturnModalType>({
    defaultValues: {
      itemQuantities: {},
      reason_for_return: "",
      additional_information: "",
    },
  });
  const formValues = watch();

  useEffect(() => {
    const lastOpenReturnLineItems = lastOpenReturn?.purchase_return_line_items || [];

    const orliQuantities: Record<number, number> = lastOpenReturnLineItems.reduce((acc, orli) => {
      acc[orli.purchase_line_item_id] = orli.quantity;
      return acc;
    }, {});

    const formQuantities: Record<number, number> = purchaseLineItems.reduce((acc, pli) => {
      if (acc[pli.id] === undefined) {
        if (selectedId === pli.id) {
          acc[pli.id] = pli.quantity;
        } else {
          acc[pli.id] = 0;
        }
      }

      return acc;
    }, orliQuantities);

    reset({
      itemQuantities: formQuantities,
      reason_for_return: lastOpenReturn?.reason_for_return || "",
      additional_information: lastOpenReturn?.additional_information || "",
    });
  }, [isOpen, lastOpenReturn, purchaseLineItems]);

  const toast = useToast();
  const { mutate: newPurchaseReturn, isLoading: isNewLoading } = useNewPurchaseReturn();
  const { mutate: updatePurchaseReturn, isLoading: isUpdateLoading } = useUpdatePurchaseReturn();

  const lightTealColor = useColorModeValue("teal.50", "teal.500");
  const darkTealColor = useColorModeValue("teal.200", "teal.400");

  const handleSubmit = (
    purchaseReturnFormData: FormValuesReturnModalType,
    currentUserId: number
  ) => {
    const returnLineItems: CreatePurchaseReturnLineItemArguements[] = purchaseLineItems
      .filter((item) => purchaseReturnFormData.itemQuantities[item.id] > 0)
      .map((item) => ({
        purchase_line_item_id: item.id,
        quantity: purchaseReturnFormData.itemQuantities[item.id],
      }));

    const purchaseReturnData = {
      purchase_id: purchaseLineItems[0].purchase_id,
      user_id: currentUserId,
      purchase_return_line_items: returnLineItems,
      reason_for_return: purchaseReturnFormData.reason_for_return,
      additional_information: purchaseReturnFormData.additional_information,
    };
    if (lastOpenReturn) {
      updatePurchaseReturn(
        {
          id: lastOpenReturn.id,
          ...purchaseReturnData,
        },
        {
          onSuccess: () => {
            onClose();
            toast({
              title: "You update the request to return item(s)",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
          },
        }
      );
    } else {
      newPurchaseReturn(purchaseReturnData, {
        onSuccess: () => {
          onClose();
          toast({
            title: "You created a request to return item(s)",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        },
      });
    }
  };

  purchaseLineItems = purchaseLineItems?.filter(
    (item) => !isShippingOrTaxOrDiscount.includes(item.item_name)
  );

  const disableSubmitButton =
    _.values(formValues.itemQuantities).every((quantity) => quantity === 0) ||
    !formValues.itemQuantities ||
    !formValues.reason_for_return ||
    (formValues.reason_for_return === "other" && formValues.additional_information === "");

  return (
    <FormModal
      title="Return Items"
      submitButtonColorSchema="teal"
      submitButtonTitle={lastOpenReturn ? "Update Request" : "Create Request"}
      size="2xl"
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={onClose}
      handleSubmit={() => {
        currentUser?.data?.id && handleSubmit(formValues, currentUser.data.id);
      }}
      submitButtonDisabled={disableSubmitButton || isNewLoading || isUpdateLoading}>
      <FormControl isRequired>
        <FormLabel>Specify the quantity of each item you would like to return.</FormLabel>
        {lastOpenReturn && (
          <Text fontSize={"sm"} marginTop={"-2"} marginBottom={"3"}>
            Items selected will be added on to the pending return
          </Text>
        )}
        <Flex direction="column" gap={4}>
          {purchaseLineItems.length > 0 ? (
            purchaseLineItems.map((lineItem) => {
              const hasQty = (formValues.itemQuantities[lineItem.id] || 0) > 0;
              return (
                <Stack
                  direction={["column", "row"]}
                  alignItems="center"
                  key={`line-item-${lineItem.id}`}
                  p={3}
                  bg={hasQty ? lightTealColor : "chakra-bg-color"}
                  spacing={6}
                  border="1px"
                  borderRadius="md"
                  borderColor={hasQty ? darkTealColor : "chakra-border-color"}>
                  <Icon as={BsBoxSeam} />
                  <Text fontSize="sm" fontWeight="medium" noOfLines={1}>
                    {lineItem.item_name}
                  </Text>
                  <Text width={"10%"} fontSize="sm" fontWeight={"bold"} marginLeft={-4}>
                    x {lineItem.quantity}
                  </Text>
                  <Spacer />
                  <Stack width={"20%"}>
                    <Controller
                      name={"itemQuantities"}
                      control={control}
                      rules={{ required: true, min: 1, max: lineItem.quantity }}
                      render={({ field }) => {
                        return (
                          <FormControl isRequired>
                            <HStack alignItems={"center"}>
                              <NumberInput
                                {...field}
                                allowMouseWheel={false}
                                value={field.value[lineItem.id]}
                                max={lineItem.quantity}
                                min={0}
                                clampValueOnBlur={true}
                                width={"max-content"}
                                onChange={(value) => {
                                  const newItemQuantities = { ...field.value };
                                  newItemQuantities[lineItem.id] = Number(value);
                                  setValue("itemQuantities", newItemQuantities);
                                }}>
                                <NumberInputField />
                                <NumberInputStepper>
                                  <NumberIncrementStepper />
                                  <NumberDecrementStepper />
                                </NumberInputStepper>
                              </NumberInput>
                            </HStack>
                          </FormControl>
                        );
                      }}
                    />
                  </Stack>
                </Stack>
              );
            })
          ) : (
            <EmptyState size="2xs" title="No purchase line items available to select" />
          )}
        </Flex>
      </FormControl>
      <Controller
        name="reason_for_return"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FormControl isRequired>
            <FormLabel>Reason for Return</FormLabel>
            <RadioGroup
              onChange={(value) => setValue("reason_for_return", value)}
              value={formValues.reason_for_return}
              colorScheme={"teal"}>
              <VStack direction={["column", "row"]} align="start" gap={1}>
                <Radio value="issue_with_item">Issue with Item</Radio>
                <Radio value="item_not_needed">Item not needed</Radio>
                <Radio value="wrong_item">Wrong Item received</Radio>
                <Radio value="other">Other</Radio>
              </VStack>
            </RadioGroup>
          </FormControl>
        )}
      />
      <Controller
        name="additional_information"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FormControl isRequired={formValues.reason_for_return === "other"}>
            <FormLabel>Additional Information</FormLabel>
            {lastOpenReturn && (
              <Alert marginBottom={"3"}>
                <AlertIcon />
                Any changes made here will update the existing return.
              </Alert>
            )}
            <Textarea
              placeholder="Type Here..."
              value={formValues.additional_information}
              onChange={(e) => setValue("additional_information", e.target.value)}
            />
          </FormControl>
        )}
      />
    </FormModal>
  );
};

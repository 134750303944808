interface FileObject {
  name: string;
  file: string;
}

interface UseFileUploadProps {
  onUpload: (files: FileObject[]) => void;
}

export const useFileUpload = ({ onUpload }: UseFileUploadProps) => {
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);

      const promises = files.map((file) => {
        return new Promise<FileObject>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.result) {
              resolve({
                name: file.name,
                file: reader.result as string,
              });
            } else reject(new Error("File couldn't be read"));
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });

      Promise.all(promises)
        .then((fileObjects) => onUpload(fileObjects))
        .catch((error) => console.error("Error reading files:", error));
    }
  };

  return {
    handleFileChange,
  };
};

import { EditableText, EmptyState, FormModal } from "@sciencecorp/helix-components";
import { Alert, AlertIcon, Box, Button, IconButton, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  TeamServiceCategory,
  TeamServiceCategoryParams,
  useCreateTeamServiceCategory,
  useDeleteTeamServiceCategory,
} from "../../../../api/team_service_category";

export type ManageCategoriesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  teamCategories: TeamServiceCategory[];
  teamId: number;
};

export const ManageCategoriesModal = ({
  isOpen,
  onClose,
  teamCategories,
  teamId,
}: ManageCategoriesModalProps) => {
  const [categories, setCategories] = useState<TeamServiceCategory[] | TeamServiceCategoryParams[]>(
    Array.isArray(teamCategories) ? teamCategories : []
  );
  const { mutateAsync: createTeamServiceCategory } = useCreateTeamServiceCategory(teamId);
  const { mutateAsync: deleteTeamServiceCategory, isLoading: isLoadingDeleteTeamServiceCategory } =
    useDeleteTeamServiceCategory(teamId);
  const [itemsToDelete, setItemsToDelete] = useState<number[]>([]);

  const handleClose = () => {
    onClose();
    setItemsToDelete([]);
    setCategories(Array.isArray(teamCategories) ? teamCategories : []);
  };
  const handleAdd = () => {
    setCategories([...categories, { name: "", team_id: teamId }]);
  };

  const onSubmit = () => {
    if (itemsToDelete.length > 0) {
      itemsToDelete.forEach((id) => {
        deleteTeamServiceCategory(id);
      });
    }
    if (categories.length > 0) {
      createTeamServiceCategory(categories).then(() => {
        setCategories([...categories]);
        handleClose();
      });
    } else {
      handleClose();
    }
  };

  return (
    <FormModal
      size="2xl"
      title="Manage Categories"
      isOpen={isOpen}
      closeOnOverlayClick={false}
      submitButtonColorSchema="teal"
      submitButtonTitle="Done"
      onClose={handleClose}
      handleSubmit={onSubmit}>
      <Text>
        Categories help you organize incoming requests based on principles you set as a team lead.
      </Text>
      {categories.length === 0 ? (
        <EmptyState title="No categories yet" size="xs" />
      ) : (
        <>
          {categories.map((category, index) => (
            <VStack key={category.id} width="100%">
              <Box
                padding={3}
                border="1px"
                borderColor="chakra-border-color"
                borderRadius="md"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%">
                <VStack alignItems="flex-start">
                  <EditableText
                    preview={category.name}
                    placeholder="Add a category name"
                    onSubmit={(value) => {
                      if (value) {
                        const newCategories = [...categories];
                        newCategories[index] = { ...newCategories[index], name: value };
                        setCategories(newCategories);
                      }
                    }}
                  />
                  {category.service_request_count && category.service_request_count > 0 && (
                    <Text fontSize="xs" color="gray.500">
                      {category.service_request_count} requests are associated with this category
                    </Text>
                  )}
                </VStack>
                <Box>
                  <IconButton
                    aria-label="delete team category"
                    icon={<DeleteIcon />}
                    onClick={() => {
                      setItemsToDelete([...itemsToDelete, category.id]);
                      setCategories(categories.filter((_, i) => i !== index));
                    }}
                  />
                </Box>
              </Box>
            </VStack>
          ))}
        </>
      )}

      {categories.length < 5 && (
        <Button size="sm" onClick={handleAdd}>
          Add Category
        </Button>
      )}
      {categories.length === 5 && (
        <Alert status="warning" title="You can only have 5 categories">
          <AlertIcon />
          You can only have 5 categories at a time.
        </Alert>
      )}
    </FormModal>
  );
};

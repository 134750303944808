import {
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Text,
  Stack,
  Textarea,
  useDisclosure,
  OrderedList,
  VStack,
  useColorModeValue,
  Box,
  Flex,
  ListItem,
  Icon,
} from "@chakra-ui/react";

import React, { useState } from "react";
import { WarningTwoIcon } from "@chakra-ui/icons";
import {
  decryptReview,
  newReview,
  ReviewMetaData,
  ReviewPromptPartialData,
  useUpdateReviewPrompts,
} from "../../api/review";
import { UserLoggedInData, useUserQuery } from "../../api/user";
import { DateTime } from "luxon";
import { newReviewFeedback } from "../../api/review_feedback";
import { SubmitPinModal } from "./components/SubmitPinModal";
import { OnboardingReviewTalkImage, OnboardingScale } from "./OnboardingImages";

type newReviewProps = {
  reviewPrompt: ReviewPromptPartialData;
  currentUser: UserLoggedInData;
  adminKeys: ReviewMetaData;
  reviewFeedbackQuery: () => void;
  onCreate: () => void;
};

const scoreMap = {
  "3": "green",
  "2": "yellow",
  "1": "orange",
  "0": "red",
};

export const NewReview = ({
  reviewPrompt,
  currentUser,
  adminKeys,
  onCreate,
  reviewFeedbackQuery,
}: newReviewProps) => {
  const { data: reviewee } = useUserQuery(reviewPrompt.reviewee.id);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate: updateReviewPrompt } = useUpdateReviewPrompts();
  const [score, setScore] = useState("");
  const [technicalScore, setTechnicalScore] = useState("");
  const [impactScore, setImpactScore] = useState("");
  const helperText =
    reviewPrompt.prompt_type === "self_review" || reviewPrompt.prompt_type === "lead"
      ? "Impactful achievements: \nAreas of opportunity: \nNotable contributions to the greater mission: \nGoal for the next month:"
      : "";
  const [anonymousFeedback, setAnonymousFeedback] = useState(helperText);
  const [confidentialFeedback, setConfidentialFeedback] = useState("");
  const isSelfReview =
    reviewPrompt.prompt_type === "self_review" && reviewPrompt.occasion === "30 Day"; // only show disclaimer for self reviews

  const closeModal = () => {
    setScore("");
    setTechnicalScore("");
    setImpactScore("");
    setAnonymousFeedback(helperText);
    setShowDisclaimer(isSelfReview);
    setDisclaimerPage(0);
    setConfidentialFeedback("");
    onClose();
  };

  const [decryptedContent, setDecryptedContent] = useState("");
  const [decryptionError, setDecryptionError] = useState(false);
  const [pin, setPin] = useState("");
  const [showDisclaimer, setShowDisclaimer] = useState(isSelfReview);
  const [disclaimerPage, setDisclaimerPage] = useState(0);
  const onboardingLead = currentUser.onboarding_lead_id
    ? useUserQuery(currentUser.onboarding_lead_id)
    : null;

  const submitPin = () => {
    setDecryptionError(false);
    if (reviewPrompt.parent_prompt) {
      decryptReview(reviewPrompt.parent_prompt.review, currentUser, pin)
        .then((decryptedReview) => {
          if (!decryptedReview) {
            setPin("");
            setDecryptionError(true);
            return;
          }
          setDecryptionError(false);
          setDecryptedContent(decryptedReview.anonymous_feedback);
        })
        .catch((err) => {
          console.error(err);
          setPin("");
          setDecryptionError(true);
        });
    }
  };
  const onboardingLeadKey = onboardingLead?.data?.public_key!;

  const reviewKeys = [...new Set([adminKeys.ceo_key, currentUser.public_key!])]; // peer reviews

  // all onboarding related review messages include HR admins, ceo, and related users
  const selfReviewKeys = onboardingLeadKey
    ? [...new Set([...reviewKeys, ...adminKeys.hr_admin_keys, onboardingLeadKey])]
    : [...new Set([...reviewKeys, ...adminKeys.hr_admin_keys])];
  const leadReviewKeys = [...new Set([...reviewKeys, ...adminKeys.hr_admin_keys])];
  const onboardingReviewFeedbackKeys = [...new Set([...leadReviewKeys, reviewee?.public_key!])];

  return (
    <>
      <Button size="sm" colorScheme="teal" py="0" variant="link" onClick={onOpen}>
        Submit Review
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        size={
          reviewPrompt.prompt_type === "lead" && reviewPrompt.parent_prompt && !decryptedContent
            ? "md"
            : reviewPrompt.prompt_type === "peer"
            ? "2xl"
            : showDisclaimer
            ? "lg"
            : "xl"
        }>
        <ModalOverlay />
        <ModalContent>
          {reviewPrompt.prompt_type === "lead" &&
          reviewPrompt.parent_prompt &&
          !decryptedContent ? (
            <>
              <ModalHeader pb={2}>PIN</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <SubmitPinModal
                  pin={pin}
                  setPin={setPin}
                  decryptionError={decryptionError}
                  submitPin={submitPin}
                />
              </ModalBody>
            </>
          ) : (
            <>
              <ModalHeader pb={4}>
                {reviewPrompt.prompt_type === "peer"
                  ? `Review ${reviewPrompt.reviewee.name}`
                  : reviewPrompt.prompt_type === "self_review"
                  ? "Self Review"
                  : `${reviewPrompt.occasion} Review for ${reviewPrompt.reviewee.name}`}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack spacing={4}>
                  {reviewPrompt.prompt_type === "peer" ? (
                    <>
                      <FormControl>
                        <FormLabel>
                          Given all of the knowledge you have today, if {reviewPrompt.reviewee.name}{" "}
                          was presently interviewing, how would you vote for their hire?
                        </FormLabel>
                        <RadioGroup value={score} onChange={setScore}>
                          <Stack>
                            <Radio value="3" colorScheme="green">
                              Strong Yes
                            </Radio>
                            <Radio value="2" colorScheme="yellow">
                              Yes
                            </Radio>
                            <Radio value="1" colorScheme="orange">
                              No
                            </Radio>
                            <Radio value="0" colorScheme="red">
                              Strong No
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      </FormControl>
                      <Divider />
                      <FormControl>
                        <FormHelperText>
                          Optional further feedback on {reviewPrompt.reviewee.name}
                        </FormHelperText>
                      </FormControl>

                      <Flex direction={["column", "row"]} alignItems="start">
                        <VStack spacing={4} minWidth={"250px"}>
                          <FormControl>
                            <FormLabel>Individual technical contribution (or equivalent)</FormLabel>
                            <RadioGroup value={technicalScore} onChange={setTechnicalScore}>
                              <Stack>
                                <Radio value="3" colorScheme="green">
                                  Exceptional
                                </Radio>
                                <Radio value="2" colorScheme="yellow">
                                  Strong
                                </Radio>
                                <Radio value="1" colorScheme="orange">
                                  Acceptable
                                </Radio>
                                <Radio value="0" colorScheme="red">
                                  Poor
                                </Radio>
                              </Stack>
                            </RadioGroup>
                          </FormControl>
                          <FormControl>
                            <FormLabel>Impact on others</FormLabel>
                            <RadioGroup value={impactScore} onChange={setImpactScore}>
                              <Stack>
                                <Radio value="3" colorScheme="green">
                                  Exceptional
                                </Radio>
                                <Radio value="2" colorScheme="yellow">
                                  Strong
                                </Radio>
                                <Radio value="1" colorScheme="orange">
                                  Acceptable
                                </Radio>
                                <Radio value="0" colorScheme="red">
                                  Poor
                                </Radio>
                              </Stack>
                            </RadioGroup>
                          </FormControl>
                        </VStack>
                        <Divider orientation="vertical" />
                        <VStack spacing={4} pt={[4, 0]} width={["auto", "50%"]}>
                          <FormControl>
                            <FormLabel>
                              Confidential feedback about {reviewPrompt.reviewee.name}
                            </FormLabel>
                            <Textarea
                              value={confidentialFeedback}
                              onChange={(e) => setConfidentialFeedback(e.target.value)}
                              rows={6}
                            />
                            <FormHelperText>
                              Your comments will be synthesized with others into a holistic picture
                              for the reviewee. No direct text from your feedback will make it to
                              the summary the reviewee receives.
                            </FormHelperText>
                          </FormControl>
                        </VStack>
                      </Flex>
                    </>
                  ) : reviewPrompt.prompt_type === "self_review" ? (
                    showDisclaimer ? (
                      <OnboardingReviewDisclamer disclaimerPage={disclaimerPage} />
                    ) : (
                      <>
                        <FormControl>
                          <FormLabel>
                            How would you describe your performance at this time?
                          </FormLabel>
                        </FormControl>
                        <Textarea
                          height={40}
                          placeholder="Write about your achievements, areas of opportunity, and contributions to the greater mission."
                          value={anonymousFeedback}
                          onChange={(e) => setAnonymousFeedback(e.target.value)}
                        />
                      </>
                    )
                  ) : (
                    <>
                      {reviewPrompt.parent_prompt ? (
                        <Stack
                          width="100%"
                          bg={useColorModeValue("teal.50", "gray.600")}
                          p={5}
                          border="1px"
                          borderRadius="md"
                          borderColor={useColorModeValue("teal.200", "gray.500")}>
                          <Heading size="sm">{reviewPrompt.reviewee.name}'s Self Review </Heading>
                          {decryptedContent.split("\n").map((line, key) => (
                            <Text key={key} fontSize="sm">
                              {line}
                            </Text>
                          ))}
                        </Stack>
                      ) : (
                        <HStack width="100%" p={5} bg={useColorModeValue("gray.50", "gray.600")}>
                          <WarningTwoIcon color="orange.500" />
                          <Text fontSize="sm">
                            {reviewPrompt.reviewee.name} has not submitted the self review form.
                          </Text>
                        </HStack>
                      )}
                      <FormLabel>
                        Given all of the knowledge you have today, if {reviewPrompt.reviewee.name}{" "}
                        was presently interviewing, how would you vote for their hire?
                      </FormLabel>
                      <RadioGroup value={score} onChange={setScore} width="100%">
                        <Stack>
                          <Radio value="3" colorScheme="green">
                            Strong Yes
                          </Radio>
                          <Radio value="2" colorScheme="yellow">
                            Yes
                          </Radio>
                          <Radio value="1" colorScheme="orange">
                            No
                          </Radio>
                          <Radio value="0" colorScheme="red">
                            Strong No
                          </Radio>
                        </Stack>
                      </RadioGroup>
                      <FormControl>
                        <FormLabel>
                          How would you describe {reviewPrompt.reviewee.name}'s performance at this
                          time?
                        </FormLabel>
                      </FormControl>
                      <Textarea
                        height={40}
                        placeholder="Type here..."
                        value={anonymousFeedback}
                        onChange={(e) => setAnonymousFeedback(e.target.value)}
                      />
                    </>
                  )}
                </VStack>
              </ModalBody>
              <ModalFooter>
                {showDisclaimer ? (
                  <ButtonGroup width="100%" justifyContent="end">
                    {disclaimerPage !== 0 && (
                      <Button
                        width="50%"
                        justifySelf="flex-end"
                        onClick={() => setDisclaimerPage((prev) => --prev)}>
                        Previous
                      </Button>
                    )}
                    {disclaimerPage !== 2 ? (
                      <Button
                        width="50%"
                        justifySelf="flex-end"
                        colorScheme="teal"
                        onClick={() => setDisclaimerPage((prev) => ++prev)}>
                        Next
                      </Button>
                    ) : (
                      <Button
                        width="50%"
                        colorScheme="teal"
                        onClick={() => setShowDisclaimer(false)}>
                        I Understand
                      </Button>
                    )}
                  </ButtonGroup>
                ) : (
                  <ButtonGroup>
                    {reviewPrompt.prompt_type === "peer" ? (
                      <>
                        <Button
                          size="sm"
                          onClick={() => {
                            updateReviewPrompt({
                              id: reviewPrompt.id,
                              declined_at: DateTime.now().toISO(),
                            });
                            onCreate();
                          }}>
                          Decline
                        </Button>
                        <Button
                          colorScheme="teal"
                          size="sm"
                          isDisabled={score === ""}
                          onClick={async () => {
                            await newReview({
                              reviewPromptId: reviewPrompt.id,
                              score: parseInt(score),
                              anonymousFeedback,
                              publicKeys: reviewKeys,
                              type: "Peer Review",
                              technicalScore: parseInt(technicalScore),
                              impactScore: parseInt(impactScore),
                              confidentialFeedback: confidentialFeedback,
                            });
                            onCreate();
                          }}>
                          Submit
                        </Button>
                      </>
                    ) : (
                      <Button
                        colorScheme="teal"
                        size="sm"
                        isDisabled={anonymousFeedback === helperText}
                        onClick={async () => {
                          if (reviewPrompt.prompt_type === "self_review") {
                            await newReview({
                              reviewPromptId: reviewPrompt.id,
                              score: parseInt(score),
                              anonymousFeedback: anonymousFeedback,
                              publicKeys: selfReviewKeys,
                              type: "Self Review",
                            });
                          } else if (reviewPrompt.prompt_type === "lead" && reviewPrompt.occasion) {
                            await newReview({
                              reviewPromptId: reviewPrompt.id,
                              score: parseInt(score),
                              anonymousFeedback: anonymousFeedback,
                              publicKeys: leadReviewKeys,
                              type: "Lead Review",
                            });

                            const months =
                              reviewee?.joined_at &&
                              Math.round(
                                DateTime.now().diff(DateTime.fromISO(reviewee?.joined_at), "months")
                                  .months
                              );

                            if (months && reviewee?.joined_at) {
                              await newReviewFeedback({
                                revieweeId: currentUser.id,
                                content: {
                                  self_review: decryptedContent,
                                  lead_review: anonymousFeedback,
                                },
                                score: scoreMap[score],
                                keys: onboardingReviewFeedbackKeys,
                                keyringMessageType: "Onboarding Review",
                                type: "onboarding",
                                send_at: DateTime.fromISO(reviewee.joined_at)
                                  .plus({ months: months })
                                  .toString(),
                                occasion: reviewPrompt.occasion,
                                onboarding_user_id: reviewPrompt.reviewee.id,
                              });
                              reviewFeedbackQuery();
                            }
                          }
                          onCreate();
                        }}>
                        Submit
                      </Button>
                    )}
                  </ButtonGroup>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

const OnboardingReviewDisclamer = ({ disclaimerPage }) => {
  switch (disclaimerPage) {
    case 0:
      return (
        <VStack width="100%" spacing={9}>
          <Text
            fontSize="5xl"
            lineHeight={10}
            p={6}
            border="1px"
            borderRadius="full"
            borderColor={useColorModeValue("green.200", "gray.300")}
            bg={useColorModeValue("green.50", "gray.500")}>
            🎉
          </Text>
          <VStack width="100%" align="start" pb={24}>
            <Heading size="sm">Congratulations on your one month anniversary!</Heading>
            <Text>
              As you embark on your journey at Science, we have structured{" "}
              <Box as="span" fontWeight="bold">
                30/60/90 day reviews
              </Box>{" "}
              to help both you and your lead reflect, give feedback, and set clear goals.
            </Text>
          </VStack>
          <VStack width="100%" spacing={4}>
            <Flex gap={2}>
              <Box bg="teal.500" boxSize={3} borderRadius="full"></Box>
              <Box bg="gray.200" boxSize={3} borderRadius="full"></Box>
              <Box bg="gray.200" boxSize={3} borderRadius="full"></Box>
            </Flex>
          </VStack>
        </VStack>
      );
    case 1:
      return (
        <VStack width="100%" spacing={9}>
          <Icon as={OnboardingReviewTalkImage} boxSize={40} />
          <VStack width="100%" align="start">
            <Heading size="sm">How does it work?</Heading>
            <OrderedList px={4} spacing={8} fontSize="md">
              <ListItem>
                <Box as="span" fontWeight="semibold">
                  Reflection:
                </Box>{" "}
                Think about your achievements, potential areas for growth, and contributions to our
                mission. You will fill a form to document this.{" "}
                <Box as="span" fontWeight="bold">
                  Please complete your self review before meeting with your lead.
                </Box>
              </ListItem>
              <ListItem>
                <Box as="span" fontWeight="semibold">
                  Feedback:
                </Box>{" "}
                During the reviews, your lead will also provide feedback. They might add notes
                during or post the discussion.
              </ListItem>
            </OrderedList>
          </VStack>
          <VStack width="100%" spacing={4}>
            <Flex gap={2}>
              <Box bg="gray.200" boxSize={3} borderRadius="full"></Box>
              <Box bg="teal.500" boxSize={3} borderRadius="full"></Box>
              <Box bg="gray.200" boxSize={3} borderRadius="full"></Box>
            </Flex>
          </VStack>
        </VStack>
      );
    case 2:
      return (
        <VStack width="100%" spacing={9}>
          <Icon as={OnboardingScale} boxSize={40} />
          <VStack width="100%" align="start">
            <Heading size="sm">How does it work?</Heading>
            <OrderedList px={4} spacing={8} fontSize="md" start={3}>
              <ListItem>
                <Box as="span" fontWeight="semibold">
                  Setting Goals:
                </Box>{" "}
                Based on your discussion and the team's priorities, you and your lead will set
                actionable and measurable goals to revisit in the next review.
              </ListItem>
              <ListItem>
                <Box as="span" fontWeight="semibold">
                  Your Input:
                </Box>{" "}
                This review isn't just about you; it's also for you. Feel free to share feedback on
                how your lead can support you better.
              </ListItem>
            </OrderedList>
          </VStack>
          <VStack width="100%" spacing={4}>
            <Flex gap={2}>
              <Box bg="gray.200" boxSize={3} borderRadius="full"></Box>
              <Box bg="gray.200" boxSize={3} borderRadius="full"></Box>
              <Box bg="teal.500" boxSize={3} borderRadius="full"></Box>
            </Flex>
          </VStack>
        </VStack>
      );
    default:
      return null;
  }
};

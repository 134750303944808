import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  VStack,
  Flex,
  HStack,
  Stat,
  StatNumber,
  StatLabel,
  StatGroup,
  Text,
  Heading,
  Input,
  Spinner,
  Divider,
  Link,
  Avatar,
  Tag,
  ButtonGroup,
  Button,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import {
  useGetPurchaseDashboardGroupedStatistics,
  useGetPurchaseDashboardTimingStatistics,
} from "../../../api/purchase";
import { DateTime } from "luxon";
import { RecordLink, Select } from "@sciencecorp/helix-components";

import { Pie } from "react-chartjs-2";
import { FlaggedPurchases } from "./FlaggedPurchases";
import { VendorStatisticsGraph } from "./VendorStatisticsGraph";

export const formatDaysToReadable = (days: number | null | undefined) => {
  if (!days) return "N/A";
  const absoluteDays = Math.abs(days);
  const wholeDays = Math.floor(absoluteDays);
  const remainingHours = Math.round((absoluteDays - wholeDays) * 24);

  if (wholeDays === 0) {
    return `${remainingHours}h`;
  }
  return `${wholeDays} days ${remainingHours} hrs`;
};

const validDate = (date: DateTime) => {
  if (!date.isValid) return false;

  const minDate = DateTime.fromObject({ year: 2020 });
  const now = DateTime.now();

  return date >= minDate && date <= now;
};

export const PurchasingDashboard = () => {
  const [startDate, setStartDate] = useState(DateTime.now().startOf("year").toFormat("yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(DateTime.now().toFormat("yyyy-MM-dd"));
  const [debouncedStartDate, setDebouncedStartDate] = useState(startDate);
  const [debouncedEndDate, setDebouncedEndDate] = useState(endDate);
  const [purchaseType, setPurchaseType] = useState("all");
  const [urgentRequestFilter, setUrgentRequestFilter] = useState("user");
  const [vendorGraphType, setVendorGraphType] = useState("count");
  // Create debounced setters
  const debouncedSetStartDate = useCallback(
    debounce((value: string) => setDebouncedStartDate(value), 500),
    []
  );

  const debouncedSetEndDate = useCallback(
    debounce((value: string) => setDebouncedEndDate(value), 500),
    []
  );

  // Update the query to use debounced values
  const { data: groupedStatistics, isLoading: isLoadingGroupedStatistics } =
    useGetPurchaseDashboardGroupedStatistics(
      purchaseType === "all" ? null : purchaseType,
      validDate(DateTime.fromISO(debouncedStartDate)) ? DateTime.fromISO(debouncedStartDate) : null,
      validDate(DateTime.fromISO(debouncedEndDate)) ? DateTime.fromISO(debouncedEndDate) : null
    );

  const { data: timingStatistics, isLoading: isLoadingTimingStatistics } =
    useGetPurchaseDashboardTimingStatistics(
      purchaseType === "all" ? null : purchaseType,
      validDate(DateTime.fromISO(debouncedStartDate)) ? DateTime.fromISO(debouncedStartDate) : null,
      validDate(DateTime.fromISO(debouncedEndDate)) ? DateTime.fromISO(debouncedEndDate) : null
    );

  // Update Input handlers
  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
    debouncedSetStartDate(e.target.value);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
    debouncedSetEndDate(e.target.value);
  };

  const legendColor = useColorModeValue("black", "white");

  return (
    <Flex direction="column" w="100%" gap={4}>
      <Stack justify="space-between" direction={{ base: "column", md: "row" }}>
        <Heading size="lg">Dashboard</Heading>
        <Stack direction={{ base: "column", md: "row" }}>
          <HStack>
            <Text w="max-content">Start Date: </Text>
            <Input type="date" value={startDate} onChange={handleStartDateChange} />
          </HStack>
          <HStack>
            <Text>End Date: </Text>
            <Input type="date" value={endDate} onChange={handleEndDateChange} />
          </HStack>
          <HStack>
            <Text>Type</Text>
            <Select
              value={purchaseType}
              onChange={(value) => setPurchaseType(value.toString())}
              options={[
                { value: "all", label: "All" },
                { value: "purchase_request", label: "Purchase Requests" },
                { value: "purchase_order", label: "Purchase Orders" },
              ]}
              placeholder="Select Purchase Type"
            />
          </HStack>
        </Stack>
      </Stack>
      <Flex
        direction="column"
        gap={4}
        border="1px"
        borderColor="chakra-border-color"
        borderRadius="md"
        p={4}>
        <Heading size="md">Summary</Heading>
        <StatGroup flexDirection={{ base: "column", lg: "row" }}>
          <Stat>
            <StatNumberLoading
              number={timingStatistics?.purchase_count_by_status.created_count}
              isLoading={isLoadingGroupedStatistics}
            />
            <StatLabel>purchase requests created</StatLabel>
          </Stat>

          <Stat>
            <StatNumberLoading
              number={timingStatistics?.purchase_count_by_status.needs_approval_count}
              isLoading={isLoadingGroupedStatistics}
            />
            <StatLabel>
              in{" "}
              <Tag colorScheme="red" size="sm">
                needs approval
              </Tag>{" "}
              state
            </StatLabel>
          </Stat>

          <Stat>
            <StatNumberLoading
              number={timingStatistics?.purchase_count_by_status.awaiting_purchase_count}
              isLoading={isLoadingGroupedStatistics}
            />
            <StatLabel>
              in{" "}
              <Tag colorScheme="orange" size="sm">
                awaiting purchase
              </Tag>{" "}
              state
            </StatLabel>
          </Stat>
          <Stat>
            <StatNumberLoading
              number={timingStatistics?.purchase_count_by_status.completed_count}
              isLoading={isLoadingGroupedStatistics}
            />
            <StatLabel>
              marked{" "}
              <Tag colorScheme="green" size="sm">
                delivered
              </Tag>
            </StatLabel>
          </Stat>
        </StatGroup>
        <StatGroup flexDirection={{ base: "column", lg: "row" }}>
          <Stat>
            <StatNumberLoading
              number={formatDaysToReadable(timingStatistics?.average_time_for_approval)}
              isLoading={isLoadingTimingStatistics}
            />
            <StatLabel>Average Time for Approval</StatLabel>
          </Stat>

          <Stat>
            <StatNumberLoading
              number={formatDaysToReadable(timingStatistics?.average_time_approved_to_ordered)}
              isLoading={isLoadingTimingStatistics}
            />
            <StatLabel>Average Time Approved to Ordered</StatLabel>
          </Stat>

          <Stat>
            <StatNumberLoading
              number={formatDaysToReadable(timingStatistics?.average_time_ordered_to_delivered)}
              isLoading={isLoadingTimingStatistics}
            />
            <StatLabel>Average Time Ordered To Delivered</StatLabel>
          </Stat>
        </StatGroup>
      </Flex>
      <Flex
        direction="column"
        gap={4}
        border="1px"
        borderColor="chakra-border-color"
        borderRadius="md"
        p={6}>
        <FlaggedPurchases startDate={debouncedStartDate} endDate={debouncedEndDate} />
      </Flex>
      <Flex gap={4} direction={{ base: "column", lg: "row" }}>
        <Box p={4} border="1px" borderColor="chakra-border-color" borderRadius="md" flex="1">
          <Heading size="md" mb={10}>
            # Orders by Shipping Priority
          </Heading>
          <VStack w="100%" gap={4} mt={3}>
            {isLoadingGroupedStatistics ? (
              <Spinner />
            ) : (
              groupedStatistics?.shipping_speed_breakdown && (
                <Box w="100%" h="300px">
                  <Pie
                    data={{
                      labels: ["Expedited", "Not Available", "Overnight", "Standard"],
                      datasets: [
                        {
                          data: [
                            groupedStatistics.shipping_speed_breakdown.expedited_count,
                            groupedStatistics.shipping_speed_breakdown.not_available_count,
                            groupedStatistics.shipping_speed_breakdown.overnight_count,
                            groupedStatistics.shipping_speed_breakdown.standard_count,
                          ],
                          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
                          borderColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
                          borderWidth: 1,
                        },
                      ],
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          position: "bottom",
                          labels: {
                            color: legendColor,
                          },
                        },
                      },
                    }}
                  />
                </Box>
              )
            )}
          </VStack>
        </Box>
        <Flex
          direction="column"
          flex="1"
          gap={4}
          p={4}
          border="1px"
          borderColor="chakra-border-color"
          borderRadius="md">
          <HStack justify="space-between">
            <Heading size="md"># Urgent Requests</Heading>
            <Select
              options={[
                { label: "By User", value: "user" },
                { label: "By Budget", value: "budget" },
              ]}
              value={urgentRequestFilter}
              onChange={(value) => setUrgentRequestFilter(value.toString())}
            />
          </HStack>
          <Divider />
          <VStack>
            {isLoadingGroupedStatistics ? (
              <Spinner />
            ) : urgentRequestFilter === "user" ? (
              groupedStatistics?.shipping_urgency_by_user.map((user) => (
                <>
                  <HStack w="100%" justify="space-between">
                    <Link as={RouterLink} to={user.user.app_href}>
                      <Avatar src={user?.user.picture_uri} size="sm" />
                      <Text as={"span"} fontWeight={"semibold"} fontSize="sm">
                        {" "}
                        {user?.user.name}{" "}
                      </Text>
                    </Link>
                    <Text fontSize="sm">{user.count} orders</Text>
                  </HStack>
                  <Divider />
                </>
              ))
            ) : (
              groupedStatistics?.shipping_urgency_by_source_of_auth.map((spendAuth) => (
                <>
                  <HStack w="100%" justify="space-between">
                    <RecordLink
                      type={spendAuth.source_of_authority.type}
                      identifier={spendAuth.source_of_authority.name}
                      link={spendAuth.source_of_authority.app_href}
                    />
                    <Text fontSize="sm">{spendAuth.count} orders</Text>
                  </HStack>
                  <Divider />
                </>
              ))
            )}
          </VStack>
        </Flex>
      </Flex>
      <Flex
        direction="column"
        gap={4}
        border="1px"
        borderColor="chakra-border-color"
        borderRadius="md"
        p={4}>
        <HStack justify="space-between">
          <Heading size="md">Top Vendors</Heading>
          <ButtonGroup spacing={0.5} size="sm">
            <Button
              borderRightRadius={0}
              onClick={() => setVendorGraphType("count")}
              colorScheme={vendorGraphType === "count" ? "teal" : "gray"}>
              Total Number of Orders
            </Button>
            <Button
              borderLeftRadius={0}
              colorScheme={vendorGraphType === "lifespan" ? "teal" : "gray"}
              onClick={() => setVendorGraphType("lifespan")}>
              Avg Purchase Lifespan
            </Button>
          </ButtonGroup>
        </HStack>
        <Divider />

        {isLoadingGroupedStatistics ? (
          <Spinner />
        ) : (
          <VendorStatisticsGraph groupedStatistics={groupedStatistics} filter={vendorGraphType} />
        )}
      </Flex>
    </Flex>
  );
};

const StatNumberLoading = ({
  number,
  isLoading,
}: {
  number?: number | string;
  isLoading: boolean;
}) => {
  return <StatNumber>{isLoading ? <Spinner size="sm" /> : number}</StatNumber>;
};

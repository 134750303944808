import { z } from "zod";
import { userMinimalSchema } from "./user";

export const baseServiceUserSchema = z.object({
  id: z.number(),
  service_id: z.number(),
  user: z.lazy(() => userMinimalSchema),
});
export const serviceAssignedUserSchema = baseServiceUserSchema.extend({
  type: z.literal("ServiceAssignedUser"),
});
export const serviceSubscribedUserSchema = baseServiceUserSchema.extend({
  type: z.literal("ServiceSubscribedUser"),
});

export const serviceRequestSubscriberSchema = z.object({
  id: z.number(),
  user: z.lazy(() => userMinimalSchema),
});

export const serviceUserSchema = z.discriminatedUnion("type", [
  serviceAssignedUserSchema,
  serviceSubscribedUserSchema,
]);

export const serviceUserBulkCreateSchema = z.object({
  service_id: z.number(),
  user_ids: z.array(z.number()),
});

export const serviceRequestSubscriberBulkCreateSchema = z.object({
  service_request_id: z.number(),
  user_ids: z.array(z.number()),
});

export type ServiceUser = z.infer<typeof serviceUserSchema>;
export type ServiceUserCreateParams = z.infer<typeof serviceUserBulkCreateSchema>;
export type ServiceRequestSubscriberCreateParams = z.infer<
  typeof serviceRequestSubscriberBulkCreateSchema
>;
export type ServiceRequestSubscriber = z.infer<typeof serviceRequestSubscriberSchema>;
